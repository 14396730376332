import { useThis } from "../../assets/context/Context";
import { styled, Tooltip, Zoom } from "@mui/material";
import Orientation from "../components/Orientation";
import CloseIcon from "@mui/icons-material/Close";
import AvatarUI from "../components/AvatarUI";
import v1 from "../../images/v1.svg";
import v2 from "../../images/v2.svg";
import v3 from "../../images/v3.svg";
import v4 from "../../images/v4.svg";
import v5 from "../../images/v5.svg";
import v6 from "../../images/v6.svg";
import "../../css/background.css";
import Home from "./Home";

/**
 * @name Background
 * @description Muestra una pantalla con el fondo de inicio
 * @param {ReactElement} component
 * @param {String} text
 * @returns View
 * @version 1.0
 */
const Background = ({ component, text }) => {
  const { yearText, user, lang } = useThis();
  const css = styles(yearText);

  /**
   * @name closeSystem
   * @description Método encargado de cerrar la ventana del navegador
   * @version 1.0
   */
  const closeSystem = () => window.electronContext && window.electronContext.closeWindow();

  return (
    <div id="sky" style={css.sky}>
      {!user && window.electronContext && typeof window.electronContext.isElectron !== "undefined" && (
        <Tooltip TransitionComponent={Zoom} title={lang.close}>
          <Close onClick={closeSystem}>
            <CloseIcon sx={css.icon} />
          </Close>
        </Tooltip>
      )}
      {component.type !== Home && (
        <div>
          <AvatarUI backgroundColor="#FFFFFF" colorText="#444444" />
          <div className="cloud x1"></div>
          <div className="cloud x2"></div>
          <div className="cloud x3"></div>
          <div className="cloud x4"></div>
          <div className="cloud x5"></div>
          <div className="cloud x6"></div>
          <img src={v1} className="vegetable y1 selectDisable" alt="V1" />
          <img src={v2} className="vegetable y2 selectDisable" alt="V2" />
          <img src={v3} className="vegetable y3 selectDisable" alt="V3" />
          <img src={v4} className="vegetable y4 selectDisable" alt="V4" />
          <img src={v5} className="vegetable y5 selectDisable" alt="V5" />
          <img src={v6} className="vegetable y6 selectDisable" alt="V6" />
        </div>
      )}
      <div style={css.container}>{component}</div>
      <p style={css.texto}>{text}</p>
      <Orientation />
    </div>
  );
};

/**
 * @name Close
 * @description Agrega estilo personalizado a la etiqueta div de cierre de ventana
 * @version 1.0
 */
const Close = styled("div")({
  position: "absolute",
  top: "0px",
  right: "0px",
  width: "40px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#444",
  justifyContent: "center",
  "&:hover": { cursor: "pointer" },
});

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} yearText
 * @returns Object
 */
const styles = (yearText) => {
  return {
    icon: { color: "#FFF" },
    sky: { overflow: "hidden" },
    container: { width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" },
    texto: { position: "absolute", bottom: "5px", width: "100%", textAlign: "center", fontSize: "12px", display: yearText },
  };
};

export default Background;
