import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import LoadingButton from "../components/LoadingButton";
import { useThis } from "../../assets/context/Context";
import DialogTitle from "@mui/material/DialogTitle";
import getConstant from "../../assets/js/Constant";
import CloseIcon from "@mui/icons-material/Close";
import LoadingBar from "../components/LoadingBar";
import Message from "../components/Message";
import Dialog from "@mui/material/Dialog";
import Label from "../components/Label";

/** Inicialización de variables */
const constant = getConstant();

/**
 * @name Modal
 * @description Método que devuelve un componente de tipo modal, genérico.
 * @param {Object} - { open, setOpen, clickBtnOk, content, title, btnText, icon, loading, snack, setSnack, callBackHandleClose, color, colorBar, showDeleteBtn, clickBtnDelete, deleteBtnText }
 * @returns View
 * @version 1.0
 */
const Modal = ({
  open,
  icon,
  snack,
  title,
  setOpen,
  content,
  btnText,
  loading,
  setSnack,
  reference,
  clickBtnOk,
  showAppBar = true,
  deleteBtnText = "",
  showActionBar = true,
  colorBar = "success",
  showDeleteBtn = false,
  clickBtnDelete = () => {},
  color = constant.primaryColor,
  callBackHandleClose = () => {},
}) => {
  const { lang } = useThis();
  const css = styles(color, showDeleteBtn);

  /**
   * @name handleClose
   * @description Método encargado de cerrar el modal
   */
  const handleClose = () => {
    setSnack([]);
    setOpen(false);
    callBackHandleClose();
  };

  return (
    <Dialog sx={css.dialog} open={open} scroll="paper">
      {showAppBar && (
        <DialogTitle sx={css.header}>
          <div style={css.appBar}>
            {icon}
            <Label text={title} color="#FFF" margin="0 0 0 10px" />
          </div>
          <Tooltip TransitionComponent={Zoom} title={lang.close}>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={css.icon} />
            </IconButton>
          </Tooltip>
        </DialogTitle>
      )}
      <DialogContent sx={css.content} dividers={true}>
        {content}
      </DialogContent>
      {showActionBar && (
        <>
          <LoadingBar visible={loading} color={colorBar} />
          <DialogActions sx={css.dialogAction}>
            {showDeleteBtn && (
              <LoadingButton text={deleteBtnText} handleClick={clickBtnDelete} icon={<DeleteForeverIcon />} color={constant.deleteColor} />
            )}
            <LoadingButton reference={reference} text={btnText} width="100%" handleClick={clickBtnOk} icon={icon} color={color} />
          </DialogActions>
        </>
      )}
      <Message snack={snack}></Message>
    </Dialog>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} color
 * @param {Boolean} showDeleteBtn
 * @returns Object
 */
const styles = (color, showDeleteBtn) => {
  return {
    icon: { color: "#FFF" },
    dialog: { marginTop: "30px" },
    content: { overflowX: "hidden" },
    appBar: { display: "flex", alignItems: "center" },
    dialogAction: { display: "flex", justifyContent: showDeleteBtn ? "space-between" : "right" },
    header: { background: color, color: "#FFF", display: "flex", alignItems: "center", justifyContent: "space-between" },
  };
};

export default Modal;
