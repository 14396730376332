import { formatNumber } from "../../../../assets/js/Commons";
import { useThis } from "../../../../assets/context/Context";
import PlaceIcon from "@mui/icons-material/Place";
import { useEffect } from "react";
import DOMPurify from "dompurify";
import Label from "../../Label";

/** Variables globales */
const icon = <PlaceIcon color="info" sx={{ fontSize: "30px" }} />;

/**
 * @name AddExpressOrder
 * @description Método que maneja el contenido del modal para agregar el express a la orden
 * @param {Object} { setOrder, menu, expressMessage, edit }
 * @returns View
 * @version 1.0
 */
const AddExpressOrder = ({ setOrder, menu, expressMessage, edit }) => {
  const css = styles();
  const { lang, currency } = useThis();

  /**
   * @description Elimina el <script> malicioso
   */
  const secureMessage = DOMPurify.sanitize(lang.expressCost.replace("[COST]", currency.symbol + formatNumber(menu.finalPrice)));

  /** Efecto que maneja el seteo del id del producto */
  useEffect(() => {
    setOrder({ ...menu, amount: 1, detail: "", requestKitchen: false, id: edit ? menu.id : "" });
  }, []);

  return (
    <div style={css.constainer}>
      <Label margin="0 0 10px 0" text={expressMessage} />
      <Label margin="0 0 0 10px" icon={icon} text={menu.name} />
      <p dangerouslySetInnerHTML={{ __html: secureMessage }} />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns {Object}
 * @version 1.0
 */
const styles = () => {
  return {
    constainer: { width: "300px" },
  };
};

export default AddExpressOrder;
