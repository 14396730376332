import { Tooltip, Zoom } from "@mui/material";

/**
 * @name Label
 * @description Método que devuelve un componente de tipo Label (texto con o sin ícono)
 * @param {String} text
 * @param {String} fontSize
 * @param {String} margin
 * @param {String} width
 * @param {String} fontWeight
 * @param {String} textAlign
 * @param {String} color
 * @param {String} whiteSpace
 * @param {Component} icon
 * @param {String} tooltip
 * @param {Boolean} iconRight
 * @returns View
 * @version 1.0
 */
const Label = ({
  text,
  margin,
  width,
  fontSize,
  textAlign,
  fontWeight,
  whiteSpace,
  icon = null,
  tooltip = "",
  iconRight = true,
  color = "#4b4b4b",
  justifyContent = "start",
}) => {
  const css = styles(icon, iconRight, justifyContent);

  return (
    <div style={css.container}>
      {icon && (
        <div style={{ ...css.icon, order: iconRight ? 1 : 2 }}>
          <Tooltip TransitionComponent={Zoom} title={tooltip}>
            <span>{icon}</span>
          </Tooltip>
        </div>
      )}
      <p style={{ fontSize, color, margin, width, fontWeight, textAlign, whiteSpace, order: iconRight ? 2 : 1 }}>{text}</p>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Component} icon
 * @param {Boolean} iconRight
 * @param {String} justifyContent
 * @returns Object
 * @version 1.0
 */
const styles = (icon, iconRight, justifyContent) => {
  return {
    container: { display: icon ? "flex" : "block", alignItems: "center", justifyContent },
    icon: { marginRight: iconRight ? "3px" : "0px", marginTop: "4px", cursor: "pointer", marginLeft: iconRight ? "0px" : "3px" },
  };
};

export default Label;
