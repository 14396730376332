import { Box, IconButton, ToggleButton, ToggleButtonGroup, Tooltip, Zoom } from "@mui/material";
import { collection, getDocs, orderBy, query, Timestamp } from "firebase/firestore";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import EmojiPeopleOutlinedIcon from "@mui/icons-material/EmojiPeopleOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { formatDate, formatNumber } from "../../../../assets/js/Commons";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import MoneyOutlinedIcon from "@mui/icons-material/MoneyOutlined";
import ModalFullScreen from "../../../containers/ModalFullScreen";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import { db } from "../../../../assets/context/firebase-config";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { useThis } from "../../../../assets/context/Context";
import getConstant from "../../../../assets/js/Constant";
import AutoCompleteInput from "../../AutoCompleteInput";
import endpoints from "../../../../assets/js/Endpoints";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SavingsIcon from "@mui/icons-material/Savings";
import { DeleteForever } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ValidatePayment from "./ValidatePayment";
import LoadingButton from "../../LoadingButton";
import DetailedAccount from "./DetailedAccount";
import { getAmounts } from "./BottomLeftPanel";
import Customer from "../customers/Customers";
import Modal from "../../../containers/Modal";
import { DataGrid } from "@mui/x-data-grid";
import { Spring } from "../command/Command";
import LoadingBar from "../../LoadingBar";
import InputText from "../../InputText";
import Message from "../../Message";
import Label from "../../Label";

/**
 * @name Billing
 * @description Componente que renderiza la vista de facturación
 * @param {Object} { onClose, paymentType, checks, rows }
 * @returns View
 * @version 1.0
 */
const Billing = ({ onClose, paymentType, checks, rows }) => {
  const constant = getConstant();
  const { lang, currency, config, dataBranch, settings, branch, setAllCustomers, allCustomers, closingBoxCurrent, snapshotBranches } = useThis();
  const dataBill = { paymentType: "02", numberDoc: "", amountDoc: "", totalDoc: 0, cashColon: "", cashDollar: "", accountDetail: true, withdrawal: true };
  const paymentTypes = { "00": lang.onlySinpe, "01": lang.cash, "02": lang.card, "04": lang.transfer, "03": lang.checkbook };
  const defaultCustomer = [{ firstLetter: "C", id: 0, name: lang.cashCustomer.toUpperCase(), ide: "999999999" }];
  const currencies = config.contries[dataBranch.country.code].currencies;

  const divRef = useRef(null);
  const btnSaleFocus = useRef(null);
  const numberDocFocus = useRef(null);
  const amountDocFocus = useRef(null);
  const colonCashFocus = useRef(null);
  const dollarCashFocus = useRef(null);
  const btnModalValidateFocus = useRef(null);

  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [change, setChange] = useState(0);
  const [income, setIncome] = useState(0);
  const [loading, setLoading] = useState(false);
  const [changeText, setChangeText] = useState("");
  const [snackLocal, setSnackLocal] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [gridHeight, setGridHeight] = useState(100);
  const [paymentDocs, setPaymentDocs] = useState([]);
  const [loadingBar, setLoadingBar] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [clickChange, setClickChange] = useState(false);
  const [customers, setCustomers] = useState(defaultCustomer);
  const [openModalCustomer, setOpenModalCustomer] = useState(false);
  const [openDetailedAccount, setOpenDetailedAccount] = useState(false);
  const [data, setData] = useState({ ...dataBill, customer: customers[0] });
  const [openModalValidatePayment, setOpenModalValidatePayment] = useState(false);
  const [date, setDate] = useState(formatDate(Timestamp.fromDate(new Date()), lang));
  const [amounts, setAmounts] = useState({ subtotal: 0, tax: 0, service: 0, utility: 0, total: 0 });

  const css = styles({ color: constant.primaryColor, fontSize: 16, constant, change, gridHeight });

  /**
   * @name paymentTypeChange
   * @description Método encargado de cambiar el tipo de pago
   * @param {Event} _
   * @param {"String"} value
   * @version 1.0
   */
  const paymentTypeChange = (_, value) => {
    numberDocFocus.current.focus();
    setData((prev) => ({ ...prev, paymentType: value }));
  };

  /**
   * @name getSprings
   * @description Método encargado de devolver los springs
   * @returns {Array}
   * @version 1.0
   */
  const getSprings = () => {
    let springs = [];
    for (let i = 0; i < 7; i++) {
      springs.push(<Spring point={css.point} key={i} />);
    }
    return springs;
  };

  /**
   * @name actionRowDelete
   * @description Método que elimina una fila de la tabla
   * @param {Object} param
   * @version 1.0
   */
  const actionRowDelete = (param) => {
    const result = paymentDocs.filter((row) => row.id !== param.row.id);
    setPaymentDocs(result);
  };

  /**
   * @name addPaymentDoc
   * @description Método que agrega un documento de pago
   * @returns N/A
   * @version 1.0
   */
  const addPaymentDoc = () => {
    if (!data.numberDoc) {
      numberDocFocus.current.focus();
      return setSnackLocal([146, constant.error]);
    }
    if (!data.amountDoc) {
      amountDocFocus.current.focus();
      return setSnackLocal([147, constant.error]);
    }
    setPaymentDocs((prev) => [
      ...prev,
      { id: prev.length + 1, type: paymentTypes[data.paymentType], doc: data.numberDoc, amount: data.amountDoc, paymentType: data.paymentType },
    ]);
    numberDocFocus.current.focus();
    setData((prev) => ({ ...prev, numberDoc: "", amountDoc: "" }));
  };

  /**
   * @name onClickTotalDollar
   * @description Carga el total en el campo de efectivo en dólar
   * @version 1.0
   */
  const onClickTotalDollar = () => {
    dollarCashFocus.current.focus();
    setData((prev) => ({ ...prev, cashDollar: settings.exchangeRate ? amounts.total / (settings.exchangeRate * 1) : 0 }));
  };

  /**
   * @name onClickTotalColon
   * @description Carga el total en el campo de efectivo de la moneda local
   * @version 1.0
   */
  const onClickTotalColon = () => {
    colonCashFocus.current.focus();
    setData((prev) => ({ ...prev, cashColon: amounts.total }));
  };

  /**
   * @name onKey
   * @description Maneja el evento onKey de los inputs
   * @param {Event} e
   * @returns N/A
   * @version 1.0
   */
  const onKey = (e) => {
    if (e.key !== "Enter") return;
    switch (e.target.name) {
      case "numberDoc":
        if (data.numberDoc === "") return colonCashFocus.current.focus();
        amountDocFocus.current.focus();
        break;
      case "amountDoc":
        addPaymentDoc();
        break;
      case "cashDollar":
        btnSaleFocus.current.focus();
        break;
      case "cashColon":
        btnSaleFocus.current.focus();
        break;
      default:
        btnSaleFocus.current.focus();
        break;
    }
  };

  /**
   * @name onChange
   * @description Método que maneja el cambio del selector de clientes.
   * @param {Array} option Contiene el conjunto de clientes
   * @param {String} name Nombre de la propiedad del objeto (hace match con el name del Autocomplete)
   * @version 1.0
   */
  const onChange = (option, name) => setData({ ...data, [name]: option });

  /**
   * @name orderBilling
   * @description Método que realiza la facturación	de pedidos
   * @version 1.0
   */
  const orderBilling = () => {
    if (!closingBoxCurrent) return setSnackLocal([150, constant.error]);
    const nRows = rows.filter((row) => row.selectedLeft);
    const currentBranch = snapshotBranches.find((doc) => doc.id === branch.branchId);
    const consecutive = createConsecutive(currentBranch.data());
    const key = Object.values(consecutive).toString().split(",").join("");
    const order = rows.reduce(
      (acc, e) => {
        if (acc.rows[e.idMenuOrExpress]) {
          acc.rows[e.idMenuOrExpress].amount += e.amount;
          acc.rows[e.idMenuOrExpress].finalPrice += e.finalPrice;
        } else {
          acc.rows[e.idMenuOrExpress] = {
            name: e.name,
            cabys: e.cabys,
            amount: e.amount,
            id: e.idMenuOrExpress,
            idCategory: e.idCategory,
            finalPrice: e.finalPrice,
          };
        }
        acc.list.push(e.id);
        return acc;
      },
      { list: [], rows: {} }
    );
    console.log("LINES", Object.values(order.rows));
    console.log("CONSECUTIVE", consecutive);
    console.log("KEY", key);
    console.log("KEY_LENGTH", key.length);
    console.log("DOCUMENTTYPE", documentType);
    console.log("BRANCH", currentBranch.data());
    console.log("CAJA ACTUAL:", closingBoxCurrent);
    console.log("PEDIDOS:", order.list);
    // Cierra los modales
    onClose(false);
    setOpenModalValidatePayment(false);
  };

  const createConsecutive = (currentBranch) => {
    return {
      countryCode: currentBranch.country.area.toString().padStart(3, "0"),
      day: new Date().getDate().toString().padStart(2, "0"),
      month: (new Date().getMonth() + 1).toString().padStart(2, "0"),
      year: new Date().getFullYear().toString().substring(2, 4),
      identification: currentBranch.identification.padStart(12, "0"),
      branchConsecutive: currentBranch.consecutive.toString().padStart(3, "0"),
      terminal: closingBoxCurrent.numberBox.toString().padStart(5, "0"),
      documentType,
      consecutive: (currentBranch.consecutives[documentType].consecutive + 1).toString().padStart(10, "0"),
      documentStatus: constant.documentStatus,
      securityCode: (currentBranch.consecutives[documentType].securityCode + 1).toString().padStart(8, "0"),
    };
  };

  /**
   * @name actionButton
   * @description Método que muestra el modal para validar el pago
   * @param {String} code
   * @version 1.0
   */
  const actionButton = (code) => {
    setDocumentType(code);
    setOpenModalValidatePayment(true);
  };

  /**
   * @name renderButton
   * @description Método que renderiza el botón de eliminar
   * @param {Object} params
   * @returns View
   * @version 1.0
   */
  const renderButton = (params) => {
    return (
      <strong style={css.actions}>
        <IconButton onClick={() => actionRowDelete(params)} sx={css.btnDelete}>
          <DeleteForever />
        </IconButton>
      </strong>
    );
  };

  /** Columnas de la tabla documentos de pago */
  const columns = [
    { field: "type", headerName: lang.type, sortable: false, flex: 0 },
    { field: "doc", headerName: lang.doc, sortable: false, flex: 0 },
    { field: "amount", headerName: lang.ammount, sortable: false, flex: 0 },
    { field: "action", headerAlign: "right", headerName: lang.delete, sortable: false, flex: 1, renderCell: renderButton },
  ];

  /**
   * @description Objeto para la creación del modal fullScreen de espacios (mesa, lleva o express)
   * @version 1.0
   */
  const customerParameters = { title: lang.customers, icon: <SwitchAccountIcon />, content: <Customer openModal={true} setCustomer={setData} /> };

  /**
   * @description Objeto para la creación del modal de validar pago
   */
  const parametersValidatePayment = {
    btnText: lang.collect,
    title: lang.validatePayment,
    icon: <SavingsOutlinedIcon />,
    content: <ValidatePayment data={data} setData={setData} total={amounts.total} income={income} reference={btnModalValidateFocus} />,
  };

  /**
   * @description Objeto para la creación del modal de cuenta detallada
   */
  const parametersDetailed = {
    title: lang.detailedAccount,
    icon: <ArticleOutlinedIcon />,
    content: <DetailedAccount data={data} setData={setData} rows={rows} amounts={amounts} />,
  };

  /** Efecto para inicializar el foco del campo efectivo en colones y los montos */
  useEffect(() => {
    // Inicializa el foco en el campo de efectivo en colones
    colonCashFocus.current.focus();
    // Obtiene los montos de los cargos
    const amounts = getAmounts(rows, checks.tax, checks.service, checks.utility, settings, lang.express);
    setAmounts(amounts);
  }, []);

  /** Efecto que maneja el total de los montos de los documentos de pago */
  useEffect(() => {
    const totalPaymentDocs = paymentDocs.reduce((acc, doc) => acc + doc.amount * 1, 0);
    setData((prev) => ({ ...prev, totalDoc: totalPaymentDocs }));
  }, [paymentDocs]);

  /** Efecto que calcula el cambio */
  useEffect(() => {
    const nIncome = data.totalDoc + (data.cashColon ? data.cashColon * 1 : 0) + (data.cashDollar ? data.cashDollar * 1 * (settings.exchangeRate || 0) : 0);
    const diff = amounts.total - nIncome;
    setChange(diff);
    setIncome(nIncome);
  }, [data]);

  /** Efecto que maneja el cambio en texto */
  useEffect(() => {
    const exchangeRate = settings.exchangeRate || 1;
    const calculatedChange = clickChange && currency.code !== constant.USD ? change / exchangeRate : change;
    setChangeText((clickChange && settings.exchangeRate ? currencies[1].symbol : currency.symbol) + formatNumber(calculatedChange));
  }, [change, clickChange]);

  /** Efecto que mantiene actualizado el combo de clientes y establece la altura de la tabla de documentos*/
  useEffect(() => {
    // Establece la altura de la tabla de documentos
    if (divRef.current) setGridHeight(divRef.current.getBoundingClientRect().height);
    // Mantiene actualizado el combo de clientes, solo se llama una vez
    if (allCustomers.length > 0) return;
    const getCustomers = async () => {
      let customers = [];
      setLoadingBar(true);
      try {
        const snapshot = await getDocs(query(collection(db, endpoints.customers(branch.administrator)), orderBy("name", "asc")));
        customers = snapshot.docs.reduce((acc, doc) => {
          acc.push({ ...doc.data(), id: doc.id, date: formatDate(doc.data().date, lang) });
          return acc;
        }, []);
      } catch (error) {
        console.log(error);
      }
      setLoadingBar(false);
      setAllCustomers(customers);
    };
    getCustomers();
  }, []);

  /** Efecto que carga el combo de clientes */
  useEffect(() => {
    const list = allCustomers.reduce((acc, e) => {
      acc.push({ firstLetter: e.name[0].toUpperCase(), id: e.id, name: e.name, ide: e.identification });
      return acc;
    }, []);
    setCustomers([...defaultCustomer, ...list]);
  }, [allCustomers]);

  /** Efecto que muestra la hora corriendo */
  useEffect(() => {
    const interval = setInterval(() => {
      setDate(formatDate(Timestamp.fromDate(new Date()), lang));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={css.container}>
      <div className="note-background3" style={css.note} onClick={() => {}}>
        <div style={css.springs}>
          {getSprings()}
          <div style={css.loadingBar}>{loadingBar && <LoadingBar visible={loadingBar} />}</div>
        </div>
        <div style={css.content}>
          <div style={css.toolbar}>
            <div style={css.title}>
              <PointOfSaleIcon sx={css.titleIcon} />
              <div>
                <Label text={paymentType ? lang.cashBilling : lang.creditBilling} margin={"0 0 0 5px"} fontWeight={500} fontSize="22px" width={"auto"} />
                <Label
                  width={"auto"}
                  fontSize="12px"
                  fontWeight={500}
                  margin={"0 0 0 5px"}
                  text={data.accountDetail ? lang.detailedAccount : lang.restaurantService}
                />
              </div>
            </div>
            <div style={css.title}>
              <Label width={"auto"} text={date} />
              <Tooltip TransitionComponent={Zoom} title={lang.close}>
                <IconButton onClick={() => onClose(false)} sx={css.fabsIcons}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div style={css.panels}>
            <div style={css.panelLeft}>
              <div style={css.amounts}>
                <Label text={lang.ammount + ":"} fontWeight={700} fontSize="22px" />
                {currency.code !== constant.USD && (
                  <Tooltip TransitionComponent={Zoom} title={lang.change + ": " + (settings.exchangeRate || lang.undefined)}>
                    <p onClick={onClickTotalDollar} style={css.totalDolar}>
                      {currencies[1].symbol + formatNumber(settings.exchangeRate ? amounts.total / (settings.exchangeRate * 1) : 0)}
                    </p>
                  </Tooltip>
                )}
                <p onClick={onClickTotalColon} style={css.totalColon}>
                  {currency.symbol + formatNumber(amounts.total)}
                </p>
                <Tooltip TransitionComponent={Zoom} title={lang.accountDetail}>
                  <IconButton onClick={() => setOpenDetailedAccount(true)} sx={css.fabs}>
                    <ArticleOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div style={css.customers}>
                <AutoCompleteInput name="customer" data={data} onChange={onChange} collection={customers} text={lang.selectCustomer} width="100%" />
                <Tooltip TransitionComponent={Zoom} title={lang.customers}>
                  <IconButton onClick={() => setOpenModalCustomer(true)} sx={css.customerFabs}>
                    <EmojiPeopleOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <Label icon={<AddCardOutlinedIcon sx={css.icons} />} fontWeight={500} text={lang.paymentType + ":"} />
              <ToggleButtonGroup size="small" sx={css.widthFull} color="primary" value={data.paymentType} exclusive onChange={paymentTypeChange}>
                <ToggleButton sx={css.widthFull} value="02">
                  {lang.card}
                </ToggleButton>
                <ToggleButton sx={css.widthFull} value="00">
                  {lang.onlySinpe}
                </ToggleButton>
                <ToggleButton sx={css.widthFull} value="04">
                  {lang.transfer}
                </ToggleButton>
                <ToggleButton sx={css.widthFull} value="03">
                  {lang.checkbook}
                </ToggleButton>
              </ToggleButtonGroup>
              <div style={css.docs}>
                <InputText
                  data={data}
                  onKey={onKey}
                  type={"text"}
                  size={"small"}
                  setData={setData}
                  name={"numberDoc"}
                  marginRight={"5px"}
                  label={lang.numberDoc}
                  value={data.numberDoc}
                  reference={numberDocFocus}
                  icon={<TagOutlinedIcon />}
                />
                <InputText
                  data={data}
                  onKey={onKey}
                  size={"small"}
                  type={"number"}
                  setData={setData}
                  marginLeft={"5px"}
                  name={"amountDoc"}
                  label={lang.ammount}
                  value={data.amountDoc}
                  reference={amountDocFocus}
                  icon={<PaymentsOutlinedIcon />}
                />
                <Tooltip TransitionComponent={Zoom} title={lang.add}>
                  <IconButton onClick={addPaymentDoc} sx={css.fabs}>
                    <PlaylistAddIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div style={css.containerGrid} ref={divRef}>
                <Box style={css.box}>
                  <DataGrid
                    hideFooter
                    rowHeight={35}
                    sx={css.grid}
                    columns={columns}
                    disableColumnMenu
                    rows={paymentDocs}
                    disableColumnFilter
                    checkboxSelection={false}
                    localeText={{ noRowsLabel: lang.noDocs }}
                  />
                </Box>
              </div>
              <div style={css.totalDoc}>
                <Label fontWeight={500} text={lang.totalDoc + ":"} />
                <Label fontWeight={500} text={currency.symbol + formatNumber(data.totalDoc)} />
              </div>
              <Label icon={<SavingsIcon sx={css.icons} />} fontWeight={500} text={lang.kash + ":"} />
              <div style={css.cash}>
                {currency.code !== constant.USD && (
                  <InputText
                    data={data}
                    onKey={onKey}
                    size={"small"}
                    type={"number"}
                    setData={setData}
                    name={"cashDollar"}
                    marginRight={"10px"}
                    label={lang.cashDollar}
                    value={data.cashDollar}
                    reference={dollarCashFocus}
                    icon={<PaidOutlinedIcon />}
                  />
                )}
                <InputText
                  data={data}
                  onKey={onKey}
                  size={"small"}
                  type={"number"}
                  setData={setData}
                  name={"cashColon"}
                  value={data.cashColon}
                  reference={colonCashFocus}
                  icon={<MoneyOutlinedIcon />}
                  label={currency.code === constant.USD ? lang.cashDollar : lang.cashColon}
                />
              </div>
            </div>
            <div style={css.panelRight}>
              <div>
                <LoadingButton
                  width="100%"
                  height="55px"
                  color="#94C9DA"
                  text={lang.sale}
                  colorText="#357C93"
                  reference={btnSaleFocus}
                  icon={<SavingsOutlinedIcon />}
                  handleClick={() => actionButton("90")}
                />
                <LoadingButton
                  width="100%"
                  height="55px"
                  marginTop="10px"
                  colorText="green"
                  text={lang.courtesy}
                  color={constant.primaryColor}
                  icon={<FavoriteBorderOutlinedIcon />}
                  handleClick={() => actionButton("91")}
                />
                <LoadingButton
                  width="100%"
                  height="55px"
                  color="#d44452"
                  marginTop="10px"
                  colorText="#3b070c"
                  text={lang.invoice}
                  icon={<TextSnippetOutlinedIcon />}
                  handleClick={() => actionButton("01")}
                />
                <LoadingButton
                  width="100%"
                  height="55px"
                  color="#f4f4ab"
                  marginTop="10px"
                  colorText="#C2C21A"
                  text={lang.ticket}
                  icon={<ReceiptIcon />}
                  handleClick={() => actionButton("04")}
                />
              </div>
              <div>
                <Label
                  fontSize="30px"
                  textAlign="center"
                  text={lang.change2}
                  justifyContent="center"
                  tooltip={lang.changeMessage}
                  icon={<PointOfSaleIcon sx={css.billingIcon} />}
                />
                <p onClick={() => setClickChange((prev) => !prev)} style={css.change}>
                  {changeText}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** Modal para mostrar la ventana de administrar clientes */}
      <ModalFullScreen
        show={openModalCustomer}
        icon={customerParameters.icon}
        setShow={setOpenModalCustomer}
        title={customerParameters.title}
        content={customerParameters.content}
      />
      {/** Modal para mostrar el detalle de la cuenta */}
      <Modal
        snack={[]}
        loading={false}
        showActionBar={false}
        clickBtnOk={() => {}}
        setSnack={setSnackLocal}
        open={openDetailedAccount}
        icon={parametersDetailed.icon}
        setOpen={setOpenDetailedAccount}
        title={parametersDetailed.title}
        btnText={parametersDetailed.btnText}
        content={parametersDetailed.content}
      />
      {/** Modal para mostrar la validación de pago */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        clickBtnOk={orderBilling}
        open={openModalValidatePayment}
        reference={btnModalValidateFocus}
        setOpen={setOpenModalValidatePayment}
        icon={parametersValidatePayment.icon}
        title={parametersValidatePayment.title}
        btnText={parametersValidatePayment.btnText}
        content={parametersValidatePayment.content}
      />
      <Message snack={snackLocal}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} { color, fontSize, constant, change, gridHeight }
 * @returns Object
 * @version 1.0
 */
const styles = ({ color, fontSize, constant, change, gridHeight }) => {
  const colorChange = change > 0 ? constant.deleteColor : constant.primaryColor;
  return {
    containerGrid: { flex: 1 },
    box: { height: gridHeight },
    widthFull: { width: "100%" },
    fabsIcons: { marginLeft: "10px" },
    description: { fontSize: fontSize + "px" },
    icons: { fontSize: "18px", color: "#444" },
    titleIcon: { color: "#444", fontSize: "48px" },
    title: { display: "flex", alignItems: "center" },
    billingIcon: { color: "#444", fontSize: "28px" },
    actions: { display: "flex", justifyContent: "right" },
    select: { fontSize: "16px", color: "#444", marginRight: "5px" },
    cash: { display: "flex", alignItems: "center", marginTop: "5px" },
    docs: { display: "flex", alignItems: "center", margin: "10px 0" },
    btnDelete: { color: constant.deleteColor, height: "35px", width: "35px" },
    customers: { display: "flex", alignItems: "center", marginBottom: "15px" },
    panels: { flex: 1, display: "flex", paddingTop: "10px", overflowX: "auto" },
    loadingBar: { position: "absolute", width: "100%", left: "0px", bottom: "0px" },
    note: { width: "55%", minWidth: "600px", display: "flex", flexDirection: "column" },
    panelLeft: { flex: 1, display: "flex", flexDirection: "column", padding: "0 20px 0 5px" },
    content: { display: "flex", flexDirection: "column", height: "100%", padding: "5px 15px" },
    totalColon: { fontSize: "22px", fontWeight: 700, color: constant.deleteColor, cursor: "pointer" },
    totalDolar: { fontSize: "22px", fontWeight: 700, color: constant.primaryColor, cursor: "pointer" },
    toolbar: { display: "flex", alignItems: "center", justifyContent: "space-between", overflowX: "auto" },
    panelRight: { width: "28%", display: "flex", flexDirection: "column", justifyContent: "space-between" },
    amounts: { display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "8px" },
    change: { fontWeight: 700, fontSize: "26px", cursor: "pointer", textAlign: "center", color: colorChange },
    totalDoc: { display: "flex", alignItems: "center", margin: "5px 0 15px 0", justifyContent: "space-between" },
    customerFabs: { backgroundColor: "#666", color: "#000", "&:hover": { backgroundColor: "#505050" }, marginLeft: "10px" },
    fabs: { backgroundColor: constant.primaryColor, color: "#FFF", "&:hover": { backgroundColor: "#4c9668" }, marginLeft: "5px" },
    text: { flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between", margin: "0 10px", overflow: "hidden" },
    container: { height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", overflow: "hidden" },
    grid: {
      fontSize: "12px",
      "& .MuiDataGrid-columnHeader": { height: "30px !important" },
      "& .MuiDataGrid-columnHeaderTitle": { fontSize: "12px", fontWeight: 600 },
      "& .MuiDataGrid-checkboxInput .MuiSvgIcon-root": { color: constant.primaryColor },
    },
    springs: {
      display: "flex",
      position: "relative",
      backgroundColor: color,
      borderRadius: "3px 3px 0 0",
      padding: "5px 10px 20px 10px",
      justifyContent: "space-between",
      boxShadow: "inset 2px 2px 15px 2px rgba(44, 44, 44, 0.3)",
    },
    point: {
      width: "23px",
      height: "23px",
      position: "relative",
      borderRadius: "50px",
      backgroundColor: "#e3e3e3",
      border: "1px solid #626262",
      boxShadow: "inset 2px 2px 2px 2px rgba(0, 0, 0, 0.3)",
    },
  };
};

export default Billing;
