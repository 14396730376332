/**
 * @name getConstant
 * @description Método encargado de devolver un objeto con las constantes de la aplicación.
 * Para apuntar a producción desde localhost es necesario agregar la url con host antes de "/api/v1"
 * @returns {Object}
 * @version 1.0
 */
const getConstant = () => {
  return {
    cr: "CR",
    USD: "USD",
    decimals: 2,
    free: "free",
    info: "info",
    error: "error",
    passwordLength: 6,
    warning: "warning",
    success: "success",
    documentStatus: "1",
    maxScreenWidth: 768,
    infoColor: "#177FA0",
    approved: "approved",
    editColor: "#AFA73B",
    defaultColor: "#444",
    defaultMenuSize: 120,
    defaultTableSize: 120,
    checkColor: "#5CB67F",
    deleteColor: "#8A1521",
    defaultCommandSize: 160,
    imageExtension: ".webp",
    primaryColor: "#5CB67F",
    unsubscribed: "Desinscrito",
    notRegistered: "No inscrito",
    //functionsBasePath: "/api/v1",
    subscriptions: "subscriptions",
    onlyImage: "image/png,image/jpeg",
    defaultCabys: { code: "2399999009900" },
    90: { consecutive: 0, securityCode: 0 },
    91: { consecutive: 0, securityCode: 0 },
    "01": { consecutive: 0, securityCode: 0 },
    "04": { consecutive: 0, securityCode: 0 },
    branch: { branchId: "", administrator: "" },
    branchCode: { cod1: "", cod2: "", cod3: "" },
    _PROVINCE: { firstLetter: "A", id: 2, name: "Alajuela" },
    _CANTON: { firstLetter: "A", id: 201, name: "Alajuela" },
    _DISTRICT: { firstLetter: "A", id: 20101, name: "Alajuela" },
    data: { email: "", password: "", userName: "", remember: false },
    getExchangeRate: "https://api.hacienda.go.cr/indicadores/tc/dolar",
    getInfoByIdePath: "https://api.hacienda.go.cr/fe/ae?identificacion=",
    opening: { dollarCoins: "", dollarBills: "", colonCoins: "", colonBills: "" },
    activity: { id: 0, name: "Sin actividades registradas", icon: null, code: "0" },
    functionsBasePath: "http://127.0.0.1:5001/broshetta-web/us-central1/functions/api/v1",
    renew: { docTypes: { id: 0, code: "CF" }, term: 0, document: "", documentName: "", file: null },
    closingSystem: { cash: 0, checks: 0, transfers: 0, sinpe: 0, cards: 0, expenses: 0, credits: 0 },
    closingCount: { cash: "", checks: "", transfers: "", sinpe: "", cards: "", expenses: "", credits: "" },
  };
};

export default getConstant;
