import { useThis } from "../../../../assets/context/Context";
import { Timestamp } from "firebase/firestore";
import CommandItem from "./CommandItem";
import { Slider } from "@mui/material";

/**
 * @name ZoomCommand
 * @description Muestra el modal para cambiar de tamaño a los ítems de la comanda
 * @param {Object} { size: useState, setSize: useState }
 * @returns View
 * @version 1.0
 */
const ZoomCommand = ({ size, setSize }) => {
  const { lang } = useThis();
  const css = styles();

  return (
    <div style={css.box}>
      <div style={css.container}>
        <CommandItem
          size={size}
          color={"8A1521"}
          order={lang.ribEye}
          space={lang.table + " N.° 1"}
          description={lang.ribEyeDescription}
          date={Timestamp.fromDate(new Date())}
        />
      </div>
      <Slider min={80} max={245} value={size * 1} aria-label="Default" valueLabelDisplay="auto" onChange={(e) => setSize(e.target.value)} />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    box: { display: "flex", flexDirection: "column" },
    container: {
      width: "300px",
      display: "flex",
      height: "250px",
      alignItems: "center",
      marginBottom: "10px",
      border: "1px solid #CCC",
      justifyContent: "center",
    },
  };
};

export default ZoomCommand;
