import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useThis } from "../../assets/context/Context";
import getConstant from "../../assets/js/Constant";
import { IconButton } from "@mui/material";
import { icon } from "./AddMenu";
import Label from "./Label";

/**
 * @name AddInventoryToMenu
 * @description Método que carga la vista del modal para vincular productos y/o servicios al menú
 * @param {Object} { prodsAndServs: useState, setProdsAndServs: useState }
 * @returns View
 * @version 1.0
 */
const AddInventoryToMenu = ({ prodsAndServs, setProdsAndServs }) => {
  const constant = getConstant();
  const css = styles(constant);
  const { lang } = useThis();

  /**
   * @name add
   * @description Método para aumentar la cantidad de un producto y/o servicio
   * @param {String} id
   * @version 1.0
   */
  const add = (id) => {
    setProdsAndServs((prev) => ({ ...prev, values: { ...prev.values, [id]: prev.values[id] + 1 } }));
  };

  /**
   * @name subtract
   * @description Método para restar la cantidad de un producto y/o servicio
   * @param {String} id
   * @version 1.0
   */
  const subtract = (id) => {
    setProdsAndServs((prev) => ({ ...prev, values: { ...prev.values, [id]: prev.values[id] > 0 ? prev.values[id] - 1 : 0 } }));
  };

  return (
    <div style={css.container}>
      <Label text={lang.itemsToMenu} margin={"0 0 10px 0"} />
      {prodsAndServs.items.length ? (
        prodsAndServs.items.map((item) => (
          <div key={item.id} style={css.item}>
            <Label text={item.data.name} />
            <div style={css.containerInputs}>
              <IconButton onClick={() => subtract(item.id)} sx={css.new}>
                <RemoveOutlinedIcon />
              </IconButton>
              <div style={css.inputs}>
                <Label text={prodsAndServs.values[item.id]} />
              </div>
              <IconButton onClick={() => add(item.id)} sx={css.new}>
                <AddOutlinedIcon />
              </IconButton>
            </div>
          </div>
        ))
      ) : (
        <Label tooltip={lang.noProdOrServInfo} icon={icon} key={lang.noProdOrServ} text={lang.noProdOrServ} />
      )}
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} constant
 * @returns Object
 * @version 1.0
 */
const styles = (constant) => {
  return {
    new: { width: "auto", color: "#5AB262", margin: "0 5px" },
    containerInputs: { display: "flex", alignItems: "center" },
    container: { display: "flex", flexDirection: "column", maxWidth: "350px", borderBottom: "1px solid #CCC" },
    item: { display: "flex", justifyContent: "space-between", alignItems: "center", borderTop: "1px solid #CCC", padding: "3px 0" },
    inputs: {
      width: "35px",
      height: "35px",
      display: "flex",
      borderRadius: "5px",
      alignItems: "center",
      justifyContent: "center",
      border: `1px solid ${constant.primaryColor}`,
    },
  };
};

export default AddInventoryToMenu;
