import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useThis } from "../../../../assets/context/Context";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import getConstant from "../../../../assets/js/Constant";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Checkbox, Tooltip, Zoom } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { DataGrid } from "@mui/x-data-grid";

/**
 * @name TopLeftPanel
 * @description Método que devuelve el contenido que carga la tabla de pedidos
 * @param {Object} { rows, setRows, setShowDeleteBtn }
 * @returns View
 * @version 1.0
 */
const TopLeftPanel = ({ rows, setRows, handleDoubleClick }) => {
  const { lang } = useThis();
  const constant = getConstant();
  const css = styles(rows.length, constant);

  /**
   * @name handleCheckboxChange
   * @description Función para manejar el cambio de un checkbox individual
   * @param {String} column
   * @param {String} id
   * @param {Boolen} isChecked
   * @version 1.0
   */
  const handleCheckboxChange = (column, id, isChecked) => {
    setRows((prevRows) => prevRows.map((row) => (row.id === id ? { ...row, [`selected${column}`]: isChecked } : row)));
  };

  /**
   * @name handleSelectAll
   * @description Función para manejar el checkbox de "seleccionar todos"
   * @param {String} column
   * @param {Boolean} isChecked
   * @version 1.0
   */
  const handleSelectAll = (column, isChecked) => {
    setRows((prevRows) => prevRows.map((row) => ({ ...row, [`selected${column}`]: isChecked })));
  };

  /** Constante que define las columnas para la tabla */
  const columns = [
    {
      flex: 0.1,
      minWidth: 62,
      sortable: false,
      field: "check1",
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip TransitionComponent={Zoom} title={lang.selectedOrders}>
          <Checkbox
            sx={css.check}
            checked={rows.every((row) => row.selectedLeft)}
            onChange={(e) => handleSelectAll("Left", e.target.checked)}
            indeterminate={rows.some((row) => row.selectedLeft) && !rows.every((row) => row.selectedLeft)}
          />
        </Tooltip>
      ),
      renderCell: (params) => (
        <Checkbox
          sx={css.check}
          checked={params.row.selectedLeft || false}
          onChange={(e) => handleCheckboxChange("Left", params.row.id, e.target.checked)}
        />
      ),
    },
    {
      flex: 0.1,
      minWidth: 62,
      sortable: false,
      field: "check2",
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip TransitionComponent={Zoom} title={lang.restaurantService}>
          <Checkbox
            sx={css.check}
            icon={<BookmarkBorderIcon />}
            checkedIcon={<BookmarkIcon />}
            indeterminateIcon={<BookmarksIcon />}
            checked={rows.every((row) => row.selectedRight)}
            onChange={(e) => handleSelectAll("Right", e.target.checked)}
            indeterminate={rows.some((row) => row.selectedRight) && !rows.every((row) => row.selectedRight)}
          />
        </Tooltip>
      ),
      renderCell: (params) => (
        <Checkbox
          sx={css.check}
          icon={<CloseIcon />}
          checkedIcon={<CheckIcon />}
          checked={params.row.selectedRight || false}
          onChange={(e) => handleCheckboxChange("Right", params.row.id, e.target.checked)}
        />
      ),
    },
    { field: "fullName", headerName: lang.order, flex: 1 },
    { field: "finalPriceText", headerName: lang.price, flex: 0, headerAlign: "right", align: "right" },
  ];

  return (
    <div style={css.container}>
      <DataGrid
        hideFooter
        rows={rows}
        sx={css.grid}
        rowHeight={40}
        columns={columns}
        disableRowSelectionOnClick
        autoHeight={rows.length > 0}
        localeText={{ noRowsLabel: lang.noOrders }}
        onRowDoubleClick={(params) => handleDoubleClick(params.row)}
      />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Number} rows
 * @param {Object} constant
 * @returns Object
 * @version 1.0
 */
const styles = (rows, constant) => {
  return {
    container: { width: "auto" },
    grid: { height: rows === 0 ? "100px" : "auto", width: "auto" },
    check: {
      color: constant.deleteColor,
      "&.Mui-checked": {
        color: constant.primaryColor,
      },
      "&.MuiCheckbox-indeterminate": {
        color: constant.defaultColor,
      },
    },
  };
};

export default TopLeftPanel;
