import DescriptionIcon from "@mui/icons-material/Description";
import { getCurrentBranch } from "../../assets/js/Commons";
import { useThis } from "../../assets/context/Context";
import ReceiptIcon from "@mui/icons-material/Receipt";
import getConstant from "../../assets/js/Constant";
import ArrowButton from "./ArrowButton";
import Selector from "./Selector";
import { useEffect } from "react";
import Label from "./Label";

/**
 * @name RenewStep1
 * @description Método que devuelve un componente de tipo RenewStep1
 * @returns View
 * @version 1.0
 */
const RenewStep1 = ({ consecutiveBranch, setSnack }) => {
  const css = styles();
  const constant = getConstant();
  const { snapshotBranches } = useThis();
  const branch = getCurrentBranch(snapshotBranches, consecutiveBranch);
  const { setStep, lang, dataRenew, setDataRenew, docTypes } = useThis();

  /**
   * @name next
   * @description Método que avanza un paso del Stepper
   * @returns N/A
   */
  const next = () => setStep(1);

  /** Efecto para cargar el tipo de documento actual, si no se ha seleccionado nada es el índice 0 */
  useEffect(() => {
    setDataRenew({ ...dataRenew, docTypes: docTypes[dataRenew.docTypes.id] });
  }, []);

  useEffect(() => {
    if (branch.data().electronicBill && dataRenew.docTypes.code === "CF") return setSnack([46, constant.warning]);
    if (!branch.data().electronicBill && dataRenew.docTypes.code === "CE") return setSnack([47, constant.warning]);
  }, [dataRenew.docTypes.code]);

  return (
    <div style={css.container}>
      <div style={css.fragment}>
        <div style={css.panelLeft}>
          <Label text={lang.selectPlan} fontWeight={"500"} fontSize={"18px"} margin={"5px 0 10px 0"} />
          {dataRenew.docTypes.id === 0 ? <ReceiptIcon sx={css.icon} /> : <DescriptionIcon sx={css.icon} />}
        </div>
        <div style={css.panelRight}>
          <Selector name="docTypes" data={dataRenew} setData={setDataRenew} collection={docTypes} group={2} />
          <Label text={lang.description} fontWeight={"600"} fontSize={"16px"} margin={"25px 0 5px 0"} />
          <Label text={dataRenew.docTypes.id === 0 ? lang.docType2 : lang.docType1} fontSize={"16px"} />
        </div>
      </div>
      <div style={css.footer}>
        <div></div>
        <ArrowButton index={1} handleClick={next} />
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = () => {
  return {
    icon: { fontSize: "200px", color: "#444" },
    fragment: { display: "flex", height: "275px" },
    footer: { display: "flex", justifyContent: "space-between", marginTop: "10px" },
    panelLeft: { display: "flex", flexDirection: "column", width: "230px", marginTop: "30px" },
    panelRight: { display: "flex", flexDirection: "column", width: "370px", marginTop: "30px" },
    container: { width: "100%", position: "relative", display: "flex", flexDirection: "column", justifyContent: "space-between" },
  };
};

export default RenewStep1;
