import { getCRCantonsByProvince, getCRDistrictsByProvincAndCanton, getCRProvinces, getInfoByIde } from "../../assets/js/Commons";
import { ImageNotSupported, AlternateEmail, Directions, LocalPhone, FoodBank, EditNote, Badge } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { useThis } from "../../assets/context/Context";
import AutoCompleteInput from "./AutoCompleteInput";
import getConstant from "../../assets/js/Constant";
import InputFileUpload from "./InputFileUpload";
import defalut from "../../images/default.svg";
import { Tooltip, Zoom } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingButton from "./LoadingButton";
import CustomSwitch from "./CustomSwitch";
import InputText from "./InputText";
import Selector from "./Selector";
import Message from "./Message";
import Label from "./Label";

/** Variables globales */
const icon = <InfoIcon color="info" sx={{ fontSize: "16px" }} />;

/**
 * @name CreateBranch
 * @description Método que devuelve un componente tipo CreateBranch, formulario para crear una sucursal
 * @param {Object} { ideFocus, nameFocus, phoneFocus, setLoading, action }
 * @returns View
 * @version 1.0
 */
const CreateBranch = ({ ideFocus, nameFocus, emailFocus, setLoading, action }) => {
  const constant = getConstant();
  const { lang, isMobile, dataBranch, setDataBranch, typesIDs, activity, setActivity } = useThis();
  const width = isMobile ? "auto" : "400px";

  const provinces = getCRProvinces();

  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [display, setDisplay] = useState("none"); // Muestra los componentes para facturación electrónica
  const [branchName, setBranchName] = useState(lang.branchName);
  const [cantons, setCantons] = useState(getCRCantonsByProvince(constant._PROVINCE));
  const [districts, setDistricts] = useState(getCRDistrictsByProvincAndCanton(constant._PROVINCE, constant._CANTON));

  const css = styles(width, display);

  /**
   * @name onChange
   * @description Método encargado de filtrar los cantones y distritos para agregarlos al selector. Acción de los selectores de dirección.
   * @param {Array} option Contiene el conjunto de provincias, cantones o distritos
   * @param {String} name province, canton, district
   * @version 1.0
   */
  const onChange = (option, name) => {
    switch (name) {
      case "province":
        // Captura los cantones de la provincia seleccionada
        const cantons = getCRCantonsByProvince(option);
        setCantons(cantons);
        // Captura los distritos del primer cantón de la provincia seleccionada
        const districtsByProvincAndCanton = getCRDistrictsByProvincAndCanton(option, cantons[0]);
        setDistricts(districtsByProvincAndCanton);
        delete option.cantons;
        delete cantons[0].district;
        setDataBranch({ ...dataBranch, province: option, canton: cantons[0], district: districtsByProvincAndCanton[0] });
        break;
      case "canton":
        // Captura los distritos del cantón seleccionado
        const districts = getCRDistrictsByProvincAndCanton(dataBranch.province, option);
        setDistricts(districts);
        delete option.district;
        setDataBranch({ ...dataBranch, canton: option, district: districts[0] });
        break;
      default:
        setDataBranch({ ...dataBranch, district: option });
        break;
    }
  };

  /**
   * @name onKey
   * @description Método encargado de controlar si se presiona enter para consultar ante Hacienda CR la información de la identificación ingresada
   * @param {Event} e
   */
  const onKey = async (e) => {
    if (e.key !== "Enter") return;
    // Inicia la barra de cargando...
    setLoading(true);
    // Llama a la función que consulta los datos del usuario a Hacienda CR
    const message = await getInfoByIde(dataBranch.identification, dataBranch.electronicBill);
    // Detiene la barra de cargando cuando se optiene la respuesta
    setLoading(false);
    // Si la respuesta viene con error, se muestra el mensaje tipo Toast
    if (message.error) return setSnack([message.errorCode, constant.error]);
    // Se selecciona el tipo de identificación y se agrega en el selector de tipos de IDs
    const typeID = typesIDs.find((element) => element.type === message.response.tipoIdentificacion);
    // Se seleccionan las actividades y en caso de tener, se agregan en el selector de código de actividad
    const activity = getActivityCode(message.response.actividades);
    setDataBranch({ ...dataBranch, typeID, name: message.response.nombre, activityCode: activity });
  };

  /**
   * @name getActivityCode
   * @description Método encargado de validar las actividades devueltas por Hacienda CR y las formatea para agregarlas al selector
   * @param {Array} activities - Arreglo con las actividades respondidas por Hacienda CR
   * @returns Object con la primer actividad del array
   */
  const getActivityCode = (activities) => {
    if (activities.length === 0) return constant.activity;
    const array = activities.map((activity, index) => ({
      id: index,
      icon: null,
      code: activity.codigo,
      name: `${activity.codigo} - ${activity.descripcion}`,
    }));
    setActivity(array);
    return array[0];
  };

  /**
   * @name removeLogo
   * @description Método que remueve la imagen del inputFile
   * @returns N/A
   * @version 1.0
   */
  const removeLogo = () => setDataBranch({ ...dataBranch, logo: "", file: null });

  /**
   * @description Efecto que carga los componentes de acuerdo a la selección de factura electrónica (EB)
   */
  useEffect(() => {
    if (dataBranch.electronicBill) {
      setDisplay("flex");
      setBranchName(lang.branchNameForEB);
    } else {
      setDisplay("none");
      setBranchName(lang.branchName);
    }
  }, [dataBranch.electronicBill]);

  /** Efecto para inicializar el foco del campo identificación */
  useEffect(() => {
    ideFocus.current.focus();
  }, []);

  return (
    <div style={css.container}>
      <Label tooltip={lang.createBranchMessage} icon={icon} text={lang.generalData} fontSize="16px" fontWeight="600" />
      <br />
      <Selector name="country" data={dataBranch} setData={setDataBranch} group={0} />
      <Tooltip TransitionComponent={Zoom} title={dataBranch.country.id !== 0 ? lang.disabledCountry : ""}>
        <div style={css.switch}>
          <Label tooltip={lang.electronicBillMessage} icon={action === lang.add ? icon : null} text={lang.electronicBill} fontSize="16px" />
          <CustomSwitch
            data={dataBranch}
            textLeft={lang.yes}
            textRight={lang.no}
            name="electronicBill"
            setData={setDataBranch}
            disabled={dataBranch.country.code !== constant.cr || action === lang.add}
          />
        </div>
      </Tooltip>
      <Selector name="typeID" data={dataBranch} setData={setDataBranch} group={1} display={display} />
      <InputText
        marginTop={2}
        onKey={onKey}
        type={"text"}
        size={"small"}
        width={"auto"}
        marginBottom={2}
        icon={<Badge />}
        data={dataBranch}
        reference={ideFocus}
        name="identification"
        setData={setDataBranch}
        label={lang.idePlusEnter}
        value={dataBranch.identification}
      />
      <InputText
        name="name"
        marginTop={2}
        type={"text"}
        size={"small"}
        width={"auto"}
        marginBottom={2}
        data={dataBranch}
        label={branchName}
        icon={<FoodBank />}
        reference={nameFocus}
        value={dataBranch.name}
        setData={setDataBranch}
      />
      <InputText
        marginTop={2}
        type={"text"}
        size={"small"}
        width={"auto"}
        name="tradename"
        marginBottom={2}
        data={dataBranch}
        display={display}
        icon={<EditNote />}
        label={lang.tradename}
        setData={setDataBranch}
        value={dataBranch.tradename}
      />
      <Selector group={2} data={dataBranch} display={display} fontSize={"13px"} name="activityCode" collection={activity} setData={setDataBranch} />
      <InputText
        name="email"
        marginTop={2}
        type={"email"}
        size={"small"}
        width={"auto"}
        color={"#872B2B"}
        data={dataBranch}
        display={display}
        reference={emailFocus}
        setData={setDataBranch}
        value={dataBranch.email}
        label={`*${lang.email}`}
        icon={<AlternateEmail />}
      />
      <InputText
        name="phone"
        marginTop={2}
        type={"text"}
        size={"small"}
        width={"auto"}
        data={dataBranch}
        label={lang.phone}
        icon={<LocalPhone />}
        setData={setDataBranch}
        value={dataBranch.phone}
      />
      <Label text={lang.requiredFields} fontSize="12px" margin="10px 0 0 0" color="#872B2B" />
      <div style={css.containerAddress}>
        <Label text={lang.selectAddress} fontWeight="600" fontSize="16px" margin="15px 0 15px 0" />
        <AutoCompleteInput mb="15px" name="province" data={dataBranch} onChange={onChange} collection={provinces} text={lang.selectProvince} />
        <AutoCompleteInput mb="15px" name="canton" data={dataBranch} onChange={onChange} collection={cantons} text={lang.selectCanton} />
        <AutoCompleteInput mb="15px" name="district" data={dataBranch} onChange={onChange} collection={districts} text={lang.selectDistrict} />
        <InputText
          rows={2}
          marginTop={2}
          type={"text"}
          size={"small"}
          width={"auto"}
          data={dataBranch}
          name="otherAddress"
          icon={<Directions />}
          setData={setDataBranch}
          label={lang.otherAddress}
          value={dataBranch.otherAddress}
        />
      </div>
      <Label text={lang.selectLogo} fontWeight="600" fontSize="16px" margin="15px 0 5px 0" />
      <img src={dataBranch.logo || defalut} alt="" style={css.img} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <InputFileUpload
          name="logo"
          data={dataBranch}
          setData={setDataBranch}
          accept={constant.onlyImage}
          text={dataBranch.logo ? lang.changeImage : lang.uploadImage}
        />
        {dataBranch.logo && <LoadingButton text={lang.remove} handleClick={removeLogo} icon={<ImageNotSupported />} color={constant.deleteColor} />}
      </div>
      <Message snack={snack}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} width Ancho del contenedor
 * @param {String} display - "flex" ó "none"
 * @returns Object
 */
const styles = (width, display) => {
  return {
    container: { width },
    img: { width: "100%", height: "auto", border: "1px solid #CCC" },
    containerAddress: { display: display === "flex" ? "block" : "none" },
    switch: { display: "flex", justifyContent: "space-between", alignItems: "center", margin: "15px 0" },
  };
};

export default CreateBranch;
