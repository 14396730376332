import getConstant from "../../../assets/js/Constant";
import { Box, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { v4 } from "uuid";

/**
 * @name GenericGridType2
 * @description Método que devuelve una tabla con filas sencillas (no desplegables), y con botones de acción
 * @param {Object} { rowHeight:Number, columns:Array, rows:Array, actions:Array, setSelectedRows:useState, showDeleteBtn:Boolean, checkboxSelection:Boolean, noRowsLabel:String }
 * @returns View
 */
const GenericGridType2 = ({ rowHeight, columns, rows, actions, setSelectedRows, showDeleteBtn = true, checkboxSelection = true, noRowsLabel = "" }) => {
  const css = styles(noRowsLabel, rows.length);
  const constant = getConstant();

  /**
   * @name renderDetailsButton
   * @description Método que redenderiza los botones de las acciones de la fila
   * @param {Object} params
   * @returns View
   * @version 1.0
   */
  const renderDetailsButton = (params) => {
    const buttons = actions.reduce((acc, element) => {
      let display = "inline-flex";
      if (element.color === constant.deleteColor) display = showDeleteBtn ? "inline-flex" : "none";
      acc.push(
        <IconButton key={v4()} onMouseUp={() => element.callBack(params)} sx={{ color: element.color, display }}>
          {element.icon}
        </IconButton>
      );
      return acc;
    }, []);
    return <strong style={css.buttons}>{buttons}</strong>;
  };

  /**
   * @name getColumns
   * @description Método que retorna un arreglo con las columnas de la tabla
   * @returns {Array}
   * @version 1.0
   */
  const getColumns = () => {
    const elements = columns.reduce((acc, e, index) => {
      let item = { field: e.field, headerName: e.headerName, flex: e.flex, editable: e.editable, align: e.align };
      // Valida si la tabla requiere acciones y si está en la última columna para agregar los botones
      if (index === columns.length - 1 && e.field === "action") item = { ...item, sortable: false, renderCell: renderDetailsButton };
      acc.push(item);
      return acc;
    }, []);
    return elements;
  };

  return (
    <Box sx={css.main}>
      <DataGrid
        hideFooter
        rows={rows}
        sx={css.grid}
        rowHeight={rowHeight}
        columns={getColumns()}
        disableRowSelectionOnClick
        autoHeight={rows.length > 0}
        localeText={{ noRowsLabel }}
        checkboxSelection={checkboxSelection}
        onRowSelectionModelChange={(params) => setSelectedRows(params)}
      />
    </Box>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} noRowsLabel
 * @param {Number} rowsLength
 * @returns {Object}
 * @version 1.0
 */
const styles = (noRowsLabel, rowsLength) => {
  return {
    main: { width: "100%" },
    buttons: { display: "flex", justifyContent: "right" },
    grid: { height: noRowsLabel && rowsLength === 0 ? "100px" : "auto" },
  };
};

export default GenericGridType2;
