import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useThis } from "../../../assets/context/Context";
import TerminalIcon from "@mui/icons-material/Terminal";
import getConstant from "../../../assets/js/Constant";
import CircleIcon from "./CircleIcon";
import Label from "../Label";

/** Declaración de varianbles globales */
const constant = getConstant();

/**
 * @name System
 * @description Método que carga el contenido para el modal de administración del sistema
 * @returns View
 * @version 1.0
 */
const System = () => {
  const css = styles();
  const { lang, dataBranch, config, currency, setCurrency } = useThis();
  const currencies = config.contries[dataBranch.country.code].currencies;

  /**
   * @name onChangeCurrency
   * @description Método que maneja el evento onChange de los radio buttons para monedas
   * @param {Event} event
   * @version 1.0
   */
  const onChangeCurrency = (event) => {
    const selectedCurrency = currencies.find((item) => item.code === event.target.value);
    localStorage.setItem(dataBranch.country.code + "_currency", JSON.stringify(selectedCurrency));
    setCurrency(selectedCurrency);
  };

  /**
   * @name loadCurrencies
   * @description Método que pinta los radio buttons de moneda obtenidos del archivo de configuración por país.
   * @returns {Array} Array con las vistas de los radio buttons
   * @version 1.0
   */
  const loadCurrencies = () => {
    return currencies.reduce((acc, e) => {
      acc.push(<FormControlLabel key={e.code} value={e.code} control={<Radio sx={css.checked} />} label={lang.currencies[e.code]} />);
      return acc;
    }, []);
  };

  return (
    <div style={css.container}>
      <div style={css.left}>
        <CircleIcon icon={<TerminalIcon sx={css.icon} />} text={lang.configSystemInfo} />
      </div>
      <div style={css.right}>
        <br />
        <Label text={lang.configSystemTitle} fontSize={"30px"} />
        <br />
        <Label text={lang.currency} fontSize={"20px"} fontWeight={500} />
        <Label text={currencies.length > 1 ? lang.selectCurrency : lang.defaultCurrency} />
        <RadioGroup value={currency.code} onChange={onChangeCurrency}>
          {loadCurrencies()}
        </RadioGroup>
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    right: { flex: 1 },
    icon: { fontSize: "70px", color: "#FFF" },
    container: { width: "100%", height: "100%", display: "flex" },
    left: { display: "flex", justifyContent: "center", alignItems: "center", margin: "0 5rem" },
    checked: { color: constant.primaryColor, "&.Mui-checked": { color: constant.primaryColor } },
  };
};

export default System;
