import getConstant from "../../assets/js/Constant";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useEffect } from "react";

/** Inicialización de variables */
const constant = getConstant();

/**
 * @name getSwitch
 * @description Método que devuelve los estilos del Swith
 * @param {Boolean} disabled
 * @returns View
 * @version 1.0
 */
const getSwitch = (disabled) =>
  styled(Switch)(() => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: disabled ? "#CCC" : constant.primaryColor,
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: disabled ? "#CCC" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

/**
 * @name CustomSwitch
 * @description Método que devuelve un componente de tipo CustomSwitch
 * @param {Object} { data, setData, name, textLeft, textRight, disabled, iconLeft, iconRight }
 * @returns View
 */
const CustomSwitch = ({ data, setData, name, textLeft, textRight, disabled, iconLeft, iconRight }) => {
  const css = styles();
  const MaterialUISwitch = getSwitch(disabled);

  /**
   * @name handleOnChange
   * @description Método que maneja el check del switch
   */
  const handleOnChange = () => {
    setData((prev) => ({ ...prev, [name]: !data[name] }));
  };

  useEffect(() => {
    if (disabled) setData((prev) => ({ ...prev, [name]: false }));
  }, [disabled]);

  return (
    <div style={css.container}>
      <p style={{ ...css.text, fontWeight: data[name] ? "bold" : "normal" }}>
        {iconLeft && <img src={iconLeft} alt="" style={css.icon} />}
        {textLeft}
      </p>
      <MaterialUISwitch name={name} disabled={disabled} onChange={handleOnChange} checked={!data[name]} />
      <p style={{ ...css.text, fontWeight: data[name] ? "normal" : "bold" }}>
        {iconRight && <img src={iconRight} alt="" style={css.icon} />}
        {textRight}
      </p>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = () => {
  return {
    icon: { marginRight: "5px" },
    container: { display: "flex", alignItems: "center" },
    text: { display: "flex", alignItems: "center", color: "#444444" },
  };
};

export default CustomSwitch;
