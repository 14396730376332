import { CenterFocusWeak, TableRestaurant, Close, Dining, DeleteForever, Delete } from "@mui/icons-material";
import { createService, deleteService, getServices } from "../../../assets/js/firebaseMethods";
import { CircularProgress, Fab, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import getLocalStorageCodes from "../../../assets/config/config-localStorage";
import { getServiceColors } from "../../../assets/config/config-tables";
import { getFirstMissingNumber } from "../../../assets/js/Commons";
import ServiceItem, { getStorageKey } from "../ServiceItem";
import { useThis } from "../../../assets/context/Context";
import { CustomizedSpeedDial } from "../SpeedDialButton";
import getConstant from "../../../assets/js/Constant";
import CreateService from "../CreateService";
import { useEffect, useState } from "react";
import Modal from "../../containers/Modal";
import AlertMessage from "../AlertMessage";
import ZoomTable from "../ZoomTable";
import Message from "../Message";

/** Inicialización de variables */
const constant = getConstant();

/**
 * @name Tables
 * @description Método utilizado para mostrar el módulo de mesas y otros servicios
 * @returns View
 * @version 1.0
 */
const Tables = () => {
  const { user, lang, branch, size, setSize, isInside, isDragging, refDeleteTable } = useThis();
  const css = styles(isInside);
  const colors = getServiceColors();
  const codes = getLocalStorageCodes();

  const [index, setIndex] = useState(0); // Índice del servicio seleccionado (imágenes)
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [snackLocal, setSnackLocal] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [viewsServices, setViewsServices] = useState([]);
  const [spaceToDelete, setSpaceToDelete] = useState({});
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [services, setServices] = useState({ 0: [], 1: [], 2: [] }); // 0 = Mesas, 1 = Express, 2 = Llevar
  const [openModalZoomTable, setOpenModalZoomTable] = useState(false);
  const [openModalCreateTable, setOpenModalCreateTable] = useState(false);

  /**
   * @name onSaveSizeInLocalStorage
   * @description Método utilizado para almacenar el tamaño de los ítems de los servicios (mesas, express, llevar)
   * @version 1.0
   */
  const onSaveSizeInLocalStorage = () => {
    localStorage.setItem(getStorageKey(branch, codes.spaceSize), size);
    setOpenModalZoomTable(false);
  };

  /**
   * @name onCreateService
   * @description Método utilizado para crear el request y enviarlo al endpoint de crear servicios (mesas, express, llevar)
   * @returns N/A
   * @version 1.0
   */
  const onCreateService = async () => {
    if (loading) return;
    setLoading(true);
    const serviceData = {
      type: index,
      branchId: branch.branchId,
      number: getFirstMissingNumber(services[index]),
      color: colors[Math.floor(Math.random() * colors.length)],
    };
    // Se consume el servicio para almacenar los datos
    const response = await createService(user, serviceData, branch.administrator);
    if (response.error) {
      setSnack([response.errorCode, constant.error]);
    } else {
      setOpenModalCreateTable(false);
    }
    setLoading(false);
  };

  /**
   * @description Objeto para la creación del modal Crear Sucursal
   */
  const parameters = {
    icon: <Dining />,
    btnText: lang.add,
    title: lang.newService,
    content: <CreateService index={index} setIndex={setIndex} services={services} />,
  };

  /**
   * @description Objeto para la creación del modal Zoom
   */
  const zoomParameters = {
    title: lang.zoom,
    btnText: lang.save,
    icon: <CenterFocusWeak />,
    content: <ZoomTable size={size} setSize={setSize} />,
  };

  /** Efecto para obtener los datos de cada servicio (mesa, express, llevar) */
  useEffect(() => {
    getServices(setServices, branch.administrator, branch.branchId, setProgress);
  }, []);

  /** Efecto para cargar los servicios en la vista*/
  useEffect(() => {
    let views = [];
    const text = [lang.table, lang.express, lang.carry];
    for (let x = 0; x < 3; x++) {
      const spaces = services[x].reduce(
        (acc, e) => {
          const title = text[x] + " N.° " + e.number;
          const service = getServiceFromLocalstorage(e.id);
          acc.views.push(
            <ServiceItem
              id={e.id}
              type={e.type}
              title={title}
              orders={e.orders}
              services={services}
              edge={service.edge}
              key={text[x] + e.number}
              position={service.position}
              requestDate={e.requestDate}
              status={e.orders.length === 0}
              setSpaceToDelete={setSpaceToDelete}
              setOpenAlertMessage={setOpenAlertMessage}
            />
          );
          return acc;
        },
        { views: [] }
      );
      views = [...views, ...spaces.views];
    }
    setViewsServices(views);
  }, [services]);

  /**
   * @name getPositionFromLocalstorage
   * @description Método que retorna la posición de un servicio del localstorage
   * @param {String} id
   * @returns {Object}
   * @version 1.0
   */
  const getServiceFromLocalstorage = (id) => {
    const list = JSON.parse(localStorage.getItem(getStorageKey(branch, codes.spacePosition))) || {};
    if (id in list) {
      return list[id];
    } else {
      return { position: { x: 15, y: 15 }, edge: true };
    }
  };

  /**
   * @name getCustomizedSpeedDial
   * @description Muestra el botón SpeedDial
   * @returns View
   * @version 1.0
   */
  const getCustomizedSpeedDial = () => (
    <CustomizedSpeedDial ariaLabel="" sx={css.speedDial} icon={<SpeedDialIcon openIcon={<Close />} />}>
      <SpeedDialAction onClick={() => setOpenModalZoomTable(true)} icon={<CenterFocusWeak />} tooltipTitle={lang.zoom} />
      <SpeedDialAction onClick={() => setOpenModalCreateTable(true)} icon={<TableRestaurant />} tooltipTitle={lang.addService} />
    </CustomizedSpeedDial>
  );

  /**
   * @name getFabDeleteTable
   * @description Muestra el botón Fab para eliminar un servicio (mesa, express, llevar)
   * @returns View
   * @version 1.0
   */
  const getFabDeleteTable = () => (
    <div ref={refDeleteTable} style={css.fabDelete}>
      <Fab sx={css.fab} aria-label="add">
        {isInside ? <DeleteForever sx={css.white} /> : <Delete sx={css.white} />}
      </Fab>
    </div>
  );

  /**
   * @name onDeleteService
   * @description Método encargado de consumir el servicio de eliminar un servicio (mesa, express, llevar)
   * @version 1.0
   */
  const onDeleteService = async () => {
    if (deleting) return;
    // Se cargan todos los espacios
    const spaces = [...services[0], ...services[1], ...services[2]];
    // Se busca el espacio a eliminar
    const space = spaces.find((e) => e.id === spaceToDelete.id);
    if (!space) return setSnackLocal([128, constant.error]);
    if (space.orders.length > 0) return setSnackLocal([127, constant.error]);
    setDeleting(true);
    // Llama al servicio de eliminar servicio
    const response = await deleteService(user, branch.administrator, branch.branchId, spaceToDelete.id);
    setDeleting(false);
    if (response.error) return setSnackLocal([response.errorCode, constant.error]);
    setSnackLocal([136, constant.success]);
    setOpenAlertMessage(false);
  };

  return (
    <div>
      {progress ? <CircularProgress sx={css.progress} /> : viewsServices}
      {isDragging ? getFabDeleteTable() : getCustomizedSpeedDial()}
      {/** Modal para mostrar la pantalla de crear espacio */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        icon={parameters.icon}
        title={parameters.title}
        open={openModalCreateTable}
        clickBtnOk={onCreateService}
        btnText={parameters.btnText}
        content={parameters.content}
        setOpen={setOpenModalCreateTable}
      />
      {/** Modal para mostrar la pantalla de Zoom */}
      <Modal
        snack={snack}
        loading={false}
        setSnack={setSnack}
        open={openModalZoomTable}
        icon={zoomParameters.icon}
        title={zoomParameters.title}
        setOpen={setOpenModalZoomTable}
        btnText={zoomParameters.btnText}
        content={zoomParameters.content}
        clickBtnOk={onSaveSizeInLocalStorage}
      />
      {/** Modal para eliminar un espacio */}
      <AlertMessage
        twoButtons={true}
        loading={deleting}
        title={lang.delete}
        open={openAlertMessage}
        textButton={lang.delete}
        onClick={onDeleteService}
        setOpen={setOpenAlertMessage}
        icon={<DeleteForever sx={css.deleteIcon} />}
        message={lang.deleteMessage.replace("[SPACE]", spaceToDelete.title)}
      />
      <Message snack={snackLocal}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Boolean} isInside Valida que se encuentre dentro del botón de eliminar espacio
 * @returns Object
 * @version 1.0
 */
const styles = (isInside) => {
  return {
    white: { color: "#fff" },
    fab: { background: "#A7524C" },
    progress: { color: constant.primaryColor },
    deleteIcon: { color: constant.deleteColor },
    speedDial: { position: "absolute", bottom: 20, right: 25, zIndex: 0 },
    fabDelete: {
      right: "-45px",
      bottom: "-50px",
      display: "flex",
      padding: "70px",
      borderRadius: "50%",
      position: "absolute",
      background: isInside ? "#a7524c95" : "#a7524c50",
    },
  };
};

export default Tables;
