import { formatNumber, getFinalPrice } from "../../assets/js/Commons";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { useThis } from "../../assets/context/Context";
import SellIcon from "@mui/icons-material/Sell";
import { InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import InputText from "./InputText";
import Selector from "./Selector";
import Label from "./Label";

/** Variables globales */
const icon = <InfoIcon color="info" sx={{ fontSize: "16px" }} />;

/**
 * @name AddExpress
 * @description Muestra los componentes del modal para agregar express
 * @param {Object} { data: useState, setData: useState, nameFocus: useRef, taxesTypes: Array, taxes: Array }
 * @returns View
 * @version 1.0
 */
const AddExpress = ({ data, setData, nameFocus, priceFocus, taxesTypes, taxes }) => {
  const css = styles();
  const { lang, currency, settings, snapshotBranches, branch } = useThis();

  const [finalPrice, setfinalPrice] = useState(0);
  const [dataBranch] = useState(snapshotBranches.find((doc) => doc.id === branch.branchId));

  /** Efecto para manejar el label del precio final */
  useEffect(() => {
    setfinalPrice(formatNumber(getFinalPrice({ priceCalculation: settings.priceCalculation, basePrice: data.price, taxTariff: data.tax.tariff }).total));
  }, [data.price, data.tax.tariff]);

  return (
    <div style={css.container}>
      <InputText
        data={data}
        type={"text"}
        name={"name"}
        size={"small"}
        width={"auto"}
        marginBottom={2}
        setData={setData}
        value={data.name}
        label={lang.place}
        reference={nameFocus}
        icon={<EditLocationIcon />}
      />
      <InputText
        data={data}
        size={"small"}
        width={"auto"}
        name={"price"}
        type={"number"}
        setData={setData}
        label={lang.price}
        value={data.price}
        icon={<SellIcon />}
        reference={priceFocus}
        marginBottom={dataBranch.data().electronicBill ? 2 : 0}
        startProp={<InputAdornment position="start">{currency.symbol}</InputAdornment>}
      />
      {dataBranch.data().electronicBill && (
        <div>
          <Selector name="taxType" data={data} setData={setData} collection={taxesTypes} group={2} iconType={1} />
          <br />
          <Selector name="tax" data={data} setData={setData} collection={taxes} group={2} iconType={1} />
          <Label tooltip={lang.cabysMessage} icon={icon} text={lang.cabysCode} fontWeight={500} margin="12px 0 10px 0" />
          <InputText
            data={data}
            type={"text"}
            size={"small"}
            width={"auto"}
            name={"cabys"}
            setData={setData}
            value={data.cabys}
            label={lang.cabysCode}
            icon={<ViewColumnIcon />}
            marginBottom={dataBranch.data().electronicBill ? 1 : 0}
          />
          <div style={css.price}>
            <Label
              color={"#444"}
              fontWeight={500}
              text={lang.finalPrice}
              icon={<InfoIcon color="info" sx={css.icon} />}
              tooltip={lang.priceType + (settings.priceCalculation ? lang.simple : lang.compound)}
            />
            <Label text={currency.symbol + finalPrice} fontWeight={500} color={"#444"} />
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    icon: { fontSize: "16px" },
    container: { display: "flex", flexDirection: "column" },
    price: { display: "flex", justifyContent: "space-between", marginTop: "10px" },
  };
};

export default AddExpress;
