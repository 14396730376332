import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, serverTimestamp, updateDoc } from "firebase/firestore";
import { deleteAnyFile, getAllCategories, uploadAnyFile } from "../../../assets/js/firebaseMethods";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import { db } from "../../../assets/context/firebase-config";
import { Edit, DeleteForever } from "@mui/icons-material";
import { formatNumber } from "../../../assets/js/Commons";
import { useThis } from "../../../assets/context/Context";
import GenericGridType2 from "../grids/GenericGridType2";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import getConstant from "../../../assets/js/Constant";
import endpoints from "../../../assets/js/Endpoints";
import { useEffect, useRef, useState } from "react";
import HeaderProducts from "../HeaderProducts";
import DeleteAnything from "../DeleteAnything";
import Modal from "../../containers/Modal";
import LoadingBar from "../LoadingBar";
import AddMenu from "../AddMenu";
import Message from "../Message";
import { v4 } from "uuid";

/**
 * @name ProductsMenu
 * @description Método utilizado para administrar los menús, carga la lista en una dataGrid
 * @returns View
 * @version 1.0
 */
const ProductsMenu = () => {
  const css = styles();
  const constant = getConstant();
  const { lang, branch, settings, currency, setAllMenus, setAuxAllMenus } = useThis();

  const nameFocus = useRef(null);
  const priceFocus = useRef(null);
  const categoryFocus = useRef(null);

  const [row, setRow] = useState({});
  const [data, setData] = useState({});
  const [rows, setRows] = useState([]);
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [edit, setEdit] = useState(false);
  const [auxRows, setAuxRows] = useState([]);
  const [idToEdit, setIdToEdit] = useState("");
  const [loading, setLoading] = useState(false); // Para el modal
  const [categories, setCategories] = useState([]);
  const [snackLocal, setSnackLocal] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [loadingBar, setLoadingBar] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [showDeleteBtn, setShowDeleteBtn] = useState(true);
  const [openAddOrEditMenu, setOpenAddOrEditMenu] = useState(false);
  const [openModalToDelete, setOpenModalToDelete] = useState(false);
  const [taxes, setTaxes] = useState([{ id: 0, name: lang.selectTax, icon: <TouchAppIcon sx={css.selectTax} />, code: "00", tariff: 0 }]);
  const [taxesTypes, setTaxesTypes] = useState([{ id: 0, name: lang.selectTaxType, icon: <TouchAppIcon sx={css.selectTax} />, code: "00" }]);

  /**
   * @name filterMenu
   * @description Método utilizado para filtrar los menús de la tabla por nombre
   * @param {Event} e
   * @version 1.0
   */
  const filterMenu = (e) => {
    const menu = auxRows.reduce((acc, item) => {
      if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) acc.push(item);
      return acc;
    }, []);
    setRows(menu);
  };

  /**
   * @name showCreateMenu
   * @description Método utilizado para mostrar el modal de agregar o editar un menú
   * @returns N/A
   * @version 1.0
   */
  const showCreateMenu = () => {
    if (loadingBar) return;
    setEdit(false);
    /** Inicializa los datos */
    setData(resetData());
    /** Muestra el modal para crear o ediar express */
    setOpenAddOrEditMenu(true);
  };

  /**
   * @name resetData
   * @description Método que inicializa los datos del menú
   * @returns {Object}
   * @version 1.0
   */
  const resetData = () => {
    return {
      url: "",
      name: "",
      cabys: "",
      utility: "",
      status: true,
      basePrice: "",
      tax: taxes[0],
      prodAndserv: [],
      requestKitchen: true,
      taxType: taxesTypes[0],
      category: categories.length > 0 ? categories[0] : { firstLetter: "", id: 0, name: "" },
    };
  };

  /**
   * @name addOrEditMenu
   * @description Método que maneja la acción del botón para editar o crear un menú
   * @returns N/A
   * @version 1.0
   */
  const addOrEditMenu = async () => {
    if (loading) return;
    // Se validan los inputs
    if (!data.name) {
      nameFocus.current.focus();
      return setSnack([99, constant.error]);
    }
    if (!data.category.name) {
      categoryFocus.current.querySelector("input").focus();
      return setSnack([100, constant.error]);
    }
    if (!data.basePrice) {
      priceFocus.current.focus();
      return setSnack([94, constant.error]);
    }
    if (data.electronicBill && data.taxType.code === "00") return setSnack([101, constant.error]);
    if (data.electronicBill && data.tax.code === "00") return setSnack([102, constant.error]);

    setLoading(true);

    const code = v4();
    const required = {
      name: data.name,
      status: data.status,
      utility: data.utility,
      category: data.category,
      date: serverTimestamp(),
      basePrice: data.basePrice,
      finalPrice: data.finalPrice,
      prodAndserv: data.prodAndserv,
      tax: { ...data.tax, icon: null },
      requestKitchen: data.requestKitchen,
      taxType: { ...data.taxType, icon: null },
      cabys: data.cabys || constant.defaultCabys.code,
    };

    try {
      if (edit) {
        const info = data.file ? { ...required, code } : required;
        // Si al editar existe un archivo en el input, se elimina la imagen anterior
        let imageDeleted = false;
        if ((data.file && data.auxUrl) || (!data.url && data.auxUrl)) {
          await deleteAnyFile(endpoints.storeMenu(branch.administrator, branch.branchId, data.code + constant.imageExtension));
          imageDeleted = true;
        }
        // Se actualiza el menú
        await updateDoc(doc(db, endpoints.menu(branch.administrator, branch.branchId, idToEdit)), { ...info, url: imageDeleted ? "" : data.url });
      } else {
        // Se agrega el documento del menú
        await addDoc(collection(db, endpoints.menus(branch.administrator, branch.branchId)), { ...required, code });
      }

      // Se almacena la imagen en el bucket y el trigger "resizeAndConvertToWebP" la relaciona con este documento mediante el "code"
      if (data.file) {
        const imgName = data.file.name.split(".").pop().toLowerCase();
        await uploadAnyFile(endpoints.storeMenu(branch.administrator, branch.branchId, code + "." + imgName), data.file);
      }

      setSnackLocal([edit ? 105 : 103, constant.success]);
      setOpenAddOrEditMenu(false);
    } catch (error) {
      console.log(error);
      setSnack([edit ? 104 : 106, constant.error]);
    }
    setLoading(false);
  };

  /**
   * @name actionRowDelete
   * @description Método que maneja el evento del botón eliminar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowDelete = (params) => {
    setRow(params.row);
    setOpenModalToDelete(true);
    setDeleteMessage(lang.deleteMenuMessage.replace("[MENU]", params.row.name));
  };

  /**
   * @name actionRowsDelete
   * @description Muestra el modal para eliminar varias líneas
   * @version 1.0
   */
  const actionRowsDelete = () => {
    const message = selectedRows.length === 1 ? lang.deleteOneMenuMessage : lang.deleteMoreMenuMessage.replace("[MENU]", selectedRows.length);
    setDeleteMessage(message);
    setOpenModalToDelete(true);
  };

  /**
   * @name deleteOneMenu
   * @description Método utilizado para eliminar un menú
   * @returns N/A
   * @version 1.0
   */
  const deleteOneMenu = async () => {
    if (loading) return;
    setLoading(true);

    try {
      if (row.url) await deleteAnyFile(endpoints.storeMenu(branch.administrator, branch.branchId, row.code + constant.imageExtension));
      await deleteDoc(doc(db, endpoints.menu(branch.administrator, branch.branchId, row.id)));
      setSnackLocal([108, constant.success]);
      setOpenModalToDelete(false);
    } catch (error) {
      setSnack([109, constant.error]);
    }
    setLoading(false);
  };

  /**
   * @name deleteMoreMenu
   * @description Método para eliminar múltiples menús
   * @returns N/A
   * @version 1.0
   */
  const deleteMoreMenu = async () => {
    if (loading) return;
    setLoading(true);
    let hasErrors = false;
    for (let x = 0; x < rows.length; x++) {
      if (selectedRows.includes(rows[x].id)) {
        try {
          if (rows[x].url) await deleteAnyFile(endpoints.storeMenu(branch.administrator, branch.branchId, rows[x].code + constant.imageExtension));
          await deleteDoc(doc(db, endpoints.menu(branch.administrator, branch.branchId, rows[x].id)));
        } catch (error) {
          hasErrors = true;
        }
      }
    }
    setLoading(false);
    if (hasErrors) return setSnack([79, constant.error]);
    setOpenModalToDelete(false);
    setSnackLocal([110, constant.success]);
  };

  /**
   * @name actionRowEdit
   * @description Método que maneja el evento del botón editar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowEdit = (params) => {
    setEdit(true);
    setData(params.row);
    setIdToEdit(params.row.id);
    setOpenAddOrEditMenu(true);
  };

  /** Constante que define las columnas para la tabla */
  const columns = [
    { field: "name", headerName: lang.menu, flex: 1 },
    { field: "categoryName", headerName: lang.category, flex: 1 },
    { field: "basePriceText", headerName: lang.basePrice, flex: 1 },
    { field: "utilityText", headerName: lang.utility, flex: 1 },
    { field: "taxDigit", headerName: lang.tax, flex: 1 },
    { field: "finalPriceText", headerName: lang.finalPrice, flex: 1 },
    { field: "statusText", headerName: lang.status, flex: 1 },
    { field: "action", headerName: lang.actions, flex: 0 },
  ];

  /** Constante que almacena los datos para los botones de acciones de cada fila */
  const actions = [
    { icon: <Edit />, callBack: actionRowEdit, color: constant.editColor },
    { icon: <DeleteForever />, callBack: actionRowDelete, color: constant.deleteColor },
  ];

  /**
   * @description Objeto para la creación del modal agregar o editar menú
   */
  const paramsToAddOrEditMenu = {
    btnText: edit ? lang.edit : lang.add,
    title: edit ? lang.editMenu : lang.addMenu,
    icon: edit ? <EditLocationIcon /> : <BookmarkAddIcon />,
    content: (
      <AddMenu
        data={data}
        edit={edit}
        taxes={taxes}
        setData={setData}
        nameFocus={nameFocus}
        priceFocus={priceFocus}
        categories={categories}
        taxesTypes={taxesTypes}
        categoryFocus={categoryFocus}
      />
    ),
  };

  /**
   * @description Objeto para la creación del modal eliminar menú
   */
  const parametersToDeleteMenu = {
    btnText: lang.delete,
    title: lang.deleteMenu,
    icon: <DeleteForever />,
    content: <DeleteAnything message={deleteMessage} />,
  };

  /** Efecto para cargar las categorías en el input Autocomplete */
  useEffect(() => {
    const getCategories = async () => {
      setLoadingBar(true);
      const response = await getAllCategories(branch.administrator, branch.branchId);
      setLoadingBar(false);
      if (response.error) return;
      const list = response.categories.docs.reduce((acc, doc) => {
        acc.push({ id: doc.id, name: doc.data().category });
        return acc;
      }, []);
      setCategories(
        list.map((option) => {
          const firstLetter = option.name[0].toUpperCase();
          return { firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter, ...option };
        })
      );
    };
    getCategories();
  }, []);

  /** Efecto para manejar la carga de los impuestos en los comboBox */
  useEffect(() => {
    // Carga la lista de tipos de impuestos de firestore
    const tt = settings.taxesTypes.reduce((acc, e, i) => {
      acc.push({ id: i + 1, name: e.name, icon: null, code: e.code });
      return acc;
    }, []);
    setTaxesTypes((prev) => [...prev, ...tt]);
    // Carga la lista de impuestos de firestore
    const tc = settings.tariffCodes.reduce((acc, e, i) => {
      acc.push({ id: i + 1, name: e.description, icon: null, code: e.code, tariff: e.tariff });
      return acc;
    }, []);
    setTaxes((prev) => [...prev, ...tc]);
  }, []);

  /** Efecto que mantiene actualizada la tabla de menú */
  useEffect(() => {
    setLoadingBar(true);
    const collectionMenus = collection(db, endpoints.menus(branch.administrator, branch.branchId));
    const queryMenus = query(collectionMenus, orderBy("name", "asc"));
    // Escucha los cambios en los datos del menu
    const unsubscribe = onSnapshot(queryMenus, (snapshot) => {
      setAllMenus(() => []);
      setAuxAllMenus(() => []);
      // Almacena las filas de cada menú
      const menus = snapshot.docs.reduce((acc, doc) => {
        const status = doc.data().status ? lang.active : lang.inactive;
        const finalPriceText = currency.symbol + formatNumber(doc.data().finalPrice * 1);
        const menu = {
          id: doc.id,
          finalPriceText,
          statusText: status,
          tax: doc.data().tax,
          name: doc.data().name,
          code: doc.data().code,
          idMenuOrExpress: doc.id,
          cabys: doc.data().cabys,
          url: doc.data().url || "",
          status: doc.data().status,
          taxType: doc.data().taxType,
          utility: doc.data().utility,
          auxUrl: doc.data().url || "",
          category: doc.data().category,
          basePrice: doc.data().basePrice,
          finalPrice: doc.data().finalPrice,
          idCategory: doc.data().category.id,
          prodAndserv: doc.data().prodAndserv,
          taxDigit: doc.data().tax.tariff + "%",
          categoryName: doc.data().category.name,
          requestKitchen: doc.data().requestKitchen,
          utilityText: (doc.data().utility || 0) + "%",
          nameWithPrice: doc.data().name + " " + finalPriceText,
          basePriceText: currency.symbol + formatNumber(doc.data().basePrice * 1),
        };
        acc.push(menu);
        return acc;
      }, []);
      setRows(menus);
      setAuxRows(menus);
      setLoadingBar(false);
      // Para menejo global, en el modal de órdenes
      setAllMenus(() => menus);
      setAuxAllMenus(() => menus);
    });
    return () => unsubscribe();
  }, []);

  /** Efecto que maneja el estado de la selección de filas */
  useEffect(() => {
    setShowDeleteBtn(!selectedRows.length);
  }, [selectedRows]);

  return (
    <div style={css.box}>
      <HeaderProducts
        icons={2}
        toolTip={lang.addMenu}
        title={lang.manageMenu}
        actionSearch={filterMenu}
        selectedRows={selectedRows}
        actionButton={showCreateMenu}
        actionButtonDelete={actionRowsDelete}
      />
      <LoadingBar visible={loadingBar} />
      <div style={css.containerGrid}>
        <GenericGridType2 rowHeight={40} columns={columns} rows={rows} actions={actions} setSelectedRows={setSelectedRows} showDeleteBtn={showDeleteBtn} />
      </div>
      {/** Modal para agregar un menú */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openAddOrEditMenu}
        clickBtnOk={addOrEditMenu}
        setOpen={setOpenAddOrEditMenu}
        icon={paramsToAddOrEditMenu.icon}
        title={paramsToAddOrEditMenu.title}
        btnText={paramsToAddOrEditMenu.btnText}
        content={paramsToAddOrEditMenu.content}
      />
      {/** Modal para eliminar un menú */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openModalToDelete}
        colorBar={constant.error}
        color={constant.deleteColor}
        setOpen={setOpenModalToDelete}
        icon={parametersToDeleteMenu.icon}
        title={parametersToDeleteMenu.title}
        btnText={parametersToDeleteMenu.btnText}
        content={parametersToDeleteMenu.content}
        clickBtnOk={selectedRows.length ? deleteMoreMenu : deleteOneMenu}
      />
      <Message snack={snackLocal}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    box: { width: "100%" },
    containerGrid: { flex: "1", overflowY: "auto" },
    selectTax: { fontSize: "16px", color: "#444", marginRight: "5px" },
  };
};

export default ProductsMenu;
