import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { Box, IconButton, Tooltip, Zoom } from "@mui/material";
import { useThis } from "../../../../assets/context/Context";
import getConstant from "../../../../assets/js/Constant";
import touch from "../../../../images/touch.webp";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import Selector from "../../Selector";
import Message from "../../Message";

/**
 * @name MoveOrders
 * @description Método que retorna el contenido del modal para el traslado de órdenes
 * @param {Object} { spaces, rows, title, setMoveOrders }
 * @returns View
 * @version 1.0
 */
const MoveOrders = ({ spaces, rows, title, setMoveOrders }) => {
  const { lang } = useThis();
  const constant = getConstant();
  const css = styles(constant);

  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [orders1, setOrders1] = useState([]);
  const [orders2, setOrders2] = useState(rows);
  const [selectedRows1, setSelectedRows1] = useState([]);
  const [selectedRows2, setSelectedRows2] = useState([]);
  const [data, setData] = useState({ space: { id: 0, name: lang.selectSpace, icon: touch, code: "0", orders: [] } });

  /**
   * @name sendOrders
   * @description Método que envía pedidos a la tabla de otros espacios (tabla de arriba)
   * @returns N/A
   * @version 1.0
   */
  const sendOrders = () => {
    if (selectedRows2.length === 0) return setSnack([116, constant.error]);
    const orders = orders2.reduce((acc, e) => {
      if (selectedRows2.includes(e.id)) acc.push(e);
      return acc;
    }, []);
    setOrders1((prev) => [...prev, ...orders]);
    setOrders2(orders2.filter((e) => !selectedRows2.includes(e.id)));
  };

  /**
   * @name bringOrders
   * @description Método que trae pedidos a la tabla del espacio actual (tabla de abajo)
   * @returns N/A
   * @version 1.0
   */
  const bringOrders = () => {
    if (selectedRows1.length === 0) return setSnack([116, constant.error]);
    const orders = orders1.reduce((acc, e) => {
      if (selectedRows1.includes(e.id)) acc.push(e);
      return acc;
    }, []);
    setOrders2((prev) => [...prev, ...orders]);
    setOrders1(orders1.filter((e) => !selectedRows1.includes(e.id)));
  };

  /** Columnas para las tablas traslado de pedidos */
  const columns1 = [
    { field: "name", headerName: data.space.id === 0 ? lang.order : lang.orderOf + data.space.name.toLowerCase(), sortable: false, flex: 1 },
    { field: "finalPriceText", headerName: lang.price, sortable: false, headerAlign: "right", align: "right", flex: 0 },
  ];
  const columns2 = [
    { field: "name", headerName: lang.orderOf + title.toLowerCase(), sortable: false, flex: 1 },
    { field: "finalPriceText", headerName: lang.price, sortable: false, headerAlign: "right", align: "right", flex: 0 },
  ];

  /** Efecto que maneja el cambio del selector de espacios */
  useEffect(() => {
    setOrders2(rows);
    setOrders1(spaces[data.space.id].orders);
  }, [data.space.id]);

  /** Efecto que prepara los datos para realizar el traslado a nivel de firestore */
  useEffect(() => {
    setMoveOrders({ otherSpace: orders1, thisSpace: orders2, idOtherSpace: data.space.code });
  }, [orders1.length, orders2.length]);

  return (
    <div>
      <Selector group={2} data={data} iconType={0} name="space" setData={setData} collection={spaces} width={30} />
      <div style={css.divider1} />
      <Box sx={css.container}>
        <DataGrid
          hideFooter
          sx={css.grid}
          rowHeight={30}
          rows={orders1}
          checkboxSelection
          disableColumnMenu
          columns={columns1}
          disableColumnFilter
          disableRowSelectionOnClick
          localeText={{ noRowsLabel: lang.noOrders }}
          onRowSelectionModelChange={(params) => setSelectedRows1(params)}
        />
      </Box>
      <div style={css.fabs}>
        <Tooltip TransitionComponent={Zoom} title={lang.sendOrders}>
          <IconButton onClick={sendOrders} sx={css.new}>
            <ArrowCircleUpIcon />
          </IconButton>
        </Tooltip>
        <div style={css.divider2} />
        <Tooltip TransitionComponent={Zoom} title={lang.bringOrders}>
          <IconButton onClick={bringOrders} sx={css.new}>
            <ArrowCircleDownIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Box sx={css.container}>
        <DataGrid
          hideFooter
          sx={css.grid}
          rows={orders2}
          rowHeight={30}
          checkboxSelection
          disableColumnMenu
          columns={columns2}
          disableColumnFilter
          localeText={{ noRowsLabel: lang.noOrders }}
          onRowSelectionModelChange={(params) => setSelectedRows2(params)}
        />
      </Box>
      <Message snack={snack}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} constant
 * @returns Object
 * @version 1.0
 */
const styles = (constant) => {
  return {
    divider2: { width: "15px" },
    divider1: { marginBottom: "10px" },
    new: { width: "auto", color: "#5AB262" },
    fabs: { display: "flex", justifyContent: "center" },
    container: { height: 130, width: "320px", maxWidth: "320px" },
    grid: {
      fontSize: "12px",
      "& .MuiDataGrid-columnHeader": { height: "30px !important" },
      "& .MuiDataGrid-columnHeaderTitle": { fontSize: "12px", fontWeight: 600 },
      "& .MuiDataGrid-checkboxInput .MuiSvgIcon-root": { color: constant.primaryColor },
    },
  };
};

export default MoveOrders;
