import { addDoc, collection, doc, serverTimestamp, Timestamp, updateDoc } from "firebase/firestore";
import { formatDate, formatNumber } from "../../../../assets/js/Commons";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import PaymentsTwoToneIcon from "@mui/icons-material/PaymentsTwoTone";
import { getClosingBox } from "../../../../assets/js/firebaseMethods";
import { db } from "../../../../assets/context/firebase-config";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TollTwoToneIcon from "@mui/icons-material/TollTwoTone";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { useThis } from "../../../../assets/context/Context";
import getConstant from "../../../../assets/js/Constant";
import endpoints from "../../../../assets/js/Endpoints";
import { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import LoadingButton from "../../LoadingButton";
import AlertMessage from "../../AlertMessage";
import InputText from "../../InputText";
import Label from "../../Label";

/**
 * @name OpeningAndClosing
 * @description Método que carga el módulo de apertura y cierre de caja
 * @returns View
 * @version 1.0
 */
const OpeningAndClosing = () => {
  const { lang, currency, config, dataBranch, settings, branch, user, closingBoxCurrent, setClosingBoxCurrent } = useThis();
  const currencies = config.contries[dataBranch.country.code].currencies;
  const exchange = settings.exchangeRate * 1;
  const constant = getConstant();
  const css = styles(constant);

  const sinpeFocus = useRef(null);
  const cardsFocus = useRef(null);
  const checksFocus = useRef(null);
  const creditsFocus = useRef(null);
  const expensesFocus = useRef(null);
  const transfersFocus = useRef(null);
  const btnOpeningFocus = useRef(null);
  const colonCoinsFocus = useRef(null);
  const colonBillsFocus = useRef(null);
  const dollarBillsFocus = useRef(null);
  const dollarCoinsFocus = useRef(null);

  const [numberBox] = useState("001");
  const [total, setTotal] = useState(0);
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [loading, setLoading] = useState(false);
  const [systemCash, setSystemCash] = useState(0);
  const [closingBox, setClosingBox] = useState([]);
  const [loadingBar, setLoadingBar] = useState(true);
  const [data, setData] = useState(constant.opening);
  const [count, setCount] = useState(constant.closingCount);
  const [system, setSystem] = useState(constant.closingSystem);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [date, setDate] = useState(formatDate(Timestamp.fromDate(new Date()), lang));

  /**
   * @name openBoxOrcloseBox
   * @description Método que se encarga de abrir o cerrar la caja
   * @returns N/A
   * @version 1.0
   */
  const openBoxOrcloseBox = async () => {
    if (loading) return;
    try {
      setLoading(true);
      if (closingBoxCurrent) {
        const request = {
          ...closingBoxCurrent,
          diff: total,
          status: true,
          closingCount: count,
          closingDate: serverTimestamp(),
          closingSystem: { ...system, cash: system.cash + systemCash },
        };
        await updateDoc(doc(db, endpoints.closingBox(branch.administrator, branch.branchId, closingBoxCurrent.id)), request);
      } else {
        const request = {
          numberBox,
          status: false,
          user: user.uid,
          opening: { ...data },
          openingDate: serverTimestamp(),
          exchange: settings.exchangeRate * 1,
        };
        await addDoc(collection(db, endpoints.closingBoxes(branch.administrator, branch.branchId)), request);
      }
      setTotal(0);
      setData(constant.opening);
      setOpenAlertMessage(false);
      setCount(constant.closingCount);
      setSystem(constant.closingSystem);
    } catch (error) {
      setSnack([closingBoxCurrent ? 149 : 148, constant.error]);
    }
    setLoading(false);
  };

  /**
   * @name onKey
   * @description Método que se encarga de mover el foco al siguiente input al presionar enter
   * @param {Event} e
   * @returns N/A
   * @version 1.0
   */
  const onKey = (e) => {
    if (e.key !== "Enter") return;
    switch (e.target.name) {
      case "dollarCoins":
        dollarBillsFocus.current.focus();
        break;
      case "dollarBills":
        colonCoinsFocus.current.focus();
        break;
      case "colonCoins":
        colonBillsFocus.current.focus();
        break;
      case "colonBills":
        btnOpeningFocus.current.focus();
        break;
      case "cash":
        checksFocus.current.focus();
        break;
      case "checks":
        transfersFocus.current.focus();
        break;
      case "transfers":
        sinpeFocus.current.focus();
        break;
      case "sinpe":
        cardsFocus.current.focus();
        break;
      case "cards":
        expensesFocus.current.focus();
        break;
      case "expenses":
        creditsFocus.current.focus();
        break;
      case "credits":
        btnOpeningFocus.current.focus();
        break;
      default:
        btnOpeningFocus.current.focus();
        break;
    }
  };

  /** Efecto que muestra la hora corriendo */
  useEffect(() => {
    const interval = setInterval(() => {
      setDate(formatDate(Timestamp.fromDate(new Date()), lang));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  /** Efecto que calcula el total del cierre */
  useEffect(() => {
    if (!closingBoxCurrent) return;
    const openingCash =
      closingBoxCurrent.opening.dollarCoins * closingBoxCurrent.exchange +
      closingBoxCurrent.opening.dollarBills * closingBoxCurrent.exchange +
      closingBoxCurrent.opening.colonCoins * 1 +
      closingBoxCurrent.opening.colonBills * 1;
    const sum = system.cash + openingCash;
    setSystemCash(sum);
    setTotal(Object.values(count).reduce((a, n) => a + n * 1, 0) - (Object.values(system).reduce((a, n) => a + n * 1, 0) + sum));
  }, [count, system, closingBoxCurrent]);

  /** Efecto que calcula el total de apertura */
  useEffect(() => {
    setTotal(data.dollarCoins * exchange + data.dollarBills * exchange + data.colonCoins * 1 + data.colonBills * 1);
  }, [data]);

  /** Efecto para obtener obtener y escuchar los cambios de los cierres de caja */
  useEffect(() => {
    getClosingBox(setClosingBox, branch.administrator, user.uid, branch.branchId, setLoadingBar);
  }, []);

  /** Efecto que carga el cierre de caja actual */
  useEffect(() => {
    const result = closingBox.find((element) => element.status === false);
    if (result && result.openingDate) {
      setClosingBoxCurrent(result);
    } else {
      setClosingBoxCurrent(null);
    }
  }, [closingBox]);

  return (
    <div style={css.box}>
      {loadingBar ? (
        <CircularProgress color="success" />
      ) : (
        <div style={css.container}>
          <div style={css.title}>
            <Label
              fontSize="22px"
              fontWeight={600}
              icon={<PointOfSaleIcon sx={css.titleIcon} />}
              text={closingBoxCurrent ? lang.closeBox : lang.boxOpening}
            />
            <Label width={"auto"} text={date} />
          </div>
          {closingBoxCurrent ? (
            <div>
              <table style={css.table}>
                <tbody>
                  <tr style={css.firstRow}>
                    <td style={{ ...css.l, width: "100px" }}>
                      <Label fontSize="18px" text={lang.items} />
                    </td>
                    <td style={css.totals2}>
                      <Label fontSize="18px" text={lang.system} />
                    </td>
                    <td style={css.totals}>
                      <Label fontSize="18px" text={lang.count} />
                    </td>
                    <td style={{ ...css.totals, paddingRight: "0px" }}>
                      <Label fontSize="18px" text={lang.diff} />
                    </td>
                  </tr>
                  <tr>
                    <td style={css.l}>{lang.kash + ":"}</td>
                    <td>{currency.symbol + formatNumber(systemCash)}</td>
                    <td>
                      <InputText
                        data={count}
                        name={"cash"}
                        onKey={onKey}
                        size={"small"}
                        width={"160px"}
                        type={"number"}
                        setData={setCount}
                        maxWidth={"180px"}
                        value={count.cash}
                        marginRight={"5px"}
                        icon={<PaymentsTwoToneIcon />}
                      />
                    </td>
                    <td style={{ ...css.total }}>
                      <Label fontSize="18px" text={currency.symbol + formatNumber(count.cash - systemCash)} />
                    </td>
                  </tr>
                  <tr>
                    <td style={css.l}>{lang.cards + ":"}</td>
                    <td>{currency.symbol + formatNumber(system.cards)}</td>
                    <td>
                      <InputText
                        data={count}
                        onKey={onKey}
                        name={"cards"}
                        size={"small"}
                        type={"number"}
                        width={"160px"}
                        setData={setCount}
                        maxWidth={"180px"}
                        marginRight={"5px"}
                        value={count.cards}
                        reference={cardsFocus}
                        icon={<PaymentsTwoToneIcon />}
                      />
                    </td>
                    <td style={css.total}>
                      <Label fontSize="18px" text={currency.symbol + formatNumber(count.cards - system.cards)} />
                    </td>
                  </tr>
                  <tr>
                    <td style={css.l}>{lang.sinpe + ":"}</td>
                    <td>{currency.symbol + formatNumber(system.sinpe)}</td>
                    <td>
                      <InputText
                        data={count}
                        onKey={onKey}
                        size={"small"}
                        name={"sinpe"}
                        type={"number"}
                        width={"160px"}
                        setData={setCount}
                        maxWidth={"180px"}
                        marginRight={"5px"}
                        value={count.sinpe}
                        reference={sinpeFocus}
                        icon={<PaymentsTwoToneIcon />}
                      />
                    </td>
                    <td style={css.total}>
                      <Label fontSize="18px" text={currency.symbol + formatNumber(count.sinpe - system.sinpe)} />
                    </td>
                  </tr>
                  <tr>
                    <td style={css.l}>{lang.transfers + ":"}</td>
                    <td>{currency.symbol + formatNumber(system.transfers)}</td>
                    <td>
                      <InputText
                        data={count}
                        onKey={onKey}
                        size={"small"}
                        type={"number"}
                        width={"160px"}
                        setData={setCount}
                        maxWidth={"180px"}
                        name={"transfers"}
                        marginRight={"5px"}
                        value={count.transfers}
                        reference={transfersFocus}
                        icon={<PaymentsTwoToneIcon />}
                      />
                    </td>
                    <td style={css.total}>
                      <Label fontSize="18px" text={currency.symbol + formatNumber(count.transfers - system.transfers)} />
                    </td>
                  </tr>
                  <tr>
                    <td style={css.l}>{lang.checkbooks + ":"}</td>
                    <td>{currency.symbol + formatNumber(system.checks)}</td>
                    <td>
                      <InputText
                        data={count}
                        onKey={onKey}
                        size={"small"}
                        type={"number"}
                        name={"checks"}
                        width={"160px"}
                        setData={setCount}
                        maxWidth={"180px"}
                        marginRight={"5px"}
                        value={count.checks}
                        reference={checksFocus}
                        icon={<PaymentsTwoToneIcon />}
                      />
                    </td>
                    <td style={{ ...css.total }}>
                      <Label fontSize="18px" text={currency.symbol + formatNumber(count.checks - system.checks)} />
                    </td>
                  </tr>
                  <tr>
                    <td style={css.l}>{lang.expenses + ":"}</td>
                    <td>{currency.symbol + formatNumber(system.expenses)}</td>
                    <td>
                      <InputText
                        data={count}
                        onKey={onKey}
                        size={"small"}
                        width={"160px"}
                        type={"number"}
                        name={"expenses"}
                        setData={setCount}
                        maxWidth={"180px"}
                        marginRight={"5px"}
                        value={count.expenses}
                        reference={expensesFocus}
                        icon={<PaymentsTwoToneIcon />}
                      />
                    </td>
                    <td style={css.total}>
                      <Label fontSize="18px" text={currency.symbol + formatNumber(count.expenses - system.expenses)} />
                    </td>
                  </tr>
                  <tr>
                    <td style={css.l}>{lang.credits + ":"}</td>
                    <td>{currency.symbol + formatNumber(system.credits)}</td>
                    <td>
                      <InputText
                        data={count}
                        onKey={onKey}
                        size={"small"}
                        type={"number"}
                        width={"160px"}
                        name={"credits"}
                        setData={setCount}
                        maxWidth={"180px"}
                        marginRight={"5px"}
                        value={count.credits}
                        reference={creditsFocus}
                        icon={<PaymentsTwoToneIcon />}
                      />
                    </td>
                    <td style={css.total}>
                      <Label fontSize="18px" text={currency.symbol + formatNumber(count.credits - system.credits)} />
                    </td>
                  </tr>
                  <tr style={css.lastRow}>
                    <td style={css.l}>
                      <Label text={lang.totals} />
                    </td>
                    <td style={css.totals2}>
                      <Label text={currency.symbol + formatNumber(Object.values(system).reduce((a, n) => a + n * 1, 0) + systemCash)} />
                    </td>
                    <td style={css.totals}>
                      <Label text={currency.symbol + formatNumber(Object.values(count).reduce((a, n) => a + n * 1, 0))} />
                    </td>
                    <td style={css.total}>
                      <Label text={currency.symbol + formatNumber(total)} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div>
              <br />
              <Label text={lang.openingCash} fontSize="22px" fontWeight={400} />
              <br />
              <table style={css.table}>
                <tbody>
                  <tr>
                    <td style={css.column1}>
                      <Label fontSize="18px" text={lang.currencies[currencies[1].code]} />
                    </td>
                    <td style={css.column1}>
                      <Label fontSize="18px" text={lang.currencies[currency.code]} />
                    </td>
                    <td style={css.column2}>
                      <Label fontSize="18px" text={lang.totals} />
                    </td>
                  </tr>
                  <tr>
                    <td style={css.pb}>
                      <InputText
                        data={data}
                        onKey={onKey}
                        width={"180px"}
                        size={"small"}
                        type={"number"}
                        setData={setData}
                        maxWidth={"180px"}
                        marginRight={"5px"}
                        name={"dollarCoins"}
                        label={lang.inCoins}
                        value={data.dollarCoins}
                        icon={<TollTwoToneIcon />}
                        reference={dollarCoinsFocus}
                      />
                    </td>
                    <td style={css.pb}>
                      <InputText
                        data={data}
                        onKey={onKey}
                        size={"small"}
                        width={"180px"}
                        type={"number"}
                        setData={setData}
                        maxWidth={"180px"}
                        name={"colonCoins"}
                        marginRight={"5px"}
                        label={lang.inCoins}
                        value={data.colonCoins}
                        icon={<TollTwoToneIcon />}
                        reference={colonCoinsFocus}
                      />
                    </td>
                    <td style={{ ...css.total, ...css.pb }}>
                      <Label fontSize="18px" text={currency.symbol + formatNumber(data.dollarCoins * exchange + data.colonCoins * 1)} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <InputText
                        data={data}
                        onKey={onKey}
                        maxWidth={"180px"}
                        width={"180px"}
                        size={"small"}
                        type={"number"}
                        setData={setData}
                        marginRight={"5px"}
                        name={"dollarBills"}
                        label={lang.inBills}
                        value={data.dollarBills}
                        reference={dollarBillsFocus}
                        icon={<PaymentsTwoToneIcon />}
                      />
                    </td>
                    <td>
                      <InputText
                        data={data}
                        onKey={onKey}
                        size={"small"}
                        width={"180px"}
                        maxWidth={"180px"}
                        type={"number"}
                        setData={setData}
                        name={"colonBills"}
                        marginRight={"5px"}
                        label={lang.inBills}
                        value={data.colonBills}
                        reference={colonBillsFocus}
                        icon={<PaymentsTwoToneIcon />}
                      />
                    </td>
                    <td style={css.total}>
                      <Label fontSize="18px" text={currency.symbol + formatNumber(data.dollarBills * exchange + data.colonBills * 1)} />
                    </td>
                  </tr>
                  <tr>
                    <td style={css.totals}>
                      <Label fontSize="18px" text={currencies[1].symbol + formatNumber(data.dollarCoins * 1 + data.dollarBills * 1)} />
                    </td>
                    <td style={css.totals}>
                      <Label fontSize="18px" text={currency.symbol + formatNumber(data.colonCoins * 1 + data.colonBills * 1)} />
                    </td>
                    <td style={css.total}>
                      <Label fontSize="18px" fontWeight={700} text={currency.symbol + formatNumber(total)} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <div style={css.containerBottom}>
            <div style={css.footer}>
              <Label textAlign="right" width={"auto"} text={lang.changeTo + ": " + currency.symbol + exchange} />
              <Label
                width={"auto"}
                textAlign="right"
                text={lang.boxOpening + ": " + (closingBoxCurrent ? formatDate(closingBoxCurrent.openingDate, lang) : date)}
              />
            </div>
            <LoadingButton
              width="100%"
              reference={btnOpeningFocus}
              handleClick={() => setOpenAlertMessage(true)}
              text={closingBoxCurrent ? lang.toCloseBox : lang.openBox}
              color={closingBoxCurrent ? constant.deleteColor : constant.primaryColor}
              icon={closingBoxCurrent ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
            />
          </div>
          {/** Modal para aperturar o cerrar caja */}
          <AlertMessage
            snack={snack}
            twoButtons={true}
            loading={loading}
            setSnack={setSnack}
            open={openAlertMessage}
            onClick={openBoxOrcloseBox}
            setOpen={setOpenAlertMessage}
            title={closingBoxCurrent ? lang.closeBox : lang.boxOpening}
            textButton={closingBoxCurrent ? lang.toCloseBox : lang.openBox}
            icon={closingBoxCurrent ? <LockOutlinedIcon sx={css.openBoxIcon} /> : <LockOpenOutlinedIcon sx={css.openBoxIcon} />}
            message={(closingBoxCurrent ? lang.closeBoxMessage : lang.openBoxMessage) + " " + currency.symbol + formatNumber(total)}
          />
        </div>
      )}
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} constant
 * @returns Object
 * @version 1.0
 */
const styles = (constant) => {
  return {
    pb: { paddingBottom: "10px" },
    firstRow: { fontWeight: 600 },
    openBoxIcon: { color: constant.primaryColor },
    titleIcon: { fontSize: "24px", color: "#444" },
    lastRow: { fontWeight: 700, fontSize: "18px" },
    column1: { textAlign: "left", paddingBottom: "10px" },
    column2: { textAlign: "right", paddingBottom: "10px", width: "30%" },
    total: { textAlign: "right", width: "100px", overflow: "hidden", textOverflow: "ellipsis" },
    table: { borderCollapse: "collapse", width: "100%", textAlign: "center", tableLayout: "fixed" },
    totals: { textAlign: "right", paddingRight: "10px", overflow: "hidden", textOverflow: "ellipsis" },
    footer: { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start" },
    title: { display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "5px" },
    box: { display: "grid", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", overflowY: "auto" },
    container: { margin: "20px", minWidth: "550px", width: "550px", border: "1px solid #CCC", padding: "5px 15px 10px 15px", height: "auto" },
    containerBottom: { display: "flex", justifyContent: "space-between", width: "100%", marginTop: "10px", alignItems: "center" },
    l: { textAlign: "left" },
    totals2: { textAlign: "center", paddingRight: "10px", overflow: "hidden", textOverflow: "ellipsis" },
  };
};

export default OpeningAndClosing;
