import { SearchIconWrapper, SearchView, StyledInputBase } from "./ManageUsers";
import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import { useThis } from "../../assets/context/Context";
import PostAddIcon from "@mui/icons-material/PostAdd";
import getConstant from "../../assets/js/Constant";
import MopedIcon from "@mui/icons-material/Moped";
import { Search } from "@mui/icons-material";
import Label from "./Label";

/**
 * @name HeaderProducts
 * @description Método encargado de pintar la barra de título y búsqueda en el múdulo de Productos
 * @param {Object} { title: String, toolTip: String, actionSearch: callBack, actionButton: callBack, icons: Number,  selectedRows: Array, actionButtonDelete: callBack }
 * @returns View
 * @version 1.0
 */
const HeaderProducts = ({ title, toolTip, actionSearch, actionButton, icons, selectedRows, actionButtonDelete }) => {
  const css = styles();
  const { lang } = useThis();
  const titleIcons = [<FolderSpecialIcon />, <EmojiFoodBeverageIcon />, <RestaurantMenuIcon />, <MopedIcon />, <EmojiPeopleIcon />];
  const buttonIcons = [<CreateNewFolderIcon />, <PostAddIcon />, <BookmarkAddIcon />, <AddLocationAltIcon />, <PersonAddIcon />];

  return (
    <div style={css.header}>
      <div style={css.left}>
        {titleIcons[icons]}
        <Label text={title} margin={"0 0 0 10px"} fontWeight={"500"} fontSize={"20px"} />
      </div>
      <div style={css.right}>
        <SearchView onChange={(e) => actionSearch(e)} sx={css.search}>
          <SearchIconWrapper>
            <Search sx={css.searchIcon} />
          </SearchIconWrapper>
          <StyledInputBase placeholder={lang.searchDotDot} />
        </SearchView>
        <Tooltip TransitionComponent={Zoom} title={selectedRows.length ? lang.deleteAll : toolTip}>
          <IconButton onClick={selectedRows.length ? actionButtonDelete : actionButton} sx={selectedRows.length ? css.delete : css.new}>
            {selectedRows.length ? <DeleteForeverIcon /> : buttonIcons[icons]}
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  const constant = getConstant();
  return {
    searchIcon: { color: "#CCC" },
    search: { color: "#333", background: "#eee" },
    right: { display: "flex", alignItems: "center" },
    new: { width: "auto", color: "#5AB262", marginLeft: "10px" },
    left: { display: "flex", alignItems: "center", color: "#4E4E4E" },
    delete: { width: "auto", color: constant.deleteColor, marginLeft: "10px" },
    header: { display: "flex", justifyContent: "space-between", padding: "10px" },
  };
};

export default HeaderProducts;
