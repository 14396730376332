import getLocalStorageCodes from "../../assets/config/config-localStorage";
import ModalFullScreen from "../containers/ModalFullScreen";
import { useThis } from "../../assets/context/Context";
import ModalTable from "./modules/tables/ModalTable";
import getConstant from "../../assets/js/Constant";
import express from "../../images/express.svg";
import { Tooltip, Zoom } from "@mui/material";
import { useDrag } from "@use-gesture/react";
import { useEffect, useState } from "react";
import table from "../../images/table.svg";
import carry from "../../images/carry.svg";
import MessageInput from "./MessageInput";
import { StyledBadge } from "./AvatarUI";

/** Inicialización de variables */
const constant = getConstant();

/**
 * @name ServiceItem
 * @description Método que se encarga de pintar un sersvicio (mesa, express, llevar) en el módulo Mesas
 * @param {Object} { title, position, status, edge, type, id, setOpenAlertMessage, setSpaceToDelete, orders, services, requestDate }
 * @returns View
 * @version 1.0
 */
const ServiceItem = ({ title, position, status, edge, type, id, setOpenAlertMessage, setSpaceToDelete, orders, services, requestDate }) => {
  const codes = getLocalStorageCodes();
  const images = [table, express, carry];
  const { branch, size, setIsDragging, setIsInside, refDeleteTable, lang } = useThis();

  const [XY, setXY] = useState(position);
  const [border, setBorder] = useState(edge);
  const [cursor, setCursor] = useState("grab");
  const [searchMenu, setSearchMenu] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [openModalTable, setOpenModalTable] = useState(false);

  const css = styles(XY, status, border, cursor, size);

  /**
   * @name handleClickTable
   * @description Método que maneja el evendo del click de cada espacio
   * @version 1.0
   */
  const handleClickTable = () => {
    setOpenMessage(false);
    setOpenModalTable(true);
  };

  /**
   * @name actionSearch
   * @description Método que maneja la búsqueda del menú
   * @param {Event} e
   * @version 1.0
   */
  const actionSearch = (e) => setSearchMenu(e.target.value);

  /**
   * @name currentPosition
   * @description Método que maneja los gestos de cada servicio de la vista
   * @version 1.0
   */
  const currentPosition = useDrag(
    (params) => {
      setXY({ x: params.offset[0], y: params.offset[1] });
      if (params.dragging) {
        setIsDragging(true);
        setCursor("grabbing");
        setIsInside(isInside(params.offset[0], params.offset[1]));
      } else {
        setIsDragging(false);
      }
      if (!params.down) {
        savePosition();
        setBorder(false);
        setCursor("grab");
        if (isInside(params.offset[0], params.offset[1])) {
          setOpenAlertMessage(true);
          setSpaceToDelete({ id, title });
        }
      }
    },
    { pointer: { buttons: -1, touch: true }, from: () => [XY.x, XY.y] }
  );

  /**
   * @name isInside
   * @description Método que valida si un espacio se encuentra dentro del área de eliminar
   * @param {Number} x
   * @param {Number} y
   * @returns {Boolean}
   * @version 1.0
   */
  const isInside = (x, y) =>
    refDeleteTable.current &&
    x + refDeleteTable.current.offsetWidth / 3 > refDeleteTable.current.offsetLeft &&
    x < refDeleteTable.current.offsetLeft + refDeleteTable.current.offsetWidth &&
    y + refDeleteTable.current.offsetHeight / 3 > refDeleteTable.current.offsetTop &&
    y < refDeleteTable.current.offsetTop + refDeleteTable.current.offsetHeight;

  /**
   * @name savePosition
   * @description Método que almacena las coordenadas del servicio en el localstorage
   * @version 1.0
   */
  const savePosition = () => {
    const list = JSON.parse(localStorage.getItem(getStorageKey(branch, codes.spacePosition))) || {};
    list[id] = { position: XY, edge: false };
    localStorage.setItem(getStorageKey(branch, codes.spacePosition), JSON.stringify(list));
  };

  /**
   * @description Objeto para la creación del modal fullScreen de espacios (mesa, lleva o express)
   * @version 1.0
   */
  const modalTable = {
    title,
    icon: <img style={css.modalIcon} src={images[type]} alt={title} />,
    content: (
      <ModalTable
        type={type}
        idTable={id}
        title={title}
        orders={orders}
        services={services}
        searchMenu={searchMenu}
        requestDate={requestDate}
        setSearchMenu={setSearchMenu}
      />
    ),
  };

  /** Efecto para manejar los pedidos enviados a cocina, si no se han enviado se muetra el snack */
  useEffect(() => {
    // Bloque para validar el envío de pedidos a la cocina
    const show = orders.reduce((acc, e) => {
      if (e.requestKitchen && !e.inKitchen) acc = true;
      return acc;
    }, false);
    setOpenMessage(show);
  }, [orders]);

  return (
    <>
      <div {...currentPosition()} style={css.container} onClick={handleClickTable}>
        <Tooltip TransitionComponent={Zoom} title={title}>
          <StyledBadge overlap="circular" anchorOrigin={css.pointPosition} variant="dot" sx={css.point}>
            <p style={css.title}>{title}</p>
            <img style={css.img} src={images[type]} alt={title} />
          </StyledBadge>
        </Tooltip>
      </div>
      {/** Modal para mostrar la ventana del espacio con los pedidos */}
      <ModalFullScreen
        showSearch={true}
        show={openModalTable}
        icon={modalTable.icon}
        title={modalTable.title}
        actionSearch={actionSearch}
        setShow={setOpenModalTable}
        content={modalTable.content}
      />
      <MessageInput
        vertical="bottom"
        open={openMessage}
        textButton={lang.go}
        setOpen={setOpenMessage}
        onClickButton={handleClickTable}
        message={`${title} ${lang.pendingOrders.toLowerCase()}`}
      />
    </>
  );
};

/**
 * @name getStorageKey
 * @description Método que obtiene un key para el localstorage
 * @param {Object} branch
 * @param {String} code
 * @returns {String}
 * @version 1.0
 */
const getStorageKey = (branch, code) => branch.administrator + branch.branchId + code;

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} position { x: 15, y: 15}
 * @param {Boolean} status true = espacio libre, false = espacio ocupado
 * @param {Boolean} edge true = con borde, false = sin borde
 * @param {String} cursor
 * @param {String} size
 * @returns Object
 * @version 1.0
 */
const styles = (position, status, edge, cursor, size) => {
  return {
    pointPosition: { vertical: "bottom", horizontal: "right" },
    modalIcon: { width: "50px", height: "50px", padding: "0px 5px" },
    img: { width: size + "px", height: size + "px", padding: "0px 5px" },
    container: {
      cursor,
      padding: "3px",
      top: position.y,
      left: position.x,
      touchAction: "none",
      position: "absolute",
      border: edge ? "2px dotted brown" : "none",
    },
    point: {
      "& .MuiBadge-badge": {
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        color: status ? constant.primaryColor : constant.deleteColor,
        backgroundColor: status ? constant.primaryColor : constant.deleteColor,
      },
    },
    title: {
      width: "100%",
      fontSize: "14px",
      fontFamily: "Arial",
      textAlign: "center",
      position: "absolute",
      color: "rgb(78, 78, 78)",
      textShadow: "2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff",
    },
  };
};

export default ServiceItem;
export { getStorageKey };
