import { formatNumber, getFinalPrice } from "../../../../assets/js/Commons";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";
import { useThis } from "../../../../assets/context/Context";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import getConstant from "../../../../assets/js/Constant";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PrintIcon from "@mui/icons-material/Print";
import LoadingButton from "../../LoadingButton";
import CustomSwitch from "../../CustomSwitch";
import { useEffect, useState } from "react";
import { ItemPrice } from "./RequestOrder";
import Label from "../../Label";

/**
 * @name BottomLeftPanel
 * @description Método que contiene los componentes con los datos para el pago
 * @param {Object} { onHandleBtnClick, rows, setOpenModalSplitAccount, setSnackLocal, data, setData, checks, setChecks }
 * @returns View
 * @version 1.0
 */
const BottomLeftPanel = ({ onHandleBtnClick, rows, setOpenModalSplitAccount, setSnackLocal, data, setData, checks, setChecks }) => {
  const { lang, currency, settings } = useThis();
  const constant = getConstant();
  const css = styles(constant);

  const icon = <ReceiptIcon sx={css.icon} />;
  const totalIcon = <InfoIcon color="info" sx={{ fontSize: "16px" }} />;

  const [totals, setTotals] = useState({ subtotal: 0, tax: 0, service: 0, utility: 0, total: 0 });

  /**
   * @name printAccount
   * @description Método para imprimir una cuenta
   * @version 1.0
   */
  const printAccount = () => {
    window.print();
  };

  /** Efecto que maneja los montos de cada cargo */
  useEffect(() => {
    const amounts = getAmounts(rows, checks.tax, checks.service, checks.utility, settings, lang.express);
    setTotals(amounts);
  }, [rows, checks.tax]);

  return (
    <div style={css.container}>
      <div style={css.containerTop}>
        <div>
          <div style={css.line}>
            <Label fontSize="14px" text={lang.selectedRequest + ": " + rows.filter((row) => row.selectedLeft).length} fontWeight={500} />
          </div>
          <div style={css.line}>
            <CustomSwitch data={data} setData={setData} name="paymentType" textLeft={lang.cash} textRight={lang.credit} disabled={false} />
            <div style={css.fabs}>
              <Tooltip TransitionComponent={Zoom} title={lang.splitAccountMessage}>
                <IconButton
                  sx={css.fabsIcons}
                  onClick={() =>
                    rows.filter((row) => row.selectedLeft).length === 0 ? setSnackLocal([116, constant.error]) : setOpenModalSplitAccount(true)
                  }
                >
                  <SafetyDividerIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title={lang.printAccount}>
                <IconButton onClick={printAccount} sx={css.fabsIcons}>
                  <PrintIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
        <div>
          <div style={css.line2}>
            <Label text={lang.subtotal} />
            <Label text={currency.symbol + formatNumber(totals.subtotal)} fontWeight={500} />
          </div>
          <div style={css.line2}>
            <Label text={lang.utility} />
            <Label text={currency.symbol + formatNumber(totals.utility)} fontWeight={500} />
          </div>
          <div style={css.line2}>
            <Label text={lang.service + " (" + settings.restaurantService + "%)"} />
            <Label text={currency.symbol + formatNumber(totals.service)} fontWeight={500} />
          </div>
          <div style={css.line2}>
            <ItemPrice
              css={css}
              name="tax"
              data={checks}
              fontWeight={500}
              text={lang.taxes}
              value={checks.tax}
              setData={setChecks}
              amount={currency.symbol + formatNumber(totals.tax)}
            />
          </div>
          <div style={{ ...css.line2, borderTop: "1px solid #CCC" }}>
            <Label tooltip={lang.priceType + (settings.priceCalculation ? lang.simple : lang.compound)} text={lang.total} icon={totalIcon} />
            <Label text={currency.symbol + formatNumber(totals.total)} fontWeight={500} />
          </div>
        </div>
      </div>
      <div style={css.containerBottom}>
        <LoadingButton text={lang.bill} width="100%" handleClick={onHandleBtnClick} icon={icon} color={constant.primaryColor} />
      </div>
    </div>
  );
};

/**
 * @name getAmounts
 * @description Método que obtiene los montos de los cargos
 * @param {Array} rows
 * @param {Boolean} checksTax
 * @param {Boolean} checksService
 * @param {Boolean} checksUtility
 * @param {Object} settings
 * @param {String} category
 * @returns View
 * @version 1.0
 */
const getAmounts = (rows, checksTax, checksService, checksUtility, settings, category) =>
  rows.reduce(
    (acc, e) => {
      if (!e.selectedLeft) return acc;
      const price = getFinalPrice({
        amount: e.amount,
        checksTax: checksTax,
        basePrice: e.basePrice,
        taxTariff: e.tax.tariff,
        checksService: checksService,
        checksUtility: checksUtility,
        utilityPercentage: e.utility,
        priceCalculation: settings.priceCalculation,
        restaurantTaxPercentage: settings.restaurantTax.tariff,
        restaurantService: e.idCategory === category || !e.selectedRight ? 0 : settings.restaurantService,
      });
      acc = {
        ...acc,
        tax: acc.tax + price.tax,
        total: acc.total + price.total,
        utility: acc.utility + price.utility,
        service: acc.service + price.service,
        subtotal: acc.subtotal + price.subtotal,
      };
      return acc;
    },
    { subtotal: 0, tax: 0, service: 0, utility: 0, total: 0 }
  );

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} constant
 * @returns Object
 * @version 1.0
 */
const styles = (constant) => {
  return {
    item: { display: "flex" },
    icon: { fontSize: "30px" },
    containerBottom: { padding: "5px" },
    container: { display: "flex", flexDirection: "column", height: "100%" },
    fabs: { display: "flex", justifyContent: "right", alignItems: "center" },
    line: { display: "flex", justifyContent: "space-between", alignItems: "center" },
    amount: { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" },
    line2: { display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1px 0" },
    containerTop: { flex: 1, display: "flex", justifyContent: "space-between", flexDirection: "column", padding: "10px" },
    fabsIcons: { backgroundColor: constant.primaryColor, color: "#FFF", "&:hover": { backgroundColor: "#4c9668" }, marginLeft: "5px" },
  };
};

export default BottomLeftPanel;
export { getAmounts };
