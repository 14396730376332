import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useThis } from "../../assets/context/Context";
import getConstant from "../../assets/js/Constant";

/**
 * @name Selector
 * @description Método que devuelve un componente tipo Selector, combo con la selección de países, tipos de IDs
 * @param {Object} { data, setData, group: 0=countries - 1=typesIDs - 2=collection, collection, name, fontSize, display = "flex", maxWidth, iconType, width }
 * En caso de recibir un "collection" este debe tener la siguiente estructura (con los ids numéricos):
 * [{ id: 0, name: "a", icon: "", code: "a1" }, { id: 1, name: "b", icon: "", code: "b1" }]
 * Si iconType es 0 pinta los íconos en una <img/> si es 1 pinta los íconos de MUI
 * @returns View
 * @version 1.0
 */
const Selector = ({ data, setData, group, name, collection, fontSize, display = "flex", maxWidth = null, iconType = 0, width = null }) => {
  const css = styles(fontSize, display, maxWidth);
  const { lang, countries, typesIDs } = useThis();

  const groups = [countries, typesIDs, collection];

  /**
   * @name handleChange
   * @description Método que maneja la selección de ítems
   * @param {Event} e Acción del selector
   * @version 1.0
   */
  const handleChange = (e) => setData({ ...data, [name]: groups[group][e.target.value] });

  /**
   * @name getItem
   * @description Método que devuelve un componente ítem para el selector
   * @param {Object} value
   * @returns View
   * @version 1.0
   */
  const getItem = (value) => {
    if (value)
      return (
        <div style={css.item}>
          {iconType === 0 && value.icon && <img style={css.img} src={value.icon} alt={value.code} width={width} />}
          {iconType === 1 && value.icon}
          <p style={css.text}>{value.name}</p>
        </div>
      );
  };

  /**
   * @name getMenuItems
   * @description Método que obtiene los menú del ítems para el selector
   * @returns Array
   */
  const getMenuItems = () =>
    groups[group].map((value) => {
      return (
        <MenuItem key={name + value.id} value={value.id}>
          {getItem(value)}
        </MenuItem>
      );
    });

  return (
    <FormControl sx={css.form}>
      <InputLabel>{lang[name]}</InputLabel>
      <Select
        size="small"
        sx={css.border}
        label={lang[name]}
        value={data[name].id}
        onChange={handleChange}
        renderValue={(index) => getItem(groups[group][index])}
      >
        {getMenuItems()}
      </Select>
    </FormControl>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} fontSize
 * @param {String} display
 * @param {String} maxWidth
 * @returns Object
 */
const styles = (fontSize, display, maxWidth) => {
  const constant = getConstant();
  return {
    img: { marginRight: "10px" },
    form: { width: "100%", display, maxWidth },
    item: { display: "flex", alignItems: "center" },
    text: { textOverflow: "ellipsis", overflow: "hidden", fontSize },
    border: {
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: constant.primaryColor,
      },
    },
  };
};

export default Selector;
