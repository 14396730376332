import { SvgIcon } from "@mui/material";
import COSTA_RICA from "./address/CR";
import getConstant from "./Constant";

/** Inicialización de variables */
const constant = getConstant();

/**
 * @name imageInSvg
 * @description Método que convierte una imagen de url a svg
 * @param {String} imageUrl
 * @param {String} size - Default 40px
 * @returns SvgIcon
 * @version 1.0
 */
const imageInSvg = (imageUrl, size = "40px") => {
  return (
    <SvgIcon sx={{ width: size, height: size }}>
      <svg viewBox="0 0 40 40" width={size} height={size}>
        <image href={imageUrl} alt="" width={size} height={size} />
      </svg>
    </SvgIcon>
  );
};

/**
 * @name isMobileDevice
 * @description Método que detecta si se está desde un dispositivo móvil
 * @returns Boolean
 * @version 1.0
 */
const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent) || window.innerWidth <= constant.maxScreenWidth;
};

/**
 * @name getCRProvinces
 * @description Función que devuelve las provincias de Costa Rica
 * @returns Array
 * @version 1.0
 */
const getCRProvinces = () => {
  return COSTA_RICA().map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
};

/**
 * @name getCRCantonsByProvince
 * @description Función que devuelve los cantones de Costa Rica por provincia
 * @param {Object} province
 * @returns Array
 * @version 1.0
 */
const getCRCantonsByProvince = (province) => {
  const prov = getCRProvinces().filter((prov) => prov.id === province.id);
  return prov[0].cantons.map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
};

/**
 * @name getCRDistrictsByProvincAndCanton
 * @description Función que devuelve los distritos de Costa Rica por provincia y cantón
 * @param {Object} province
 * @param {Object} canton
 * @returns Array
 * @version 1.0
 */
const getCRDistrictsByProvincAndCanton = (province, canton) => {
  const prov = getCRProvinces().filter((prov) => prov.id === province.id);
  const can = prov[0].cantons.filter((can) => can.id === canton.id);
  return can[0].district.map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
};

/**
 * @name getInfoByIde
 * @description Función encargada de solicitar los datos tributarios ante Hacienda, Costa Rica
 * @param {String} identification
 * @param {Boolean} isElectronicBill
 * @version 1.0
 */
const getInfoByIde = async (identification, isElectronicBill) => {
  const message = { error: false };

  try {
    const controller = new AbortController();
    const signal = controller.signal;
    const timeoutId = setTimeout(() => controller.abort(), 5000);

    const response = await fetch(constant.getInfoByIdePath + identification, { signal });
    clearTimeout(timeoutId);

    if (!response.ok) return { ...message, error: true, errorCode: 19 };

    const data = await response.json();
    if (isElectronicBill && (data.situacion.estado === constant.notRegistered || data.situacion.estado === constant.unsubscribed)) {
      return { ...message, error: true, errorCode: 20 };
    }

    return { ...message, response: data };
  } catch (error) {
    return { ...message, error: true, errorCode: 21 };
  }
};

/**
 * @name formatDate
 * @description Método encargado de formatear un timestamp
 * @param {Number} timestamp
 * @param {Object} lang
 * @returns {String} - 01/04/2024 a las 15:51
 * @version 1.0
 */
const formatDate = (timestamp, lang) => {
  if (!timestamp) return "";
  // Convertir el timestamp a un objeto de fecha
  const date = timestamp.toDate();

  // Obtener los componentes de la fecha y la hora
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hour = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  // Formatear la fecha y la hora en el formato deseado
  const dateFormated = `${day}/${month}/${year}`;
  const hourFormated = `${hour}:${minutes}:${seconds}`;

  // Retornar la fecha y hora formateadas
  return `${dateFormated}${lang.at}${hourFormated}`;
};

/**
 * @name darkenColor
 * @description Método que oscurece un color hexadecimal
 * @param {String} hex
 * @param {Number} percent
 * @returns {String} Color hexadecimal
 * @version 1.0
 */
const darkenColor = (hex, percent) => {
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  r = Math.floor(r * (1 - percent / 100));
  g = Math.floor(g * (1 - percent / 100));
  b = Math.floor(b * (1 - percent / 100));

  const darkerHex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  return darkerHex;
};

/**
 * @name timestampToDaysAndHours
 * @description Método que devuelve la diferencia en días y horas entre 2 Timestamps
 * @param {Timestamp} newTimestamp
 * @param {Timestamp} oldTimestamp
 * @returns {Object} { days, hoursLeft }
 * @version 1.0
 */
const timestampToDaysAndHours = (newTimestamp, oldTimestamp) => {
  const difference = Math.abs(newTimestamp - oldTimestamp);
  const seconds = Math.floor(difference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  // Calcula las horas restantes
  const hoursLeft = hours % 24;
  return { days, hoursLeft };
};

/**
 * @name getDaysAndHoursText
 * @description Método que devuelve los días y horas en texto
 * @param {Timestamp} expirationDate
 * @param {Object} lang
 * @returns String
 * @version 1.0
 */
const getDaysAndHoursText = (expirationDate, lang) => {
  const daysAndHours = timestampToDaysAndHours(new Date().getTime(), expirationDate.toDate().getTime());
  const daysAnd = daysAndHours.days + (daysAndHours.days === 1 ? lang.dayAnd : lang.daysAnd);
  const hours = daysAndHours.hoursLeft + (daysAndHours.hoursLeft === 1 ? lang.hour : lang.hours);
  return daysAnd + hours;
};

/**
 * @name getCurrentBranch
 * @description Método encargado de obtener una sucursal por consecutivo
 * @param {Number} snapshotBranches - Snapshot con todas las sucursales de Firestore
 * @param {String} consecutiveBranch - Llega al sistema como un String de 3 caracteres
 * @returns  {Boolean, Object}
 * @version 1.0
 */
const getCurrentBranch = (snapshotBranches, consecutiveBranch) => {
  if (snapshotBranches.length) {
    const branch = snapshotBranches.find((doc) => doc.data().consecutive.toString().padStart(3, "0") === consecutiveBranch);
    return branch;
  } else {
    return false;
  }
};

/**
 * @name termText
 * @description Método encargado de devolver el plazo en texto, ejemplo: 9 meses
 * @param {Object} lang
 * @param {Number} term
 * @returns {String}
 * @version 1.0
 */
const termText = (lang, term) => {
  let termText = "";
  if (term < 30) {
    termText = term === 1 ? 1 + " " + lang.day : term + " " + lang.days;
  } else if (term < 360) {
    const auxTerm = term / 30;
    termText = auxTerm === 1 ? 1 + " " + lang.month : auxTerm + " " + lang.months;
  } else {
    const auxTerm = term / 360;
    termText = auxTerm === 1 ? 1 + " " + lang.year : auxTerm + " " + lang.years;
  }
  return termText;
};

/**
 * @name formatNumber
 * @description Método que formatea un monto a millares con coma ejemplo: 9,000.00
 * @param {Number} amount
 * @returns {String}
 * @version 1.0
 */
const formatNumber = (amount) =>
  amount
    .toFixed(constant.decimals)
    .split(".")
    .map((part, index) => (index === 0 ? part.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : part))
    .join(".");

/**
 * @name getFirstMissingNumber
 * @description Método que obtiene el primer número faltante de una lista de menor a mayor
 * @param {Array} list
 * @returns {Number}
 * @version 1.0
 */
const getFirstMissingNumber = (list) => {
  // Si la lista está vacía, devolvemos 1
  if (list.length === 0) return 1;
  // Si el único número es menor que 1, devolvemos 1, ya que 0 no cuenta
  if (list.length === 1) {
    if (list[0].number < 1) {
      return 1;
    } else {
      // Si el número es 1 o más, devolvemos el siguiente faltante
      return list[0].number + 1;
    }
  }
  // Recorremos la lista buscando el primer número faltante mayor que 0
  for (let i = 0; i < list.length - 1; i++) {
    // Si encontramos un hueco en la secuencia mayor o igual a 1, lo devolvemos
    if (list[i].number >= 1 && list[i].number + 1 !== list[i + 1].number) {
      return list[i].number + 1;
    }
  }
  // Si no falta ningún número, devolvemos el siguiente después del último
  return list[list.length - 1].number + 1;
};

/**
 * @name getCurrency
 * @description Método que obtiene la moneda, si no existe en el localstorage la toma de la primera opción del archivo config.json
 * @param {Object} config
 * @param {Object} dataBranch
 * @returns {Object} Ej: {"symbol":"$","code":"USD"}
 * @version 1.0
 */
const getCurrency = (config, dataBranch) => {
  const currencies = config.contries[dataBranch.country.code].currencies;
  const currency = localStorage.getItem(dataBranch.country.code + "_currency");
  return JSON.parse(currency) || currencies[0];
};

/**
 * @name getExchangeRate
 * @description Función encargada de obtener el tipo de cambio del dólar en CR
 * @returns {Object}
 * @version 1.0
 */
const getExchangeRateCR = async () => {
  try {
    // Se realiza la petición a Hacienda CR
    const options = { timeout: 1000 };
    let response = await fetch(constant.getExchangeRate, options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true };
    response = await response.json();
    // Se devuelva la respuesta válida
    return { error: false, response };
  } catch (error) {
    console.log(error);
    return { error: true };
  }
};

/**
 * @name getFinalPrice
 * @description Método que calcula el precio final dependiendo del tipo seleccionado
 * @param {Object} { basePrice, taxTariff, amount = 1, checksTax = true, priceCalculation, utilityPercentage, restaurantService, checksUtility = true,checksService = true }
 * @returns {Object} { utility, subtotal, total, tax, service }
 * @version 1.0
 */
const getFinalPrice = ({
  basePrice,
  amount = 1,
  taxTariff = 0,
  checksTax = true,
  priceCalculation,
  checksUtility = true,
  checksService = true,
  restaurantService = 0,
  utilityPercentage = 0,
  restaurantTaxPercentage = 0,
}) => {
  const tax = (taxTariff * 1) / 100;
  const utility = (utilityPercentage * 1) / 100;
  const service = (restaurantService * 1) / 100;
  const restaurantTax = (restaurantTaxPercentage * 1) / 100;
  const u = checksUtility ? (priceCalculation ? basePrice * utility * amount : ((basePrice * amount) / (1 - utility)) * utility) : 0;
  const rt = checksService ? (basePrice * amount + u) * service * restaurantTax : 0;
  const t = checksTax ? (basePrice * amount + u) * tax + rt : 0;
  const s = checksService ? (basePrice * amount + u) * service : 0;
  return { utility: u, subtotal: basePrice * amount, total: basePrice * amount + u + t + s, tax: t, service: s };
};

/**
 * @name decimalToFraction
 * @description Método que convierte un decimal a fracción
 * @param {Number} decimal
 * @returns {String}
 * @version 1.0
 */
const decimalToFraction = (decimal) => {
  if (decimal % 1 === 0) return `${decimal}/1`;
  const decimalString = decimal.toString();
  const match = decimalString.match(/^0\.(\d*?)(\d+?)\2+$/);
  if (match) {
    const nonRepeating = match[1];
    const repeating = match[2];
    const lenNonRepeating = nonRepeating.length;
    const lenRepeating = repeating.length;
    const numerator = parseInt(nonRepeating + repeating) - (nonRepeating ? parseInt(nonRepeating) : 0);
    const denominator = Math.pow(10, lenNonRepeating + lenRepeating) - Math.pow(10, lenNonRepeating);
    const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
    const divisor = gcd(numerator, denominator);
    return `${numerator / divisor}/${denominator / divisor}`;
  }
  let numerator = decimal;
  let denominator = 1;
  while (numerator % 1 !== 0) {
    numerator *= 10;
    denominator *= 10;
  }
  const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
  const divisor = gcd(numerator, denominator);
  return `${numerator / divisor}/${denominator / divisor}`;
};

/**
 * @name sortByDate
 * @description Método encargado de ordenar un array de objetos por fecha de cada objeto
 * @param {Array} list
 * @param {String} atr
 * @returns {Array}
 * @version 1.0
 */
const sortByDate = (list, atr) => list.sort((a, b) => a[atr] - b[atr]);

/**
 * @name getPrinters
 * @description Método encargado de llamar al endpoint del backend para obtener las impresoras
 * @returns {Object}
 * @version 1.0
 */
const getPrinters = async () => {
  const options = { method: "GET" };
  const url = localStorage.getItem("serverInfo");
  try {
    // Se realiza la petición de lectura
    let response = await fetch(url + "/api/printers", options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 137 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 137 };
  }
};

/**
 * @name print
 * @description Método encargado de enviar a imprimir un texto a una impresora
 * @param {String} printerName
 * @param {String} content
 * @returns {Object}
 * @version 1.0
 */
const print = async (printerName, content) => {
  const url = localStorage.getItem("serverInfo");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ printerName, content }),
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(url + "/api/print", options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 141 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 141 };
  }
};

export {
  print, // Método encargado de enviar a imprimir un texto a una impresora
  termText, // Método encargado de devolver el plazo en texto, ejemplo: 9 meses
  imageInSvg, // Método que convierte una imagen de url a svg
  formatDate, // Método encargado de formatear un timestamp => 01/04/2024 a las 15:51
  sortByDate, // Método encargado de ordenar un array de objetos por fecha de cada objeto
  getPrinters, // Método encargado de llamar al endpoint del backend para obtener las impresoras
  getCurrency, // Método que obtiene la moneda, si no existe en el localstorage la toma de la primera opción del archivo config.json
  darkenColor, // Método que oscurece un color hexadecimal
  getInfoByIde, // Función encargada de solicitar los datos tributarios ante Hacienda, Costa Rica
  formatNumber, // Método que formatea un monto a millares con coma ejemplo: 9,000.00
  getFinalPrice, // Método que calcula el precio final dependiendo del tipo seleccionado
  getCRProvinces, // Función que devuelve las provincias de Costa Rica
  isMobileDevice, // Método que detecta si se está desde un dispositivo móvil
  getCurrentBranch, // Método encargado de obtener una sucursal por consecutivo
  decimalToFraction, // Método que convierte un decimal a fracción
  getExchangeRateCR, // Función encargada de obtener el tipo de cambio del dólar en CR
  getDaysAndHoursText, // Método que devuelve los días y horas en texto
  getFirstMissingNumber, // Método que obtiene el primer número faltante de una lista de menor a mayor
  getCRCantonsByProvince, // Función que devuelve los cantones de Costa Rica por provincia
  timestampToDaysAndHours, // Método que devuelve la diferencia en días y horas entre 2 Timestamps
  getCRDistrictsByProvincAndCanton, // Función que devuelve los distritos de Costa Rica por provincia y cantón
};
