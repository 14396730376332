import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import { useThis } from "../../../../assets/context/Context";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import getConstant from "../../../../assets/js/Constant";
import MenuItem from "./MenuItem";
import Label from "../../Label";

/**
 * @name BottomRightPanel
 * @description Método que muestra el panel de los ítems del manú
 * @param {Object} { size, setMenu, showCategories, setShowCategories, categoryName, setIdCategory, setSearchMenu, setCategoryName, setOpenModalRequestOrder, setShowDeleteBtn, setEdit }
 * @returns View
 * @version 1.0
 */
const BottomRightPanel = ({
  size,
  setMenu,
  setEdit,
  categoryName,
  setIdCategory,
  setSearchMenu,
  showCategories,
  setCategoryName,
  setShowDeleteBtn,
  setShowCategories,
  setOpenModalAddOrder,
  setOpenModalRequestOrder,
}) => {
  const { lang, allMenus, setAllMenus, auxAllMenus, allExpress, setAllExpress, auxAllExpress } = useThis();
  const constant = getConstant();
  const css = styles(constant);

  /**
   * @name onClickShowAllMenu
   * @description Método que se ejectua para mostrar todo el menú
   * @version 1.0
   */
  const onClickShowAllMenu = () => {
    setIdCategory("");
    setSearchMenu("");
    setCategoryName("");
    setAllMenus(auxAllMenus);
    setAllExpress(auxAllExpress);
  };

  /**
   * @name onClickMenuItem
   * @description Método que se ejectua para mostrar el modal de pedidos
   * @version 1.0
   */
  const onClickMenuItem = (e) => {
    setMenu(e);
    setEdit(false);
    setShowDeleteBtn(false);
    setOpenModalRequestOrder(true);
  };

  /**
   * @name onClickMenuItem
   * @description Método que se ejectua para mostrar el modal de agregar express
   * @version 1.0
   */
  const onClickExpressItem = (e) => {
    setMenu(e);
    setEdit(false);
    setOpenModalAddOrder(true);
  };

  return (
    <div style={css.constainer}>
      <div style={css.top}>
        <Label icon={<DinnerDiningIcon sx={css.icon} />} text={lang.menu + categoryName} />
        <div>
          <Tooltip TransitionComponent={Zoom} title={lang.showAllMenu}>
            <IconButton onClick={onClickShowAllMenu} sx={css.button}>
              {<FormatListBulletedIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip TransitionComponent={Zoom} title={showCategories ? lang.hiddenCategories : lang.showCategories}>
            <IconButton onClick={() => setShowCategories(!showCategories)} sx={css.button}>
              {showCategories ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div style={css.containerItem}>
        {allMenus.map((e) => (
          <MenuItem key={e.id} size={size} item={e} text={e.nameWithPrice} onClick={() => onClickMenuItem(e)} className="image-container" />
        ))}
        {allExpress.map((e) => (
          <MenuItem key={e.id} size={size} item={e} text={e.nameWithPrice} onClick={() => onClickExpressItem(e)} className="image-container" />
        ))}
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} constant
 * @returns {Object}
 * @version 1.0
 */
const styles = (constant) => {
  return {
    constainer: { padding: "5px" },
    button: { color: "#3f7dbd", marginLeft: "10px" },
    containerItem: { display: "flex", flexWrap: "wrap" },
    icon: { color: constant.defaultColor, fontSize: "22px" },
    top: { display: "flex", justifyContent: "space-between", alignItems: "center" },
  };
};

export default BottomRightPanel;
