import { useThis } from "../../../../assets/context/Context";
import getConstant from "../../../../assets/js/Constant";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import express from "../../../../images/express.svg";
import MenuItem from "./MenuItem";
import Label from "../../Label";

/**
 * @name TopRightPanel
 * @description Método que muestra el panel de los ítems de las categorías
 * @param {Object} { size, setIdCategory }
 * @returns View
 * @version 1.0
 */
const TopRightPanel = ({ size, setIdCategory, setCategoryName }) => {
  const constant = getConstant();
  const css = styles(constant);
  const { lang, allCategories } = useThis();

  /**
   * @name onClick
   * @description Método que maneja el evento click del ítem categoría
   * @param {String} id
   * @param {String} name
   * @version 1.0
   */
  const onClick = (id, name) => {
    setIdCategory(id);
    setCategoryName(" - " + name);
  };

  return (
    <div style={css.constainer}>
      <Label icon={<MenuBookIcon sx={css.icon} />} text={lang.categories} />
      <div style={css.containerItem}>
        <MenuItem
          size={size}
          text={lang.express}
          className="image-container"
          onClick={() => onClick(lang.express, lang.express)}
          item={{ id: lang.express, url: express, category: lang.express, status: true }}
        />
        {allCategories.map((e) => (
          <MenuItem key={e.id} size={size} item={e} text={e.category} onClick={() => onClick(e.id, e.category)} className="image-container" />
        ))}
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} constant
 * @returns {Object}
 * @version 1.0
 */
const styles = (constant) => {
  return {
    constainer: { padding: "5px" },
    containerItem: { display: "flex", flexWrap: "wrap" },
    icon: { color: constant.defaultColor, fontSize: "22px" },
  };
};

export default TopRightPanel;
