import { useThis } from "../../../../assets/context/Context";
import { formatNumber } from "../../../../assets/js/Commons";
import getConstant from "../../../../assets/js/Constant";
import CustomSwitch from "../../CustomSwitch";
import { useEffect } from "react";
import Label from "../../Label";

const ValidatePayment = ({ data, setData, total, income, reference }) => {
  const css = styles();
  const constant = getConstant();
  const { lang, currency } = useThis();

  useEffect(() => {
    if (reference.current) reference.current.focus();
  }, []);

  return (
    <div style={css.container}>
      <div style={css.withdrawal}>
        <Label text={lang.withdrawOrders + ":"} />
        <CustomSwitch data={data} disabled={false} setData={setData} name="withdrawal" textLeft={lang.yes} textRight={lang.no} />
      </div>
      <div style={css.item}>
        <Label text={lang.ammount + ":"} />
        <Label text={currency.symbol + formatNumber(total)} />
      </div>
      <div style={css.item}>
        <Label text={lang.income + ":"} />
        <Label text={currency.symbol + formatNumber(income)} color={constant.primaryColor} />
      </div>
      <div style={css.item}>
        <Label text={lang.diff + ":"} />
        <Label text={currency.symbol + formatNumber(total - income)} color={total - income > 0 ? constant.deleteColor : constant.primaryColor} />
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    container: { display: "flex", flexDirection: "column", width: "300px" },
    withdrawal: { display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" },
    item: { display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: "28px", fontWeight: 600 },
  };
};

export default ValidatePayment;
