import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { userRegistration } from "../../assets/js/firebaseMethods";
import { useThis } from "../../assets/context/Context";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import getConstant from "../../assets/js/Constant";
import { useRef, useState } from "react";
import ArrowButton from "./ArrowButton";
import LoadingBar from "./LoadingBar";
import InputText from "./InputText";
import Message from "./Message";
import Label from "./Label";
import Logo from "./Logo";

/**
 * @name StepRegister2
 * @description Método que devuelve un componente de tipo StepRegister2
 * @returns View
 * @version 1.0
 */
const StepRegister2 = () => {
  const css = styles();
  const constant = getConstant();
  const { lang, setStep, data, setData, signup, updateUser, sendEmailVerification } = useThis();

  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [loading, setLoading] = useState(false);

  const emailFocus = useRef(null);
  const passwordFocus = useRef(null);

  /**
   * @name next
   * @description Método que valida el resgistro y avanza un paso del Stepper
   * @returns N/A
   */
  const next = async () => {
    if (loading) return;

    if (!data.email) {
      emailFocus.current.focus();
      return setSnack([1, constant.error]);
    }
    if (!data.password) {
      passwordFocus.current.focus();
      return setSnack([2, constant.error]);
    }
    if (data.password.length < constant.passwordLength) {
      passwordFocus.current.focus();
      return setSnack([5, constant.error]);
    }

    setLoading(true);
    const request = {
      userName: data.userName,
      email: data.email,
      password: data.password,
      signup,
      updateUser,
      sendEmailVerification,
    };
    const result = await userRegistration(request);
    setLoading(false);

    if (result.error) {
      return setSnack([result.message, constant.error]);
    }
    // Avanza a la pantalla de verificación de correo
    setStep(2);
  };

  return (
    <div style={css.container}>
      <div style={css.fragment}>
        <Logo type={4} size={200} />
        <div style={css.right}>
          <Label text={lang.registerCredentials} fontSize="20px" fontWeight="500" />
          <Label text={lang.emailAndPassword} fontSize="16px" />
          <InputText
            data={data}
            name="email"
            marginTop={3}
            size={"small"}
            type={"email"}
            width={"300px"}
            setData={setData}
            value={data.email}
            label={lang.email}
            reference={emailFocus}
            icon={<AlternateEmailIcon />}
          />
          <InputText
            data={data}
            marginTop={2}
            size={"small"}
            name="password"
            width={"300px"}
            type={"password"}
            setData={setData}
            label={lang.password}
            value={data.password}
            icon={<VpnKeyIcon />}
            reference={passwordFocus}
          />
          <Label text={lang.condition} fontSize="12px" margin="5px 0 0 0" />
        </div>
      </div>
      <LoadingBar visible={loading} color={"success"} />
      <div style={css.footer}>
        <ArrowButton index={0} handleClick={() => setStep(0)} />
        <ArrowButton index={1} handleClick={next} />
      </div>
      <Message snack={snack}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = () => {
  return {
    right: { margin: "0", width: "380px" },
    footer: { display: "flex", justifyContent: "space-between", marginTop: "10px" },
    fragment: { display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "10px", height: "275px" },
    container: { width: "100%", position: "relative", display: "flex", flexDirection: "column", justifyContent: "space-between" },
  };
};

export default StepRegister2;
