import { useThis } from "../../../../assets/context/Context";
import { formatDate } from "../../../../assets/js/Commons";
import { useEffect, useState } from "react";
import { Spring } from "./Command";
import DOMPurify from "dompurify";
import Label from "../../Label";

/**
 * @name CommandItem
 * @description Método que devuelve un componente tipo ítem para la comanda
 * @param {Object} { size: Number, date: Timestamp, description: String, order: String, space: String, idSpace: String, active: Boolean }
 * @returns View
 * @version 1.0
 */
const CommandItem = ({ size, date, description, order, space, color, id, setOpenAlertMessage, setOrder, idSpace, active }) => {
  const { lang } = useThis();

  const [fontSize, setFontSize] = useState(16);
  const css = styles({ width: size * 1, color, fontSize });

  /**
   * @name onClickItem
   * @description Método que maneja el evento onClick del ítem de comanda
   * @version 1.0
   */
  const onClickItem = () => {
    // Si está activo es si no se ha retirado
    if (active) {
      setOpenAlertMessage(true);
      setOrder({ id, name: order, idSpace });
    }
  };

  /**
   * @description Elimina el <script> malicioso
   */
  const secureMessage = DOMPurify.sanitize("<b>" + order + "</b>: ");

  /** Efecto que maneja el tamaño de la letra dependiendo del ancho del ítem */
  useEffect(() => {
    if (size * 1 > 200) {
      setFontSize(18);
    } else if (size * 1 > 159) {
      setFontSize(16);
    } else if (size * 1 > 100) {
      setFontSize(14);
    } else {
      setFontSize(12);
    }
  }, [size]);

  return (
    <div className="note-background" style={css.note} onClick={onClickItem}>
      <div style={css.springs}>
        <Spring point={css.point} />
        <Spring point={css.point} />
        <Spring point={css.point} />
      </div>
      <div style={css.text}>
        <Label fontWeight={700} text={space} fontSize={fontSize + "px"} />
        <p style={css.description} dangerouslySetInnerHTML={{ __html: secureMessage + (description || lang.noDescription) }} />
        <Label textAlign="end" fontSize={fontSize - 4 + "px"} text={formatDate(date, lang)} />
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} { width, color, fontSize }
 * @returns Object
 * @version 1.0
 */
const styles = ({ width, color, fontSize }) => {
  return {
    description: { fontSize: fontSize + "px" },
    note: { width, display: "flex", flexDirection: "column" },
    text: { flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between", margin: "0 10px", overflow: "hidden" },
    springs: {
      display: "flex",
      marginBottom: "5px",
      padding: "5px 10px 8px 10px",
      backgroundColor: "#" + color,
      justifyContent: "space-between",
      boxShadow: "inset 2px 2px 15px 2px rgba(44, 44, 44, 0.3)",
    },
    point: {
      width: "23px",
      height: "23px",
      position: "relative",
      borderRadius: "50px",
      backgroundColor: "#e3e3e3",
      border: "1px solid #626262",
      boxShadow: "inset 2px 2px 2px 2px rgba(0, 0, 0, 0.3)",
    },
  };
};

export default CommandItem;
