import { IconButton, InputAdornment, Tooltip, Zoom } from "@mui/material";
import { DeleteForever, ImageNotSupported } from "@mui/icons-material";
import { formatNumber, getFinalPrice } from "../../assets/js/Commons";
import { getAllProdAndServ } from "../../assets/js/firebaseMethods";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import GenericGridType2 from "./grids/GenericGridType2";
import { useThis } from "../../assets/context/Context";
import PercentIcon from "@mui/icons-material/Percent";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AddInventoryToMenu from "./AddInventoryToMenu";
import AutoCompleteInput from "./AutoCompleteInput";
import getConstant from "../../assets/js/Constant";
import SellIcon from "@mui/icons-material/Sell";
import InputFileUpload from "./InputFileUpload";
import defalut from "../../images/default.svg";
import { Warning } from "@mui/icons-material";
import LoadingButton from "./LoadingButton";
import { useEffect, useState } from "react";
import CustomSwitch from "./CustomSwitch";
import Modal from "../containers/Modal";
import InputText from "./InputText";
import Selector from "./Selector";
import Label from "./Label";

/** Variables globales */
const icon = <InfoIcon color="info" sx={{ fontSize: "16px" }} />;

/**
 * @name AddMenu
 * @description Método que carga la vista del modal para agregar un menú
 * @param {Object} { data, setData, priceFocus, nameFocus, categories, taxesTypes, taxes, categoryFocus, action }
 * @returns View
 * @version 1.0
 */
const AddMenu = ({ data, setData, priceFocus, nameFocus, categories, taxesTypes, taxes, categoryFocus, edit }) => {
  const css = styles(data);
  const constant = getConstant();
  const { lang, currency, settings, branch, snapshotBranches } = useThis();

  const [rows, setRows] = useState([]);
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [finalPrice, setfinalPrice] = useState(0);
  const [warningIcon, setWarningIcon] = useState(null);
  const [openAddProdOrServ, setOpenAddProdOrServ] = useState(false);
  const [prodsAndServs, setProdsAndServs] = useState({ items: [], values: {} });
  const [dataBranch] = useState(snapshotBranches.find((doc) => doc.id === branch.branchId));

  /**
   * @name onChangeAutoComplete
   * @description Método que maneja el comportamiento del input Autocomplete
   * @version 1.0
   */
  const onChangeAutoComplete = (option, name) => setData({ ...data, [name]: option });

  /**
   * @name showAddInventory
   * @description Método que muestra el modal para agregar productos y/o servicios
   * @version 1.0
   */
  const showAddInventory = () => {
    setOpenAddProdOrServ(true);
    // Carga las cantidades en el objeto de los ítems con lo que tiene data
    data.prodAndserv.forEach((e) => {
      setProdsAndServs((prev) => ({ ...prev, values: { ...prev.values, [e.id]: e.amount } }));
    });
  };

  /**
   * @name addProdOrServ
   * @description Acción para vincular productos y/o servicios al menú, y mostrarlos en la tabla
   * @returns N/A
   * @version 1.0
   */
  const addProdOrServ = () => {
    if (Object.keys(prodsAndServs.values).length === 0) return setSnack([98, constant.error]);
    if (Object.values(prodsAndServs.values).every((value) => value === 0)) return setSnack([97, constant.error]);

    const array = [];
    for (const key in prodsAndServs.values) {
      if (prodsAndServs.values[key] > 0) {
        const item = prodsAndServs.items.find((e) => e.id === key);
        array.push({ id: key, amount: prodsAndServs.values[key], name: item.data.name });
      }
    }
    setOpenAddProdOrServ(false);
    setData((prev) => ({ ...prev, prodAndserv: array }));
  };

  /**
   * @name removeImage
   * @description Método que remueve la imagen del contenedor
   * @returns N/A
   * @version 1.0
   */
  const removeImage = () => setData({ ...data, file: null, url: "" });

  /**
   * @name actionRowDelete
   * @description Método que maneja el evento del botón eliminar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowDelete = (params) => {
    const array = data.prodAndserv.filter((e) => e.id !== params.row.id);
    setProdsAndServs((prev) => ({ ...prev, values: { ...prev.values, [params.row.id]: 0 } }));
    setData((prev) => ({ ...prev, prodAndserv: array }));
  };

  /** Constante que define las columnas para la tabla */
  const columns = [
    { field: "prodOrServ", headerName: lang.prodOrServ, flex: 0 },
    { field: "amount", headerName: lang.amount, flex: 1, align: "right" },
    { field: "action", headerName: lang.action, flex: 1 },
  ];

  /** Constante que almacena los datos para los botones de acciones de cada fila */
  const actions = [{ icon: <DeleteForever />, callBack: actionRowDelete, color: constant.deleteColor }];

  /**
   * @description Objeto para la creación del modal agregar o editar menú
   */
  const paramsToAddProdOrServ = {
    btnText: lang.add,
    icon: <PostAddIcon />,
    title: lang.addProdAndServ,
    content: <AddInventoryToMenu prodsAndServs={prodsAndServs} setProdsAndServs={setProdsAndServs} />,
  };

  /** Efecto para manejar el label del precio final */
  useEffect(() => {
    const finalPriceMenu = getFinalPrice({
      basePrice: data.basePrice,
      taxTariff: data.tax.tariff,
      utilityPercentage: data.utility || 0,
      priceCalculation: settings.priceCalculation,
    }).total;
    setData((prev) => ({ ...prev, finalPrice: finalPriceMenu }));
    setfinalPrice(formatNumber(finalPriceMenu));
  }, [data.basePrice, data.tax.tariff, data.utility]);

  /** Efecto para validar que el precio final sea el mismo */
  useEffect(() => {
    const finalPrice = getFinalPrice({
      basePrice: data.basePrice,
      taxTariff: data.tax.tariff,
      utilityPercentage: data.utility || 0,
      priceCalculation: settings.priceCalculation,
    }).total;
    if (edit && currency.symbol + formatNumber(finalPrice) !== data.finalPriceText) {
      setWarningIcon(<Warning sx={css.warningIcon} />);
    } else {
      setWarningIcon(null);
    }
  }, [data]);

  /** Efecto que maneja la consulta de los productos y/o servicios a firestore */
  useEffect(() => {
    const getProdsAndServs = async () => {
      const response = await getAllProdAndServ(branch.administrator, branch.branchId);
      const list = response.prodAndServ.docs.reduce(
        (acc, doc) => {
          acc.values = { ...acc.values, [doc.id]: 0 };
          acc.items.push({ id: doc.id, data: doc.data() });
          return acc;
        },
        { items: [], values: {} }
      );
      setProdsAndServs(list);
    };
    getProdsAndServs();
    if (nameFocus.current) nameFocus.current.focus();
  }, []);

  /** Efecto que maneja las filas de la tabla */
  useEffect(() => {
    const lines = data.prodAndserv.reduce((acc, e) => {
      acc.push({ id: e.id, prodOrServ: e.name, amount: e.amount });
      return acc;
    }, []);
    setRows(lines);
  }, [data.prodAndserv]);

  return (
    <div style={css.container}>
      {edit && (
        <div style={css.containerSwitch}>
          <Label tooltip={lang.inactiveText} icon={icon} text={lang.status + ":"} fontWeight={500} />
          <CustomSwitch data={data} setData={setData} name="status" textLeft={lang.active} textRight={lang.inactive} disabled={false} />
        </div>
      )}
      <InputText
        data={data}
        type={"text"}
        name={"name"}
        size={"small"}
        width={"auto"}
        marginBottom={1}
        setData={setData}
        value={data.name}
        label={lang.name}
        reference={nameFocus}
        icon={<MenuBookIcon />}
        marginTop={edit ? 2 : 0}
      />
      <AutoCompleteInput
        mb="15px"
        mt="15px"
        data={data}
        name="category"
        collection={categories}
        reference={categoryFocus}
        text={lang.selectCategory}
        onChange={onChangeAutoComplete}
      />
      <div style={css.containerSwitch}>
        <Label tooltip={lang.requestKitchenInfo} icon={icon} text={lang.requestKitchen} fontWeight={500} />
        <CustomSwitch data={data} setData={setData} name="requestKitchen" textLeft={lang.yes} textRight={lang.no} disabled={false} />
      </div>
      <InputText
        data={data}
        marginTop={2}
        size={"small"}
        width={"auto"}
        type={"number"}
        marginBottom={2}
        setData={setData}
        name={"basePrice"}
        icon={<SellIcon />}
        reference={priceFocus}
        label={lang.basePrice}
        value={data.basePrice}
        startProp={<InputAdornment position="start">{currency.symbol}</InputAdornment>}
      />
      <InputText
        data={data}
        size={"small"}
        width={"auto"}
        type={"number"}
        name={"utility"}
        setData={setData}
        value={data.utility}
        icon={<PercentIcon />}
        label={lang.utilityPercentage}
        marginBottom={dataBranch.data().electronicBill ? 2 : 0}
      />
      {dataBranch.data().electronicBill && (
        <div>
          <Selector name="taxType" data={data} setData={setData} collection={taxesTypes} group={2} iconType={1} />
          <br />
          <Selector name="tax" data={data} setData={setData} collection={taxes} group={2} iconType={1} />
          <Label tooltip={lang.cabysMessage} icon={icon} text={lang.cabysCode} fontWeight={500} margin="12px 0 10px 0" />
          <InputText
            data={data}
            type={"text"}
            size={"small"}
            width={"auto"}
            name={"cabys"}
            setData={setData}
            value={data.cabys}
            label={lang.cabysCode}
            icon={<ViewColumnIcon />}
            marginBottom={dataBranch.data().electronicBill ? 1 : 0}
          />
        </div>
      )}
      <div style={css.finalPrice}>
        <Label
          icon={icon}
          fontWeight={500}
          text={lang.finalPrice}
          color={constant.defaultColor}
          tooltip={lang.priceType + (settings.priceCalculation ? lang.simple : lang.compound)}
        />
        <Label
          fontWeight={500}
          iconRight={false}
          icon={warningIcon}
          tooltip={lang.warningPrice}
          color={constant.defaultColor}
          text={currency.symbol + finalPrice}
        />
      </div>
      <div style={css.line}></div>
      <Label text={lang.optionalImage} fontWeight={500} />
      <img src={data.url ? data.url : defalut} alt="" style={css.img} />
      <div style={css.buttons}>
        {(data.file || data.url) && (
          <LoadingButton text={lang.remove} handleClick={removeImage} icon={<ImageNotSupported />} color={constant.deleteColor} />
        )}
        <InputFileUpload name="url" text={lang.image} accept={constant.onlyImage} color={"#666"} data={data} setData={setData} />
      </div>
      <br />
      <div style={css.line}></div>
      <div style={css.addInventory}>
        <Label fontWeight={500} text={lang.linkInventory} color={constant.defaultColor} icon={icon} tooltip={lang.linkInventoryInfo} />
        <Tooltip TransitionComponent={Zoom} title={lang.addProdAndServ}>
          <IconButton onClick={showAddInventory} sx={css.new}>
            <PostAddIcon />
          </IconButton>
        </Tooltip>
      </div>
      <GenericGridType2
        rows={rows}
        rowHeight={40}
        columns={columns}
        actions={actions}
        setSelectedRows={[]}
        checkboxSelection={false}
        noRowsLabel={lang.noProductsLabel}
      />
      {/** Modal para agregar un producto o servicio al menú */}
      <Modal
        snack={snack}
        loading={false}
        setSnack={setSnack}
        open={openAddProdOrServ}
        clickBtnOk={addProdOrServ}
        setOpen={setOpenAddProdOrServ}
        icon={paramsToAddProdOrServ.icon}
        title={paramsToAddProdOrServ.title}
        btnText={paramsToAddProdOrServ.btnText}
        content={paramsToAddProdOrServ.content}
      />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} data
 * @returns Object
 * @version 1.0
 */
const styles = (data) => {
  return {
    warningIcon: { color: "#D8C249", fontSize: "16px" },
    container: { display: "flex", flexDirection: "column" },
    line: { borderBottom: "1px solid #CCC", margin: "10px 0" },
    new: { width: "auto", color: "#5AB262", marginLeft: "10px" },
    containerSwitch: { display: "flex", justifyContent: "space-between", alignItems: "center" },
    buttons: { display: "flex", justifyContent: data.file || data.url ? "space-between" : "right" },
    finalPrice: { display: "flex", justifyContent: "space-between", marginTop: "10px", alignItems: "center" },
    img: { width: "280px", height: "280px ", border: "1px solid #CCC", marginBottom: "3px", objectFit: "cover" },
    addInventory: { display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "5px" },
  };
};

export default AddMenu;
export { icon };
