import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { useThis } from "../../assets/context/Context";
import InputText from "./InputText";
import Label from "./Label";

/**
 * @name AddProdAndServ
 * @description Método que carga la vista del modal para agregar o editar un servicio o producto
 * @param {Object} { data: useState, setData: useState, nameFocus: useRef, amountFocus: useRef, edit: Boolean }
 * @returns View
 * @version 1.0
 */
const AddProdAndServ = ({ data, setData, nameFocus, amountFocus, edit }) => {
  const { lang } = useThis();
  const css = styles();

  const icon = <InfoIcon color="info" sx={{ fontSize: "30px", marginBottom: "10px" }} />;

  return (
    <div style={css.container}>
      <Label tooltip={lang.inventoryMessageTip} fontSize={"14px"} icon={icon} text={lang.inventoryMessage} margin={"0 0 15px 10px"} />
      <InputText
        data={data}
        name={"name"}
        type={"text"}
        size={"small"}
        width={"auto"}
        marginBottom={1}
        setData={setData}
        label={lang.name}
        value={data.name}
        reference={nameFocus}
        icon={<AssignmentIcon />}
      />
      {!edit && (
        <InputText
          data={data}
          size={"small"}
          width={"auto"}
          name={"amount"}
          type={"number"}
          marginBottom={1}
          setData={setData}
          label={lang.amount}
          value={data.amount}
          reference={amountFocus}
          icon={<FormatListNumberedIcon />}
        />
      )}
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    container: { display: "flex", flexDirection: "column", maxWidth: "300px" },
  };
};

export default AddProdAndServ;
