import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { useThis } from "../../assets/context/Context";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { useEffect, useRef } from "react";
import LoadingBar from "./LoadingBar";
import InputText from "./InputText";
import Label from "./Label";

/**
 * @name StepVerticalRegister2
 * @description Método que devuelve un componente de tipo StepVerticalRegister2
 * @param {useState} ef - Variable que maneja el email focus del input
 * @param {useState} setEf
 * @param {useState} pf - Variable que maneja el password focus del input
 * @param {useState} setPf
 * @param {useState} loading - Estado cargando de la barra
 * @returns View
 * @version 1.0
 */
const StepVerticalRegister2 = ({ ef, setEf, pf, setPf, loading }) => {
  const css = styles();
  const { lang, data, setData } = useThis();

  const emailFocus = useRef(null);
  const passwordFocus = useRef(null);

  useEffect(() => {
    if (ef) {
      emailFocus.current.focus();
      setEf(false);
    }

    if (pf) {
      passwordFocus.current.focus();
      setPf(false);
    }
  }, [ef, pf]);

  return (
    <div style={css.container}>
      <div style={css.fragment}>
        <div>
          <Label text={lang.registerCredentials} fontSize="20px" fontWeight="500" />
          <Label text={lang.emailAndPassword} fontSize="16px" />
          <InputText
            data={data}
            name="email"
            marginTop={3}
            size={"small"}
            type={"email"}
            setData={setData}
            value={data.email}
            label={lang.email}
            reference={emailFocus}
            icon={<AlternateEmailIcon />}
          />
          <InputText
            data={data}
            marginTop={2}
            size={"small"}
            name="password"
            type={"password"}
            setData={setData}
            label={lang.password}
            value={data.password}
            icon={<VpnKeyIcon />}
            reference={passwordFocus}
          />
        </div>
      </div>
      <LoadingBar margin="10px 0 0 0" visible={loading} color={"success"} />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = () => {
  return {
    container: { width: "100%", position: "relative" },
    fragment: { display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "10px" },
  };
};

export default StepVerticalRegister2;
