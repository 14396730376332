import { useThis } from "../../../../assets/context/Context";
import { formatNumber } from "../../../../assets/js/Commons";
import getConstant from "../../../../assets/js/Constant";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import CustomSwitch from "../../CustomSwitch";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import Label from "../../Label";

/**
 * @name DetailedAccount
 * @description Método que contiene los componentes con los datos para el pago
 * @param {Object} { data, setData, rows, amounts }
 * @returns View
 * @version 1.0
 */
const DetailedAccount = ({ data, setData, rows, amounts }) => {
  const totalIcon = <InfoIcon color="info" sx={{ fontSize: "16px" }} />;
  const { lang, settings, currency } = useThis();
  const constant = getConstant();
  const css = styles(constant);

  const defaultDetail = [
    { id: 0, fullName: lang.restaurantService, finalPriceText: currency.symbol + formatNumber(amounts.subtotal + amounts.utility + amounts.tax) },
  ];

  /** Columnas de la tabla documentos de pago */
  const columns = [
    { field: "fullName", headerName: lang.order, flex: 1 },
    { field: "finalPriceText", headerName: lang.price, flex: 0, headerAlign: "right", align: "right" },
  ];

  return (
    <div>
      <div style={css.detailedAccount}>
        <Label text={lang.detailedAccount + ":"} />
        <CustomSwitch data={data} disabled={false} setData={setData} name="accountDetail" textLeft={lang.yes} textRight={lang.no} />
      </div>
      <Box style={css.box}>
        <DataGrid
          hideFooter
          rowHeight={35}
          sx={css.grid}
          columns={columns}
          disableColumnMenu
          disableColumnFilter
          checkboxSelection={false}
          localeText={{ noRowsLabel: lang.noOrders }}
          rows={data.accountDetail ? rows : defaultDetail}
        />
      </Box>
      <div>
        <div style={css.line2}>
          <Label text={lang.subtotal} />
          <Label text={currency.symbol + formatNumber(amounts.subtotal)} fontWeight={500} />
        </div>
        <div style={css.line2}>
          <Label text={lang.utility} />
          <Label text={currency.symbol + formatNumber(amounts.utility)} fontWeight={500} />
        </div>
        <div style={css.line2}>
          <Label text={lang.service + " (" + settings.restaurantService + "%)"} />
          <Label text={currency.symbol + formatNumber(amounts.service)} fontWeight={500} />
        </div>
        <div style={css.line2}>
          <Label text={lang.taxes} />
          <Label text={currency.symbol + formatNumber(amounts.tax)} fontWeight={500} />
        </div>
        <div style={{ ...css.line2, borderTop: "1px solid #CCC" }}>
          <Label tooltip={lang.priceType + (settings.priceCalculation ? lang.simple : lang.compound)} text={lang.total} icon={totalIcon} />
          <Label text={currency.symbol + formatNumber(amounts.total)} fontWeight={500} />
        </div>
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} constant
 * @returns Object
 * @version 1.0
 */
const styles = (constant) => {
  return {
    box: { height: 150, width: "300px", marginBottom: "10px" },
    detailedAccount: { display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" },
    line2: { display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1px 0", fontSize: "14px" },
    grid: {
      fontSize: "12px",
      "& .MuiDataGrid-columnHeader": { height: "30px !important" },
      "& .MuiDataGrid-columnHeaderTitle": { fontSize: "12px", fontWeight: 600 },
      "& .MuiDataGrid-checkboxInput .MuiSvgIcon-root": { color: constant.primaryColor },
    },
  };
};

export default DetailedAccount;
