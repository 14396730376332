import { deleteOrders, moveAnyOrders, updateOrders } from "../../../../assets/js/firebaseMethods";
import { formatDate, formatNumber, getFinalPrice } from "../../../../assets/js/Commons";
import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, Timestamp, where } from "firebase/firestore";
import getLocalStorageCodes from "../../../../assets/config/config-localStorage";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";
import ModalFullScreen from "../../../containers/ModalFullScreen";
import { db } from "../../../../assets/context/firebase-config";
import { SpeedDialAction, SpeedDialIcon } from "@mui/material";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import { useThis } from "../../../../assets/context/Context";
import { CenterFocusWeak, Close } from "@mui/icons-material";
import { CustomizedSpeedDial } from "../../SpeedDialButton";
import { DeleteForever, Edit } from "@mui/icons-material";
import getConstant from "../../../../assets/js/Constant";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import endpoints from "../../../../assets/js/Endpoints";
import imgExpress from "../../../../images/express.svg";
import express from "../../../../images/express.svg";
import { useEffect, useRef, useState } from "react";
import MopedIcon from "@mui/icons-material/Moped";
import { getStorageKey } from "../../ServiceItem";
import BottomRightPanel from "./BottomRightPanel";
import DeleteAnything from "../../DeleteAnything";
import touch from "../../../../images/touch.webp";
import carry from "../../../../images/carry.svg";
import table from "../../../../images/table.svg";
import AddExpressOrder from "./AddExpressOrder";
import BottomLeftPanel from "./BottomLeftPanel";
import MessageInput from "../../MessageInput";
import Modal from "../../../containers/Modal";
import { useDrag } from "@use-gesture/react";
import TopRightPanel from "./TopRightPanel";
import LoadingBar from "../../LoadingBar";
import TopLeftPanel from "./TopLeftPanel";
import RequestOrder from "./RequestOrder";
import SplitAccount from "./SplitAccount";
import MoveOrders from "./MoveOrders";
import Message from "../../Message";
import ZoomMenu from "./ZoomMenu";
import Billing from "./Billing";

/**
 * @name ModalTable
 * @description Método que muestra el modal para solicitar pedidos
 * @param {Object} { idTable, searchMenu: String, setSearchMenu: useState, type: 0=Mesa, 1=Express, 2=Llevar, orders, services, title, requestDate }
 * @returns View
 * @version 1.0
 */
const ModalTable = ({ idTable, searchMenu, setSearchMenu, type, orders, services, title, requestDate }) => {
  const codes = getLocalStorageCodes();
  const constant = getConstant();
  const {
    user,
    lang,
    branch,
    allMenus,
    currency,
    settings,
    allExpress,
    auxAllMenus,
    setAllMenus,
    allCategories,
    setAllExpress,
    auxAllExpress,
    setAuxAllMenus,
    setAllCategories,
    setAuxAllExpress,
    closingBoxCurrent,
    setClosingBoxCurrent,
  } = useThis();

  const [rows, setRows] = useState([]);
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [edit, setEdit] = useState(false);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false); // Para el modal secundario
  const [menu, setMenu] = useState({ detail: "" }); // Recibe el objeto al presionar un express o un menú (ítem)
  const [snackLocal, setSnackLocal] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [idCategory, setIdCategory] = useState("");
  const [splitOrders, setSplitOrders] = useState([]);
  const [loadingBar, setLoadingBar] = useState(false); // Para el modal principal
  const [categoryName, setCategoryName] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [openModalPay, setOpenModalPay] = useState(false);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [showCategories, setShowCategories] = useState(true);
  const [openModalZoomTable, setOpenModalZoomTable] = useState(false);
  const [paymentType, setPaymentType] = useState({ paymentType: true });
  const [openModalMoveOrders, setOpenModalMoveOrders] = useState(false);
  const [openModalRequestOrder, setOpenModalRequestOrder] = useState(false);
  const [openModalDeleteOrders, setOpenModalDeleteOrders] = useState(false);
  const [openModalSplitAccount, setOpenModalSplitAccount] = useState(false);
  const [openModalAddExpressOrder, setOpenModalAddExpressOrder] = useState(false);
  const [checks, setChecks] = useState({ service: true, tax: true, utility: true }); // Para el cálculo de los montos
  const [panelLeft, setPanelLeft] = useState(localStorage.getItem(codes.verticalDivider) || 30); // Porcentaje inicial del panelLeft
  const [moveOrders, setMoveOrders] = useState({ otherSpace: [], thisSpace: [], idOtherSpace: "" });
  const [leftPanelTop, setLeftPanelTop] = useState(localStorage.getItem(codes.leftHorizontalDivider) || 50); // Porcentaje inicial del leftPanelTop
  const [rightPanelTop, setRightPanelTop] = useState(localStorage.getItem(codes.rightHorizontalDivider) || 50); // Porcentaje inicial del rightPanelTop
  const [size, setSize] = useState(localStorage.getItem(getStorageKey(branch, codes.menuSize)) || constant.defaultMenuSize);

  const css = styles(panelLeft, leftPanelTop, rightPanelTop, showCategories);

  const [spaces, setSpaces] = useState([{ id: 0, name: lang.selectSpace, icon: touch, code: "0", orders: [] }]);

  const containerRef = useRef(null); // Referencia al contenedor
  const panelLeftRef = useRef(null); // Referencia al contenedor
  const panelRightRef = useRef(null); // Referencia al contenedor

  /**
   * @name verticalDivider
   * @description  Configuración del gesto de arrastre para el divisor vertical
   * @version 1.0
   */
  const verticalDivider = useDrag(({ first, last, event }) => {
    const container = containerRef.current;

    if (!container) return; // Asegurarse de que el contenedor exista
    if (event.cancelable) event.preventDefault(); // Evita el comportamiento no permitido del navegador

    const containerRect = container.getBoundingClientRect();
    const offsetX = Math.min(Math.max(event.clientX - containerRect.left, 0), containerRect.width);
    const newPanelLeftWidth = (offsetX / containerRect.width) * 100;

    // Respetar límites de ancho
    if (newPanelLeftWidth >= 15 && newPanelLeftWidth <= 85) {
      setPanelLeft(newPanelLeftWidth);
      localStorage.setItem(codes.verticalDivider, newPanelLeftWidth);
    }

    if (last) document.body.style.cursor = "default";
    if (first) document.body.style.cursor = "ew-resize";
  });

  /**
   * @name horizontalDivider
   * @description  Configuración del gesto de arrastre para el divisor horizontal
   * @version 1.0
   */
  const horizontalDivider = useDrag(({ first, last, event }) => {
    const container = event.target.id === "RHD" ? panelRightRef.current : panelLeftRef.current;

    if (!container) return; // Asegurarse de que el contenedor exista
    if (event.cancelable) event.preventDefault(); // Evita el comportamiento no permitido del navegador

    const containerRect = container.getBoundingClientRect();
    const offsetY = Math.min(Math.max(event.clientY - containerRect.top, 0), containerRect.height);
    const newPanelTopHeight = (offsetY / containerRect.height) * 100;

    // Respetar límites de altura
    if (newPanelTopHeight >= 25 && newPanelTopHeight <= 75) {
      localStorage.setItem(event.target.id, newPanelTopHeight);
      event.target.id === "RHD" ? setRightPanelTop(newPanelTopHeight) : setLeftPanelTop(newPanelTopHeight);
    }

    if (last) document.body.style.cursor = "default";
    if (first) document.body.style.cursor = "ns-resize";
  });

  /**
   * @name onSaveSizeInLocalStorage
   * @description Método utilizado para almacenar el tamaño de los ítems en el localstorage
   * @version 1.0
   */
  const onSaveSizeInLocalStorage = () => {
    localStorage.setItem(getStorageKey(branch, codes.menuSize), size);
    setOpenModalZoomTable(false);
  };

  /**
   * @name onUpdateOrder
   * @description Método utilizado para agregar, editar o eliminar un pedido
   * @param {Object} { action: 0 = default (add), 1 = delete, 2 = update, 3 = split, nOrders: Object, showMessage: Boolean }
   * @version 1.0
   */
  const onUpdateOrder = async ({ action, nOrders, showMessage = true }) => {
    if (loading) return;
    setLoading(true);
    let request = { requestDate, serviceId: idTable };
    switch (action) {
      // Eliminar un pedido, sea express o menú
      case 1:
        console.log(1);
        request = { ...request, orders: nOrders.filter((item) => item.id !== order.id) };
        break;
      // Modifica un menú
      case 2:
        request = {
          ...request,
          orders: nOrders.map((item) => {
            if (item.id === order.id) return { ...order, id: order.id };
            return item;
          }),
        };
        break;
      // Prepara los pedidos divididos y/o actualiza todos
      case 3:
        request = { ...request, orders: nOrders };
        break;
      // Agrega un menú o un express
      default: {
        const array = [];
        for (let index = 0; index < order.amount; index++) {
          array.push({ ...order, amount: 1 });
        }
        request = { ...request, orders: [...nOrders, ...array] };
        break;
      }
    }
    // Se llama al servicio de actualizar pedidos
    const response = await updateOrders(user, request, branch.administrator, branch.branchId);
    setLoading(false);
    if (response.error) {
      if (showMessage) setSnack([response.errorCode, constant.error]);
      return response;
    }
    setOpenModalRequestOrder(false);
    setOpenModalSplitAccount(false);
    setOpenModalAddExpressOrder(false);
    if (showMessage) setSnackLocal([115, constant.success]);
    return response;
  };

  /**
   * @name onPay
   * @description Método utilizado para facturar el o los pedidos
   * @version 1.0
   */
  const onPay = async () => {
    if (loading) return;
    if (!closingBoxCurrent) {
      setLoadingBar(true);
      try {
        const snapshot = await getDocs(
          query(collection(db, endpoints.closingBoxes(branch.administrator, branch.branchId)), where("user", "==", user.uid), where("status", "==", false))
        );
        setLoadingBar(false);
        if (snapshot.docs.length > 0) {
          setClosingBoxCurrent(snapshot.docs[0].data());
        } else {
          return setSnackLocal([150, constant.error]);
        }
      } catch (error) {
        setLoadingBar(false);
        return setSnackLocal([151, constant.error]);
      }
    }
    const nRows = rows.filter((row) => row.selectedLeft);
    if (nRows.length === 0) return setSnackLocal([116, constant.error]);
    setOpenModalPay(true);
  };

  /**
   * @name handleDoubleClick
   * @description Método que maneja el doble click de la tabla para eliminar o editar filas
   * @param {Object} params
   * @version 1.0
   */
  const handleDoubleClick = (params) => {
    setEdit(true);
    setMenu(params);
    if (params.idCategory === lang.express) {
      setOpenModalAddExpressOrder(true);
    } else {
      setShowDeleteBtn(true);
      setOpenModalRequestOrder(true);
    }
  };

  /**
   * @name deleteSelectedOrders
   * @description Méto que se utiliza para eliminar las filas seleccionadas (pedidos)
   * @returns N/A
   * @version 1.0
   */
  const deleteSelectedOrders = async () => {
    if (loading) return;
    setLoading(true);
    const selectedRows = rows.filter((row) => !row.selectedLeft);
    const request = { orders: selectedRows, serviceId: idTable };
    // Se llama al servicio de actualizar pedidos
    const response = await deleteOrders(user, request, branch.administrator, branch.branchId);
    setLoading(false);
    if (response.error) return setSnack([response.errorCode, constant.error]);
    setOpenModalDeleteOrders(false);
    setSnackLocal([117, constant.success]);
  };

  /**
   * @name moveOrdersModalClose
   * @description Método que inicializa el combo de espacios en trasladar pedidos al cerrar el modal
   * @version 1.0
   */
  const moveOrdersModalClose = () => {
    setSpaces([{ id: 0, name: lang.selectSpace, icon: touch, code: "0", orders: [] }]);
  };

  /**
   * @name updateMovedOrders
   * @description Método que llama al servicio para trasladar los pedidos
   * @returns N/A
   * @version 1.0
   */
  const updateMovedOrders = async () => {
    if (loading) return;
    // Se valida que hayan cambios antes de llamar al servicio
    if (moveOrders.thisSpace.length === rows.length) {
      if (rows.every((obj1) => moveOrders.thisSpace.some((obj2) => obj1.id === obj2.id))) return setSnack([119, constant.error]);
    }
    setLoading(true);
    const request = { idDoc1: idTable, idDoc2: moveOrders.idOtherSpace, data1: moveOrders.thisSpace, data2: moveOrders.otherSpace };
    // Se llama al servicio de actualizar pedidos
    const response = await moveAnyOrders(user, request, branch.administrator, branch.branchId);
    setLoading(false);
    if (response.error) return setSnack([response.errorCode, constant.error]);
    setOpenModalMoveOrders(false);
    setSnackLocal([122, constant.success]);
    // Se resetea el combo del modal trasladar pedidos
    setSpaces([{ id: 0, name: lang.selectSpace, icon: touch, code: "0", orders: [] }]);
  };

  /**
   * @name sendOrders
   * @description Método para enviar los pedidos a la comanda
   * @version 1.0
   */
  const sendOrders = async () => {
    const ordersToSend = orders.map((e) => {
      if (e.requestKitchen) return { ...e, inKitchen: true };
      return e;
    });
    setLoadingBar(true);
    const response = await onUpdateOrder({ action: 3, nOrders: ordersToSend, showMessage: false });
    setLoadingBar(false);
    if (response.error) return setSnackLocal([124, constant.error]);
    setOpenMessage(false);
    setSnackLocal([123, constant.success]);
  };

  /**
   * @name getCustomizedSpeedDial
   * @description Muestra el botón SpeedDial
   * @returns View
   * @version 1.0
   */
  const getCustomizedSpeedDial = () => (
    <CustomizedSpeedDial ariaLabel="" sx={css.speedDial} icon={<SpeedDialIcon openIcon={<Close />} />}>
      <SpeedDialAction onClick={() => setOpenModalZoomTable(true)} icon={<CenterFocusWeak />} tooltipTitle={lang.zoom} />
      <SpeedDialAction onClick={() => setOpenModalMoveOrders(true)} icon={<MoveDownIcon />} tooltipTitle={lang.moveOrder} />
      <SpeedDialAction
        onClick={() => (rows.filter((row) => row.selectedLeft).length === 0 ? setSnackLocal([116, constant.error]) : setOpenModalDeleteOrders(true))}
        icon={<DeleteForever sx={{ color: constant.deleteColor }} />}
        tooltipTitle={lang.deleteSelectedOrders}
      />
    </CustomizedSpeedDial>
  );

  /**
   * @description Objeto para la creación del modal Zoom
   */
  const zoomParameters = {
    title: lang.zoom,
    btnText: lang.save,
    icon: <CenterFocusWeak />,
    content: <ZoomMenu size={size} setSize={setSize} />,
  };

  /**
   * @description Objeto para la creación del modal de realizar pedidos
   */
  const requestOrders = {
    icon: edit ? <Edit /> : <RamenDiningIcon />,
    title: edit ? lang.editOrder : lang.newOrder,
    btnText: edit ? lang.editOrder : lang.addOrder,
    content: <RequestOrder setOrder={setOrder} menu={menu} type={type} edit={edit} />,
  };

  /**
   * @description Objeto para la creación del modal agregar express
   */
  const parametersToAddExpress = {
    btnText: edit ? lang.delete : lang.add,
    icon: edit ? <DeleteForever /> : <MopedIcon />,
    title: edit ? lang.deleteExpress : lang.addExpress,
    content: <AddExpressOrder setOrder={setOrder} menu={menu} expressMessage={edit ? lang.deleteExpressInfo : lang.addExpressMessage} edit={edit} />,
  };

  /**
   * @description Objeto para la creación del modal eliminar pedidos seleccionados
   */
  const parametersToDeleteOrders = {
    btnText: lang.delete,
    icon: <DeleteForever />,
    title: lang.deleteOrders,
    content: (
      <DeleteAnything
        message={
          lang.deleteOrdersMessage.replace("[AMOUNT]", rows.filter((row) => row.selectedLeft).length) +
          (rows.filter((row) => row.selectedLeft).length === 1 ? lang.deleteOrdersMessageB : lang.deleteOrdersMessageA)
        }
      />
    ),
  };

  /**
   * @description Objeto para la creación del modal dividir cuenta
   */
  const splitAccountParameters = {
    title: lang.splitAccount,
    btnText: lang.splitAccount,
    icon: <SafetyDividerIcon />,
    content: <SplitAccount auxRows={rows} setSplitOrders={setSplitOrders} />,
  };

  /**
   * @description Objeto para la creación del modal trasladar pedidos
   */
  const moveOrdersParameters = {
    title: lang.moveOrder,
    icon: <MoveDownIcon />,
    btnText: lang.moveOrder,
    content: <MoveOrders spaces={spaces} rows={rows} title={title} setMoveOrders={setMoveOrders} />,
  };

  /**
   * @description Objeto para la creación del modal Zoom
   */
  const payParameters = {
    content: <Billing rows={rows} checks={checks} onClose={setOpenModalPay} paymentType={paymentType.paymentType} />,
  };

  /**
   * @description Efecto que maneja la consulta de categorías del menú
   * Permite realizar solamente una consulta y las almacena globalmente.
   */
  useEffect(() => {
    if (allCategories.length > 0) return;
    setLoadingBar(true);
    const collectionCategories = collection(db, endpoints.categories(branch.administrator, branch.branchId));
    const queryCategories = query(collectionCategories, orderBy("category", "asc"));
    // Escucha los cambios en los datos de las categorías
    const unsubscribe = onSnapshot(queryCategories, (snapshot) => {
      // Almacena las filas de cada categoría
      const categories = snapshot.docs.reduce((acc, doc) => {
        acc.push({ id: doc.id, url: doc.data().url || "", category: doc.data().category, status: doc.data().status });
        return acc;
      }, []);
      setLoadingBar(false);
      setAllCategories(categories);
    });
    return () => unsubscribe();
  }, []);

  /**
   * @description Efecto que maneja la consulta de todos los menús
   * Permite realizar solamente una consulta y los almacena globalmente.
   */
  useEffect(() => {
    if (allMenus.length > 0) return;
    setLoadingBar(true);
    const collectionMenus = collection(db, endpoints.menus(branch.administrator, branch.branchId));
    const queryMenus = query(collectionMenus, orderBy("name", "asc"));
    // Escucha los cambios en los datos del menu
    const unsubscribe = onSnapshot(queryMenus, (snapshot) => {
      // Almacena las filas de cada menú
      const menus = snapshot.docs.reduce((acc, doc) => {
        const finalPriceText = currency.symbol + formatNumber(doc.data().finalPrice * 1);
        const menu = {
          id: doc.id,
          finalPriceText,
          tax: doc.data().tax,
          name: doc.data().name,
          code: doc.data().code,
          idMenuOrExpress: doc.id,
          cabys: doc.data().cabys,
          url: doc.data().url || "",
          status: doc.data().status,
          taxType: doc.data().taxType,
          utility: doc.data().utility,
          auxUrl: doc.data().url || "",
          category: doc.data().category,
          basePrice: doc.data().basePrice,
          finalPrice: doc.data().finalPrice,
          idCategory: doc.data().category.id,
          prodAndserv: doc.data().prodAndserv,
          taxDigit: doc.data().tax.tariff + "%",
          categoryName: doc.data().category.name,
          requestKitchen: doc.data().requestKitchen,
          utilityText: (doc.data().utility || 0) + "%",
          nameWithPrice: doc.data().name + " " + finalPriceText,
          basePriceText: currency.symbol + formatNumber(doc.data().basePrice * 1),
        };
        acc.push(menu);
        return acc;
      }, []);
      setAllMenus(menus);
      setLoadingBar(false);
      setAuxAllMenus(menus);
    });
    return () => unsubscribe();
  }, []);

  /**
   * @description Efecto que maneja la consulta de todos los express
   * Permite realizar solamente una consulta y los almacena globalmente.
   */
  useEffect(() => {
    if (allExpress.length > 0) return;
    setLoadingBar(true);
    const collectionExpress = collection(db, endpoints.express(branch.administrator, branch.branchId));
    const queryMenus = query(collectionExpress, orderBy("name", "asc"));
    // Escucha los cambios en los datos del menu
    const unsubscribe = onSnapshot(queryMenus, (snapshot) => {
      // Almacena las filas de cada menú
      const express = snapshot.docs.reduce((acc, doc) => {
        const finalPrice = getFinalPrice({ priceCalculation: settings.priceCalculation, basePrice: doc.data().price, taxTariff: doc.data().tariff }).total;
        const item = {
          utility: 0,
          id: doc.id,
          finalPrice,
          status: true,
          url: imgExpress,
          name: doc.data().name,
          cabys: doc.data().cabys,
          idMenuOrExpress: doc.id,
          express: doc.data().name,
          tariff: doc.data().tariff,
          basePrice: doc.data().price,
          taxCode: doc.data().taxCode,
          taxType: doc.data().taxType,
          taxName: doc.data().taxName,
          category: doc.data().category,
          priceNumber: doc.data().price,
          taxText: doc.data().tariff + "%",
          idCategory: doc.data().category.id,
          taxTypeName: doc.data().taxTypeName,
          edition: formatDate(doc.data().date, lang),
          finalPriceText: currency.symbol + formatNumber(finalPrice),
          basePriceText: currency.symbol + formatNumber(doc.data().price * 1),
          nameWithPrice: doc.data().name + " " + currency.symbol + formatNumber(finalPrice),
          tax: { code: doc.data().taxCode, id: 0, name: doc.data().taxName, tariff: doc.data().tariff },
        };
        acc.push(item);
        return acc;
      }, []);
      setLoadingBar(false);
      setAllExpress(express);
      setAuxAllExpress(express);
    });
    return () => unsubscribe();
  }, []);

  /** Efecto que maneja la búsqueda del menú presionando la categoría */
  useEffect(() => {
    if (!idCategory) return;
    if (idCategory === lang.express) {
      setAllMenus([]);
      setAllExpress(auxAllExpress);
    } else {
      const menus = auxAllMenus.reduce((acc, item) => {
        if (item.idCategory === idCategory) acc.push(item);
        return acc;
      }, []);
      setAllExpress([]);
      setAllMenus(menus);
    }
  }, [idCategory]);

  /** Efecto que maneja la búsqueda del menú y/o express digitando el nombre */
  useEffect(() => {
    const menus = auxAllMenus.reduce((acc, item) => {
      if (item.name.toLowerCase().includes(searchMenu.toLowerCase())) acc.push(item);
      return acc;
    }, []);
    setAllMenus(menus);
    const express = auxAllExpress.reduce((acc, item) => {
      if (item.name.toLowerCase().includes(searchMenu.toLowerCase())) acc.push(item);
      return acc;
    }, []);
    setIdCategory("");
    setAllExpress(express);
    setCategoryName(searchMenu ? " - " + searchMenu : "");
  }, [searchMenu]);

  /** Efecto que maneja la carga de datos en la tabla */
  useEffect(() => {
    const items = orders.reduce(
      (acc, e) => {
        if (!e.status) return acc;
        const dateText = formatDate(Timestamp.fromDate(new Date(e.date.seconds * 1000 + e.date.nanoseconds / 1e6)), lang);
        acc.rows.push({
          id: e.id,
          dateText,
          tax: e.tax,
          name: e.name,
          code: e.code,
          date: e.date,
          cabys: e.cabys,
          url: e.url || "",
          amount: e.amount,
          detail: e.detail,
          status: e.status,
          taxType: e.taxType,
          utility: e.utility,
          selectedLeft: true,
          auxUrl: e.url || "",
          category: e.category,
          inKitchen: e.inKitchen,
          basePrice: e.basePrice,
          finalPrice: e.finalPrice,
          idCategory: e.category.id,
          selectedRight: type === 0,
          prodAndserv: e.prodAndserv,
          taxDigit: e.tax.tariff + "%",
          categoryName: e.category.name,
          statusKitchen: e.statusKitchen,
          requestKitchen: e.requestKitchen,
          idMenuOrExpress: e.idMenuOrExpress,
          utilityText: (e.utility || 0) + "%",
          basePriceText: currency.symbol + formatNumber(e.basePrice * 1),
          finalPriceText: currency.symbol + formatNumber(e.finalPrice * 1),
          fullName: e.category.id === lang.express ? lang.express + " - " + e.name : e.name,
        });
        // Bloque para validar el envío de pedidos a la cocina
        if (e.requestKitchen && !e.inKitchen) acc.open = true;
        return acc;
      },
      { rows: [], open: false }
    );
    setRows(items.rows);
    setOpenMessage(items.open);
  }, [orders]);

  /** Efecto para menejar la cantidad de espacios para mover órdenes */
  useEffect(() => {
    if (!openModalMoveOrders) return;
    let id = 1;
    const tablesItems = services[0].reduce((acc, e) => {
      if (idTable === e.id) return acc;
      acc.push({ id: id++, code: e.id, icon: table, name: lang.table + " n.°" + e.number, orders: e.orders });
      return acc;
    }, []);
    const expressItems = services[1].reduce((acc, e) => {
      if (idTable === e.id) return acc;
      acc.push({ id: id++, code: e.id, icon: express, name: lang.express + " n.°" + e.number, orders: e.orders });
      return acc;
    }, []);
    const carryItems = services[2].reduce((acc, e) => {
      if (idTable === e.id) return acc;
      acc.push({ id: id++, code: e.id, icon: carry, name: lang.carry + " n.°" + e.number, orders: e.orders });
      return acc;
    }, []);
    const allServices = [...spaces, ...tablesItems, ...expressItems, ...carryItems];
    setSpaces(allServices);
  }, [services, openModalMoveOrders]);

  return (
    <div style={css.container}>
      {getCustomizedSpeedDial()}
      <div style={css.bar}>{loadingBar && <LoadingBar visible={loadingBar} />}</div>
      <div style={css.panels} ref={containerRef}>
        <div style={css.panelLeft} ref={panelLeftRef}>
          <div style={css.leftPanelTop}>
            <TopLeftPanel rows={rows} setRows={setRows} handleDoubleClick={handleDoubleClick} />
          </div>
          <div id="LHD" style={css.leftDividerHorizontal} {...horizontalDivider()} onDoubleClick={(e) => e.preventDefault()} />
          <div style={css.panelBottom}>
            <BottomLeftPanel
              rows={rows}
              checks={checks}
              data={paymentType}
              setChecks={setChecks}
              onHandleBtnClick={onPay}
              setData={setPaymentType}
              setSnackLocal={setSnackLocal}
              setOpenModalSplitAccount={setOpenModalSplitAccount}
            />
          </div>
        </div>
        <div style={css.dividerVertical} {...verticalDivider()} onDoubleClick={(e) => e.preventDefault()} />
        <div style={css.panelRight} ref={panelRightRef}>
          <div style={css.rightPanelTop}>
            <TopRightPanel size={size} setIdCategory={setIdCategory} setCategoryName={setCategoryName} />
          </div>
          <div id="RHD" style={css.rightDividerHorizontal} {...horizontalDivider()} onDoubleClick={(e) => e.preventDefault()} />
          <div style={css.panelBottom}>
            <BottomRightPanel
              size={size}
              setMenu={setMenu}
              setEdit={setEdit}
              categoryName={categoryName}
              setSearchMenu={setSearchMenu}
              setIdCategory={setIdCategory}
              showCategories={showCategories}
              setCategoryName={setCategoryName}
              setShowDeleteBtn={setShowDeleteBtn}
              setShowCategories={setShowCategories}
              setOpenModalAddOrder={setOpenModalAddExpressOrder}
              setOpenModalRequestOrder={setOpenModalRequestOrder}
            />
          </div>
        </div>
      </div>
      {/** Modal para mostrar la pantalla de Zoom */}
      <Modal
        snack={snack}
        loading={false}
        setSnack={setSnack}
        open={openModalZoomTable}
        icon={zoomParameters.icon}
        title={zoomParameters.title}
        setOpen={setOpenModalZoomTable}
        btnText={zoomParameters.btnText}
        content={zoomParameters.content}
        clickBtnOk={onSaveSizeInLocalStorage}
      />
      {/** Modal para mostrar la pantalla de solicitar o modificar pedidos */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        icon={requestOrders.icon}
        title={requestOrders.title}
        open={openModalRequestOrder}
        showDeleteBtn={showDeleteBtn}
        btnText={requestOrders.btnText}
        content={requestOrders.content}
        deleteBtnText={lang.deleteOrder}
        setOpen={setOpenModalRequestOrder}
        clickBtnDelete={() => onUpdateOrder({ action: 1, nOrders: orders })}
        clickBtnOk={() => onUpdateOrder({ action: edit ? 2 : 0, nOrders: orders })}
      />
      {/** Modal para agregar o eliminar un express */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openModalAddExpressOrder}
        icon={parametersToAddExpress.icon}
        title={parametersToAddExpress.title}
        setOpen={setOpenModalAddExpressOrder}
        btnText={parametersToAddExpress.btnText}
        content={parametersToAddExpress.content}
        color={edit ? constant.deleteColor : constant.primaryColor}
        clickBtnOk={() => onUpdateOrder({ action: edit ? 1 : 0, nOrders: orders })}
      />
      {/** Modal para eliminar varios pedidos */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openModalDeleteOrders}
        color={constant.deleteColor}
        clickBtnOk={deleteSelectedOrders}
        setOpen={setOpenModalDeleteOrders}
        icon={parametersToDeleteOrders.icon}
        title={parametersToDeleteOrders.title}
        btnText={parametersToDeleteOrders.btnText}
        content={parametersToDeleteOrders.content}
      />
      {/** Modal para dividir la cuenta */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openModalSplitAccount}
        setOpen={setOpenModalSplitAccount}
        icon={splitAccountParameters.icon}
        title={splitAccountParameters.title}
        btnText={splitAccountParameters.btnText}
        content={splitAccountParameters.content}
        clickBtnOk={() => onUpdateOrder({ action: 3, nOrders: splitOrders })}
      />
      {/** Modal para el traslado de pedidos */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openModalMoveOrders}
        clickBtnOk={updateMovedOrders}
        setOpen={setOpenModalMoveOrders}
        icon={moveOrdersParameters.icon}
        title={moveOrdersParameters.title}
        btnText={moveOrdersParameters.btnText}
        content={moveOrdersParameters.content}
        callBackHandleClose={moveOrdersModalClose}
      />
      {/** Modal para mostrar la ventana facturación */}
      <ModalFullScreen
        title={""}
        icon={null}
        noBack={true}
        setShow={() => {}}
        showSearch={false}
        showAppBar={false}
        show={openModalPay}
        content={payParameters.content}
      />
      <Message snack={snackLocal}></Message>
      <MessageInput
        vertical="bottom"
        open={openMessage}
        textButton={lang.send}
        setOpen={setOpenMessage}
        onClickButton={sendOrders}
        message={lang.pendingOrders}
      />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Number} panelLeft
 * @param {Number} leftPanelTop
 * @param {Number} rightPanelTop
 * @param {Boolean} showCategories
 * @returns {Object}
 * @version 1.0
 */
const styles = (panelLeft, leftPanelTop, rightPanelTop, showCategories) => {
  const dividerHorizontal = { height: "5px", backgroundColor: "#CCC", cursor: "ns-resize", touchAction: "none" };
  return {
    leftDividerHorizontal: dividerHorizontal,
    leftPanelTop: { height: `${leftPanelTop}%`, overflowY: "auto" },
    panelBottom: { flex: 1, overflowY: "auto", overflowX: "hidden" },
    bar: { position: "absolute", top: "0px", width: "100%", zIndex: 1 },
    speedDial: { position: "absolute", bottom: 20, right: 25, zIndex: 1 },
    container: { height: "100%", overflow: "hidden", position: "relative" },
    panelRight: { display: "flex", flexDirection: "column", flex: 1, height: "100%" },
    panels: { display: "flex", height: "100%", position: "relative", touchAction: "none" },
    rightDividerHorizontal: { ...dividerHorizontal, display: showCategories ? "block" : "none" },
    panelLeft: { display: "flex", flexDirection: "column", height: "100%", width: `${panelLeft}%` },
    dividerVertical: { width: "5px", backgroundColor: "#CCC", cursor: "ew-resize", touchAction: "none" },
    rightPanelTop: { height: `${rightPanelTop}%`, overflowY: "auto", display: showCategories ? "block" : "none" },
  };
};

export default ModalTable;
