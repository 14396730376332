import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useThis } from "../../../../assets/context/Context";
import getConstant from "../../../../assets/js/Constant";
import Label from "../../Label";

/**
 * @name CommandSettings
 * @description Método que retorna el contenido del modal settings de la comanda
 * @param {Object} { commandType, setCommandType }
 * @returns View
 * @version 1.0
 */
const CommandSettings = ({ commandType, setCommandType }) => {
  const constant = getConstant();
  const css = styles(constant);
  const { lang } = useThis();

  /**
   * @name onChange
   * @description Método que maneja el evento de cambio de los radios
   * @version 1.0
   */
  const onChange = (e) => {
    setCommandType(e.target.value);
  };

  return (
    <div style={css.container}>
      <Label text={lang.commandType + ":"} fontWeight={500} />
      <RadioGroup name="commandType" value={commandType} onChange={onChange} sx={css.group}>
        <FormControlLabel value="a" control={<Radio sx={css.radio} />} label={lang.forOrder} />
        <FormControlLabel value="b" control={<Radio sx={css.radio} />} label={lang.forSpace} />
      </RadioGroup>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} constant
 * @returns Object
 * @version 1.0
 */
const styles = (constant) => {
  return {
    container: { width: "300px" },
    group: { display: "flex", flexDirection: "row" },
    radio: { color: constant.defaultColor, "&.Mui-checked": { color: constant.primaryColor } },
  };
};

export default CommandSettings;
