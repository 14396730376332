import { getServices, updateOrders } from "../../../../assets/js/firebaseMethods";
import getLocalStorageCodes from "../../../../assets/config/config-localStorage";
import { CenterFocusWeak, Close, Settings } from "@mui/icons-material";
import { SpeedDialAction, SpeedDialIcon } from "@mui/material";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import { useThis } from "../../../../assets/context/Context";
import { CustomizedSpeedDial } from "../../SpeedDialButton";
import { sortByDate } from "../../../../assets/js/Commons";
import getConstant from "../../../../assets/js/Constant";
import { useEffect, useRef, useState } from "react";
import { getStorageKey } from "../../ServiceItem";
import CommandSettings from "./CommandSettings";
import { Timestamp } from "firebase/firestore";
import Modal from "../../../containers/Modal";
import AlertMessage from "../../AlertMessage";
import WithdrawOrders from "./WithdrawOrders";
import { useDrag } from "@use-gesture/react";
import CommandItem2 from "./CommandItem2";
import LoadingBar from "../../LoadingBar";
import ZoomCommand from "./ZoomCommand";
import CommandItem from "./CommandItem";
import Message from "../../Message";

/**
 * @name Command
 * @description Método que retorna el módulo para manejar la comanda
 * @returns View
 * @version 1.0
 */
const Command = () => {
  const constant = getConstant();
  const codes = getLocalStorageCodes();
  const { branch, lang, user } = useThis();
  const text = [lang.table, lang.express, lang.carry];

  const containerRef = useRef(null); // Referencia al contenedor

  const [data, setData] = useState({});
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [orders, setOrders] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(true);
  const [snackModal, setSnackModal] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [withdrawOrders, setWithdrawOrders] = useState([]);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [services, setServices] = useState({ 0: [], 1: [], 2: [] }); // 0 = Mesas, 1 = Express, 2 = Llevar
  const [openModalWithdraw, setOpenModalWithdraw] = useState(false);
  const [order, setOrder] = useState({ id: "", name: "", idSpace: "" });
  const [openModalZoomCommand, setOpenModalZoomCommand] = useState(false);
  const [openModalCommandType, setOpenModalCommandType] = useState(false);
  const [withdrawObject, setWithdrawObject] = useState({ orders: [], title: "", idSpace: "" });
  const [panelTop, setPanelTop] = useState(localStorage.getItem(codes.commandHorizontalDivider) || 66); // Porcentaje inicial del leftPanelTop
  const [commandType, setCommandType] = useState(localStorage.getItem(getStorageKey(branch, codes.commandType)) || "a");
  const [size, setSize] = useState(localStorage.getItem(getStorageKey(branch, codes.commandSize)) || constant.defaultCommandSize);

  const css = styles({ width: size, constant, panelTop });

  /**
   * @name withdrawOrder
   * @description Método que realiza la acción de retirar la orden de la comanda
   * @version 1.0
   */
  const withdrawOrder = async () => {
    if (loading) return;
    // Se cargan todos los espacios
    const spaces = [...services[0], ...services[1], ...services[2]];
    // Se busca el espacio de la orden a retirar
    const space = spaces.find((e) => e.id === order.idSpace);
    if (!space) return setSnackModal([128, constant.error]);
    setLoading(true);
    // Se cambia el estado de la orden
    const orders = space.orders.map((e) => {
      if (e.id === order.id) return { ...e, statusKitchen: true, withdrawOrderDate: new Date() };
      return e;
    });
    // Se llama al servicio de actualizar pedidos
    const request = { orders, serviceId: order.idSpace, requestDate: space.requestDate };
    const response = await updateOrders(user, request, branch.administrator, branch.branchId);
    setLoading(false);
    if (response.error) setSnackModal([126, constant.error]);
    setOpenAlertMessage(false);
    setSnack([125, constant.success]);
  };

  /**
   * @name validateOrder
   * @description Método para validar si mostrar una orden en el ítem de comanda tipo 2
   * @param {Object} service
   * @returns {Boolean}
   * @version 1.0
   */
  const validateOrder = (service) =>
    service.orders.length === 0 ||
    service.orders.filter((item) => item.idCategory !== lang.express).every((order) => order.statusKitchen) ||
    service.orders.filter((item) => item.idCategory !== lang.express).every((order) => !order.inKitchen);

  /**
   * @name withdrawOrders
   * @description Método que maneja la acción de retirar los pedidos
   * @version 1.0
   */
  const withdrawAction = async () => {
    if (loading) return;
    // Se busca el espacio de la orden a retirar
    const space = spaces.find((e) => e.id === withdrawObject.idSpace);
    if (!space) return setSnackModal([128, constant.error]);
    // Se valida que se haya seleccionado al menos un pedido
    if (Object.values(data).every((value) => !value)) return setSnackModal([116, constant.error]);

    setLoading(true);
    // Se cambia el estado de la orden
    const orders = space.orders.map((e) => {
      for (const key in data) {
        if (key === e.id && data[key]) return { ...e, statusKitchen: true, withdrawOrderDate: new Date() };
      }
      return e;
    });
    // Se llama al servicio de actualizar pedidos
    const request = { orders, serviceId: withdrawObject.idSpace, requestDate: space.requestDate };
    const response = await updateOrders(user, request, branch.administrator, branch.branchId);
    setLoading(false);
    if (response.error) setSnackModal([114, constant.error]);
    setSnack([115, constant.success]);
    setOpenModalWithdraw(false);
  };

  /**
   * @name closeModalWithdraw
   * @description Limpia los valores de los checks de retirar pedidos
   * @version 1.0
   */
  const closeModalWithdraw = () => {
    const list = orders.reduce((acc, e) => {
      acc = { ...acc, [e.id]: false };
      return acc;
    }, {});
    setData(list);
    setLoading(false);
  };

  /**
   * @name onSaveSizeInLocalStorage
   * @description Método utilizado para almacenar el tamaño de los ítems en el localstorage
   * @version 1.0
   */
  const onSaveSizeInLocalStorage = () => {
    localStorage.setItem(getStorageKey(branch, codes.commandSize), size);
    setOpenModalZoomCommand(false);
  };

  /**
   * @name onSaveSizeInLocalStorage
   * @description Método utilizado para almacenar el tamaño de los ítems en el localstorage
   * @version 1.0
   */
  const onSaveCommandTypeInLocalStorage = () => {
    localStorage.setItem(getStorageKey(branch, codes.commandType), commandType);
    setOpenModalCommandType(false);
  };

  /**
   * @name getCustomizedSpeedDial
   * @description Muestra el botón SpeedDial
   * @returns View
   * @version 1.0
   */
  const getCustomizedSpeedDial = () => (
    <CustomizedSpeedDial ariaLabel="" sx={css.speedDial} icon={<SpeedDialIcon openIcon={<Close />} />}>
      <SpeedDialAction onClick={() => setOpenModalZoomCommand(true)} icon={<CenterFocusWeak />} tooltipTitle={lang.zoom} />
      <SpeedDialAction onClick={() => setOpenModalCommandType(true)} icon={<Settings />} tooltipTitle={lang.config} />
    </CustomizedSpeedDial>
  );

  /**
   * @description Objeto para la creación del modal Zoom
   */
  const zoomParameters = {
    title: lang.zoom,
    btnText: lang.save,
    icon: <CenterFocusWeak />,
    content: <ZoomCommand size={size} setSize={setSize} />,
  };

  /**
   * @description Objeto para la creación del modal Zoom
   */
  const commandTypeParameters = {
    btnText: lang.save,
    icon: <Settings />,
    title: lang.config,
    content: <CommandSettings commandType={commandType} setCommandType={setCommandType} />,
  };

  /**
   * @description Objeto para la creación del modal retirar pedidos
   */
  const withdrawParameters = {
    title: lang.withdraw,
    btnText: lang.withdraw,
    icon: <SportsScoreIcon />,
    content: <WithdrawOrders withdrawObject={withdrawObject} data={data} setData={setData} />,
  };

  /**
   * @name horizontalDivider
   * @description  Configuración del gesto de arrastre para el divisor horizontal
   * @version 1.0
   */
  const horizontalDivider = useDrag(({ first, last, event }) => {
    const container = containerRef.current;

    if (!container) return; // Asegurarse de que el contenedor exista
    if (event.cancelable) event.preventDefault(); // Evita el comportamiento no permitido del navegador

    const containerRect = container.getBoundingClientRect();
    const offsetY = Math.min(Math.max(event.clientY - containerRect.top, 0), containerRect.height);
    const newPanelTopHeight = (offsetY / containerRect.height) * 100;

    // Respetar límites de altura
    if (newPanelTopHeight >= 31 && newPanelTopHeight <= 100) {
      localStorage.setItem("CHD", newPanelTopHeight);
      setPanelTop(newPanelTopHeight);
    }

    if (last) document.body.style.cursor = "default";
    if (first) document.body.style.cursor = "ns-resize";
  });

  /** Efecto para obtener los datos de cada servicio (mesa, express, llevar) */
  useEffect(() => {
    getServices(setServices, branch.administrator, branch.branchId, setProgress);
  }, []);

  /** Efecto para cargar los servicios en la vista*/
  useEffect(() => {
    // Se recorre la lista de cada espacio (mesa, express, llevar)
    const spaces = [...services[0], ...services[1], ...services[2]].reduce(
      (acc, e) => {
        const title = text[e.type] + " N.° " + e.number;
        let requestDate = null;
        if (e.requestDate) requestDate = Timestamp.fromDate(new Date(e.requestDate.seconds * 1000 + e.requestDate.nanoseconds / 1e6), lang);
        // Se recorre la lista de órdenes de cada espacio
        const ordersList = e.orders.reduce(
          (ac, order) => {
            // Valida que sea un pedido de enviar a cocina y que ya se haya enviado
            if (!order.requestKitchen || !order.inKitchen) return ac;
            //Se agrega a la lista de órdenes para mostrar en la comanda!
            const date = Timestamp.fromDate(new Date(order.date.seconds * 1000 + order.date.nanoseconds / 1e6), lang);
            const item = {
              date,
              id: order.id,
              space: title,
              idSpace: e.id,
              color: e.color,
              order: order.name,
              description: order.detail,
              withdrawOrderDate: new Date(order.withdrawOrderDate),
            };
            order.statusKitchen ? ac.inactiveOrders.push(item) : ac.activeOrders.push(item);
            return ac;
          },
          { activeOrders: [], inactiveOrders: [] }
        );
        acc.allServices = [...acc.allServices, { ...e, requestDate, title }];
        acc.activeOrders = [...acc.activeOrders, ...ordersList.activeOrders];
        acc.inactiveOrders = [...acc.inactiveOrders, ...ordersList.inactiveOrders];
        return acc;
      },
      { activeOrders: [], inactiveOrders: [], allServices: [] }
    );

    setOrders(sortByDate(spaces.activeOrders, "date"));
    setSpaces(sortByDate(spaces.allServices, "requestDate"));
    setWithdrawOrders(sortByDate(spaces.inactiveOrders, "withdrawOrderDate").reverse());
    /** Efecto que inicializa los checks para retirar órdenes */
    const list = spaces.activeOrders.reduce((acc, e) => {
      acc = { ...acc, [e.id]: false };
      return acc;
    }, {});
    setData(list);
  }, [services]);

  return (
    <div style={css.container}>
      {getCustomizedSpeedDial()}
      {progress ? (
        <LoadingBar visible={progress} />
      ) : commandType === "a" ? (
        <div style={css.panels} ref={containerRef}>
          <div style={css.panelTop}>
            <div style={css.box}>
              {orders.map((e) => (
                <CommandItem
                  id={e.id}
                  key={e.id}
                  size={size}
                  active={true}
                  date={e.date}
                  color={e.color}
                  order={e.order}
                  space={e.space}
                  idSpace={e.idSpace}
                  setOrder={setOrder}
                  description={e.description}
                  setOpenAlertMessage={setOpenAlertMessage}
                />
              ))}
            </div>
          </div>
          <div style={css.dividerHorizontal} {...horizontalDivider()} onDoubleClick={(e) => e.preventDefault()}>
            <div style={css.tag}>{lang.withdrawn}</div>
          </div>
          <div style={css.panelBottom}>
            <div style={css.box2}>
              {withdrawOrders.map((e) => (
                <CommandItem
                  id={e.id}
                  key={e.id}
                  size={size}
                  date={e.date}
                  active={false}
                  color={e.color}
                  order={e.order}
                  space={e.space}
                  idSpace={e.idSpace}
                  setOrder={setOrder}
                  description={e.description}
                  setOpenAlertMessage={setOpenAlertMessage}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div style={css.panelCommandType2}>
          <div style={css.panel}>
            {spaces.map((service) => {
              if (validateOrder(service)) return null;
              return (
                <CommandItem2
                  size={size}
                  key={service.id}
                  setData={setData}
                  idSpace={service.id}
                  color={service.color}
                  title={service.title}
                  orders={service.orders}
                  requestDate={service.requestDate}
                  setWithdrawObject={setWithdrawObject}
                  setOpenModalWithdraw={setOpenModalWithdraw}
                />
              );
            })}
          </div>
        </div>
      )}
      {/** Modal para mostrar la pantalla de Zoom */}
      <Modal
        snack={[]}
        loading={false}
        setSnack={setSnackModal}
        icon={zoomParameters.icon}
        open={openModalZoomCommand}
        title={zoomParameters.title}
        btnText={zoomParameters.btnText}
        content={zoomParameters.content}
        setOpen={setOpenModalZoomCommand}
        clickBtnOk={onSaveSizeInLocalStorage}
      />
      {/** Modal para eliminar una orden */}
      <AlertMessage
        twoButtons={true}
        loading={loading}
        onClick={withdrawOrder}
        open={openAlertMessage}
        textButton={lang.withdraw}
        title={lang.withdrawOrder}
        setOpen={setOpenAlertMessage}
        icon={<SportsScoreIcon sx={css.deleteIcon} />}
        message={lang.withdrawOrderMessage.replace("[ORDER]", order.name)}
      />
      {/** Modal para la configuración de la comanda*/}
      <Modal
        snack={[]}
        loading={false}
        setSnack={setSnackModal}
        open={openModalCommandType}
        setOpen={setOpenModalCommandType}
        icon={commandTypeParameters.icon}
        title={commandTypeParameters.title}
        btnText={commandTypeParameters.btnText}
        content={commandTypeParameters.content}
        clickBtnOk={onSaveCommandTypeInLocalStorage}
      />
      {/** Modal para mostrar la pantalla de retirar pedidos */}
      <Modal
        snack={snackModal}
        loading={loading}
        open={openModalWithdraw}
        setSnack={setSnackModal}
        clickBtnOk={withdrawAction}
        icon={withdrawParameters.icon}
        setOpen={setOpenModalWithdraw}
        title={withdrawParameters.title}
        btnText={withdrawParameters.btnText}
        content={withdrawParameters.content}
        callBackHandleClose={closeModalWithdraw}
      />
      <Message snack={snack}></Message>
    </div>
  );
};

/**
 * @name Spring
 * @description Método que retorna un componente que pinta un resorte
 * @param {Object} { point }
 * @returns View
 * @version 1.0
 */
const Spring = ({ point }) => {
  return (
    <div style={point}>
      <div className="spring" />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} width
 * @returns Object
 * @version 1.0
 */
const styles = ({ width, constant, panelTop }) => {
  const dividerHorizontal = { height: "3px", backgroundColor: "#CCC", cursor: "ns-resize", touchAction: "none", position: "relative" };
  return {
    point: {
      width: "23px",
      height: "23px",
      position: "relative",
      borderRadius: "50px",
      backgroundColor: "#444",
      border: "1px solid #626262",
      boxShadow: "inset 2px 2px 2px 2px rgba(0, 0, 0, 0.3)",
    },
    tag: {
      left: "10px",
      top: "-23px",
      color: "#FFF",
      height: "20px",
      display: "flex",
      position: "absolute",
      padding: "3px 8px",
      borderRadius: "5px 5px 0 0",
      backgroundColor: constant.primaryColor,
      textShadow: "1px 0 #444, -1px 0 #444, 0 1px #444, 0 -1px #444, 1px 1px #444, -1px -1px #444, 1px -1px #444, -1px 1px #444",
    },
    note: { width },
    dividerHorizontal: dividerHorizontal,
    progress: { color: constant.primaryColor },
    deleteIcon: { color: constant.deleteColor },
    box2: { display: "flex", flexWrap: "wrap" },
    button: { color: "#3f7dbd", marginLeft: "10px" },
    panelTop: { height: `${panelTop}%`, overflowY: "auto" },
    box: { display: "flex", flexWrap: "wrap", paddingTop: "10px" },
    panelBottom: { flex: 1, overflowY: "auto", paddingTop: "10px" },
    panel: { display: "flex", flexDirection: "row", flexWrap: "wrap" },
    speedDial: { position: "absolute", bottom: 20, right: 25, zIndex: 1 },
    panelCommandType2: { width: "100%", overflowX: "hidden", overflowY: "auto" },
    panels: { height: "100%", width: "100%", display: "flex", flexDirection: "column" },
    springs: { display: "flex", justifyContent: "space-between", padding: "0 5px", marginBottom: "10px" },
    container: { height: "100%", width: "100%", overflowX: "auto", display: "flex", flexDirection: "column" },
  };
};

export default Command;
export { Spring };
