import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { getExchangeRateCR } from "../../../assets/js/Commons";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import { db } from "../../../assets/context/firebase-config";
import { useThis } from "../../../assets/context/Context";
import { Checkbox, InputAdornment } from "@mui/material";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import PercentIcon from "@mui/icons-material/Percent";
import getConstant from "../../../assets/js/Constant";
import endpoints from "../../../assets/js/Endpoints";
import { doc, updateDoc } from "firebase/firestore";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "../LoadingButton";
import { useEffect, useState } from "react";
import CustomSwitch from "../CustomSwitch";
import LoadingBar from "../LoadingBar";
import CircleIcon from "./CircleIcon";
import InputText from "../InputText";
import Selector from "../Selector";
import DOMPurify from "dompurify";
import Message from "../Message";
import Label from "../Label";

/**
 * @name Others
 * @description Método que construye el contenido para el modal de Administración - Otros
 * @returns View
 * @version 1.0
 */
const Others = () => {
  const constant = getConstant();
  const [display, setDisplay] = useState("none");
  const { lang, config, dataBranch, branch, settings, setSettings, currency } = useThis();
  const css = styles(display);

  const [taxes, setTaxes] = useState([{ id: 0, name: lang.selectTax, icon: <TouchAppIcon sx={css.selectTax} />, code: "00", tariff: 0 }]);
  const [data, setData] = useState({ exchangeRate: settings.exchangeRate, restaurantService: settings.restaurantService, restaurantTax: taxes[0] });
  const [tariffCodes, setTariffCodes] = useState(config.contries[constant.cr].taxes.tariffCodes);
  const [taxesTypes, setTaxesTypes] = useState(config.contries[constant.cr].taxes.types);
  const [exangeRate, setExangeRate] = useState({ buy: "", sell: "" });
  const [taxesTypesViews, setTaxesTypesViews] = useState([]);
  const [loadingBar, setLoadingBar] = useState(false); // Para la tabla
  const [taxesViews, setTaxesViews] = useState([]);
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]

  /**
   * @name onCheckTax
   * @description Método que maneja el onChange de los checks de impuestos
   * @param {Event} e
   * @version 1.0
   */
  const onCheckTax = (e) => {
    const tc = tariffCodes.map((item) => (item.code === e.target.id ? { ...item, check: !item.check } : item));
    setTariffCodes(tc);
  };

  /**
   * @name onCheckTaxTypes
   * @description Método que maneja el onChange de los tipos de checks de impuestos
   * @param {Event} e
   * @version 1.0
   */
  const onCheckTaxTypes = (e) => {
    const tt = taxesTypes.map((item) => (item.code === e.target.id ? { ...item, check: !item.check } : item));
    setTaxesTypes(tt);
  };

  /**
   * @name getMessageExchangeRate
   * @description Método que devuelve el texto del tipo de cambio
   * @returns {String}
   * @version 1.0
   */
  const getMessageExchangeRate = () => lang.messageExchangeRate.replace("[BUY]", exangeRate.buy).replace("[SELL]", exangeRate.sell);

  /**
   * @name saveData
   * @description Método que maneja la acción del botón guardar
   * @returns N/A
   * @version 1.0
   */
  const saveData = async () => {
    if (loadingBar) return;
    setLoadingBar(true);
    const selectedTaxes = tariffCodes.filter((e) => e.check);
    const selectedTypesTaxes = taxesTypes.filter((e) => e.check);
    const request = {
      tariffCodes: selectedTaxes,
      taxesTypes: selectedTypesTaxes,
      exchangeRate: data.exchangeRate,
      priceCalculation: data.priceCalculation,
      restaurantService: data.restaurantService,
      restaurantTax: { code: data.restaurantTax.code, name: data.restaurantTax.name, tariff: data.restaurantTax.tariff },
    };
    console.log(request);
    try {
      // Se actualizan lod settings
      await updateDoc(doc(db, endpoints.setting(branch.administrator, branch.branchId, "others")), { ...settings, ...request });
      setSettings({ ...settings, ...request });
      setSnack([91, constant.success]);
    } catch (error) {
      console.log(error);
      setSnack([92, constant.error]);
    }
    setLoadingBar(false);
  };

  /** Efecto para manejar el tipo de cambio del dólar */
  useEffect(() => {
    const getExangeRate = async () => {
      setLoadingBar(true);
      const result = await getExchangeRateCR(); // Llama al servicio
      if (!result.error) {
        setDisplay("flex");
        setExangeRate({ buy: result.response.compra.valor, sell: result.response.venta.valor });
      }
      setLoadingBar(false);
    };
    getExangeRate();
    //Se carga el valor del precio final en el switch
    setData((prev) => ({ ...prev, priceCalculation: settings.priceCalculation }));
  }, []);

  /** Efecto para manejar la carga de los impuestos */
  useEffect(() => {
    let id = 1; // Inicia en 1 porque el primer item es el de "Seleccione un impuesto" en el array "selectedTaxes"
    const values = tariffCodes.reduce(
      (acc, e) => {
        // Agrega los items de los impuestos seleccionados para el comboBox
        if (e.check) acc.selectedTaxes.push({ id: id++, name: e.description, icon: null, code: e.code, tariff: e.tariff });
        // Pinta todos los items con sus respectivos estados al haber un cambio
        acc.taxesViews.push(
          <div key={"tariffCodes_" + e.code} style={css.checkTaxes}>
            <p style={css.width100}>{e.description}</p>
            <Checkbox onMouseUp={onCheckTax} id={e.code} sx={css.check} checked={e.check} />
          </div>
        );
        return acc;
      },
      { taxesViews: [], selectedTaxes: [{ id: 0, name: lang.selectTax, icon: <TouchAppIcon sx={css.selectTax} />, code: "00", tariff: 0 }] }
    );
    setTaxes(values.selectedTaxes);
    setTaxesViews(values.taxesViews);
    // Vuelve el valor por defecto del comboBox ("Seleccione un impuesto")
    setData((prev) => ({ ...prev, restaurantTax: values.selectedTaxes[0] }));
  }, [tariffCodes]);

  /** Efecto para manejar la carga de los tipos de impuestos en la vista*/
  useEffect(() => {
    const values = taxesTypes.reduce((acc, e) => {
      acc.push(
        <div key={"taxTypes_" + e.code} style={css.checkTypesTaxes}>
          <div style={css.checkTypesTaxes2}>
            <p style={css.taxNames}>{e.name}</p>
            <p>{e.description}</p>
          </div>
          <Checkbox onMouseUp={onCheckTaxTypes} id={e.code} sx={css.check} checked={e.check} />
        </div>
      );
      return acc;
    }, []);
    setTaxesTypesViews(values);
  }, [taxesTypes]);

  /** Efecto para cargar los valores de los checks de firestore en la vista */
  useEffect(() => {
    const tc = tariffCodes.map((item) => (settings.tariffCodes.find((e) => e.code === item.code) ? { ...item, check: true } : item));
    setTariffCodes(tc);
    const tt = taxesTypes.map((item) => (settings.taxesTypes.find((e) => e.code === item.code) ? { ...item, check: true } : item));
    setTaxesTypes(tt);
  }, []);

  /** Efecto para manejar la carga del impuesto seleccionado en el comboBox */
  useEffect(() => {
    const taxSelected = taxes.find((e) => e.code === settings.restaurantTax.code);
    if (taxSelected) setData((prev) => ({ ...prev, restaurantTax: taxSelected }));
  }, [taxes]);

  return (
    <>
      <LoadingBar visible={loadingBar} />
      <div style={css.container}>
        <div style={css.left}>
          <CircleIcon icon={<ContentPasteIcon sx={css.icon} />} text={lang.configOthersInfo} />
        </div>
        <div style={css.right}>
          <br />
          <Label text={lang.configOthersTitle} fontSize={"30px"} />
          <br />
          <Label text={lang.exchangeRate} fontSize={"20px"} fontWeight={500} />
          <div style={css.exchangeRateContainer}>
            <Label text={lang.enterExchangeRate} fontSize={"18px"} margin={"0 15px 0 0"} />
            <InputText
              data={data}
              size={"small"}
              type={"number"}
              setData={setData}
              maxWidth={"300px"}
              name={"exchangeRate"}
              value={data.exchangeRate}
              icon={<PriceChangeIcon />}
              label={lang.currentExchangeRate}
              startProp={<InputAdornment position="start">{config.contries[dataBranch.country.code].currencies[0].symbol}</InputAdornment>}
            />
          </div>
          <div style={css.messageExchangeRate}>
            <CurrencyExchangeIcon fontSize="large" sx={css.exchangeRateIcon} />
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getMessageExchangeRate()) }} />
          </div>
          <div style={css.line1}></div>
          <Label text={lang.titleTax} fontSize={"20px"} fontWeight={500} margin={"10px 0"} />
          <div style={css.enableTax}>
            <div style={css.dot2}></div>
            <Label text={lang.enableTax} fontSize={"18px"} fontWeight={500} />
          </div>
          <p style={css.marginLeft17}>{lang.taxMessage1}</p>
          <p style={css.subTitleText} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(lang.taxMessage2) }} />
          {taxesViews}
          <div style={css.line2}></div>
          <div style={css.subTitle}>
            <div style={css.dot}></div>
            <Label text={lang.taxTypes} fontSize={"18px"} fontWeight={500} margin={"15px 0 0 0"} />
          </div>
          <p style={css.subTitleText}>{lang.taxTypesText}</p>
          {taxesTypesViews}
          <div style={css.line2}></div>
          <div style={css.subTitle}>
            <div style={css.dot}></div>
            <Label text={lang.taxServiceRestaurant} fontSize={"18px"} fontWeight={500} margin={"15px 0 0 0"} />
          </div>
          <div style={css.serviceTaxContainer}>
            <InputText
              data={data}
              size={"small"}
              type={"number"}
              setData={setData}
              maxWidth={"250px"}
              marginRight={"20px"}
              icon={<PercentIcon />}
              name={"restaurantService"}
              value={data.restaurantService}
              label={lang.restaurantService}
            />
            <Selector name="restaurantTax" data={data} setData={setData} collection={taxes} group={2} maxWidth={"260px"} iconType={1} />
          </div>
          <div style={css.line1}></div>
          <Label text={lang.finalPriceCalculation} fontSize={"20px"} fontWeight={500} />
          <Label text={lang.finalPriceCalculationText} margin={"0 0 15px 0"} />
          <div style={{ display: "flex" }}>
            <CustomSwitch data={data} setData={setData} name="priceCalculation" textLeft={lang.simple} textRight={lang.compound} />
            <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px", paddingLeft: "25px", borderLeft: "1px solid #CCC" }}>
              <p>
                <span style={{ fontWeight: 500, color: "#444" }}>{lang.formula}</span>
                {data.priceCalculation ? lang.simpleText : lang.compoundText}
              </p>
              <p>
                <span style={{ fontWeight: 500, color: "#444" }}>{lang.example}</span>
                {data.priceCalculation
                  ? lang.simpleExample.replaceAll("[SYMBOL]", currency.symbol)
                  : lang.compoundExample.replaceAll("[SYMBOL]", currency.symbol)}
              </p>
            </div>
          </div>
          <br />
          <div style={css.line3}></div>
          <div style={css.button}>
            <LoadingButton text={lang.save} width="200px" handleClick={saveData} icon={<SaveIcon />} color={constant.primaryColor} marginTop={"15px"} />
          </div>
          <br />
        </div>
        <Message snack={snack}></Message>
      </div>
    </>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} display
 * @returns Object
 * @version 1.0
 */
const styles = (display) => {
  return {
    width100: { width: "100%" },
    taxNames: { fontWeight: "600" },
    marginLeft17: { marginLeft: "17px" },
    line3: { borderBottom: "1px solid #CCC" },
    subTitleText: { margin: "0 0 15px 17px" },
    icon: { fontSize: "70px", color: "#FFF" },
    button: { display: "flex", justifyContent: "end" },
    subTitle: { display: "flex", alignItems: "center" },
    exchangeRateIcon: { color: "#444", marginRight: "15px" },
    line1: { borderBottom: "1px solid #CCC", margin: "15px 0" },
    right: { flex: 1, padding: "0 25px 0 0", overflowY: "auto" },
    container: { width: "100%", height: "100%", display: "flex" },
    line2: { borderBottom: "1px solid #CCC", margin: "0 0 0 17px" },
    serviceTaxContainer: { display: "flex", margin: "15px 0 0 17px" },
    selectTax: { fontSize: "16px", color: "#444", marginRight: "5px" },
    check: { color: "#A7524C", "&.Mui-checked": { color: "#38805a" } },
    enableTax: { display: "flex", alignItems: "center", margin: "5px 0" },
    messageExchangeRate: { display, alignItems: "center", marginTop: "10px" },
    exchangeRateContainer: { display: "flex", marginTop: "15px", alignItems: "center" },
    left: { display: "flex", justifyContent: "center", alignItems: "center", margin: "0 5rem" },
    checkTypesTaxes2: { display: "flex", flexDirection: "column", width: "100%", paddingBottom: "3px" },
    checkTaxes: { display: "flex", alignItems: "center", borderTop: "1px solid #ccc", marginLeft: "17px" },
    dot2: { borderRadius: "50%", width: "12px", height: "12px", backgroundColor: "#a7524c", margin: "0 5px 0 0" },
    dot: { borderRadius: "50%", width: "12px", height: "12px", backgroundColor: "#a7524c", margin: "15px 5px 0 0" },
    checkTypesTaxes: { display: "flex", borderTop: "1px solid #ccc", paddingTop: "3px", marginBottom: "2px", marginLeft: "17px" },
  };
};

export default Others;
