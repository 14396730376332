import Label from "../Label";

/**
 * @name CircleIcon
 * @param {Object} { icon: View, text: String }
 * @returns View
 * @version 1.0
 */
const CircleIcon = ({ icon, text }) => {
  const css = styles();

  return (
    <div style={css.container}>
      <div style={css.circleIcon}>
        <div style={css.chartIcon}>
          <div style={css.barChartIcon}>
            <span style={css.btnRed}></span>
            <span style={css.btnYellow}></span>
            <span style={css.btnGreen}></span>
          </div>
          <div style={css.bodyChartIcon}>{icon}</div>
        </div>
      </div>
      <Label text={text} fontSize={"18px"} textAlign={"center"} margin={"15px 0"} />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    container: { display: "flex", flexDirection: "column", justifyContent: "center", width: "300px" },
    circleIcon: {
      display: "flex",
      height: "300px",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#3d8c63",
      border: "5px solid #38805a",
      boxShadow: "inset 0 0 15px 20px #38805a",
    },
    chartIcon: {
      width: "150px",
      height: "150px",
      border: "1px solid #444",
    },
    barChartIcon: {
      height: "25px",
      display: "flex",
      paddingLeft: "10px",
      alignItems: "center",
      backgroundColor: "#252525",
    },
    btnRed: {
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      backgroundColor: "crimson",
    },
    btnYellow: {
      width: "10px",
      height: "10px",
      marginLeft: "5px",
      borderRadius: "50%",
      backgroundColor: "yellow",
    },
    btnGreen: {
      width: "10px",
      height: "10px",
      marginLeft: "5px",
      borderRadius: "50%",
      backgroundColor: "#4daf7c",
    },
    bodyChartIcon: {
      width: "100%",
      height: "125px",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#444",
      justifyContent: "center",
    },
  };
};

export default CircleIcon;
