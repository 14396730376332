import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { useThis } from "../../../../assets/context/Context";
import AssignmentIcon from "@mui/icons-material/Assignment";
import OpeningAndClosing from "./OpeningAndClosing";
import { getNavigationAction } from "../Products";
import { BottomNavigation } from "@mui/material";
import MovementsDay from "./MovementsDay";
import { useState } from "react";

/**
 * @name CasCashClosing
 * @description Método que carga el módulo de cierre de caja
 * @returns View
 * @version 1.0
 */
const CashClosing = () => {
  const css = styles();
  const { lang } = useThis();

  const views = [<OpeningAndClosing />, <MovementsDay />];

  const [value, setValue] = useState(0);
  const [BottomNavigationAction] = useState(getNavigationAction());

  return (
    <div style={css.box}>
      <div style={css.container}>{views[value]}</div>
      <BottomNavigation showLabels value={value} sx={css.paper} onChange={(_, newValue) => setValue(newValue)}>
        <BottomNavigationAction label={lang.openingAndClosing} icon={<PointOfSaleIcon />} />
        <BottomNavigationAction label={lang.movementsDay} icon={<AssignmentIcon />} />
        <BottomNavigationAction label={lang.historic} icon={<HistoryOutlinedIcon />} />
        <BottomNavigationAction label={lang.expenses} icon={<ShoppingBasketIcon />} />
      </BottomNavigation>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    paper: { height: "60px", background: "#eeeeee", width: "100%" },
    box: { display: "flex", flexDirection: "column", width: "100%", height: "100%", },
    container: { flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflow: "hidden" },
  };
};

export default CashClosing;
