import { AppBar, Dialog, Divider, IconButton, Slide, Toolbar, Tooltip, Typography, Zoom } from "@mui/material";
import { SearchIconWrapper, SearchView, StyledInputBase } from "../components/ManageUsers";
import { useThis } from "../../assets/context/Context";
import { forwardRef, useRef, useState } from "react";
import getConstant from "../../assets/js/Constant";
import CloseIcon from "@mui/icons-material/Close";
import { Search } from "@mui/icons-material";

/** Inicialización de variables */
const constant = getConstant();
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

/**
 * @name ModalFullScreen
 * @description Método que se encarga de mostrar un modal fullScreen genérico
 * @param {Object} { title, icon, content, show, setShow, , showSearch, actionSearch, showAppBar, noBack }
 * @returns View
 * @version 1.0
 */
const ModalFullScreen = ({ title, icon, content, show, setShow, showSearch = false, actionSearch = () => {}, showAppBar = true, noBack = false }) => {
  const { lang } = useThis();

  const [barHeight, setBarHeight] = useState("");
  const css = styles(barHeight);

  const appBar = useRef(null);

  /**
   * @name onFocus
   * @description Método que se ejecuta cuando el modal obtiene el foco, para que tenga tiempo de tomar la altura del AppBar al redenderizar
   * @version 1.0
   */
  const onFocus = () => {
    if (barHeight === "" && appBar.current) setBarHeight(`${appBar.current.offsetHeight}px`);
  };

  return (
    <Dialog onFocus={onFocus} fullScreen open={show} onClose={() => setShow(false)} TransitionComponent={Transition} sx={noBack ? css.noBack : {}}>
      {showAppBar && (
        <AppBar ref={appBar} sx={css.appBar}>
          <Toolbar sx={css.toolBar}>
            <div style={css.title}>
              {icon}
              <Typography sx={css.typography} variant="h6">
                {title.toUpperCase()}
              </Typography>
            </div>
            <div style={css.close}>
              {showSearch && (
                <SearchView onChange={(e) => actionSearch(e)} sx={css.search}>
                  <SearchIconWrapper>
                    <Search sx={css.searchIcon} />
                  </SearchIconWrapper>
                  <StyledInputBase placeholder={lang.searchDotDot} />
                </SearchView>
              )}
              <Divider sx={css.divider} orientation="vertical" />
              <Tooltip TransitionComponent={Zoom} title={lang.close}>
                <IconButton edge="end" onClick={() => setShow(false)}>
                  <CloseIcon sx={css.white} />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>
      )}
      <div style={css.container}>{content}</div>
    </Dialog>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} barHeight - "69px"
 * @returns Object
 * @version 1.0
 */
const styles = (barHeight) => {
  return {
    white: { color: "#fff" },
    searchIcon: { color: "#555" },
    divider: { height: 28, m: 0.5 },
    search: { color: "#333", marginRight: "15px" },
    close: { display: "flex", alignItems: "center" },
    typography: { color: "#fff", marginLeft: "10px" },
    title: { ml: 2, display: "flex", alignItems: "center" },
    appBar: { width: "100%", background: constant.primaryColor },
    toolBar: { display: "flex", justifyContent: "space-between" },
    container: { flex: "1", padding: barHeight + " 0px 0px 0px", overflowY: "hidden" },
    noBack: { ".MuiPaper-root": { background: "#00000050", WebkitBackdropFilter: "blur(8px)", backdropFilter: "blur(8px)" } },
  };
};

export default ModalFullScreen;
