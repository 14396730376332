import getConstant from "../js/Constant";

/**
 * @name messages_es
 * @description Método que devuelve los mensajes en español para el componente SnackBar
 * @returns {Object} Objeto con los mensajes en español
 */
const messages_es = () => {
  const constant = getConstant();

  return {
    lang: "lang-es",
    0: "Error en el test",
    1: "Ingrese su correo electrónico",
    2: "Ingrese su contraseña",
    3: "Entra al enlace enviado a tu correo",
    4: "Debe ingresar un nombre de usuario",
    5: `La contraseña debe tener ${constant.passwordLength} o más caracteres.`,
    6: "El correo electrónico es incorrecto",
    7: "La contraseña es incorrecta",
    8: "El correo electrónico ya existe",
    9: "Ha ocurrido un error no controlado",
    10: "Ha ocurrido un error al enviar el correo",
    11: "Complete el proceso de registro",
    12: "Proceso de registro completado",
    13: "Vuelva a intentar en un momento",
    14: "Este correo aún no se ha registrado",
    15: "Verifique sus datos",
    16: "Verifique su conexión a internet",
    17: "El campo Identificación es requerido",
    18: "El campo Nombre es requerido",
    19: "Error al consultar la identificación",
    20: "No se encuentra inscrito(a) en Hacienda",
    21: "Error en el servidor de Hacienda!",
    22: "Falta código de actividad de Hacienda",
    23: "Error al crear la sucursal",
    24: "Usuario inválido",
    25: "Sucursal agregada con éxito",
    26: "Error al obtener la configuración",
    27: "No cuenta con los permisos de administrador",
    28: "Request inválido",
    29: "Ocurrió un error al realizar el registro",
    30: "Código de sucursal incorrecto",
    31: "Digite el código de la sucursal",
    32: "No se pudo eliminar la sucursal",
    33: "Sucursal eliminada con éxito",
    34: "Ocurrió un error al eliminar la sucursal",
    35: "Error al consultar las suscripciones",
    36: "Error al cargar!",
    37: "Error al crear la suscripción",
    38: "Error al obtener datos de la sucursal",
    39: "Se creó la solicitud de suscripción",
    40: "Suscripciones pendientes de aprobar",
    41: "Este método de pago está inactivo",
    42: "Error en almacenar el archivo",
    43: "Es necesario adjuntar el comprobante",
    44: "Error al agregar claims",
    45: "Para esta sucursar seleccione otro plan",
    46: "Plan no permitido para esta sucursal",
    47: "Sucursal sin comprobantes electrónicos",
    48: "Error al consultar suscripciones válidas",
    49: "No posee suscripciones válidas",
    50: "Error al conceder permisos",
    51: "Error al obtener los roles",
    52: "Error al crear el nuevo usuario",
    53: "Error al obtener los datos del usuario",
    54: "Este correo aún no se ha registrado",
    55: "Error al cargar los usuarios",
    56: "Ya existe un usuario con este nombre",
    57: "Ya existe un usuario con este correo",
    58: "Error al editar el usuario",
    59: "Digite el correo del usuario",
    60: "El usuario no posee permisos",
    61: "Error al eliminar los permisos",
    62: "Sin usuarios seleccionados",
    63: "Error al eliminar usuarios",
    64: "Error al cargar la sucursal",
    65: "Error al editar la sucursal",
    66: "Su plan actual es de documentos físicos",
    67: "Sucursal modificada con éxito",
    68: "Existen sucursales sin suscripción",
    69: "Error al obtener las sucursales",
    70: "Error al crear el servicio",
    71: "Error al guardar la imagen",
    72: "Error al agregar la categoría",
    73: "Categoría agregada con éxito",
    74: "Error al editar la categoría",
    75: "Categoría editada con éxito",
    76: "Nombre de categoría requerido",
    77: "Error al eliminar la categoría",
    78: "Categoría eliminada con éxito",
    79: "Eliminación con errores",
    80: "Categorías eliminadas éxitosamente",
    81: "Agregado con éxito",
    82: "Error al agregar",
    83: "Nombre requerido",
    84: "Cantidad requerida",
    85: "Editado con éxito",
    86: "Error al editar",
    87: "Ítem eliminado con éxito",
    88: "Error al eliminar el ítem",
    89: "Ítems eliminados éxitosamente",
    90: "Error al eliminar el logo",
    91: "Actualización exitosa",
    92: "Error al actualizar los datos",
    93: "El lugar es requerido",
    94: "El precio es requerido",
    95: "Express eliminado con éxito",
    96: "Error al eliminar el express",
    97: "Cantidades iguales a cero",
    98: "No posee productos ni servicios",
    99: "Nombre del menú requerido",
    100: "Seleccione una categoría",
    101: "Tipo de impuesto requerido",
    102: "Impuesto requerido",
    103: "Menú agregado con éxito",
    104: "Error al editar el menú",
    105: "Menú editado con éxito",
    106: "Error al agregar el menú",
    107: "Sucursal con plan gratuito",
    108: "Menú eliminado con éxito",
    109: "Error al eliminar el menú",
    110: "Menús eliminados éxitosamente",
    111: "Autenticación no comprobada",
    112: "Error al eliminar la imagen",
    113: "Ya existe ésta categoría",
    114: "Error al actualizar",
    115: "Actualización exitosa",
    116: "Sin pedidos seleccionados",
    117: "Pedidos eliminados con éxito",
    118: "Error al eliminar pedidos",
    119: "Sin cambios para actualizar",
    120: "Error validando los documentos",
    121: "Error al trasladar pedidos",
    122: "Pedidos trasladados con éxito",
    123: "Órdenes enviadas a cocina!",
    124: "Error al enviar a cocina!",
    125: "Pedido retirado",
    126: "Error al retirar el pedido",
    127: "Tiene pedidos pendientes",
    128: "Este espacio no existe",
    129: "Nombre de cliente requerido",
    130: "Ya existe éste cliente",
    131: "Error al agregar el cliente",
    132: "Cliente agregado con éxito",
    133: "Error al editar el cliente",
    134: "Cliente editado con éxito",
    135: "Error al eliminar el espacio",
    136: "Espacio eliminado con éxito",
    137: "Error obteniendo impresoras",
    138: "Impresoras cargadas con éxito",
    139: "Request de impresión inválido",
    140: "Impresora no encontrada",
    141: "Error al imprimir",
    142: "Impresión exitosa",
    143: "Error al eliminar el cliente",
    144: "Cliente eliminado con éxito",
    145: "Clientes eliminados éxitosamente",
    146: "Número de documento requerido",
    147: "Monto del documento requerido",
    148: "Error al aperturar la caja",
    149: "Error al cerrar la caja",
    150: "Debe abrir la caja primero",
    151: "Error al obtener la caja",
    152: "Error al validar el token",
    153: "Error al obtener el consecutivo",
    154: "Error actualizando consecutivo",
    155: "Error al actualizar expiración",
  };
};

export default messages_es;
