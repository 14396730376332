import AlarmTwoToneIcon from "@mui/icons-material/AlarmTwoTone";
import { useThis } from "../../../../assets/context/Context";
import { formatDate } from "../../../../assets/js/Commons";
import getConstant from "../../../../assets/js/Constant";
import defalut from "../../../../images/default.svg";
import { Timestamp } from "firebase/firestore";
import { IconButton } from "@mui/material";
import { Spring } from "./Command";
import DOMPurify from "dompurify";
import { useEffect } from "react";
import Label from "../../Label";

/**
 * @name CommandItem2
 * @description Método que devuelve un componente tipo ítem de para la comanda (por espacio)
 * @param {Object} { size, title, orders, color, requestDate, setOpenModalWithdraw, setWithdrawObject, idSpace }
 * @returns View
 * @version 1.0
 */
const CommandItem2 = ({ size, title, orders, color, requestDate, setOpenModalWithdraw, setWithdrawObject, idSpace }) => {
  const { lang } = useThis();
  const constant = getConstant();
  const css = styles({ width: size * 1, color, constant });

  /**
   * @name onClickItem
   * @description Método que maneja el evento onClick del ítem de comanda
   * @version 1.0
   */
  const onClickItem = () => {
    setOpenModalWithdraw(true);
    setWithdrawObject({ orders, title, idSpace });
  };

  return (
    <div className="note-background2" style={css.note} onClick={onClickItem}>
      <div style={css.springs}>
        <Spring point={css.point} />
        <Spring point={css.point} />
        <Spring point={css.point} />
      </div>
      <div style={css.text}>
        <Label fontWeight={700} text={title} fontSize={"14px"} margin="0 0 10px 0" />
        {orders.map((order) => {
          if (!order.requestKitchen || !order.inKitchen) return null;
          const date = Timestamp.fromDate(new Date(order.date.seconds * 1000 + order.date.nanoseconds / 1e6), lang);
          return (
            <div key={order.id} style={css.row}>
              <div style={css.containerImg}>
                {order.statusKitchen || (
                  <IconButton sx={css.fab}>
                    <AlarmTwoToneIcon sx={css.icon} />
                  </IconButton>
                )}
                <img
                  alt="img"
                  width={50}
                  height={50}
                  src={order.url || defalut}
                  style={styles({ width: size * 1, color, constant, statusKitchen: order.statusKitchen }).img}
                />
              </div>
              <div>
                <p style={css.description} dangerouslySetInnerHTML={{ __html: secureMessage(order.name) + (order.detail || lang.noDescription) }} />
                <Label textAlign="end" fontSize={"12px"} text={formatDate(date, lang)} />
              </div>
            </div>
          );
        })}
        <p style={css.init}>
          <b>{lang.init + ": "}</b>
          <span>{formatDate(Timestamp.fromDate(new Date(requestDate.seconds * 1000 + requestDate.nanoseconds / 1e6)), lang)}</span>
        </p>
      </div>
    </div>
  );
};

/**
 * @name secureMessage
 * @description Elimina el <script> malicioso
 * @param {String} order
 * @version 1.0
 */
const secureMessage = (order) => DOMPurify.sanitize("<b>" + order + "</b>: ");

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} { width, color, constant, statusKitchen }
 * @returns Object
 * @version 1.0
 */
const styles = ({ width, color, constant, statusKitchen }) => {
  return {
    note: { width },
    init: { fontSize: "10px" },
    icon: { fontSize: "19px" },
    description: { fontSize: "14px" },
    row: { display: "flex", marginBottom: "8px" },
    containerImg: { width: "auto", height: "auto", position: "relative" },
    img: { borderRadius: "5px", marginRight: "10px", border: "2px solid " + (statusKitchen ? "#5CB67F" : "#8A1521") },
    text: { flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between", margin: "0 10px", overflow: "hidden" },
    fab: {
      width: "20px",
      height: "20px",
      top: "-10px",
      color: constant.deleteColor,
      marginLeft: "3px",
      position: "absolute",
      backgroundColor: "#FFF",
      "&:hover": { backgroundColor: "#FFF" },
    },
    springs: {
      display: "flex",
      marginBottom: "5px",
      padding: "5px 10px 8px 10px",
      backgroundColor: "#" + color,
      justifyContent: "space-between",
      boxShadow: "inset 2px 2px 15px 2px rgba(44, 44, 44, 0.3)",
    },
    point: {
      width: "23px",
      height: "23px",
      position: "relative",
      borderRadius: "50px",
      backgroundColor: "#e3e3e3",
      border: "1px solid #626262",
      boxShadow: "inset 2px 2px 2px 2px rgba(0, 0, 0, 0.3)",
    },
  };
};

export default CommandItem2;
export { secureMessage };
