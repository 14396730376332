import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DescriptionIcon from "@mui/icons-material/Description";
import EngineeringIcon from "@mui/icons-material/Engineering";
import Others from "../../views/components/submenu/Others";
import System from "../../views/components/submenu/System";
import Print from "../../views/components/submenu/Print";
import TerminalIcon from "@mui/icons-material/Terminal";

/**
 * @name submenuOptions
 * @description Constante que devuelve los items del menú Home
 * @param {Object} lang
 * @returns {Object}
 * @version 1.0
 */
const submenuOptions = (lang) => {
  return {
    CR: [
      { text: lang.users, icon: <EngineeringIcon />, params: getModalParameters(lang)[0], color: "#88b999" },
      { text: lang.company, icon: <FolderSharedIcon />, params: getModalParameters(lang)[0], color: "#af88b9" },
      { text: lang.atv, icon: <DescriptionIcon />, params: getModalParameters(lang)[0], color: "#8897b9" },
      { text: lang.system, icon: <TerminalIcon />, params: getModalParameters(lang)[3], color: "#cd8484" },
      { text: lang.print, icon: <LocalPrintshopIcon />, params: getModalParameters(lang)[2], color: "#ec9f83" },
      { text: lang.others, icon: <ContentPasteIcon />, params: getModalParameters(lang)[1], color: "#cdb274" },
    ],
    OT: [
      { text: lang.users, icon: <EngineeringIcon />, params: getModalParameters(lang)[0], color: "#88b999" },
      { text: lang.company, icon: <FolderSharedIcon />, params: getModalParameters(lang)[0], color: "#af88b9" },
      { text: lang.system, icon: <TerminalIcon />, params: getModalParameters(lang)[0], color: "#cd8484" },
      { text: lang.print, icon: <LocalPrintshopIcon />, params: getModalParameters(lang)[0], color: "#ec9f83" },
      { text: lang.others, icon: <ContentPasteIcon />, params: getModalParameters(lang)[0], color: "#cdb274" },
    ],
  };
};

/**
 * @name getModalParameters
 * @description Método que retorna el contenido para cada modal del módulo de Administración
 * @param {Object} lang
 * @returns {Object}
 * @version 1.0
 */
const getModalParameters = (lang) => {
  return {
    0: { title: "", icon: null, content: <></> },
    1: { title: lang.administration + " / " + lang.others, icon: <ContentPasteIcon />, content: <Others /> },
    2: { title: lang.administration + " / " + lang.print, icon: <LocalPrintshopIcon />, content: <Print /> },
    3: { title: lang.administration + " / " + lang.system, icon: <TerminalIcon />, content: <System /> },
  };
};

export { submenuOptions };
