import { getCRCantonsByProvince, getCRDistrictsByProvincAndCanton, getCRProvinces, getInfoByIde } from "../../../../assets/js/Commons";
import { Info, Badge, FoodBank, AlternateEmail, LocalPhone, Directions } from "@mui/icons-material";
import { useThis } from "../../../../assets/context/Context";
import getConstant from "../../../../assets/js/Constant";
import AutoCompleteInput from "../../AutoCompleteInput";
import CustomSwitch from "../../CustomSwitch";
import { useEffect, useState } from "react";
import InputText from "../../InputText";
import Selector from "../../Selector";
import Label from "../../Label";

/** Variables globales */
const icon = <Info color="info" sx={{ fontSize: "16px" }} />;

const AddCustomer = ({ data, setData, nameFocus, edit, identificationFocus, setLoading, setSnack }) => {
  const constant = getConstant();
  const provinces = getCRProvinces();
  const { lang, typesIDs } = useThis();

  const [display, setDisplay] = useState("none"); // Para mostrar la sección de dirección
  const [cantons, setCantons] = useState(getCRCantonsByProvince(constant._PROVINCE));
  const [districts, setDistricts] = useState(getCRDistrictsByProvincAndCanton(constant._PROVINCE, constant._CANTON));
  const css = styles(display);

  /**
   * @name onKey
   * @description Método encargado de controlar si se presiona enter para consultar ante Hacienda CR la información de la identificación ingresada
   * @param {Event} e
   * @version 1.0
   */
  const onKey = async (e) => {
    if (e.key !== "Enter") return;
    // Inicia la barra de cargando...
    setLoading(true);
    // Llama a la función que consulta los datos del usuario a Hacienda CR
    const message = await getInfoByIde(data.identification, false);
    // Detiene la barra de cargando cuando se optiene la respuesta
    setLoading(false);
    // Si la respuesta viene con error, se muestra el mensaje tipo Toast
    if (message.error) return setSnack([message.errorCode, constant.error]);
    // Se selecciona el tipo de identificación y se agrega en el selector de tipos de IDs
    const typeID = typesIDs.find((element) => element.type === message.response.tipoIdentificacion);
    // Se seleccionan las actividades y en caso de tener, se agregan en el selector de código de actividad
    setData({ ...data, typeID, name: message.response.nombre });
  };

  /**
   * @name resetData
   * @description Método que restaura los valores iniciales de tipo de indentificación nombre y códigos de actividad
   * @version 1.0
   */
  const resetData = () => {
    console.log(data);
    setData((prev) => ({ ...prev, typeID: typesIDs[0], name: "" }));
  };

  /**
   * @name onChange
   * @description Método encargado de filtrar los cantones y distritos para agregarlos al selector. Acción de los selectores de dirección.
   * @param {Array} option Contiene el conjunto de provincias, cantones o distritos
   * @param {String} name province, canton, district
   * @version 1.0
   */
  const onChange = (option, name) => {
    switch (name) {
      case "province":
        // Captura los cantones de la provincia seleccionada
        const cantons = getCRCantonsByProvince(option);
        setCantons(cantons);
        // Captura los distritos del primer cantón de la provincia seleccionada
        const districtsByProvincAndCanton = getCRDistrictsByProvincAndCanton(option, cantons[0]);
        setDistricts(districtsByProvincAndCanton);
        delete option.cantons;
        delete cantons[0].district;
        setData({ ...data, province: option, canton: cantons[0], district: districtsByProvincAndCanton[0] });
        break;
      case "canton":
        // Captura los distritos del cantón seleccionado
        const districts = getCRDistrictsByProvincAndCanton(data.province, option);
        setDistricts(districts);
        delete option.district;
        setData({ ...data, canton: option, district: districts[0] });
        break;
      default:
        setData({ ...data, district: option });
        break;
    }
  };

  /**
   * @description Efecto que muestra u oculta los componentes de dirección
   */
  useEffect(() => {
    data.showAddress ? setDisplay("flex") : setDisplay("none");
  }, [data.showAddress]);

  /**
   * @description Efecto que carga las direcciones en caso de ser una edición
   */
  useEffect(() => {
    if (edit) {
      onChange(data.province, "province");
      onChange(data.canton, "canton");
      onChange(data.district, "district");
    }
    identificationFocus.current.focus();
  }, []);

  return (
    <div style={css.container}>
      {edit && (
        <div style={css.containerSwitch}>
          <Label tooltip={lang.inactiveCustomerMessage} icon={icon} text={lang.status + ":"} fontWeight={500} />
          <CustomSwitch data={data} setData={setData} name="status" textLeft={lang.active} textRight={lang.inactive} disabled={false} />
        </div>
      )}
      <Selector name="typeID" data={data} setData={setData} group={1} display={"flex"} />
      <InputText
        data={data}
        marginTop={2}
        onKey={onKey}
        type={"text"}
        size={"small"}
        width={"auto"}
        marginBottom={2}
        icon={<Badge />}
        setData={setData}
        name="identification"
        label={lang.idePlusEnter}
        value={data.identification}
        reference={identificationFocus}
      />
      <InputText
        name="name"
        data={data}
        type={"text"}
        size={"small"}
        width={"auto"}
        marginBottom={2}
        value={data.name}
        setData={setData}
        icon={<FoodBank />}
        reference={nameFocus}
        label={lang.branchName}
      />
      <InputText
        data={data}
        name="email"
        type={"email"}
        size={"small"}
        width={"auto"}
        marginBottom={2}
        color={"#872B2B"}
        setData={setData}
        value={data.email}
        label={lang.email}
        icon={<AlternateEmail />}
      />
      <InputText
        data={data}
        name="phone"
        type={"text"}
        size={"small"}
        width={"auto"}
        marginBottom={1}
        setData={setData}
        label={lang.phone}
        value={data.phone}
        icon={<LocalPhone />}
      />
      <Label text={lang.requiredFields} fontSize="12px" color="#872B2B" />
      <div style={css.switch}>
        <Label text={lang.addDirection} fontSize="16px" />
        <CustomSwitch data={data} textLeft={lang.yes} textRight={lang.no} name="showAddress" setData={setData} />
      </div>
      <div style={css.containerAddress}>
        <Label text={lang.selectAddress} fontWeight="600" fontSize="16px" margin="15px 0 15px 0" />
        <AutoCompleteInput mb="15px" name="province" data={data} onChange={onChange} collection={provinces} text={lang.selectProvince} />
        <AutoCompleteInput mb="15px" name="canton" data={data} onChange={onChange} collection={cantons} text={lang.selectCanton} />
        <AutoCompleteInput mb="15px" name="district" data={data} onChange={onChange} collection={districts} text={lang.selectDistrict} />
        <InputText
          rows={2}
          data={data}
          marginTop={2}
          type={"text"}
          size={"small"}
          width={"auto"}
          setData={setData}
          name="otherAddress"
          icon={<Directions />}
          label={lang.otherAddress}
          value={data.otherAddress}
        />
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} display - "flex" ó "none"
 * @returns Object
 * @version 1.0
 */
const styles = (display) => {
  return {
    containerAddress: { display: display === "flex" ? "block" : "none" },
    container: { display: "flex", flexDirection: "column", width: "300px" },
    switch: { display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px 0 0 0" },
    containerSwitch: { display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" },
  };
};

export default AddCustomer;
