import { callCreateUser, callDeleteClaims, callEditUser, getAllUsers } from "../../assets/js/firebaseMethods";
import { IconButton, InputBase, Tooltip, Zoom, alpha, styled } from "@mui/material";
import { Edit, DeleteForever, PersonAdd, Search } from "@mui/icons-material";
import { db } from "../../assets/context/firebase-config";
import GenericGridType2 from "./grids/GenericGridType2";
import { useThis } from "../../assets/context/Context";
import { useEffect, useRef, useState } from "react";
import { deleteDoc, doc } from "firebase/firestore";
import getConstant from "../../assets/js/Constant";
import endpoints from "../../assets/js/Endpoints";
import AddOrEditUser from "./AddOrEditUser";
import Modal from "../containers/Modal";
import InputCheck from "./InputCheck";
import LoadingBar from "./LoadingBar";
import DeleteUser from "./DeleteUser";
import cr from "../../images/cr.svg";
import Message from "./Message";
import Label from "./Label";
import { v4 } from "uuid";

const SearchView = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

/**
 * @name ManageUsers
 * @description Método que maneja el contenido del modal para administrar usuarios
 * @param {Object} { consecutiveBranch: String, administrator: String }
 * @returns View
 * @version 1.0
 */
const ManageUsers = ({ consecutiveBranch, administrator }) => {
  const constant = getConstant();
  const { lang, permissions, user } = useThis();

  const [row, setRow] = useState({});
  const [data, setData] = useState({});
  const [rows, setRows] = useState([]);
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [edit, setEdit] = useState(false);
  const [snack2, setSnack2] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [auxRows, setAuxRows] = useState([]);
  const [idToEdit, setIdToEdit] = useState("");
  const [iconIndex, setIconIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingBar, setLoadingBar] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dataPermissions, setDataPermissions] = useState({});
  const [permissionsList, setPermissionsList] = useState([]);
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [openModalToDelete, setOpenModalToDelete] = useState(false);

  const css = styles(constant, showDeleteButton);

  const nameFocus = useRef(null);
  const emailFocus = useRef(null);

  /**
   * @name filterUser
   * @description Método que filtra los usuarios en la búsqueda
   * @param {Event} e
   * @version 1.0
   */
  const filterUser = (e) => {
    const users = auxRows.reduce((acc, item) => {
      if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) acc.push(item);
      return acc;
    }, []);
    setRows(users);
  };

  /**
   * @name actionRowDelete
   * @description Método que maneja el evento del botón eliminar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowDelete = (params) => {
    setRow(params.row);
    setOpenModalToDelete(true);
  };

  /**
   * @name deleteUser
   * @description Método encargado de llamar la funcionalidad para eliminar usuario
   * @version 1.0
   */
  const deleteUser = async () => {
    if (iconIndex === 0) return setSnack([59, constant.error]);
    if (iconIndex === 2) return setSnack([6, constant.error]);
    setLoading(true);
    const response = await callDeleteClaims(user, { email: row.email, branchID: consecutiveBranch }, administrator);
    if (response.error) {
      setSnack([response.errorCode, constant.error]);
    } else {
      await deleteDoc(doc(db, endpoints.user(administrator, consecutiveBranch, row.id)));
      setOpenModalToDelete(false);
      loadUsers();
    }
    setLoading(false);
  };

  /**
   * @name deleteUsers
   * @description Método encargado de manejar el click del botón eliminar usuarios
   * @returns N/A
   * @version 1.0
   */
  const deleteUsers = async () => {
    if (selectedRows.length === 0) return setSnack([62, constant.error]);
    setLoadingBar(true);
    let error = false;
    for (const item of rows) {
      if (selectedRows.includes(item.id)) {
        const response = await callDeleteClaims(user, { email: item.email, branchID: consecutiveBranch }, administrator);
        if (response.error) {
          error = true;
        } else {
          await deleteDoc(doc(db, endpoints.user(administrator, consecutiveBranch, item.id)));
        }
      }
    }
    loadUsers();
    if (error) setSnack2([63, constant.error]);
  };

  /**
   * @name actionRowEdit
   * @description Método que maneja el evento del botón editar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowEdit = (params) => {
    setEdit(true);
    setOpenCreateUser(true);
    setData(params.row.data);
    setIdToEdit(params.row.id);
    setDataPermissions(params.row.data.permissions);
  };

  /**
   * @name addUser
   * @description Método que maneja el evento del botón agregar usuario del modal
   * @version 1.0
   */
  const addUser = async () => {
    setLoading(true);
    const parameters = { ...data, permissions: dataPermissions, branchID: consecutiveBranch };
    deleteDataUser(parameters);
    const response = await callCreateUser(user, parameters, administrator, nameFocus, emailFocus, rows);
    if (response.error) {
      setSnack([response.errorCode, constant.error]);
    } else {
      setOpenCreateUser(false);
      loadUsers();
    }
    setLoading(false);
  };

  /**
   * @name editUser
   * @description Método que maneja el evento del botón editar usuario de modal
   * @version 1.0
   */
  const editUser = async () => {
    setLoading(true);
    const parameters = { ...data, permissions: dataPermissions, branchID: consecutiveBranch };
    deleteDataUser(parameters);
    const response = await callEditUser(user, parameters, administrator, nameFocus, emailFocus, rows, idToEdit);
    if (response.error) {
      setSnack([response.errorCode, constant.error]);
    } else {
      setOpenCreateUser(false);
      loadUsers();
    }
    setLoading(false);
  };

  /**
   * @name deleteDataUser
   * @description Método encargado de eliminar los datos innecesarios para el request de crear usuario
   * @param {Object} parameters request
   * @version 1.0
   */
  const deleteDataUser = (parameters) => {
    delete parameters.typeID.code;
    delete parameters.typeID.icon;
    delete parameters.typeID.name;
  };

  /**
   * @name resetData
   * @description Método que inicializa los datos del usuarrio
   * @version 1.0
   */
  const resetData = () => {
    return {
      name: "",
      email: "",
      phone: "",
      identification: "",
      typeID: { id: 0, name: lang.physical, icon: cr, code: "CR", type: "01" },
    };
  };

  /** Constante que define las columnas para la tabla */
  const columns = [
    { field: "name", headerName: lang.name, flex: 1 },
    { field: "identification", headerName: lang.identification, flex: 1 },
    { field: "email", headerName: lang.email, flex: 1 },
    { field: "phone", headerName: lang.phone, flex: 1 },
    { field: "action", headerName: lang.actions, flex: 0 },
  ];

  /** Constante que almacena los datos para los botones de acciones de cada fila */
  const actions = [
    { icon: <Edit />, callBack: actionRowEdit, color: constant.editColor },
    { icon: <DeleteForever />, callBack: actionRowDelete, color: constant.deleteColor },
  ];

  /**
   * @description Objeto para la creación del modal agregar o editar usuario
   */
  const parametersToAddUser = {
    btnText: edit ? lang.edit : lang.add,
    title: edit ? lang.editUser : lang.addUser,
    icon: edit ? <Edit /> : <PersonAdd />,
    content: (
      <AddOrEditUser
        data={data}
        readOnly={edit}
        setData={setData}
        setSnack={setSnack}
        nameFocus={nameFocus}
        setLoading={setLoading}
        emailFocus={emailFocus}
        permissionsList={permissionsList}
      />
    ),
  };

  /**
   * @description Objeto para la creación del modal eliminar usuario
   */
  const parametersToDeleteUser = {
    btnText: lang.delete,
    title: lang.deleteUser,
    icon: <DeleteForever />,
    content: <DeleteUser row={row} iconIndex={iconIndex} setIconIndex={setIconIndex} />,
  };

  /**
   * @name getDataPermissions
   * @description Obtiene la lista de permisos con sus valores
   * @returns {Array}
   * @version 1.0
   */
  const loadDataPermissions = () => {
    const list = permissions.reduce((acc, item) => {
      acc = { ...acc, [item.watch]: item.watchValue, [item.edit]: item.editValue };
      return acc;
    }, {});
    setDataPermissions(list);
  };

  /**
   * @name loadUsers
   * @description Carga los usuarios en la tabla
   * @version 1.0
   */
  const loadUsers = async () => {
    setLoadingBar(true);
    const response = await getAllUsers(administrator, consecutiveBranch);
    if (response.error) {
      setSnack([response.errorCode, constant.error]);
    } else {
      const users = response.users.docs.reduce((acc, item) => {
        acc.push({
          id: item.id,
          data: item.data(),
          name: item.data().name,
          email: item.data().email,
          phone: item.data().phone,
          identification: item.data().identification,
        });
        return acc;
      }, []);
      setRows(users);
      setAuxRows(users);
    }
    setLoadingBar(false);
  };

  /**
   * @name getPermissionsListView
   * @description Pinta la vista de los permisos con sus inputs
   * @version 1.0
   */
  const getPermissionsListView = () => {
    const list = permissions.reduce((acc, item) => {
      acc.push(
        <div key={v4()} style={css.permissionsRow}>
          <div style={css.permissionText}>
            <Label fontSize={"14px"} text={item.text} />
          </div>
          <div style={css.checks}>
            <InputCheck name={item.watch} data={dataPermissions} setData={setDataPermissions} value={dataPermissions[item.watch]} />
          </div>
          <div style={css.checks}>
            <InputCheck name={item.edit} data={dataPermissions} setData={setDataPermissions} value={dataPermissions[item.edit]} />
          </div>
        </div>
      );
      return acc;
    }, []);
    setPermissionsList(list);
  };

  /**
   * @name showCreateUserView
   * @description Muestra el modal para crear o ediar usuarios
   * @version 1.0
   */
  const showCreateUserView = () => {
    setEdit(false);
    /** Inicializa los datos */
    setData(resetData());
    /** Carga los valores de los permisos*/
    loadDataPermissions();
    /** Muestra el modal para crear o ediar usuario */
    setOpenCreateUser(true);
  };

  /** Efecto para cargar los usuarios y permisos*/
  useEffect(() => {
    /** Carga los usuarios en la tabla */
    loadUsers();
  }, []);

  /** Efecto que reacciona al cargar los permisos para pintar los componentes con los checks */
  useEffect(() => {
    getPermissionsListView();
  }, [dataPermissions]);

  /** Efecto para manejar el estado inicial del modal eliminar usuario */
  useEffect(() => {
    setIconIndex(0);
    setLoading(false);
  }, [openModalToDelete]);

  /** Efecto que maneja el comportamiento del botón eliminar usuarios */
  useEffect(() => {
    if (selectedRows.length > 0) {
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
  }, [selectedRows]);

  return (
    <div style={css.main}>
      <header style={css.header}>
        <Tooltip TransitionComponent={Zoom} title={lang.deleteUsers}>
          <IconButton onClick={deleteUsers} sx={css.btnDelete}>
            <DeleteForever />
          </IconButton>
        </Tooltip>
        <div style={css.flex}>
          <SearchView onChange={(e) => filterUser(e)} sx={css.search}>
            <SearchIconWrapper>
              <Search sx={css.searchIcon} />
            </SearchIconWrapper>
            <StyledInputBase placeholder={lang.searchDotDot} />
          </SearchView>
          <Tooltip TransitionComponent={Zoom} title={lang.addUser}>
            <IconButton onClick={showCreateUserView} sx={css.new}>
              <PersonAdd />
            </IconButton>
          </Tooltip>
        </div>
      </header>
      <LoadingBar visible={loadingBar} />
      <div style={css.containerDown}>
        <GenericGridType2 rowHeight={40} columns={columns} rows={rows} actions={actions} setSelectedRows={setSelectedRows} />
      </div>
      {/** Modal para agregar o editar un usuario */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openCreateUser}
        setOpen={setOpenCreateUser}
        icon={parametersToAddUser.icon}
        title={parametersToAddUser.title}
        btnText={parametersToAddUser.btnText}
        content={parametersToAddUser.content}
        clickBtnOk={edit ? editUser : addUser}
      />
      {/** Modal para eliminar un usuario */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        clickBtnOk={deleteUser}
        open={openModalToDelete}
        colorBar={constant.error}
        color={constant.deleteColor}
        setOpen={setOpenModalToDelete}
        icon={parametersToDeleteUser.icon}
        title={parametersToDeleteUser.title}
        btnText={parametersToDeleteUser.btnText}
        content={parametersToDeleteUser.content}
      />
      <Message snack={snack2}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} constant
 * @returns Object
 * @version 1.0
 */
const styles = (constant, showBtnDelete) => {
  return {
    flex: { display: "flex" },
    searchIcon: { color: "#CCC" },
    permissionText: { width: "60%" },
    containerDown: { flex: "1", overflowY: "auto" },
    search: { color: constant.primaryColor, background: "#eee" },
    new: { width: "auto", color: "#5AB262", marginLeft: "10px" },
    main: { display: "flex", flexDirection: "column", height: "100%" },
    checks: { width: "20%", display: "flex", justifyContent: "center" },
    btnDelete: { color: constant.deleteColor, visibility: showBtnDelete ? "visible" : "hidden" },
    header: { display: "flex", justifyContent: "space-between", margin: "10px 10px 5px 10px", height: "auto" },
    permissionsRow: { display: "flex", marginTop: "5px", color: "#444", alignItems: "center", justifyContent: "space-between" },
  };
};

export default ManageUsers;
export { SearchView, SearchIconWrapper, StyledInputBase };
