/**
 * @name endpoint
 * @description Objeto que contiene todos los endpoints de la base de datos, necesarios para las consultas.
 * @returns {String} endpoint
 * @version 1.0
 */
const endpoints = {
  branches: (userID) => `/accounts/${userID}/branch`,
  customers: (userID) => `/accounts/${userID}/customers`,
  branch: (userID, branchID) => `/accounts/${userID}/branch/${branchID}`,
  menus: (userID, branchID) => `/accounts/${userID}/branch/${branchID}/menus`,
  users: (userID, branchID) => `/accounts/${userID}/branch/${branchID}/users`,
  customer: (userID, customerID) => `/accounts/${userID}/customers/${customerID}`,
  express: (userID, branchID) => `/accounts/${userID}/branch/${branchID}/express`,
  services: (userID, branchID) => `/accounts/${userID}/branch/${branchID}/services`,
  products: (userID, branchID) => `/accounts/${userID}/branch/${branchID}/products`,
  user: (userID, branchID, id) => `/accounts/${userID}/branch/${branchID}/users/${id}`,
  categories: (userID, branchID) => `/accounts/${userID}/branch/${branchID}/categories`,
  closingBoxes: (userID, branchID) => `/accounts/${userID}/branch/${branchID}/closingBox`,
  setting: (userID, branchID, id) => `/accounts/${userID}/branch/${branchID}/settings/${id}`,
  subscriptions: (userID, branchID) => `/accounts/${userID}/branch/${branchID}/subscriptions`,
  menu: (userID, branchID, menuID) => `/accounts/${userID}/branch/${branchID}/menus/${menuID}`,
  closingBox: (userID, branchID, id) => `/accounts/${userID}/branch/${branchID}/closingBox/${id}`,
  storeLogo: (userID, branchID, fileName) => `/accounts/${userID}/${branchID}/images/logo/${fileName}`,
  storeMenu: (userID, branchID, fileName) => `/accounts/${userID}/${branchID}/images/menus/${fileName}`,
  product: (userID, branchID, productID) => `/accounts/${userID}/branch/${branchID}/products/${productID}`,
  oneExpress: (userID, branchID, expressID) => `/accounts/${userID}/branch/${branchID}/express/${expressID}`,
  category: (userID, branchID, categoryID) => `/accounts/${userID}/branch/${branchID}/categories/${categoryID}`,
  storeCategories: (userID, branchID, fileName) => `/accounts/${userID}/${branchID}/images/categories/${fileName}`,
  storeSubDoc: (userID, branchID, type, fileName) => `/accounts/${userID}/${branchID}/images/subscriptions/${type}/${fileName}`,
};

export default endpoints;
