import {
  signOut,
  updateProfile,
  OAuthProvider,
  setPersistence,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  browserLocalPersistence,
  browserSessionPersistence,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import lang_en from "../langs/en";
import lang_es from "../langs/es";
import cr from "../../images/cr.svg";
import ot from "../../images/ot.svg";
import endpoints from "../js/Endpoints";
import doc1 from "../../images/doc1.svg";
import doc2 from "../../images/doc2.svg";
import getConstant from "../js/Constant";
import { auth, db } from "./firebase-config";
import messages_es from "../langs/messages_es";
import messages_en from "../langs/messages_en";
import { getConfig } from "../js/firebaseMethods";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getCurrency, isMobileDevice } from "../js/Commons";
import getLocalStorageCodes from "../config/config-localStorage";
import { getStorageKey } from "../../views/components/ServiceItem";
import { createContext, useContext, useEffect, useRef, useState } from "react";

/** Se crea el contexto */
export const context = createContext();

/**
 * @name useThis
 * @description Método encargado de devolver el contexto
 * @returns context
 */
export const useThis = () => {
  const dataContext = useContext(context);
  return dataContext;
};

/**
 * @name Provider
 * @description Método encargado de proveer cada recurso
 * @param {Object} children
 * @returns N/A
 * @version 1.0
 */
export const Provider = ({ children }) => {
  const constant = getConstant();
  const codes = getLocalStorageCodes();

  const refDeleteTable = useRef();

  const [step, setStep] = useState(0); // Índice de los pasos del componente Stepper
  const [size, setSize] = useState(0); // Establece el tamaño de los servicios (mesas, express, llevar)
  const [lang, setLang] = useState({}); // Almacena el objeto de un idioma
  const [user, setUser] = useState(null); // Almacena los datos de sesión del usuario
  const [form, setForm] = useState(<></>); // Almacena las vistas (login, forgotForm...)
  const [sent, setSent] = useState(false); // Variable para definir que se envió o no el correo
  const [error, setError] = useState(false); // Maneja el error de respuesta al enviar el correo
  const [config, setConfig] = useState(null); // Almacena la configuración general del sistema
  const [message, setMessage] = useState({}); // Mensaje tipo Toast
  const [idsRows, setIdsRows] = useState({}); // Maneja los ids de las filas seleccionadas de la tabla GenericGridType1
  const [allMenus, setAllMenus] = useState([]); // Almacena todos los menús
  const [loading, setLoading] = useState(true); // Maneja el estado de autenticación de Firebase
  const [docTypes, setDocTypes] = useState([]); // Almacena los tipos de documentos del país
  const [sending, setSending] = useState(true); // Variabla para el estado de enviando correo
  const [entryType, setEntryType] = useState(0); // Tipos de entrada: 0 = Administrador, 1 = Colaborador
  const [checked, setChecked] = useState(false); // Variable para el manejo del checkbox de la columna de la tabla GenericGridType1
  const [settings, setSettings] = useState(null); // Almacena los settings
  const [currency, setCurrency] = useState(null); // Maneja la moneda del sistema
  const [isInside, setIsInside] = useState(false); // Valida que se encuentre dentro del botón de eliminar espacio
  const [verified, setVerified] = useState(false); // Variable para verificación de correo
  const [backItem, setBackItem] = useState(false); // Activa el botón de atrás en el menú del avatar
  const [isMobile, setIsMobile] = useState(false); // Establece si se accede desde un dispositivo movil
  const [backForm, setBackForm] = useState(<></>); // Establece la vista a la que se quiere regresar con el botón atrás del menú del avatar
  const [data, setData] = useState(constant.data); // Datos de inicio de usuario
  const [allExpress, setAllExpress] = useState([]); // Almacena todos los express
  const [yearText, setYearText] = useState("block"); // Muestra el texto que sale al pié de página
  const [auxAllMenus, setAuxAllMenus] = useState([]); // Auxiliar para almacenar todos los menús (para búsqueda)
  const [isDragging, setIsDragging] = useState(false); // Valida que se esté arrastrando el servicio (mesa, express, llevar)
  const [isPortrait, setIsPortrait] = useState(false); // Estado de orientación de la pantalla
  const [allCustomers, setAllCustomers] = useState([]); // Almacena todos los clientes
  const [branch, setBranch] = useState(constant.branch); // Almacena los ids de la sucursal y administrador actual
  const [auxAllExpress, setAuxAllExpress] = useState([]); // Auxiliar para almacenar todos los express (para búsqueda)
  const [allCategories, setAllCategories] = useState([]); // Almacena las categorías del menú
  const [dataRenew, setDataRenew] = useState(constant.renew); // Datos para renovar suscripción, document = url de img doc
  const [snapshotBranches, setSnapshotBranches] = useState([]); // Almacena el snapshot.docs de Firebase de la consulta de todas las sucursales
  const [activity, setActivity] = useState([constant.activity]); // Ítem por defecto cuando el usuario no tiene actividades ante Hacienda CR
  const [closingBoxCurrent, setClosingBoxCurrent] = useState(null); // Almacena el cierre de caja actual

  /** Constante para establecer los datos por defecto de una sucursal (para agregar o limpiar el modal al editar una)*/
  const defalutBranch = {
    logo: "",
    name: "",
    phone: "",
    email: "",
    logoName: "",
    tradename: "",
    otherAddress: "",
    identification: "",
    electronicBill: false,
    canton: constant._CANTON,
    province: constant._PROVINCE,
    district: constant._DISTRICT,
    activityCode: constant.activity,
    country: { id: 0, name: lang.cr, icon: cr, code: "CR", area: 506 },
    typeID: { id: 0, name: lang.physical, icon: cr, code: "CR", type: "01" },
    consecutives: { "01": constant["01"], "04": constant["04"], 90: constant["90"], 91: constant["91"] },
  };

  const [dataBranch, setDataBranch] = useState(defalutBranch); // Almacena los datos de la sucursal, al crear o editar una.

  /**
   * @description Arreglos para Selectores (países y tipos de identificaciones).
   */
  const countries = [
    { id: 0, name: lang.cr, icon: cr, code: "CR", area: 506 },
    { id: 1, name: lang.other, icon: ot, code: "OT", area: 0 },
  ];
  const typesIDs = [
    { id: 0, name: lang.physical, icon: countries[dataBranch.country.id].icon, code: "CR", type: "01" },
    { id: 1, name: lang.legal, icon: countries[dataBranch.country.id].icon, code: "CR", type: "02" },
    { id: 2, name: lang.dimex, icon: ot, code: "OT", type: "03" },
    { id: 3, name: lang.nite, icon: ot, code: "OT", type: "04" },
  ];

  /** Constante para manejar los permisos del sistema */
  const permissions = [
    { watch: "watchTables", edit: "editTables", watchValue: true, editValue: false, text: lang.tables },
    { watch: "watchUsers", edit: "editUsers", watchValue: false, editValue: true, text: lang.manageUsers },
    { watch: "watchCustomerModule", edit: "editCustomerModule", watchValue: true, editValue: false, text: lang.customerModule },
  ];

  /**
   * @description Efecto para manejar el tamaño de los servicios (mesa, express, llevar)
   */
  useEffect(() => {
    // Establece el tamaño de los servicios (mesas, express, llevar)
    setSize(localStorage.getItem(getStorageKey(branch, codes.spaceSize)) || constant.defaultTableSize);
  }, [branch]);

  /**
   * @description Efecto para manejar el estado de autenticación de Firebase
   */
  useEffect(() => {
    /** Se dispara cuando el objeto de autenticación cambia */
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    // Limpia al desmontar el componente
    return () => unsubscribe();
  }, []);

  /**
   * @description Efecto que carga el estado inicial de la aplicación
   */
  useEffect(() => {
    // Establece el idioma
    const itemLang = localStorage.getItem(codes.lang);
    if (itemLang && itemLang === "es-ES") {
      setLang(lang_es());
      setMessage(messages_es());
    } else if (itemLang && itemLang === "en-US") {
      setLang(lang_en());
      setMessage(messages_en());
    } else {
      setLang(lang_es());
      setMessage(messages_es());
    }

    //Detecta si el usuario accede desde un dispositivo móvil
    setIsMobile(isMobileDevice());

    // Valida la orentación de la pantalla
    getOrientation();
    window.screen.orientation.addEventListener("change", getOrientation);

    // Obtiene la información del servidor local
    const getServerInfo = async () => {
      try {
        if (!window.electronContext) return;
        const response = await window.electronContext.getServerInfo();
        localStorage.setItem("serverInfo", response);
      } catch (error) {
        console.error(error);
      }
    };
    getServerInfo();
  }, []);

  /**
   * @description Efecto que obtiene la configuración inicial del sistema
   */
  useEffect(() => {
    if (!user || Object.keys(lang).length === 0) return;
    // Método para obtener la configuración general del sistema, solo se llama al servicio una vez
    const getJSONConfig = async () => {
      if (config && !config.error) {
        // Se setean los tipos de documentos por país
        setDocumentsTypes(config);
        // Se setea la moneda del sistema
        setCurrency(getCurrency(config, dataBranch));
      } else {
        // Se llama al servicio getConfig solo una vez
        const JSONConfig = await getConfig(user);
        setConfig(JSONConfig);

        if (!JSONConfig.error) {
          // Se setean los tipos de documentos por país
          setDocumentsTypes(JSONConfig);
          // Se setea la moneda del sistema
          setCurrency(getCurrency(JSONConfig, dataBranch));
        }
      }
    };
    getJSONConfig();
  }, [user, lang, dataBranch]);

  /** Efecto para manejas los datos de settings */
  useEffect(() => {
    if (!user || !branch.branchId || settings) return;
    const getSettings = async () => {
      try {
        const result = await getDoc(doc(db, endpoints.setting(branch.administrator, branch.branchId, "others")));
        if (result.exists()) {
          setSettings(result.data());
        } else {
          const others = {
            taxesTypes: [],
            tariffCodes: [],
            exchangeRate: 0,
            restaurantService: 0,
            priceCalculation: true,
            restaurantTax: { code: "00", name: "", tariff: 0 },
          };
          await setDoc(doc(db, endpoints.setting(branch.administrator, branch.branchId, "others")), others);
          setSettings(others);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getSettings();
  }, [user, branch]);

  /**
   * @name setDocumentsTypes
   * @description Método encargado de cargar los datos del combo Tipo de plan (Comprobantes físicos, electrónicos...)
   * @param {Object} configuration
   * @version 1.0
   */
  const setDocumentsTypes = (configuration) => {
    const documentsTypes = [
      { name: lang.physicalDocuments, icon: doc2 },
      { name: lang.electronicDocuments, icon: doc1 },
    ];
    // Se itera la lista de tipos de documentos del JSONConfig por país
    const list = configuration.contries[dataBranch.country.code].docTypes.reduce((acc, element) => {
      acc.push({ id: element.id, name: documentsTypes[element.id].name, icon: documentsTypes[element.id].icon, code: element.code });
      return acc;
    }, []);
    setDocTypes(list);
  };

  /**
   * @name getOrientation
   * @description Método encargado de validar la orientación de la pantalla
   */
  const getOrientation = () => {
    // Valida que solo aplique para móviles ya que las PCs son landscape
    if (!isMobileDevice()) return setIsPortrait(true);
    // Valida la orientación
    if (window.screen.orientation.type.includes("portrait")) {
      setIsPortrait(true);
    } else {
      setIsPortrait(false);
    }
  };

  /**
   * @name signout
   * @description Cierra la sesión del usuario
   */
  const signout = () => signOut(auth).catch((e) => console.log(e));

  /**
   * @name resetPassword
   * @description Envía un correo para cambiar la contraseña
   * @param {String} email
   */
  const resetPassword = (email) => sendPasswordResetEmail(auth, email);

  /**
   * @name updateUser
   * @description Actualiza el perfil de un usuario en el token
   * @param {Object} user
   * @param {String} userName
   */
  const updateUser = (user, userName) => updateProfile(user, { displayName: userName });

  /**
   * @name signup
   * @description Registra un usuario con correo y contraseña
   * @param {String} email
   * @param {String} password
   */
  const signup = (email, password) => createUserWithEmailAndPassword(auth, email, password);

  /**
   * @name signInWithEmail
   * @description Método de autenticación con usuario y contraseña
   * @param {String} email
   * @param {String} password
   * @param {Boolen} check
   * @returns signIn
   */
  const signInWithEmail = async (email, password, check) => {
    try {
      await setPersistence(auth, check ? browserLocalPersistence : browserSessionPersistence);
    } catch (error) {
      console.log(error.code, error.message);
    }
    return signInWithEmailAndPassword(auth, email, password);
  };

  /**
   * @name signInWithOtherProvider
   * @description Método de autenticación con otros proveedores
   * @param {Number} providerCode - 1 = Google, 2 = Microsoft
   * @returns signIn
   */
  const signInWithOtherProvider = async (providerCode) => {
    let provider = null;
    if (providerCode === 1) {
      provider = new GoogleAuthProvider();
    } else if (providerCode === 2) {
      provider = new OAuthProvider("microsoft.com");
    }
    return signInWithPopup(auth, provider);
  };

  return (
    <context.Provider
      value={{
        user,
        form,
        lang,
        step,
        data,
        sent,
        size,
        error,
        branch,
        config,
        sending,
        loading,
        idsRows,
        message,
        checked,
        isInside,
        currency,
        allMenus,
        docTypes,
        activity,
        isMobile,
        backItem,
        backForm,
        typesIDs,
        settings,
        verified,
        yearText,
        entryType,
        countries,
        dataRenew,
        isPortrait,
        dataBranch,
        isDragging,
        allExpress,
        auxAllMenus,
        permissions,
        allCustomers,
        auxAllExpress,
        allCategories,
        defalutBranch,
        refDeleteTable,
        snapshotBranches,
        closingBoxCurrent,
        signup,
        setSize,
        setSent,
        signout,
        setData,
        setStep,
        setLang,
        setForm,
        setError,
        setBranch,
        setConfig,
        setChecked,
        setSending,
        setIdsRows,
        updateUser,
        setMessage,
        setAllMenus,
        setIsInside,
        setSettings,
        setCurrency,
        setYearText,
        setActivity,
        setVerified,
        setIsMobile,
        setBackForm,
        setBackItem,
        setDataRenew,
        setEntryType,
        setIsDragging,
        setDataBranch,
        setAllExpress,
        resetPassword,
        setAuxAllMenus,
        setAllCustomers,
        signInWithEmail,
        setAuxAllExpress,
        setAllCategories,
        setSnapshotBranches,
        setClosingBoxCurrent,
        sendEmailVerification,
        signInWithOtherProvider,
      }}
    >
      {children}
    </context.Provider>
  );
};
