import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { useThis } from "../../assets/context/Context";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import getConstant from "../../assets/js/Constant";
import LoadingButton from "./LoadingButton";
import { useRef, useState } from "react";
import ArrowButton from "./ArrowButton";
import InputText from "./InputText";
import Message from "./Message";
import Login from "./Login";
import Label from "./Label";
import Logo from "./Logo";

/**
 * @name ForgotPassword
 * @description Método que muestra la vista para recuperar la contraseña
 * @returns View
 * @version 1.0
 */
const ForgotPassword = () => {
  const css = styles();
  const constant = getConstant();
  const { lang, setForm, user, resetPassword } = useThis();

  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ email: "" });

  const emailFocus = useRef(null);

  /**
   * @name sendEmailToResetPassword
   * @description Envía un correo para reestablecer la contraseña
   */
  const sendEmailToResetPassword = async () => {
    setLoading(true);
    resetPassword(data.email)
      .then(() => setSnack([3, constant.success]))
      .catch((error) => {
        emailFocus.current.focus();
        switch (error.code) {
          case "auth/invalid-email":
            setSnack([6, constant.error]);
            break;
          default:
            setSnack([15, constant.error]);
            break;
        }
      })
      .finally(() => setLoading(false));
  };

  /**
   * @name changePassword
   * @description Método que valida el campo Correo Electrónico
   * @param {Event} e
   * @returns N/A
   */
  const changePassword = (e) => {
    e.preventDefault();
    if (user && !user.emailVerified) return setSnack([11, constant.error]);

    if (!data.email) {
      emailFocus.current.focus();
      return setSnack([1, constant.error]);
    }
    // Llama a la función que envía el correo para cambio de contraseña
    sendEmailToResetPassword();
  };

  /**
   * @name closeForm
   * @description Método para volver al Login
   * @returns View
   */
  const closeForm = () => setForm(<Login />);

  return (
    <div style={css.container}>
      <Logo type={4} size={200} />
      <Label text={lang.requestPassword} fontSize="18px" margin="15px 0" width="100%" fontWeight="500" />
      <form>
        <InputText
          data={data}
          name="email"
          marginTop={0}
          size={"small"}
          type={"email"}
          width={"300px"}
          setData={setData}
          value={data.email}
          label={lang.email}
          reference={emailFocus}
          icon={<AlternateEmailIcon />}
        />
        <LoadingButton
          marginTop={2}
          width={"300px"}
          type={"submit"}
          loading={loading}
          icon={<VpnKeyIcon />}
          text={lang.changePassword}
          handleClick={changePassword}
        />
      </form>
      <div style={css.containerIcon}>
        <ArrowButton index={0} handleClick={closeForm} />
      </div>
      <Message snack={snack}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = () => {
  return {
    containerIcon: { width: "100%", display: "flex", justifyContent: "start" },
    container: { display: "flex", flexDirection: "column", alignItems: "center" },
  };
};

export default ForgotPassword;
