import { useThis } from "../../../../assets/context/Context";
import { formatDate } from "../../../../assets/js/Commons";
import defalut from "../../../../images/default.svg";
import { Timestamp } from "firebase/firestore";
import { secureMessage } from "./CommandItem2";
import InputCheck from "../../InputCheck";
import Label from "../../Label";

/**
 * @name WithdrawOrders
 * @description Método que retorna el contenido del modal para retirar pedidos
 * @param {Object} { withdrawObject }
 * @returns View
 * @version 1.0
 */
const WithdrawOrders = ({ withdrawObject, data, setData }) => {
  const css = styles();
  const { lang } = useThis();
  return (
    <div style={css.container}>
      <Label text={withdrawObject.title} fontWeight={500} margin="0 0 10px 0" />
      <div style={css.line} />
      {withdrawObject.orders.map((order) => {
        if (!order.requestKitchen || !order.inKitchen || order.statusKitchen) return null;
        const date = Timestamp.fromDate(new Date(order.date.seconds * 1000 + order.date.nanoseconds / 1e6), lang);
        return (
          <div key={order.id} style={css.row}>
            <div style={css.containerImg}>
              <div style={css.checks}>
                <InputCheck name={order.id} data={data} setData={setData} value={data[order.id]} />
              </div>
              <img alt="img" width={50} height={50} src={order.url || defalut} style={css.img} />
            </div>
            <div>
              <p style={css.description} dangerouslySetInnerHTML={{ __html: secureMessage(order.name) + (order.detail || lang.noDescription) }} />
              <Label textAlign="end" fontSize={"12px"} text={formatDate(date, lang)} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    container: { minWidth: "280px" },
    description: { fontSize: "14px" },
    img: { borderRadius: "5px", marginRight: "10px" },
    line: { borderTop: "1px solid #CCC", marginBottom: "7px" },
    containerImg: { width: "auto", height: "auto", position: "relative" },
    row: { display: "flex", marginBottom: "8px", borderBottom: "1px solid #CCC", paddingBottom: "4px" },
    checks: { display: "flex", justifyContent: "center", position: "absolute", backgroundColor: "#FFFFFF", top: "1px", left: "1px", borderRadius: "3px" },
  };
};

export default WithdrawOrders;
