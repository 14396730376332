import { initializeFirestore, CACHE_SIZE_UNLIMITED, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  appId: process.env.REACT_APP_FIREBASE_APPID,
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const storage = getStorage(app);

const db = initializeFirestore(app, {
  synchronizeTabs: true,
  useFetchStreams: false, // Esta opción es para evitar el uso de streams HTTP/2
  experimentalForceLongPolling: true,
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
});

// Solo para pruebas locales
if (window.location.hostname === "localhost") {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectStorageEmulator(storage, "localhost", 9199);
}

export { auth, db, storage };
