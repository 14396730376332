import { collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import InitMessage from "../../views/components/InitMessage";
import BranchCard from "../../views/components/BranchCard";
import { db, storage } from "../context/firebase-config";
import getConstant from "./Constant";
import endpoints from "./Endpoints";

/** Inicialización de variables */
const constant = getConstant();

/**
 * @name userRegistration
 * @description Método encargado de ejecutar las funciones de Firebase Auth para registro y edición de userName
 * @param {*} userName - Recibe el nombre de usuario
 * @param {*} email - Recibe el correo electrónico
 * @param {*} password - Recibe la contraseña
 * @param {*} signup  - Recibe el método que llama la función de Firebase createUserWithEmailAndPassword
 * @param {*} updateUser - Recibe el método que llama la función de Firebase updateProfile
 * @returns Object
 */
const userRegistration = async ({ userName, email, password, signup, updateUser }) => {
  const result = { error: false };
  try {
    const response = await signup(email, password);
    await updateUser(response.user, userName);
  } catch (error) {
    switch (error.code) {
      case "auth/invalid-email":
        result.error = true;
        result.code = error.code;
        result.message = 6;
        break;
      case "auth/weak-password":
        result.error = true;
        result.code = error.code;
        result.message = 7;
        break;
      case "auth/email-already-in-use":
        result.error = true;
        result.code = error.code;
        result.message = 8;
        break;
      default:
        result.error = true;
        result.code = error.code;
        result.message = 9;
        break;
    }
  }
  return result;
};

/**
 * @name addClaims
 * @description Método encargado de agregar reclamos a un usuario
 * @param {Object} user - Usuario de Firebase (token)
 * @param {String} collaborator - Id del colaborador
 * @returns {Boolean}
 * @version 1.0
 */
const addClaims = async (user, parameters) => {
  const options = {
    method: "POST",
    body: JSON.stringify(parameters),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(constant.functionsBasePath + "/addClaims", options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 50 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 50 };
  }
};

/**
 * @name getClaims
 * @description Método que obtiene los reclamos de un usuario
 * @param {Object} user
 */
const getClaims = async (user) => {
  try {
    // Accede a los reclamos del usuario
    const response = await user.getIdTokenResult(true);
    return { error: false, claims: response.claims };
  } catch (error) {
    return { error: true, errorCode: 51 };
  }
};

/**
 * @name createBranch
 * @description Método encargado de llamar al endpoint para crear una nueva sucursal
 * @param {Object} user - Usuario de Firebase (token)
 * @param {Object} dataBranch - Datos de la nueva sucursal
 * @returns {Object}
 * @version 1.0
 */
const createBranch = async (user, dataBranch) => {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: `Bearer ${user.accessToken}` },
    body: JSON.stringify(dataBranch),
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(constant.functionsBasePath + "/createBranch", options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 23 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 23 };
  }
};

/**
 * @name getBranches
 * @description Método encargado de consultar las sucursales propias o de otros administradores
 * @param {useState} setView
 * @param {useState} setYearText
 * @param {Object} user
 * @param {Boolean} isMobile
 * @param {useState} setSnapshotBranches
 * @param {Number} entryType
 * @version 1.0
 */
const getBranches = (setView, setYearText, user, isMobile, setSnapshotBranches, entryType) => {
  if (entryType === 0) {
    getOwnBranches(setView, setYearText, user.uid, isMobile, setSnapshotBranches);
  } else {
    getOthersBranches(setView, setYearText, user, isMobile, setSnapshotBranches);
  }
};

/**
 * @name getOwnBranches
 * @description Método encargado de consultar las sucursales propias
 * @param {useState} setView
 * @param {useState} setYearText
 * @param {String} userID
 * @param {Boolean} isMobile
 * @param {useState} setSnapshotBranches
 * @version 1.0
 */
const getOwnBranches = (setView, setYearText, userID, isMobile, setSnapshotBranches) => {
  try {
    const collectionBranches = collection(db, endpoints.branches(userID));
    const queryBranches = query(collectionBranches, orderBy("creationDate", "asc"));
    // Escucha los cambios en los datos de las sucursales
    onSnapshot(queryBranches, (snapshot) => {
      // Almacena las sucursales en una variable de Context.js
      setSnapshotBranches(snapshot.docs);
      // Almacena los componentes de cada sucursal
      const branches = snapshot.docs.reduce((acc, doc) => {
        acc.push(getComponentCardBranch(doc.id, doc.data(), userID));
        return acc;
      }, []);
      // Muestra las sucursales en el lobby, en el caso de no haber muestra el mensaje animado.
      showBranchesOrInitialMessage(branches, setYearText, setView, isMobile, 0);
    });
  } catch (error) {
    console.log(error);
  }
};

/**
 * @name getOthersBranches
 * @description Método encargado de consultar las sucursales de otros administradores
 * @param {useState} setView
 * @param {useState} setYearText
 * @param {Object} user
 * @param {Boolean} isMobile
 * @param {useState} setSnapshotBranches
 * @version 1.0
 */
const getOthersBranches = async (setView, setYearText, user, isMobile, setSnapshotBranches) => {
  // Se consultan los reclamos de un usuario
  const response = await getClaims(user);
  if (response.error) {
    setYearText("block");
    return setView(<InitMessage index={2} />);
  }
  // Se valida que el usuario tenga permisos agregados
  if ("claims" in response && "customPermissions" in response.claims) {
    let branches = [];
    let error = false;
    // Se itera la lista de administradores
    for (const key1 in response.claims.customPermissions) {
      // Se itera la lista de sucursales
      for (const key2 in response.claims.customPermissions[key1]) {
        try {
          const result = await getDoc(doc(db, endpoints.branch(key1, key2)));
          if (result.exists()) {
            // Almacena las sucursales en una variable de Context.js
            setSnapshotBranches((prev) => [...prev, result]);
            branches.push(getComponentCardBranch(result.id, result.data(), key1));
          } else {
            error = true;
          }
        } catch (e) {
          error = true;
          console.log(e);
        }
      }
    }
    // Muestra las sucursales en el lobby, en el caso de no haber muestra el mensaje animado.
    showBranchesOrInitialMessage(branches, setYearText, setView, isMobile, error ? 3 : 1);
  } else {
    // Si no tiene permisos, se muestra el mensaje animado
    setYearText("block");
    setView(<InitMessage index={1} />);
  }
};

/**
 * @name showBranchesOrInitialMessage
 * @description Método encargado de mostrar las sucursales en el lobby, en el caso de no haber muestra el mensaje animado.
 * @param {Array} branches
 * @param {useState} setYearText
 * @param {useState} setView
 * @param {Boolean} isMobile
 * @param {Number} index
 * @version 1.0
 */
const showBranchesOrInitialMessage = (branches, setYearText, setView, isMobile, index) => {
  if (branches.length === 0) {
    setYearText("block");
    setView(<InitMessage index={index} />);
  } else {
    setYearText("none");
    setView(<ContainerCardBranch list={branches} isMobile={isMobile} />);
  }
};

/**
 * @name getComponentCardBranch
 * @description Método encargado de obtener el card de la sucursal con los datos cargados
 * @param {String} key
 * @param {Object} data
 * @param {String} administrator
 * @returns View
 * @version 1.0
 */
const getComponentCardBranch = (key, data, administrator) => {
  return (
    <BranchCard
      id={key}
      key={key}
      name={data.name}
      img={data.logo}
      ide={data.identification}
      address={data.otherAddress}
      administrator={administrator}
      initialDate={data.creationDate}
      electronicBill={data.electronicBill}
      activityCode={data.activityCode.code}
      expirationDate={data.plan.expirationDate}
      consecutive={data.consecutive.toString().padStart(3, "0")}
    />
  );
};

/**
 * @name ContainerCardBranch
 * @description Método encargado de obtener el contenedor con cada una de las sucursales
 * @param {Object} { list, isMobile }
 * @returns View
 */
const ContainerCardBranch = ({ list, isMobile }) => {
  const css = styles(list.length, isMobile);
  return <div style={css.container}>{list}</div>;
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Number} items
 * @param {Boolean} isMobile
 * @returns {Object}
 * @version 1.0
 */
const styles = (items, isMobile) => {
  return {
    container: {
      width: "100%",
      marginTop: "60px",
      overflowY: "auto",
      position: "relative",
      justifyContent: "center",
      height: "calc(100vh - 60px)",
      gridTemplateColumns: "repeat(auto-fill, 370px)",
      display: items > 2 ? "grid" : isMobile ? "grid" : "flex",
    },
  };
};

/**
 * @name getAllSubscriptions
 * @description Método encargado de obtener todas las suscripciones de una sucursal en específico
 * @param {String} userID
 * @param {String} branchID
 * @returns Object con el response
 */
const getAllSubscriptions = async (userID, branchID) => {
  try {
    const subscriptions = await getDocs(query(collection(db, endpoints.subscriptions(userID, branchID)), orderBy("subscriptionDate", "asc")));
    return { error: false, subscriptions };
  } catch (error) {
    return { error: true, errorCode: 35 };
  }
};

/**
 * @name getConfig
 * @description Método encargado de llamar al endpoint para obtener la configuración
 * @param {Object} user - Usuario de Firebase (token)
 * @returns {Object}
 * @version 1.0
 */
const getConfig = async (user) => {
  const options = {
    method: "GET",
    headers: { Authorization: `Bearer ${user.accessToken}` },
  };
  try {
    // Se realiza la petición de configuraciones al servicio
    let response = await fetch(constant.functionsBasePath + "/getConfig", options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 26 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 26 };
  }
};

/**
 * @name setSubscription
 * @description Método encargado de llamar al endpoint para crear una suscripción
 * @param {Object} user - Usuario de Firebase (token)
 * @param {Object} data - Datos de la suscripción
 * @param {String} administrator - Id de administrador
 * @returns Object
 * @version 1.0
 */
const setSubscription = async (user, data, administrator) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify({ ...data }),
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(constant.functionsBasePath + "/insertSubscription/" + administrator, options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 37 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 37 };
  }
};

/**
 * @name uploadAnyFile
 * @description Método que almacena un archivo en el Storage de Firebase
 * @param {String} url
 * @param {File} file
 * @returns {Object}
 * @version 1.0
 */
const uploadAnyFile = async (url, file) => {
  try {
    const storageRef = ref(storage, url);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return { error: false, url: downloadURL, storageRef };
  } catch (error) {
    return { error: true, errorCode: 42 };
  }
};

/**
 * @name validateSubscription
 * @description Método encargado de llamar al endpoint para ver si tiene sucursales válidas
 * @param {Object} user - Usuario de Firebase (token)
 * @param {String} administrator - Id del administrador
 * @param {String} branchID - Id de la sucursal
 * @returns Object
 * @version 1.0
 */
const validateSubscription = async (user, administrator, branchID) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(constant.functionsBasePath + "/hasValidSubscription/" + administrator + "/" + branchID, options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 48 };
    response = await response.json();
    return response;
  } catch (error) {
    console.log(error);
    return { error: true, errorCode: 48 };
  }
};

/**
 * @name createUser
 * @description Método encargado de crear un usuario, agregando los permisos a su respectivo token (claims)
 * @param {Object} user - Usuario de Firebase admin (token)
 * @param {Object} parameters - Datos del nuevo usuario (colaborador)
 * @param {String} administrator - Id administrador
 * @param {useRef} nameFocus - referencia del nombre
 * @param {useRef} emailFocus - referencia del correo
 * @param {Object} rows - Objeto con la lista de usuarios
 * @returns Object
 * @version 1.0
 */
const callCreateUser = async (user, parameters, administrator, nameFocus, emailFocus, rows) => {
  if (!parameters.name) {
    nameFocus.current.focus();
    return { error: true, errorCode: 18 };
  }
  if (!parameters.email) {
    emailFocus.current.focus();
    return { error: true, errorCode: 1 };
  }
  if (rows.find((user) => user.name === parameters.name)) {
    nameFocus.current.focus();
    return { error: true, errorCode: 56 };
  }
  if (rows.find((user) => user.email === parameters.email)) {
    emailFocus.current.focus();
    return { error: true, errorCode: 57 };
  }

  const options = {
    method: "POST",
    body: JSON.stringify(parameters),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(constant.functionsBasePath + "/createUser/" + administrator, options);
    // Se verifica una respuesta válida
    console.log(response);
    if (!response.ok) return { error: true, errorCode: 52 };
    console.log(response);
    response = await response.json();
    return response;
  } catch (error) {
    console.log(error);
    return { error: true, errorCode: 52 };
  }
};

/**
 * @name callEditUser
 * @description Método encargado de editar un usuario, agregando los permisos a su respectivo token (claims)
 * @param {Object} user - Usuario de Firebase admin (token)
 * @param {Object} parameters - Datos del nuevo usuario (colaborador)
 * @param {String} administrator - Id administrador
 * @param {useRef} nameFocus - referencia del nombre
 * @param {useRef} emailFocus - referencia del correo
 * @param {Object} rows - Objeto con la lista de usuarios
 * @param {String} id -Id del usuario
 * @returns Object
 * @version 1.0
 */
const callEditUser = async (user, parameters, administrator, nameFocus, emailFocus, rows, id) => {
  if (!parameters.name) {
    nameFocus.current.focus();
    return { error: true, errorCode: 18 };
  }
  if (!parameters.email) {
    emailFocus.current.focus();
    return { error: true, errorCode: 1 };
  }
  if (rows.find((item) => item.name === parameters.name && item.id !== id)) {
    nameFocus.current.focus();
    return { error: true, errorCode: 56 };
  }
  if (rows.find((item) => item.email === parameters.email && item.id !== id)) {
    emailFocus.current.focus();
    return { error: true, errorCode: 57 };
  }

  const options = {
    method: "POST",
    body: JSON.stringify({ ...parameters, id }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(constant.functionsBasePath + "/editUser/" + administrator, options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 58 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 58 };
  }
};

/**
 * @name getAllUsers
 * @description Método encargado de obtener todos los usuarios de una sucursal
 * @param {String} userID
 * @param {String} branchID
 * @returns Object con el response
 */
const getAllUsers = async (userID, branchID) => {
  try {
    const users = await getDocs(query(collection(db, endpoints.users(userID, branchID)), orderBy("name", "asc")));
    return { error: false, users };
  } catch (error) {
    return { error: true, errorCode: 55 };
  }
};

/**
 * @name callDeleteClaims
 * @description Método utilizado para eliminar los reclamos de un usuario de una determinada sucursal
 * @param {Object} user - Usuario de Firebase admin (token)
 * @param {Object} parameters - Datos del usuario (colaborador)
 * @param {String} administrator - Id administrador
 * @returns {Object}
 * @version 1.0
 */
const callDeleteClaims = async (user, parameters, administrator) => {
  const options = {
    method: "POST",
    body: JSON.stringify(parameters),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  };
  try {
    // Se realiza la petición de eliminar reclamos
    let response = await fetch(constant.functionsBasePath + "/deleteClaims/" + administrator, options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 58 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 58 };
  }
};

/**
 * @name callEditBranch
 * @description Método encargado de llamar al endpoint para editar una sucursal
 * @param {Object} user - Usuario de Firebase (token)
 * @param {Object} dataBranch - Datos de la sucursal
 * @param {String} administrator - Id del administrador
 * @param {String} branchID - Id de la sucursal
 * @returns {Object}
 * @version 1.0
 */
const callEditBranch = async (user, dataBranch, administrator, branchID) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify({ ...dataBranch }),
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(constant.functionsBasePath + "/editBranch/" + administrator + "/" + branchID, options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 65 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 65 };
  }
};

/**
 * @name callEditBranchLogo
 * @description Método encargado de llamar al endpoint para editar los datos del logo de una sucursal
 * @param {Object} user - Usuario de Firebase (token)
 * @param {Object} dataLogo - Datos del logo
 * @param {String} administrator - Id del administrador
 * @param {String} branchID - Id de la sucursal
 * @returns {Object}
 * @version 1.0
 */
const callEditBranchLogo = async (user, dataLogo, administrator, branchID) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(dataLogo),
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(constant.functionsBasePath + "/editBranchLogo/" + administrator + "/" + branchID, options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 65 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 65 };
  }
};

/**
 * @name deleteAnyFile
 * @description Método para eliminar un archivo del Storage
 * @param {String} path Ruta del archivo en Storage
 * @version 1.0
 */
const deleteAnyFile = async (path) => {
  try {
    const fileRef = ref(storage, path);
    await deleteObject(fileRef);
    return { error: false };
  } catch (error) {
    return { error: true, errorCode: 112 };
  }
};

/**
 * @name createService
 * @description Método encargado de llamar al endpoint para crear un nuevo servicio
 * @param {Object} user - Usuario de Firebase (token)
 * @param {Object} serviceData - Datos del nuevo servicio
 * @returns {Object}
 * @version 1.0
 */
const createService = async (user, serviceData, administrator) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(serviceData),
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(constant.functionsBasePath + "/createService/" + administrator, options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 70 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 70 };
  }
};

/**
 * @name getServices
 * @description Método utilizado para escuchar y obtener los cambios en los datos de los servicios (mesas, express y llevar)
 * @param {useState} setServices
 * @param {String} userId
 * @param {String} branchId
 * @param {useState} setProgress
 * @version 1.0
 */
const getServices = (setServices, userId, branchId, setProgress) => {
  try {
    const collectionBranches = collection(db, endpoints.services(userId, branchId));
    const queryBranches = query(collectionBranches, orderBy("number", "asc"));
    // Escucha los cambios en los datos de los servicios
    onSnapshot(queryBranches, (snapshot) => {
      // Almacena cada uno de los servicios
      const services = snapshot.docs.reduce(
        (acc, doc) => {
          acc[doc.data().type].push({ ...doc.data(), id: doc.id });
          return acc;
        },
        { 0: [], 1: [], 2: [] }
      );
      setServices(services);
      setProgress(false);
    });
  } catch (error) {
    console.log(error);
  }
};

/**
 * @name deleteCollections
 * @description Método que elimina los documentos de una colección de Firestore
 * @param {String} endpoint
 * @param {String} administrator
 * @param {String} branchId
 * @version 1.0
 */
const deleteCollections = async (endpoint, administrator, branchId) => {
  try {
    const array = endpoint.split("/");
    const coleccions = await getDocs(collection(db, endpoint));
    console.log("Eliminando la colección:", array[array.length - 1]);
    for (const doc of coleccions.docs) {
      await deleteDoc(doc.ref);
      // En el caso de ser suscripciones se eliminan también los documentos (imágenes)
      if (array[array.length - 1] === constant.subscriptions && doc.data().document) {
        await deleteAnyFile(endpoints.storeSubDoc(administrator, branchId, doc.data().paymentMethod, doc.data().documentName));
      }
      console.log("Documento eliminado correctamente:", doc.id);
    }
    return { error: false };
  } catch (error) {
    return { error: true };
  }
};

/**
 * @name callDeleteBranch
 * @description Método que elimina los documentos de la sucursal, así como sus colecciones
 * @param {String} administrator
 * @param {String} branchId
 * @returns {Object}
 * @version 1.0
 */
const callDeleteBranch = async (user, administrator, branchId) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  };
  try {
    // Se realiza la petición
    let response = await fetch(constant.functionsBasePath + "/deleteBranch/" + administrator + "/" + branchId, options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 32 };
    response = await response.json();
    return response;
  } catch (error) {
    console.log(error);
    return { error: true, errorCode: 32 };
  }
};

/**
 * @name getAllProdAndServ
 * @description Método encargado de obtener todos los productos y/o servicios
 * @param {String} userID
 * @param {String} branchID
 * @returns Object con el response
 */
const getAllProdAndServ = async (userID, branchID) => {
  try {
    const prodAndServ = await getDocs(query(collection(db, endpoints.products(userID, branchID)), orderBy("name", "asc")));
    return { error: false, prodAndServ };
  } catch (error) {
    return { error: true };
  }
};

/**
 * @name getAllCategories
 * @description Método encargado de obtener todas las categorías
 * @param {String} userID
 * @param {String} branchID
 * @returns Object con el response
 */
const getAllCategories = async (userID, branchID) => {
  try {
    const categories = await getDocs(query(collection(db, endpoints.categories(userID, branchID)), orderBy("category", "asc")));
    return { error: false, categories };
  } catch (error) {
    return { error: true };
  }
};

/**
 * @name updateOrders
 * @description Método encargado de llamar al endpoint para actualizar un nuevo pedido al servicio
 * @param {Object} user - Usuario de Firebase (token)
 * @param {Object} parameters - Pedidos + serviceId
 * @param {String} administrator
 * @param {String} branchId
 * @returns {Object}
 * @version 1.0
 */
const updateOrders = async (user, parameters, administrator, branchId) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(parameters),
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(constant.functionsBasePath + "/updateOrders/" + administrator + "/" + branchId, options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 114 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 114 };
  }
};

/**
 * @name deleteOrders
 * @description Método encargado de llamar al endpoint para eliminar varios pedidos al servicio
 * @param {Object} user - Usuario de Firebase (token)
 * @param {Object} parameters - Pedidos + serviceId
 * @param {String} administrator
 * @param {String} branchId
 * @returns {Object}
 * @version 1.0
 */
const deleteOrders = async (user, parameters, administrator, branchId) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(parameters),
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(constant.functionsBasePath + "/deleteOrders/" + administrator + "/" + branchId, options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 118 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 118 };
  }
};

/**
 * @name moveAnyOrders
 * @description Método encargado de llamar al endpoint para trasladar varios pedidos al servicio
 * @param {Object} user - Usuario de Firebase (token)
 * @param {Object} parameters
 * @param {String} administrator
 * @param {String} branchId
 * @returns {Object}
 * @version 1.0
 */
const moveAnyOrders = async (user, parameters, administrator, branchId) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(parameters),
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(constant.functionsBasePath + "/moveOrders/" + administrator + "/" + branchId, options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 121 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 121 };
  }
};

/**
 * @name deleteService
 * @description Método encargado de llamar al endpoint para eliminar un servicio (Mesa, express, llevar)
 * @param {Object} user - Usuario de Firebase (token)
 * @param {String} administrator
 * @param {String} branchId
 * @param {String} serviceId
 * @returns {Object}
 * @version 1.0
 */
const deleteService = async (user, administrator, branchId, serviceId) => {
  const options = {
    method: "DELETE",
    headers: { Authorization: `Bearer ${user.accessToken}` },
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(constant.functionsBasePath + "/deleteService/" + administrator + "/" + branchId + "/" + serviceId, options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 135 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 135 };
  }
};

/**
 * @name getClosingBox
 * @description Método utilizado para obtener los cierres de caja de un usuario
 * @param {useState} setClosingBox
 * @param {String} admninistrator
 * @param {String} userId
 * @param {String} branchId
 * @param {useState} setLoadingBar
 * @param {useState} setClosingBoxCurrent
 * @version 1.0
 */
const getClosingBox = (setClosingBox, administrator, userId, branchId, setLoadingBar) => {
  try {
    const collectionBranches = collection(db, endpoints.closingBoxes(administrator, branchId));
    const queryBranches = query(collectionBranches, where("user", "==", userId), orderBy("openingDate", "asc"));
    // Escucha los cambios en los datos de los cierres de caja
    onSnapshot(queryBranches, (snapshot) => {
      // Almacena cada uno de los cierres de caja
      const result = snapshot.docs.reduce((acc, doc) => {
        acc.push({ ...doc.data(), id: doc.id });
        return acc;
      }, []);
      setClosingBox(result);
      setLoadingBar(false);
    });
  } catch (error) {
    console.log(error);
  }
};

export {
  addClaims, // Método encargado de agregar reclamos a un usuario
  getClaims, // Método que obtiene los reclamos de un usuario
  getConfig, // Método encargado de llamar al endpoint para obtener la configuración
  getServices, // Método utilizado para escuchar y obtener los cambios en los datos de los servicios (mesas, express y llevar)
  getBranches, // Método encargado de consultar las sucursales
  getAllUsers, // Método encargado de obtener todos los usuarios de una sucursal
  deleteOrders, // Método encargado de llamar al endpoint para eliminar varios pedidos al servicio
  updateOrders, // Método encargado de llamar al endpoint para actualizar un nuevo pedido al servicio
  callEditUser, // Método encargado de editar un usuario, agregando los permisos a su respectivo token (claims)
  createBranch, // Método encargado de llamar al endpoint para crear una nueva sucursal
  moveAnyOrders, // Método encargado de llamar al endpoint para trasladar varios pedidos al servicio
  getClosingBox, // Método utilizado para obtener los cierres de caja de un usuario
  createService, // Método encargado de llamar al endpoint para crear un nuevo servicio
  deleteAnyFile, // Método para eliminar un archivo del Storage
  deleteService, // Método encargado de llamar al endpoint para eliminar un servicio (Mesa, express, llevar)
  uploadAnyFile, // Método que almacena un archivo en el Storage de Firebase
  callEditBranch, // Método encargado de llamar al endpoint para editar una sucursal
  callCreateUser, // Método encargado de crear un usuario, agregando los permisos a su respectivo token (claims)
  setSubscription, // Método encargado de llamar al endpoint para crear una suscripción
  getAllCategories, // Método encargado de obtener todas las categorías
  callDeleteClaims, // Método utilizado para eliminar los reclamos de un usuario de una determinada sucursal
  callDeleteBranch, // Método que elimina los documentos de la sucursal, así como sus colecciones
  userRegistration, // Método encargado de ejecutar las funciones de Firebase Auth para registro y edición de userName
  getAllProdAndServ, // Método encargado de obtener todos los productos y/o servicios
  deleteCollections, // Método que eliminar los documentos de una colección de Firestore
  callEditBranchLogo, // Método encargado de llamar al endpoint para editar los datos del logo de una sucursal
  getAllSubscriptions, // Método encargado de obtener todas las suscripciones de una sucursal en específico
  validateSubscription, // Método encargado de llamar al endpoint para ver si tiene sucursales válidas
};
