import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import BatteryCharging20Icon from "@mui/icons-material/BatteryCharging20";
import BatteryCharging60Icon from "@mui/icons-material/BatteryCharging60";
import { formatNumber, termText } from "../../assets/js/Commons";
import { BottomNavigation, styled } from "@mui/material";
import { useThis } from "../../assets/context/Context";
import { useEffect, useState } from "react";
import ArrowButton from "./ArrowButton";
import Label from "./Label";

/**
 * @name RenewStep2
 * @description Método que devuelve un componente de tipo RenewStep2
 * @returns View
 * @version 1.0
 */
const RenewStep2 = () => {
  const css = styles();
  const { setStep, lang, dataRenew, setDataRenew, docTypes, config, dataBranch } = useThis();
  const plans = config.contries[dataBranch.country.code].plan[dataRenew.docTypes.code];

  const [navigationActions, setNavigationActions] = useState([]);
  const [BottomNavigationAction] = useState(getStyleNavigationAction());
  const [currency] = useState(config.contries[dataBranch.country.code].currencies[0].symbol);

  /**
   * @name next
   * @description Método que avanza un paso del Stepper
   * @returns N/A
   */
  const next = () => {
    setStep(2);
  };

  /**
   * @name back
   * @description Método que retrocede en el Stepper
   * @returns N/A
   */
  const back = () => {
    setStep(0);
  };

  /**
   * @name handlerChange
   * @description Método encargado de manejar el evento onChange del menú
   * @param {Event} _
   * @param {Number} index
   * @returns N/A
   */
  const handlerChange = (_, index) => setDataRenew((data) => ({ ...data, term: index }));

  /** Efecto para cargar los planes (precios y plazos) */
  useEffect(() => {
    const icons = [<BatteryCharging20Icon />, <BatteryCharging60Icon />, <BatteryChargingFullIcon />];
    // Se crean los taps del componente de navegación con los datos del JSONConfig
    const components = plans.reduce((acc, element, index) => {
      acc.push(
        <BottomNavigationAction sx={css.tab} icon={icons[index]} label={termText(lang, element.days)} key={dataRenew.docTypes.code + element.name} />
      );
      return acc;
    }, []);
    setNavigationActions(components);
  }, []);

  return (
    <div style={css.container}>
      <div style={css.fragment}>
        <div style={css.planText}>
          <img src={docTypes[dataRenew.docTypes.id].icon} alt="img" width={24} height={24} />
          <Label text={docTypes[dataRenew.docTypes.id].name} fontWeight={"500"} fontSize={"18px"} margin={"5px"} />
          <Label text={lang.typePlanSelected} fontSize={"14px"} margin={"10px"} />
        </div>
        <Label text={lang.selectTerm} fontSize={"15px"} margin={"5px 0"} />
        <div style={css.flex}>
          <BottomNavigation showLabels sx={css.menu} value={dataRenew.term} onChange={handlerChange}>
            {navigationActions}
          </BottomNavigation>
          <div style={css.panelRight}>
            <div>
              <Label text={lang.termPeriod} fontWeight={"600"} fontSize={"16px"} margin={"10px 0 5px 0"} />
              <Label text={lang.messageTerm.replace("[DAYS]", plans[dataRenew.term].days)} fontSize={"16px"} />
            </div>
            <div style={css.price}>
              <Label text={lang.priceIVI} fontWeight={"600"} fontSize={"16px"} margin={"0 10px 0 0"} textAlign={"right"} />
              <Label text={currency + formatNumber(plans[dataRenew.term].amount)} fontSize={"16px"} />
            </div>
          </div>
        </div>
      </div>
      <div style={css.footer}>
        <ArrowButton index={0} handleClick={back} />
        <ArrowButton index={1} handleClick={next} />
      </div>
    </div>
  );
};

/**
 * @name getStyleNavigationAction
 * @description Método encargado de devolver los estilos de los tabs
 * @returns {MuiBottomNavigationAction}
 */
const getStyleNavigationAction = () =>
  styled(MuiBottomNavigationAction)(() => ({
    color: "#444",
    "&.Mui-selected": {
      color: "#499566",
      background: "#F0F0F0",
    },
  }));

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = () => {
  return {
    flex: { display: "flex" },
    menu: { flexDirection: "column", width: "70px", height: "180px" },
    fragment: { display: "flex", flexDirection: "column", height: "275px" },
    price: { display: "flex", alignItems: "center", justifyContent: "right" },
    footer: { display: "flex", justifyContent: "space-between", marginTop: "10px" },
    tab: { padding: "0", minWidth: "70px", maxWidth: "70px", borderRadius: "5px 0 0 5px" },
    planText: { display: "flex", marginTop: "20px", marginBottom: "5px", alignItems: "center" },
    container: { width: "100%", position: "relative", display: "flex", flexDirection: "column", justifyContent: "space-between" },
    panelRight: {
      flex: "1",
      display: "flex",
      width: "330px",
      padding: "10px 20px",
      background: "#F0F0F0",
      flexDirection: "column",
      borderRadius: "0 5px 5px 0",
      justifyContent: "space-between",
    },
  };
};

export default RenewStep2;
