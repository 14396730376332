import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, serverTimestamp, updateDoc } from "firebase/firestore";
import { formatDate, formatNumber, getFinalPrice } from "../../../assets/js/Commons";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import { db } from "../../../assets/context/firebase-config";
import { Edit, DeleteForever } from "@mui/icons-material";
import { useThis } from "../../../assets/context/Context";
import GenericGridType2 from "../grids/GenericGridType2";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import getConstant from "../../../assets/js/Constant";
import endpoints from "../../../assets/js/Endpoints";
import imgExpress from "../../../images/express.svg";
import { useEffect, useRef, useState } from "react";
import HeaderProducts from "../HeaderProducts";
import DeleteAnything from "../DeleteAnything";
import Modal from "../../containers/Modal";
import LoadingBar from "../LoadingBar";
import AddExpress from "../AddExpress";
import Message from "../Message";

/**
 * @name ProductsExpress
 * @description Método que retorna la vista del módulo Express
 * @returns View
 * @version 1.0
 */
const ProductsExpress = () => {
  const css = styles();
  const constant = getConstant();
  const { lang, settings, branch, currency, setAllExpress, setAuxAllExpress } = useThis();

  const nameFocus = useRef(null);
  const priceFocus = useRef(null);

  const [row, setRow] = useState({});
  const [data, setData] = useState({});
  const [rows, setRows] = useState([]);
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [edit, setEdit] = useState(false);
  const [auxRows, setAuxRows] = useState([]);
  const [idToEdit, setIdToEdit] = useState("");
  const [loading, setLoading] = useState(false); // Para el modal
  const [snackLocal, setSnackLocal] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [loadingBar, setLoadingBar] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [openModalToDelete, setOpenModalToDelete] = useState(false);
  const [openAddOrEditExpress, setOpenAddOrEditExpress] = useState(false);
  const [taxes, setTaxes] = useState([{ id: 0, name: lang.selectTax, icon: <TouchAppIcon sx={css.selectTax} />, code: "00", tariff: 0 }]);
  const [taxesTypes, setTaxesTypes] = useState([{ id: 0, name: lang.selectTaxType, icon: <TouchAppIcon sx={css.selectTax} />, code: "00" }]);

  /**
   * @name filterExpress
   * @description Método que filtra los express de la tabla, por nombre
   * @param {Event} e
   * @version 1.0
   */
  const filterExpress = (e) => {
    const express = auxRows.reduce((acc, item) => {
      if (item.express.toLowerCase().includes(e.target.value.toLowerCase())) acc.push(item);
      return acc;
    }, []);
    setRows(express);
  };

  /**
   * @name showCreateExpress
   * @description Método encargado de mostrar el modal para crear un Express
   * @version 1.0
   */
  const showCreateExpress = () => {
    setEdit(false);
    /** Inicializa los datos */
    setData(resetData());
    /** Muestra el modal para crear o ediar express */
    setOpenAddOrEditExpress(true);
  };

  /**
   * @name addOrEditExpress
   * @description Método encargado de mostrar el modal para agregar o editar express
   * @returns N/A
   * @version 1.0
   */
  const addOrEditExpress = async () => {
    if (loading) return;
    // Se validan que los campos "Lugar" y "Precio" no vayan vacíos
    if (!data.name) {
      nameFocus.current.focus();
      return setSnack([93, constant.error]);
    }
    if (!data.price) {
      priceFocus.current.focus();
      return setSnack([94, constant.error]);
    }
    setLoading(true);
    try {
      const request = {
        code: "",
        prodAndserv: [],
        name: data.name,
        price: data.price,
        taxCode: data.tax.code,
        taxName: data.tax.name,
        date: serverTimestamp(),
        tariff: data.tax.tariff,
        taxType: data.taxType.code,
        taxTypeName: data.taxType.name,
        category: { id: lang.express },
        categoryName: { id: lang.express },
        cabys: data.cabys || constant.defaultCabys.code,
      };
      if (edit) {
        // Se actualiza el express
        await updateDoc(doc(db, endpoints.oneExpress(branch.administrator, branch.branchId, idToEdit)), request);
      } else {
        // Se agrega el documento del express
        await addDoc(collection(db, endpoints.express(branch.administrator, branch.branchId)), request);
      }
      setSnackLocal([edit ? 85 : 81, constant.success]);
      setOpenAddOrEditExpress(false);
    } catch (error) {
      setSnack([edit ? 86 : 82, constant.error]);
    }
    setLoading(false);
  };

  /**
   * @name actionRowDelete
   * @description Método que maneja el evento del botón eliminar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowDelete = (params) => {
    setRow(params.row);
    setOpenModalToDelete(true);
    setDeleteMessage(lang.deleteExpressMessage.replace("[EXPRESS]", params.row.express));
  };

  /**
   * @name actionRowsDelete
   * @description Muestra el modal para eliminar varias líneas
   * @version 1.0
   */
  const actionRowsDelete = () => {
    const message = selectedRows.length === 1 ? lang.deleteOneExpressMessage : lang.deleteMoreExpressMessage.replace("[EXPRESS]", selectedRows.length);
    setOpenModalToDelete(true);
    setDeleteMessage(message);
  };

  /**
   * @name resetData
   * @description Método que inicializa los datos del usuarrio
   * @version 1.0
   */
  const resetData = () => {
    return { name: "", cabys: "", price: "", taxType: taxesTypes[0], tax: taxes[0] };
  };

  /**
   * @name actionRowEdit
   * @description Método que maneja el evento del botón editar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowEdit = (params) => {
    // Se obtiene el index de los impuestos y tipos de impuestos, para el id de Selector
    const idTax = settings.tariffCodes.findIndex((e) => e.code === params.row.taxCode);
    const idTaxType = settings.taxesTypes.findIndex((e) => e.code === params.row.taxType);
    // Se Crean los objetos con los datos del firestore para cargar los Selectors
    const taxType = { id: idTaxType + 1, name: params.row.taxTypeName, icon: null, code: params.row.taxType };
    const tax = { id: idTax + 1, name: params.row.taxName, icon: null, code: params.row.taxCode, tariff: params.row.tariff };
    // Se cargan los datos para editar y se abre el Modal
    setEdit(true);
    setIdToEdit(params.row.id);
    setOpenAddOrEditExpress(true);
    setData({ ...params.row, tax, taxType, price: params.row.priceNumber, name: params.row.express });
  };

  /**
   * @name deleteMoreExpress
   * @description Método encargado de eliminar uno o más express
   * @returns N/A
   * @version 1.0
   */
  const deleteMoreExpress = async () => {
    if (loading) return;
    setLoading(true);
    let hasErrors = false;
    for (let x = 0; x < rows.length; x++) {
      if (selectedRows.includes(rows[x].id)) {
        try {
          await deleteDoc(doc(db, endpoints.oneExpress(branch.administrator, branch.branchId, rows[x].id)));
        } catch (error) {
          hasErrors = true;
        }
      }
    }
    setLoading(false);
    if (hasErrors) return setSnack([79, constant.error]);
    setOpenModalToDelete(false);
    setSnackLocal([89, constant.success]);
  };

  /**
   * @name deleteOneExpress
   * @description Método encargado de eliminar un express (una fila)
   * @returns N/A
   * @version 1.0
   */
  const deleteOneExpress = async () => {
    if (loading) return;
    setLoading(true);

    try {
      await deleteDoc(doc(db, endpoints.oneExpress(branch.administrator, branch.branchId, row.id)));
      setSnackLocal([95, constant.success]);
      setOpenModalToDelete(false);
    } catch (error) {
      setSnack([96, constant.error]);
    }
    setLoading(false);
  };

  /** Efecto para manejar la carga de los comboBox */
  useEffect(() => {
    // Carga la lista de tipos de impuestos de firestore
    const tt = settings.taxesTypes.reduce((acc, e, i) => {
      acc.push({ id: i + 1, name: e.name, icon: null, code: e.code });
      return acc;
    }, []);
    setTaxesTypes((prev) => [...prev, ...tt]);
    // Carga la lista de impuestos de firestore
    const tc = settings.tariffCodes.reduce((acc, e, i) => {
      acc.push({ id: i + 1, name: e.description, icon: null, code: e.code, tariff: e.tariff });
      return acc;
    }, []);
    setTaxes((prev) => [...prev, ...tc]);
  }, []);

  /** Efecto que mantiene actualizada la tabla de express */
  useEffect(() => {
    setLoadingBar(true);
    const collectionCategories = collection(db, endpoints.express(branch.administrator, branch.branchId));
    const queryCategories = query(collectionCategories, orderBy("name", "asc"));
    // Escucha los cambios en los datos de los express
    const unsubscribe = onSnapshot(queryCategories, (snapshot) => {
      setAllExpress(() => []);
      setAuxAllExpress(() => []);
      // Almacena las filas de cada express
      const express = snapshot.docs.reduce((acc, doc) => {
        const finalPrice = getFinalPrice({ priceCalculation: settings.priceCalculation, basePrice: doc.data().price, taxTariff: doc.data().tariff }).total;
        const item = {
          utility: 0,
          id: doc.id,
          finalPrice,
          status: true,
          url: imgExpress,
          name: doc.data().name,
          cabys: doc.data().cabys,
          idMenuOrExpress: doc.id,
          express: doc.data().name,
          tariff: doc.data().tariff,
          basePrice: doc.data().price,
          taxCode: doc.data().taxCode,
          taxType: doc.data().taxType,
          taxName: doc.data().taxName,
          priceNumber: doc.data().price,
          category: doc.data().category,
          taxText: doc.data().tariff + "%",
          idCategory: doc.data().category.id,
          taxTypeName: doc.data().taxTypeName,
          edition: formatDate(doc.data().date, lang),
          finalPriceText: currency.symbol + formatNumber(finalPrice),
          basePriceText: currency.symbol + formatNumber(doc.data().price * 1),
          nameWithPrice: doc.data().name + " " + currency.symbol + formatNumber(finalPrice),
          tax: { code: doc.data().taxCode, id: 0, name: doc.data().taxName, tariff: doc.data().tariff },
        };
        acc.push(item);
        return acc;
      }, []);
      setRows(express);
      setAuxRows(express);
      setLoadingBar(false);
      // Para menejo global, en el modal de órdenes
      setAllExpress(() => express);
      setAuxAllExpress(() => express);
    });
    return () => unsubscribe();
  }, []);

  /** Constante que define las columnas para la tabla */
  const columns = [
    { field: "express", headerName: lang.express, flex: 1 },
    { field: "basePriceText", headerName: lang.price, flex: 1 },
    { field: "taxText", headerName: lang.tax, flex: 1 },
    { field: "finalPriceText", headerName: lang.finalPrice, flex: 1 },
    { field: "edition", headerName: lang.edition, flex: 1 },
    { field: "action", headerName: lang.actions, flex: 0 },
  ];

  /** Constante que almacena los datos para los botones de acciones de cada fila */
  const actions = [
    { icon: <Edit />, callBack: actionRowEdit, color: constant.editColor },
    { icon: <DeleteForever />, callBack: actionRowDelete, color: constant.deleteColor },
  ];

  /**
   * @description Objeto para la creación del modal agregar o editar express
   */
  const paramsToAddOrEditExpress = {
    btnText: edit ? lang.edit : lang.add,
    title: edit ? lang.editExpress : lang.addExpress,
    icon: edit ? <EditLocationIcon /> : <AddLocationAltIcon />,
    content: <AddExpress data={data} setData={setData} nameFocus={nameFocus} priceFocus={priceFocus} taxesTypes={taxesTypes} taxes={taxes} />,
  };

  /**
   * @description Objeto para la creación del modal eliminar express
   */
  const parametersToDeleteExpress = {
    btnText: lang.delete,
    icon: <DeleteForever />,
    title: lang.deleteExpress,
    content: <DeleteAnything message={deleteMessage} />,
  };

  return (
    <div style={css.box}>
      <HeaderProducts
        icons={3}
        toolTip={lang.addExpress}
        title={lang.manageExpress}
        selectedRows={selectedRows}
        actionSearch={filterExpress}
        actionButton={showCreateExpress}
        actionButtonDelete={actionRowsDelete}
      />
      <LoadingBar visible={loadingBar} />
      <div style={css.containerGrid}>
        <GenericGridType2 rowHeight={40} columns={columns} rows={rows} actions={actions} setSelectedRows={setSelectedRows} />
      </div>
      {/** Modal para agregar un express */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openAddOrEditExpress}
        clickBtnOk={addOrEditExpress}
        setOpen={setOpenAddOrEditExpress}
        icon={paramsToAddOrEditExpress.icon}
        title={paramsToAddOrEditExpress.title}
        btnText={paramsToAddOrEditExpress.btnText}
        content={paramsToAddOrEditExpress.content}
      />
      {/** Modal para eliminar un express */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openModalToDelete}
        colorBar={constant.error}
        color={constant.deleteColor}
        setOpen={setOpenModalToDelete}
        icon={parametersToDeleteExpress.icon}
        title={parametersToDeleteExpress.title}
        btnText={parametersToDeleteExpress.btnText}
        content={parametersToDeleteExpress.content}
        clickBtnOk={selectedRows.length ? deleteMoreExpress : deleteOneExpress}
      />
      <Message snack={snackLocal}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    box: { width: "100%" },
    containerGrid: { flex: "1", overflowY: "auto" },
    selectTax: { fontSize: "16px", color: "#444", marginRight: "5px" },
  };
};

export default ProductsExpress;
