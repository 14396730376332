import { decimalToFraction, formatNumber } from "../../../../assets/js/Commons";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useThis } from "../../../../assets/context/Context";
import { Box, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import InputText from "../../InputText";

/**
 * @name SplitAccount
 * @description Método que devuelve el contenido del modal para dividir la cuentar
 * @param {Object} { auxRows, setSplitOrders }
 * @returns
 */
const SplitAccount = ({ auxRows, setSplitOrders }) => {
  const css = styles();
  const { lang, currency } = useThis();

  const [rows, setRows] = useState([]);
  const [data, setData] = useState({ amount: 2 });

  /**
   * @name add
   * @description Método para aumentar la división del o los pedidos (máx. 10)
   * @param {String} id
   * @version 1.0
   */
  const add = () => {
    setData((prev) => ({ ...prev, amount: 1 * prev.amount + 1 < 10 ? 1 * prev.amount + 1 : 10 }));
  };

  /**
   * @name subtract
   * @description Método para disminuir la división del o los pedidos (mín. 2)
   * @param {String} id
   * @version 1.0
   */
  const subtract = () => {
    setData((prev) => ({ ...prev, amount: prev.amount * 1 > 2 ? prev.amount * 1 - 1 : 2 }));
  };

  /** Columnas de la tabla división de pedidos */
  const columns = [
    { field: "amount", headerName: lang.amount, sortable: false },
    { field: "order", headerName: lang.order, sortable: false },
    { field: "price", headerName: lang.price, sortable: false },
  ];

  /** Efecto que maneja la división de los pedidos seleccionados */
  useEffect(() => {
    const auxSelectedLines = auxRows.filter((row) => row.selectedLeft);
    const auxUnselectedLines = auxRows.filter((row) => !row.selectedLeft);

    /** Se dividen los pedidos en cantidad y precio */
    const selectedLines = auxSelectedLines.reduce((acc, e) => {
      for (let index = 0; index < data.amount; index++) {
        acc.push({ ...e, id: "", amount: e.amount / data.amount, basePrice: e.basePrice / data.amount, finalPrice: e.finalPrice / data.amount });
      }
      return acc;
    }, []);

    /** Se preparan las lineas para pitarse en la tabla */
    const lines = selectedLines.reduce((acc, e, index) => {
      acc.push({ id: index, amount: decimalToFraction(e.amount), order: e.name, price: currency.symbol + formatNumber(e.finalPrice) });
      return acc;
    }, []);
    setRows(lines);

    setSplitOrders([...auxUnselectedLines, ...selectedLines]);
  }, [data.amount]);

  /** Efecto que maneja la cantidad mínima y máxima de pedidos */
  useEffect(() => {
    if (data.amount < 2) setData((prev) => ({ ...prev, amount: 2 }));
    if (data.amount > 10) setData((prev) => ({ ...prev, amount: 10 }));
  }, [data.amount]);

  return (
    <div>
      <Box sx={css.container}>
        <DataGrid
          hideFooter
          rows={rows}
          rowHeight={35}
          columns={columns}
          disableColumnMenu
          disableColumnFilter
          localeText={{ noRowsLabel: lang.noOrders }}
        />
      </Box>
      <div style={css.containerInputs}>
        <IconButton onClick={subtract} sx={css.new}>
          <RemoveOutlinedIcon />
        </IconButton>
        <InputText
          data={data}
          size="small"
          type="number"
          name="amount"
          flags={false}
          readOnly={true}
          maxWidth="100px"
          marginLeft="5px"
          marginRight="5px"
          setData={setData}
          textAlign="center"
          value={data.amount}
          label={lang.persons}
        />
        <IconButton onClick={add} sx={css.new}>
          <AddOutlinedIcon />
        </IconButton>
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    container: { height: 200 },
    new: { width: "auto", color: "#5AB262" },
    containerInputs: { display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15 },
  };
};

export default SplitAccount;
