import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { getPrinters, print } from "../../../assets/js/Commons";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useThis } from "../../../assets/context/Context";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import getConstant from "../../../assets/js/Constant";
import CachedIcon from "@mui/icons-material/Cached";
import SaveIcon from "@mui/icons-material/Save";
import HttpIcon from "@mui/icons-material/Http";
import LoadingButton from "../LoadingButton";
import { useEffect, useState } from "react";
import LoadingBar from "../LoadingBar";
import CircleIcon from "./CircleIcon";
import InputText from "../InputText";
import Selector from "../Selector";
import Message from "../Message";
import Label from "../Label";

/**
 * @name Print
 * @description Componente para la configuración de impresoras
 * @returns View
 * @version 1.0
 */
const Print = () => {
  const { lang } = useThis();
  const constant = getConstant();
  const css = styles(constant);
  const defaultPrinter = [{ id: 0, name: lang.printerSelection, icon: <TouchAppIcon sx={css.selectPrinter} />, code: "0" }];

  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [loadingBar, setLoadingBar] = useState(false); // Para la tabla
  const [printers, setPrinters] = useState(defaultPrinter); // Impresoras
  const [data, setData] = useState({ vouchers: printers[0], reports: printers[0], url: "" });

  /**
   * @name saveData
   * @description Método encargado de guardar los datos
   * @version 1.0
   */
  const saveData = () => {
    localStorage.setItem("vouchers", data.vouchers.name);
    localStorage.setItem("reports", data.reports.name);
    localStorage.setItem("serverInfo", data.url);
    setSnack([115, constant.success]);
  };

  /**
   * @name loadPrinters
   * @description Método que obtiene las impresoras del servidor
   * @returns View
   * @version 1.0
   */
  const loadPrinters = async () => {
    setLoadingBar(true);
    const response = await getPrinters();
    setLoadingBar(false);
    if (response.error) return setSnack([response.errorCode, constant.error]);
    const reports = localStorage.getItem("reports");
    const vouchers = localStorage.getItem("vouchers");
    const devices = response.printers.reduce((acc, e, index) => {
      const printer = { id: index + 1, name: e, icon: <TouchAppIcon sx={css.selectPrinter} />, code: (index + 1).toString() };
      if (reports === e) setData((prev) => ({ ...prev, reports: printer }));
      if (vouchers === e) setData((prev) => ({ ...prev, vouchers: printer }));
      acc.push(printer);
      return acc;
    }, []);
    setSnack([138, constant.success]);
    setPrinters([...defaultPrinter, ...devices]);
  };

  /**
   * @name reloadPrinters
   * @description Método que recarga las impresoras
   * @version 1.0
   */
  const reloadPrinters = () => {
    localStorage.setItem("serverInfo", data.url);
    loadPrinters();
  };

  const printingTest = async (printer, content) => {
    setLoadingBar(true);
    const response = await print(printer, content);
    setLoadingBar(false);
    if (response.error) return setSnack([response.errorCode, constant.error]);
    setSnack([142, constant.success]);
  };

  /** Efecto que obtiene las impresoras del ordenador */
  useEffect(() => {
    const url = localStorage.getItem("serverInfo");
    if (url) setData({ ...data, url });
    loadPrinters();
  }, []);

  return (
    <>
      <LoadingBar visible={loadingBar} />
      <div style={css.container}>
        <div style={css.left}>
          <CircleIcon icon={<LocalPrintshopIcon sx={css.icon} />} text={lang.configPrintInfo} />
        </div>
        <div style={css.right}>
          <div>
            <br />
            <Label text={lang.configPrintInfo} fontSize={"30px"} />
            <br />
            <div style={css.subtitle}>
              <div style={css.dot} />
              <Label text={lang.urlServer} fontSize={"20px"} fontWeight={500} margin="0 0 5px 0" />
            </div>
            <div style={css.section}>
              <div style={css.inputs}>
                <InputText
                  name="url"
                  type="text"
                  data={data}
                  size="small"
                  width="260px"
                  maxWidth="260px"
                  value={data.url}
                  setData={setData}
                  icon={<HttpIcon />}
                  label={lang.ipPort}
                />
                <Tooltip TransitionComponent={Zoom} title={lang.reloadPrinters}>
                  <IconButton onClick={reloadPrinters} sx={css.fabsIcons}>
                    <CachedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <br />
            <div style={css.subtitle}>
              <div style={css.dot} />
              <Label text={lang.printersSelection} fontSize={"20px"} fontWeight={500} margin="0 0 5px 0" />
            </div>
            <div style={css.section}>
              <div style={css.item}>
                <ReceiptLongIcon sx={css.iconPrinter} />
                <Label text={lang.printedPointSale + ": "} fontSize={"16px"} fontWeight={500} />
              </div>
              <div style={css.inputs}>
                <Selector name="vouchers" data={data} setData={setData} collection={printers} group={2} maxWidth={"260px"} iconType={1} />
                <Tooltip TransitionComponent={Zoom} title={lang.testVoucher}>
                  <IconButton onClick={() => printingTest(data.vouchers.name, lang.testVoucher)} sx={css.fabsIcons}>
                    <ReceiptLongIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div style={css.item}>
                <AssignmentIcon sx={css.iconPrinter} />
                <Label text={lang.reportPrinter + ": "} fontSize={"16px"} fontWeight={500} />
              </div>
              <div style={css.inputs}>
                <Selector name="reports" data={data} setData={setData} collection={printers} group={2} maxWidth={"260px"} iconType={1} />
                <Tooltip TransitionComponent={Zoom} title={lang.testReport}>
                  <IconButton onClick={() => printingTest(data.reports.name, lang.testReport)} sx={css.fabsIcons}>
                    <AssignmentIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <br />
            <br />
          </div>
          <div>
            <div style={css.line3}></div>
            <div style={css.button}>
              <LoadingButton text={lang.save} width="200px" handleClick={saveData} icon={<SaveIcon />} color={constant.primaryColor} marginTop={"15px"} />
            </div>
            <br />
          </div>
        </div>
        <Message snack={snack}></Message>
      </div>
    </>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} constant
 * @returns Object
 * @version 1.0
 */
const styles = (constant) => {
  return {
    section: { paddingLeft: "12px" },
    icon: { fontSize: "70px", color: "#FFF" },
    line3: { borderBottom: "1px solid #CCC" },
    inputs: { display: "flex", alignItems: "center" },
    button: { display: "flex", justifyContent: "end" },
    container: { width: "100%", height: "100%", display: "flex" },
    item: { display: "flex", alignItems: "center", margin: "15px 0" },
    iconPrinter: { fontSize: "20px", color: "#444", marginRight: "5px" },
    subtitle: { display: "flex", alignItems: "center", margin: "10px 0" },
    selectPrinter: { fontSize: "16px", color: "#444", marginRight: "5px" },
    left: { display: "flex", justifyContent: "center", alignItems: "center", margin: "0 5rem" },
    dot: { borderRadius: "50%", width: "12px", height: "12px", backgroundColor: "#a7524c", margin: "0 5px 0 0" },
    fabsIcons: { backgroundColor: constant.primaryColor, color: "#FFF", "&:hover": { backgroundColor: "#4c9668" }, marginLeft: "15px" },
    right: { flex: 1, padding: "0 25px 0 0", overflowY: "auto", display: "flex", flexDirection: "column", justifyContent: "space-between" },
  };
};

export default Print;
