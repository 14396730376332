import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useThis } from "../../assets/context/Context";
import { getInfoByIde } from "../../assets/js/Commons";
import getConstant from "../../assets/js/Constant";
import BadgeIcon from "@mui/icons-material/Badge";
import HailIcon from "@mui/icons-material/Hail";
import InputText from "./InputText";
import Selector from "./Selector";
import Label from "./Label";

/**
 * @name AddOrEditUser
 * @description Metodo encargado de mostrar la vista para agregar o editar usuarios
 * @param {Object} { data, setData, nameFocus, emailFocus, permissionsList, readOnly, setLoading, setSnack }
 * @returns View
 * @version 1.0
 */
const AddOrEditUser = ({ data, setData, nameFocus, emailFocus, permissionsList, readOnly, setLoading, setSnack }) => {
  const css = styles();
  const constant = getConstant();
  const { lang, typesIDs } = useThis();

  /**
   * @name onKey
   * @description Método encargado de controlar si se presiona enter para consultar ante Hacienda CR la información de la identificación ingresada
   * @param {Event} e
   * @version 1.0
   */
  const onKey = async (e) => {
    if (e.key !== "Enter") return;
    // Inicia la barra de cargando...
    setLoading(true);
    // Llama a la función que consulta los datos del usuario a Hacienda CR
    const message = await getInfoByIde(data.identification, false);
    // Detiene la barra de cargando cuando se optiene la respuesta
    setLoading(false);
    // Si la respuesta viene con error, se muestra el mensaje tipo Toast
    if (message.error) return setSnack([message.errorCode, constant.error]);
    // Se selecciona el tipo de identificación y se agrega en el selector de tipos de IDs
    const typeID = typesIDs.find((element) => element.type === message.response.tipoIdentificacion);
    // Se seleccionan las actividades y en caso de tener, se agregan en el selector de código de actividad
    setData({ ...data, typeID, name: message.response.nombre });
    emailFocus.current.focus();
  };

  return (
    <div style={css.container}>
      <Label text={lang.personalInfo} fontWeight={"500"} margin={"0 0 15px 0"} />
      <Selector name="typeID" data={data} setData={setData} group={1} />
      <InputText
        data={data}
        marginTop={2}
        onKey={onKey}
        type={"text"}
        size={"small"}
        width={"auto"}
        marginBottom={2}
        setData={setData}
        icon={<BadgeIcon />}
        name="identification"
        value={data.identification}
        label={lang.idePlusEnter.replace("*", "")}
      />
      <InputText
        name="name"
        data={data}
        marginTop={2}
        type={"text"}
        size={"small"}
        width={"auto"}
        marginBottom={2}
        value={data.name}
        setData={setData}
        icon={<HailIcon />}
        reference={nameFocus}
        label={"*" + lang.name}
      />
      <InputText
        data={data}
        name="email"
        marginTop={2}
        type={"email"}
        size={"small"}
        width={"auto"}
        color={"#872B2B"}
        setData={setData}
        value={data.email}
        readOnly={readOnly}
        reference={emailFocus}
        label={`*${lang.email}`}
        icon={<AlternateEmailIcon />}
      />
      <InputText
        data={data}
        name="phone"
        marginTop={2}
        type={"text"}
        size={"small"}
        width={"auto"}
        setData={setData}
        label={lang.phone}
        value={data.phone}
        icon={<LocalPhoneIcon />}
      />
      <Label text={lang.systemPermissions} fontWeight={"500"} margin={"15px 0"} />
      <div style={css.permissionsHeader}>
        <div style={css.permission}>
          <Label text={lang.permissions} />
        </div>
        <div style={css.editAndWatch}>
          <Label text={lang.watch} textAlign={"center"} />
        </div>
        <div style={css.editAndWatch}>
          <Label text={lang.edit} textAlign={"center"} />
        </div>
      </div>
      {permissionsList}
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = () => {
  return {
    permission: { width: "60%" },
    container: { width: "350px" },
    editAndWatch: { width: "20%" },
    permissionsHeader: { display: "flex", justifyContent: "space-between", borderBottom: "1px solid #ccc", paddingBottom: "5px", color: "#444" },
  };
};

export default AddOrEditUser;
