import { submenuOptions } from "../../../assets/config/config-submenu";
import ModalFullScreen from "../../containers/ModalFullScreen";
import { useThis } from "../../../assets/context/Context";
import { Box } from "@mui/material";
import { useState } from "react";
import Label from "../Label";

/**
 * @name Administration
 * @description Método que muestra el módulo de administración con el submenú
 * @returns View
 * @version 1.0
 */
const Administration = () => {
  const css = styles();
  const { lang, defalutBranch } = useThis();

  const [openModal, setOpenModal] = useState(false);
  const [parameters, setParameters] = useState({ title: "", icon: null, content: <></> });

  /**
   * @name loadModal
   * @description Método que carga y muestra el modal con los parámetros de cada módulo
   * @param {Object} params
   * @version 1.0
   */
  const loadModal = (params) => {
    setOpenModal(true);
    setParameters(params);
  };

  /**
   * @name loadButtons
   * @description Método que carga los botones del submenú administrativo
   * @returns {Array}
   * @version 1.0
   */
  const loadButtons = () => {
    const buttons = submenuOptions(lang)[defalutBranch.country.code].reduce((acc, e, index) => {
      acc.push(
        <Box key={e.text + index} sx={{ ...styles(e.color).button, backgroundColor: e.color }} onClick={() => loadModal(e.params)}>
          {e.icon}
          {e.text}
        </Box>
      );
      return acc;
    }, []);
    return buttons;
  };

  return (
    <div style={css.box}>
      <div style={{ ...css.box, flex: 1 }}>
        <Label text={lang.submenu} color={"#777777"} fontSize={"40px"} fontWeight={200} />
        <br />
        <div style={css.container}>{loadButtons()}</div>
        <br />
        <Label text={lang.selectOption} fontSize={"18px"} color={"#777777"} />
      </div>
      <Label text={lang.version + process.env.REACT_APP_VERSION} fontSize={"12px"} color={"#777777"} margin={"10px"} />
      <ModalFullScreen show={openModal} icon={parameters.icon} setShow={setOpenModal} title={parameters.title} content={parameters.content} />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} color
 * @returns Object
 * @version 1.0
 */
const styles = (color) => {
  return {
    container: { display: "flex", padding: "20px", borderRadius: "5px", boxShadow: "0 3px 6px #00000029, 0 3px 6px #0000003b" },
    box: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "#f8f8f8",
    },
    button: {
      width: "50px",
      color: "#FFF",
      margin: "10px",
      height: "50px",
      padding: "15px",
      display: "flex",
      cursor: "pointer",
      borderRadius: "8px",
      alignItems: "center",
      transition: "all 0.2s",
      flexDirection: "column",
      border: "solid 1px " + color,
      justifyContent: "space-between",
      boxShadow: "0 2px 3px #00000029, 0 2px 3px #0000003b",
      "&:hover": { color, backgroundColor: "#FFF", transform: "scale(1.05)", border: "solid 1px " + color },
    },
  };
};

export default Administration;
