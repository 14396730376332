import { formatNumber, getFinalPrice } from "../../../../assets/js/Commons";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useThis } from "../../../../assets/context/Context";
import getConstant from "../../../../assets/js/Constant";
import EditNoteIcon from "@mui/icons-material/EditNote";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import CustomSwitch from "../../CustomSwitch";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import InputCheck from "../../InputCheck";
import InputText from "../../InputText";
import MenuItem from "./MenuItem";
import Label from "../../Label";

/** Variables globales */
const icon = <InfoIcon color="info" sx={{ fontSize: "16px" }} />;

/**
 * @name RequestOrder
 * @description Método que muestra el contenido del modal para realizar un pedido
 * @param {Object} { order, setOrder, menu, type: 0=Mesa, 1=Express, 2=Llevar, edit }
 * @returns View
 * @version 1.0
 */
const RequestOrder = ({ setOrder, menu, type, edit }) => {
  const { lang, currency, settings } = useThis();
  const constant = getConstant();
  const css = styles(constant);

  const [checks, setChecks] = useState({ service: type === 0, tax: true, utility: true });
  const [data, setData] = useState({ amount: 1, requestKitchen: menu.requestKitchen, detail: menu.detail || "" });
  const [amounts, setAmounts] = useState({ service: 0, tax: 0, utility: 0, total: 0, subtotal: menu.basePrice * 1 });

  /**
   * @name add
   * @description Método para aumentar la cantidad de un producto y/o servicio
   * @param {String} id
   * @version 1.0
   */
  const add = () => {
    if (edit) return;
    setData((prev) => ({ ...prev, amount: 1 * prev.amount + 1 }));
  };

  /**
   * @name subtract
   * @description Método para restar la cantidad de un producto y/o servicio
   * @param {String} id
   * @version 1.0
   */
  const subtract = () => {
    if (edit) return;
    setData((prev) => ({ ...prev, amount: prev.amount * 1 > 1 ? prev.amount * 1 - 1 : 1 }));
  };

  /** Efecto que maneja el cáculo del precio final, y los montos de cada cargo (utilidad, impuestos...) */
  useEffect(() => {
    setAmounts(
      getFinalPrice({
        amount: data.amount,
        checksTax: checks.tax,
        basePrice: menu.basePrice,
        taxTariff: menu.tax.tariff,
        checksUtility: checks.utility,
        checksService: checks.service,
        utilityPercentage: menu.utility,
        priceCalculation: settings.priceCalculation,
        restaurantService: settings.restaurantService,
        restaurantTaxPercentage: settings.restaurantTax.tariff,
      })
    );
  }, [checks.utility, checks.tax, checks.service, data.amount]);

  /** Efecto que maneja el seteo del id del producto */
  useEffect(() => {
    setOrder({
      ...menu,
      inKitchen: false,
      amount: data.amount,
      detail: data.detail,
      statusKitchen: false,
      id: edit ? menu.id : "",
      requestKitchen: data.requestKitchen,
    });
  }, [data]);

  /** Efecto que maneja la cantidad mínima de pedidos */
  useEffect(() => {
    if (data.amount <= 0) setData((prev) => ({ ...prev, amount: 1 }));
  }, [data.amount]);

  return (
    <div style={css.container}>
      <div style={css.containerLeft}>
        <MenuItem size={160} item={menu} text={data.amount + " " + menu.name} onClick={() => {}} className="image-container" />
        <div style={css.containerInputs}>
          <IconButton onClick={subtract} sx={css.new}>
            <RemoveOutlinedIcon />
          </IconButton>
          <InputText
            data={data}
            size="small"
            type="number"
            name="amount"
            flags={false}
            readOnly={edit}
            maxWidth="70px"
            marginLeft="5px"
            marginRight="5px"
            setData={setData}
            textAlign="center"
            value={data.amount}
          />
          <IconButton onClick={add} sx={css.new}>
            <AddOutlinedIcon />
          </IconButton>
        </div>
        {edit && <Label fontSize="10px" text={lang.orderAt + menu.dateText} />}
      </div>
      <div style={css.containerRight}>
        <div style={css.containerSwitch}>
          <Label tooltip={lang.requestKitchenInfo} icon={icon} text={lang.requestKitchen} fontWeight={500} />
          <CustomSwitch data={data} setData={setData} name="requestKitchen" textLeft={lang.yes} textRight={lang.no} disabled={false} />
        </div>
        <InputText
          rows={2}
          data={data}
          type="text"
          size="small"
          width="auto"
          name="detail"
          marginBottom={1}
          setData={setData}
          value={data.detail}
          label={lang.addDetail}
          icon={<EditNoteIcon sx={css.icon} />}
        />
        <ItemPrice
          css={css}
          value={true}
          data={checks}
          name="subtotal"
          setData={setChecks}
          text={lang.subtotal}
          amount={currency.symbol + formatNumber(amounts.subtotal)}
        />
        <ItemPrice
          css={css}
          data={checks}
          name="utility"
          setData={setChecks}
          value={checks.utility}
          amount={currency.symbol + formatNumber(amounts.utility)}
          text={lang.utility + ` (${menu.utility || 0}%)`}
        />
        <ItemPrice
          css={css}
          data={checks}
          name="service"
          setData={setChecks}
          value={checks.service}
          amount={currency.symbol + formatNumber(amounts.service)}
          text={lang.service + ` (${settings.restaurantService}%)`}
        />
        <ItemPrice
          css={css}
          name="tax"
          data={checks}
          text={lang.taxes}
          value={checks.tax}
          setData={setChecks}
          amount={currency.symbol + formatNumber(amounts.tax)}
        />
        <span style={css.line} />
        <div style={css.total}>
          <Label tooltip={lang.previewTotal} icon={icon} fontWeight={500} text={lang.total} />
          <Label fontWeight={500} text={currency.symbol + formatNumber(amounts.total)} />
        </div>
      </div>
    </div>
  );
};

/**
 * @name ItemPrice
 * @description Método que devuelve un componente de tipo ItemPrice (check con los datos que conforman el precio)
 * @param {Object} { data, setData, text, amount, css, name, value, fontWeight = 0 }
 * @returns View
 * @version 1.0
 */
const ItemPrice = ({ data, setData, text, amount, css, name, value, fontWeight = 0 }) => {
  return (
    <div style={css.amount}>
      <div style={css.item}>
        <InputCheck name={name} data={data} setData={setData} value={value} />
        <Label text={text} />
      </div>
      <Label text={amount} fontWeight={fontWeight} />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {object} constant
 * @returns Object
 * @version 1.0
 */
const styles = (constant) => {
  return {
    item: { display: "flex" },
    icon: { color: "#3f7dbd" },
    container: { display: "flex" },
    new: { width: "auto", color: "#5AB262" },
    line: { borderTop: "1px solid #CCC", marginTop: "2px" },
    containerRight: { display: "flex", flexDirection: "column" },
    amount: { display: "flex", justifyContent: "space-between", alignItems: "center" },
    containerInputs: { display: "flex", justifyContent: "space-between", alignItems: "center" },
    total: { display: "flex", justifyContent: "space-between", marginTop: "5px", alignItems: "center " },
    containerLeft: { marginRight: "10px", display: "flex", flexDirection: "column", justifyContent: "space-between" },
    containerSwitch: { display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "5px", width: "270px" },
    inputs: {
      width: "35px",
      height: "35px",
      display: "flex",
      borderRadius: "5px",
      alignItems: "center",
      justifyContent: "center",
      border: `1px solid ${constant.primaryColor}`,
    },
  };
};

export default RequestOrder;
export { ItemPrice };
