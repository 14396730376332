import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, serverTimestamp, updateDoc } from "firebase/firestore";
import { deleteAnyFile, uploadAnyFile } from "../../../assets/js/firebaseMethods";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { db } from "../../../assets/context/firebase-config";
import { useThis } from "../../../assets/context/Context";
import { Edit, DeleteForever } from "@mui/icons-material";
import GenericGridType2 from "../grids/GenericGridType2";
import { formatDate } from "../../../assets/js/Commons";
import getConstant from "../../../assets/js/Constant";
import endpoints from "../../../assets/js/Endpoints";
import { useEffect, useRef, useState } from "react";
import HeaderProducts from "../HeaderProducts";
import DeleteAnything from "../DeleteAnything";
import Modal from "../../containers/Modal";
import AddCategory from "../AddCategory";
import LoadingBar from "../LoadingBar";
import Message from "../Message";
import { v4 } from "uuid";

/**
 * @name ProductsCategories
 * @description Método que retorna la vista del módulo de categorías
 * @returns View Muestra el módulo de categorías
 * @version 1.0
 */
const ProductsCategories = () => {
  const css = styles();
  const constant = getConstant();
  const { lang, branch, setAllCategories } = useThis();

  const nameFocus = useRef(null);

  const [row, setRow] = useState({});
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [edit, setEdit] = useState(false);
  const [auxRows, setAuxRows] = useState([]);
  const [idToEdit, setIdToEdit] = useState("");
  const [loading, setLoading] = useState(false); // Para el modal
  const [snackLocal, setSnackLocal] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [loadingBar, setLoadingBar] = useState(false); // Para la tabla
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [showDeleteBtn, setShowDeleteBtn] = useState(true);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [openModalToDelete, setOpenModalToDelete] = useState(false);

  /**
   * @name filter
   * @description Método que filtra las categorías de la tabla, por nombre
   * @param {Event} e
   * @version 1.0
   */
  const filter = (e) => {
    const categories = auxRows.reduce((acc, item) => {
      if (item.category.toLowerCase().includes(e.target.value.toLowerCase())) acc.push(item);
      return acc;
    }, []);
    setRows(categories);
  };

  /**
   * @name showCreateCategories
   * @description Método que se encarga de mostrar el modal para agregar o editar categorías
   * @version 1.0
   */
  const showCreateCategories = () => {
    setEdit(false);
    /** Inicializa los datos */
    setData(resetData());
    /** Muestra el modal para crear o ediar categorías */
    setOpenAddCategory(true);
  };

  /**
   * @name actionRowDelete
   * @description Método que maneja el evento del botón eliminar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowDelete = (params) => {
    setRow(params.row);
    setOpenModalToDelete(true);
    setDeleteMessage(lang.deleteCategoryMessage.replace("[CATEGORY]", params.row.category));
  };

  /**
   * @name actionRowEdit
   * @description Método que maneja el evento del botón editar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowEdit = (params) => {
    setEdit(true);
    setData(params.row);
    setOpenAddCategory(true);
    setIdToEdit(params.row.id);
  };

  /**
   * @name addCategory
   * @description Método que ejecuta el llamado al servicio para agregar una categoría
   * @version 1.0
   */
  const addOrEditCategory = async () => {
    if (loading) return;

    // Se valida que no sea vacío el nombre de la categoría
    if (!data.category) {
      nameFocus.current.focus();
      return setSnack([76, constant.error]);
    }
    // Se valida que la categoría no exista
    if (!edit && rows.find((row) => row.category === data.category)) {
      nameFocus.current.focus();
      return setSnack([113, constant.error]);
    }

    setLoading(true);

    const code = v4();
    const required = { category: data.category, date: serverTimestamp(), status: data.status };

    try {
      if (edit) {
        const info = data.file ? { code, ...required } : required;
        // Si al editar existe un archivo en el input, se elimina la imagen anterior
        let imageDeleted = false;
        if ((data.file && data.auxUrl) || (!data.url && data.auxUrl)) {
          await deleteAnyFile(endpoints.storeCategories(branch.administrator, branch.branchId, data.code + constant.imageExtension));
          imageDeleted = true;
        }
        // Se actualiza la categoría
        await updateDoc(doc(db, endpoints.category(branch.administrator, branch.branchId, idToEdit)), { ...info, url: imageDeleted ? "" : data.url });
      } else {
        // Se agrega el documento de la categoría
        await addDoc(collection(db, endpoints.categories(branch.administrator, branch.branchId)), { code, ...required });
      }

      // Se almacena la imagen en el bucket y el trigger "resizeAndConvertToWebP" la relaciona con este documento mediante el "code"
      if (data.file) {
        const imgName = data.file.name.split(".").pop().toLowerCase();
        await uploadAnyFile(endpoints.storeCategories(branch.administrator, branch.branchId, code + "." + imgName), data.file);
      }
      setSnackLocal([edit ? 75 : 73, constant.success]);
      setOpenAddCategory(false);
    } catch (error) {
      console.log(error);
      setSnack([edit ? 74 : 72, constant.error]);
    }
    setLoading(false);
  };

  /**
   * @name deleteCategory
   * @description Método que elimina una categoría
   * @version 1.0
   */
  const deleteCategory = async () => {
    if (loading) return;
    setLoading(true);

    try {
      if (row.url) await deleteAnyFile(endpoints.storeCategories(branch.administrator, branch.branchId, row.code + constant.imageExtension));
      await deleteDoc(doc(db, endpoints.category(branch.administrator, branch.branchId, row.id)));
      setSnackLocal([78, constant.success]);
      setOpenModalToDelete(false);
    } catch (error) {
      setSnack([77, constant.error]);
    }
    setLoading(false);
  };

  /**
   * @name deleteCategories
   * @description Método para eliminar varias líneas
   * @version 1.0
   */
  const deleteCategories = async () => {
    if (loading) return;
    setLoading(true);
    let hasErrors = false;
    for (let x = 0; x < rows.length; x++) {
      if (selectedRows.includes(rows[x].id)) {
        try {
          if (rows[x].url) await deleteAnyFile(endpoints.storeCategories(branch.administrator, branch.branchId, rows[x].code + constant.imageExtension));
          await deleteDoc(doc(db, endpoints.category(branch.administrator, branch.branchId, rows[x].id)));
        } catch (error) {
          hasErrors = true;
        }
      }
    }
    setLoading(false);
    if (hasErrors) return setSnack([79, constant.error]);
    setOpenModalToDelete(false);
    setSnackLocal([80, constant.success]);
  };

  /**
   * @name actionRowsDelete
   * @description Muestra el modal para eliminar varias líneas
   * @version 1.0
   */
  const actionRowsDelete = () => {
    const message = selectedRows.length === 1 ? lang.deleteOneCategoryMessage : lang.deleteCategoriesMessage.replace("[CATEGORIES]", selectedRows.length);
    setOpenModalToDelete(true);
    setDeleteMessage(message);
  };

  /**
   * @name resetData
   * @description Método que inicializa los datos del usuarrio
   * @version 1.0
   */
  const resetData = () => {
    return { category: "", url: "", status: true };
  };

  /** Constante que define las columnas para la tabla */
  const columns = [
    { field: "category", headerName: lang.category, flex: 1 },
    { field: "image", headerName: lang.image, flex: 1 },
    { field: "date", headerName: lang.edition, flex: 1 },
    { field: "action", headerName: lang.actions, flex: 0 },
  ];

  /** Constante que almacena los datos para los botones de acciones de cada fila */
  const actions = [
    { icon: <Edit />, callBack: actionRowEdit, color: constant.editColor },
    { icon: <DeleteForever />, callBack: actionRowDelete, color: constant.deleteColor },
  ];

  /**
   * @description Objeto para la creación del modal agregar o editar categorías
   */
  const parametersToAddCategory = {
    btnText: edit ? lang.edit : lang.add,
    icon: edit ? <Edit /> : <CreateNewFolderIcon />,
    title: edit ? lang.editCategory : lang.newCategory,
    content: <AddCategory data={data} setData={setData} nameFocus={nameFocus} edit={edit} />,
  };

  /**
   * @description Objeto para la creación del modal eliminar categoría
   */
  const parametersToDeleteCategory = {
    btnText: lang.delete,
    title: lang.deleteCategory,
    icon: <DeleteForever />,
    content: <DeleteAnything message={deleteMessage} />,
  };

  /** Efecto que mantiene actualizada la tabla de categorías */
  useEffect(() => {
    setLoadingBar(true);
    const collectionCategories = collection(db, endpoints.categories(branch.administrator, branch.branchId));
    const queryCategories = query(collectionCategories, orderBy("category", "asc"));
    // Escucha los cambios en los datos de las categorías
    const unsubscribe = onSnapshot(queryCategories, (snapshot) => {
      setAllCategories(() => []);
      // Almacena las filas de cada categoría
      const categories = snapshot.docs.reduce((acc, doc) => {
        const image = doc.data().url ? lang.withImage : lang.noImage;
        const category = { id: doc.id, url: doc.data().url || "", category: doc.data().category, status: doc.data().status };
        acc.push({ ...category, image, code: doc.data().code, auxUrl: doc.data().url || "", date: formatDate(doc.data().date, lang) });
        setAllCategories((prev) => [...prev, category]); // Para menejo global, en el modal de órdenes
        return acc;
      }, []);
      setRows(categories);
      setLoadingBar(false);
      setAuxRows(categories);
    });
    return () => unsubscribe();
  }, []);

  /** Efecto que maneja el estado de la selección de filas */
  useEffect(() => {
    setShowDeleteBtn(!selectedRows.length);
  }, [selectedRows]);

  return (
    <div style={css.box}>
      <HeaderProducts
        icons={0}
        actionSearch={filter}
        toolTip={lang.addCategory}
        selectedRows={selectedRows}
        title={lang.manageCategories}
        actionButton={showCreateCategories}
        actionButtonDelete={actionRowsDelete}
      />
      <LoadingBar visible={loadingBar} />
      <div style={css.containerGrid}>
        <GenericGridType2 rowHeight={40} columns={columns} rows={rows} actions={actions} setSelectedRows={setSelectedRows} showDeleteBtn={showDeleteBtn} />
      </div>
      {/** Modal para agregar una categoría */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openAddCategory}
        setOpen={setOpenAddCategory}
        clickBtnOk={addOrEditCategory}
        icon={parametersToAddCategory.icon}
        title={parametersToAddCategory.title}
        btnText={parametersToAddCategory.btnText}
        content={parametersToAddCategory.content}
      />
      {/** Modal para eliminar una categoría */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openModalToDelete}
        colorBar={constant.error}
        color={constant.deleteColor}
        setOpen={setOpenModalToDelete}
        icon={parametersToDeleteCategory.icon}
        title={parametersToDeleteCategory.title}
        btnText={parametersToDeleteCategory.btnText}
        content={parametersToDeleteCategory.content}
        clickBtnOk={selectedRows.length ? deleteCategories : deleteCategory}
      />
      <Message snack={snackLocal}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    box: { width: "100%" },
    containerGrid: { flex: "1", overflowY: "auto" },
  };
};

export default ProductsCategories;
