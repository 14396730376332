import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../../../../assets/context/firebase-config";
import { useThis } from "../../../../assets/context/Context";
import GenericGridType2 from "../../grids/GenericGridType2";
import { formatDate } from "../../../../assets/js/Commons";
import { Edit, DeleteForever } from "@mui/icons-material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import getConstant from "../../../../assets/js/Constant";
import endpoints from "../../../../assets/js/Endpoints";
import { useEffect, useRef, useState } from "react";
import HeaderProducts from "../../HeaderProducts";
import DeleteAnything from "../../DeleteAnything";
import Modal from "../../../containers/Modal";
import cr from "../../../../images/cr.svg";
import LoadingBar from "../../LoadingBar";
import AddCustomer from "./AddCustomer";
import Message from "../../Message";

/**
 * @name Customer
 * @description Método que retorna el módulo para la administración de clientes
 * @param {Object} { openAddCustomer, setCustomer: Para cargar los datos del nuevo cliente }
 * @returns View
 * @version 1.0
 */
const Customer = ({ openModal = false, setCustomer }) => {
  const css = styles();
  const constant = getConstant();
  const { lang, branch, setAllCustomers } = useThis();

  const nameFocus = useRef(null);
  const identificationFocus = useRef(null);

  const [row, setRow] = useState({});
  const [data, setData] = useState({});
  const [rows, setRows] = useState([]);
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [edit, setEdit] = useState(false);
  const [auxRows, setAuxRows] = useState([]);
  const [idToEdit, setIdToEdit] = useState("");
  const [loading, setLoading] = useState(false); // Para el modal
  const [snackLocal, setSnackLocal] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [loadingBar, setLoadingBar] = useState(false); // Para la tabla
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [showDeleteBtn, setShowDeleteBtn] = useState(true);
  const [openAddCustomer, setOpenAddCustomer] = useState(false);
  const [openModalToDelete, setOpenModalToDelete] = useState(false);

  /**
   * @name filter
   * @description Método que filtra los clientes de la tabla, por nombre
   * @param {Event} e
   * @version 1.0
   */
  const filter = (e) => {
    const customers = auxRows.reduce((acc, item) => {
      if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) acc.push(item);
      return acc;
    }, []);
    setRows(customers);
  };

  /**
   * @name addOrEditCustomer
   * @description Método para agregar o editar un cliente
   * @returns N/A
   * @version 1.0
   */
  const addOrEditCustomer = async () => {
    if (loading) return;

    // Se valida que no sea vacía la identificación del cliente
    if (!data.identification) {
      identificationFocus.current.focus();
      return setSnack([17, constant.error]);
    }
    // Se valida que no sea vacío el nombre del cliente
    if (!data.name) {
      nameFocus.current.focus();
      return setSnack([129, constant.error]);
    }
    // Se valida que la identificación no exista
    if (!edit && rows.find((row) => row.identification === data.identification)) {
      identificationFocus.current.focus();
      return setSnack([130, constant.error]);
    }

    setLoading(true);

    try {
      let docRef = null;
      const request = { ...data, date: serverTimestamp() };
      if (edit) {
        // Se actualiza el cliente
        await updateDoc(doc(db, endpoints.customer(branch.administrator, idToEdit)), request);
      } else {
        // Se agrega el documento del cliente
        docRef = await addDoc(collection(db, endpoints.customers(branch.administrator)), request);
      }
      const customer = { firstLetter: data.name[0].toUpperCase(), id: docRef.id, name: data.name.toUpperCase(), ide: data.identification };
      if (openModal) setCustomer((prev) => ({ ...prev, customer })); // Solamente si se accede desde el módulo de facturación
      setSnackLocal([edit ? 134 : 132, constant.success]);
      setOpenAddCustomer(false);
    } catch (error) {
      setSnack([edit ? 133 : 131, constant.error]);
    }
    setLoading(false);
  };

  /**
   * @name showCreateCategories
   * @description Método que se encarga de mostrar el modal para agregar o editar clientes
   * @version 1.0
   */
  const showCreateCustomer = () => {
    setEdit(false);
    /** Inicializa los datos */
    setData(resetData());
    /** Muestra el modal para crear o ediar clientes */
    setOpenAddCustomer(true);
  };

  /**
   * @name resetData
   * @description Método que inicializa los datos del cliente
   * @version 1.0
   */
  const resetData = () => {
    return {
      name: "",
      email: "",
      phone: "",
      status: true,
      otherAddress: "",
      identification: "",
      showAddress: false,
      canton: constant._CANTON,
      district: constant._DISTRICT,
      province: constant._PROVINCE,
      typeID: { id: 0, name: lang.physical, icon: cr, code: "CR", type: "01" },
    };
  };

  /**
   * @name actionRowDelete
   * @description Método que maneja el evento del botón eliminar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowDelete = (params) => {
    setRow(params.row);
    setOpenModalToDelete(true);
    setDeleteMessage(lang.deleteCustomerMessage.replace("[CUSTOMER]", params.row.name));
  };

  /**
   * @name actionRowsDelete
   * @description Muestra el modal para eliminar varias líneas
   * @version 1.0
   */
  const actionRowsDelete = () => {
    const message = selectedRows.length === 1 ? lang.deleteOneCustomerMessage : lang.deleteCustomersMessage.replace("[CUSTOMERS]", selectedRows.length);
    setOpenModalToDelete(true);
    setDeleteMessage(message);
  };

  /**
   * @name actionRowEdit
   * @description Método que maneja el evento del botón editar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowEdit = (params) => {
    setEdit(true);
    setData(params.row);
    setOpenAddCustomer(true);
    setIdToEdit(params.row.id);
  };

  /**
   * @name deleteCustomer
   * @description Método que elimina un cliente
   * @version 1.0
   */
  const deleteCustomer = async () => {
    if (loading) return;
    setLoading(true);

    try {
      await deleteDoc(doc(db, endpoints.customer(branch.administrator, row.id)));
      setSnackLocal([144, constant.success]);
      setOpenModalToDelete(false);
    } catch (error) {
      setSnack([143, constant.error]);
    }
    setLoading(false);
  };

  /**
   * @name deleteCustomers
   * @description Método para eliminar varias líneas
   * @version 1.0
   */
  const deleteCustomers = async () => {
    if (loading) return;
    setLoading(true);
    let hasErrors = false;
    for (let x = 0; x < rows.length; x++) {
      if (selectedRows.includes(rows[x].id)) {
        try {
          await deleteDoc(doc(db, endpoints.customer(branch.administrator, rows[x].id)));
        } catch (error) {
          hasErrors = true;
        }
      }
    }
    setLoading(false);
    if (hasErrors) return setSnack([79, constant.error]);
    setSnackLocal([145, constant.success]);
    setOpenModalToDelete(false);
  };

  /** Constante que define las columnas para la tabla */
  const columns = [
    { field: "name", headerName: lang.name, flex: 1 },
    { field: "identification", headerName: lang.identification, flex: 1 },
    { field: "email", headerName: lang.email, flex: 1 },
    { field: "phone", headerName: lang.phone, flex: 0 },
    { field: "action", headerName: lang.actions, flex: 0 },
  ];

  /** Constante que almacena los datos para los botones de acciones de cada fila */
  const actions = [
    { icon: <Edit />, callBack: actionRowEdit, color: constant.editColor },
    { icon: <DeleteForever />, callBack: actionRowDelete, color: constant.deleteColor },
  ];

  /**
   * @description Objeto para la creación del modal agregar o editar clientes
   */
  const parametersToAddCustomer = {
    btnText: edit ? lang.edit : lang.add,
    icon: edit ? <Edit /> : <PersonAddIcon />,
    title: edit ? lang.editCustomer : lang.addCustomer,
    content: (
      <AddCustomer
        data={data}
        edit={edit}
        setData={setData}
        setSnack={setSnack}
        nameFocus={nameFocus}
        setLoading={setLoading}
        identificationFocus={identificationFocus}
      />
    ),
  };

  /**
   * @description Objeto para la creación del modal eliminar o editar clientes
   */
  const parametersToDeleteCustomer = {
    btnText: lang.delete,
    icon: <DeleteForever />,
    title: lang.deleteCustomer,
    content: <DeleteAnything message={deleteMessage} />,
  };

  /** Efecto que maneja el estado de la selección de filas */
  useEffect(() => {
    setShowDeleteBtn(!selectedRows.length);
  }, [selectedRows]);

  /** Efecto que mantiene actualizada la tabla de clientes */
  useEffect(() => {
    setLoadingBar(true);
    const collectionCustomers = collection(db, endpoints.customers(branch.administrator));
    const queryCustomers = query(collectionCustomers, orderBy("name", "asc"));
    // Escucha los cambios en los datos de los clientes
    const unsubscribe = onSnapshot(queryCustomers, (snapshot) => {
      // Almacena las filas de cada cliente
      const customers = snapshot.docs.reduce((acc, doc) => {
        acc.push({ ...doc.data(), id: doc.id, date: formatDate(doc.data().date, lang) });
        return acc;
      }, []);
      setRows(customers);
      setLoadingBar(false);
      setAuxRows(customers);
      setAllCustomers(customers);
    });
    return () => unsubscribe();
  }, []);

  /** Efecto que valida si abrir el modal de agregar cliente al cargar la ventana */
  useEffect(() => {
    if (openModal) showCreateCustomer();
  }, []);

  return (
    <div style={css.box}>
      <HeaderProducts
        icons={4}
        actionSearch={filter}
        toolTip={lang.addCustomer}
        selectedRows={selectedRows}
        title={lang.manageCustomers}
        actionButton={showCreateCustomer}
        actionButtonDelete={actionRowsDelete}
      />
      {loadingBar && <LoadingBar visible={loadingBar} />}
      <div style={css.containerGrid}>
        <GenericGridType2 rowHeight={40} columns={columns} rows={rows} actions={actions} setSelectedRows={setSelectedRows} showDeleteBtn={showDeleteBtn} />
      </div>
      {/** Modal para agregar un cliente */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openAddCustomer}
        setOpen={setOpenAddCustomer}
        clickBtnOk={addOrEditCustomer}
        icon={parametersToAddCustomer.icon}
        title={parametersToAddCustomer.title}
        btnText={parametersToAddCustomer.btnText}
        content={parametersToAddCustomer.content}
      />
      {/** Modal para eliminar un cliente */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openModalToDelete}
        colorBar={constant.error}
        color={constant.deleteColor}
        setOpen={setOpenModalToDelete}
        icon={parametersToDeleteCustomer.icon}
        title={parametersToDeleteCustomer.title}
        btnText={parametersToDeleteCustomer.btnText}
        content={parametersToDeleteCustomer.content}
        clickBtnOk={selectedRows.length ? deleteCustomers : deleteCustomer}
      />
      <Message snack={snackLocal}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    containerGrid: { flex: "1", overflowY: "auto" },
    box: { display: "flex", flexDirection: "column", width: "100%", height: "100%" },
  };
};

export default Customer;
