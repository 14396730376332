import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import defalut from "../../../../images/default.svg";
import { useState } from "react";

/**
 * @name MenuItem
 * @description Método que retorna un componente ítem para el menú
 * @param {Object} { size, item, text, onClick = () => {}, className = "" }
 * @returns View
 * @version 1.0
 */
const MenuItem = ({ size, item, text, onClick = () => {}, className = "" }) => {
  const [isHovered, setIsHovered] = useState(false);
  const css = styles(size * 1, item.status, isHovered);

  return (
    <div
      style={css.item}
      className={className}
      onClick={item.status ? onClick : null}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={item.url || defalut} alt={text} style={css.img} />
      <p style={css.text}>{text}</p>
      <div style={css.notAllowed}>
        <DoNotDisturbIcon sx={css.icon} />
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Number} size
 * @param {Boolean} status
 * @param {Boolean} isHovered
 * @returns {Object}
 * @version 1.0
 */
const styles = (size, status, isHovered) => {
  return {
    icon: { fontSize: size / 2.5 + "px", color: "#EEE" },
    img: { objectFit: "cover", borderRadius: "4px", width: "100%", height: "100%", transition: "transform 0.3s ease" },
    notAllowed: {
      top: "0px",
      width: size + "px",
      height: size + "px",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#00000095",
      display: status ? "none" : "flex",
    },
    item: {
      margin: "5px",
      overflow: "hidden",
      width: size + "px",
      borderRadius: "4px",
      height: size + "px",
      position: "relative",
      backgroundColor: "#ebebeb",
      cursor: isHovered ? (status ? "pointer" : "not-allowed") : "default",
    },
    text: {
      color: "#fff",
      bottom: "0px",
      padding: "5px",
      fontSize: "0.8rem",
      overflow: "hidden",
      position: "absolute",
      whiteSpace: "normal",
      wordBreak: "break-word",
      width: size - 10 + "px",
      maxHeight: size - 20 + "px",
      borderRadius: "0 0 4px 4px",
      backgroundColor: "#00000080",
      textShadow: "1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000, 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000",
    },
  };
};

export default MenuItem;
