import { darkenColor, formatDate, formatNumber, getDaysAndHoursText } from "../../assets/js/Commons";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { getAllSubscriptions } from "../../assets/js/firebaseMethods";
import { Divider, Fab, Tooltip, Zoom } from "@mui/material";
import ModalFullScreen from "../containers/ModalFullScreen";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import GenericGridType1 from "./grids/GenericGridType1";
import { useThis } from "../../assets/context/Context";
import { AddShoppingCart } from "@mui/icons-material";
import getConstant from "../../assets/js/Constant";
import { useEffect, useState } from "react";
import LoadingBar from "./LoadingBar";
import RenewPlan from "./RenewPlan";
import Message from "./Message";

/**
 * @name Subscriptions
 * @description Método que devuelve un modal para las suscripciones
 * @param {Object} { consecutiveBranch: String, administrator: String, branchId: String }
 * @returns View
 */
const Subscriptions = ({ consecutiveBranch, administrator, branchId }) => {
  const constant = getConstant();
  const css = styles(constant.primaryColor);
  const { lang, isMobile, message, config, dataBranch, setDataRenew } = useThis();

  // Estructura del grid de suscripciones
  const subColumns = [lang.subscription, lang.ammount, lang.paymentMethod, lang.plan, lang.term];
  const columns = [<ShoppingCartCheckoutIcon />, lang.subscription, lang.initialPlan, lang.planExpiration, lang.remainingDays, lang.status];

  const [rows, setRows] = useState([]);
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [amount, setAmount] = useState(0);
  const [subRows, setSubRows] = useState([]);
  const [totalPay, setTotalPay] = useState(0);
  const [currency, setCurrency] = useState("");
  const [status, setStatus] = useState(constant.approved);
  const [showLoadingBar, setShowLoadingBar] = useState(false);
  const [openModalRenew, setOpenModalRenew] = useState(false);
  const [remainingSubscription, setRemainingSubscription] = useState("Calculando...");

  /**
   * @name showModalRenew
   * @description Método encargado de mostrar el modal de renovación
   * @version 1.0
   */
  const showModalRenew = () => {
    // Se inicializan los datos del modal para renovación
    setDataRenew(constant.renew);
    // Valida que haya cargado el JSONConfig
    if (config && config.error) return setSnack([config.errorCode, constant.error]);
    // Valida que no hayan suscripciones pendientes de aprobar
    if (status === constant.approved) {
      setOpenModalRenew(true);
    } else {
      setSnack([40, constant.error]);
    }
  };

  /**
   * @name loadSubscriptionGrid
   * @description Método que carga la información de filas y columnas en la tabla y pinta los datos del footer
   * @param {Object} result
   * @version 1.0
   */
  const loadSubscriptionGrid = async (result) => {
    // Obtiene el total pagado de todas las suscripciones y carga los datos de las filas y subfilas para el grid
    const data = result.subscriptions.docs.reduce(
      (acc, doc) => {
        // Se obtiene el estado de la última suscripción
        setStatus(doc.data().status);
        // Valida que aún no haya pasado la fecha de expiración y calcula los días y horas restantes
        let remainingDays = lang.subscriptionTimeRemainingAt0;
        let status = lang[doc.data().status];
        if (doc.data().expirationDate.toDate().getTime() > new Date().getTime()) {
          const daysAndHours = getDaysAndHoursText(doc.data().expirationDate, lang);
          remainingDays = daysAndHours;
          setRemainingSubscription(daysAndHours);
        } else {
          status = lang.expired;
          setRemainingSubscription(lang.subscriptionTimeRemainingAt0);
        }
        // Establece el plazo en días o meses
        const d = doc.data().days;
        const term = d < 30 ? (d === 1 ? 1 + " " + lang.day : d + " " + lang.days) : d / 30 === 1 ? 1 + " " + lang.month : d / 30 + " " + lang.months;
        // Establece el tipo de pago
        let paymentMethod = { description: constant.error };
        if (config && !config.error) {
          const paymentMethods = config.contries[dataBranch.country.code].paymentMethod;
          paymentMethod = paymentMethods.find((element) => element.code === doc.data().paymentMethod);
        }
        if (!paymentMethod) paymentMethod = { description: "-" };
        // Se agregan los datos a las filas
        const subscriptionDate = formatDate(doc.data().subscriptionDate, lang);
        const price = doc.data().currency + formatNumber(doc.data().price);
        acc.rows.push([doc.id, formatDate(doc.data().initialDate, lang), formatDate(doc.data().expirationDate, lang), remainingDays, status]);
        acc.subRows.push([subscriptionDate, price, paymentMethod.description, doc.data().name, term]);
        // Se retorna el total pagado, las filas y subfilas para la tabla
        return { total: acc.total + doc.data().price, rows: acc.rows, subRows: acc.subRows };
      },
      { total: 0, rows: [], subRows: [] }
    );
    // Se agregan las filas a la tabla
    setRows(data.rows);
    setSubRows(data.subRows);
    // Se agrega el símbolo y el total
    if (result.subscriptions.docs.length > 0) setCurrency(result.subscriptions.docs[0].data().currency);
    setTotalPay(formatNumber(data.total));
    // Cantidad total de suscripciones
    setAmount(result.subscriptions.docs.length);
  };

  /**
   * @name callSubscriptions
   * @description Método encargado de llamar al servicio de obtener suscripciones
   * @returns N/A
   * @version 1.0
   */
  const callSubscriptions = async () => {
    setShowLoadingBar(true);
    const result = await getAllSubscriptions(administrator, branchId);
    setShowLoadingBar(false);
    if (result.error) {
      setRemainingSubscription(message[36]);
      return setSnack([result.errorCode, constant.error]);
    }
    loadSubscriptionGrid(result);
  };

  /** Efecto para consumir el servicio de suscripciones al cargar la página */
  useEffect(() => {
    // Cuando el modal de renovación se encuentra cerrado
    if (!openModalRenew) {
      callSubscriptions();
    }
  }, [openModalRenew]);

  /**
   * @description Objeto para la creación del modal fullScreen para solicitar suscripciones
   * @version 1.0
   */
  const renewParameters = {
    title: lang.renewYourPlan,
    icon: <AutoModeIcon />,
    content: <RenewPlan consecutiveBranch={consecutiveBranch} administrator={administrator} branchId={branchId} />,
  };

  return (
    <div style={css.container}>
      <LoadingBar visible={showLoadingBar} />
      <div style={css.containerAbove}>
        <GenericGridType1 columns={columns} subColumns={subColumns} subTitle={lang.subscriptionDetails} rows={rows} subRows={subRows} />
      </div>
      <Divider />
      {isMobile ? (
        <FooterForMobile
          css={css}
          lang={lang}
          amount={amount}
          currency={currency}
          totalPay={totalPay}
          onClick={showModalRenew}
          remainingSubscription={remainingSubscription}
        />
      ) : (
        <FooterForweb
          css={css}
          lang={lang}
          amount={amount}
          currency={currency}
          totalPay={totalPay}
          onClick={showModalRenew}
          remainingSubscription={remainingSubscription}
        />
      )}
      <ModalFullScreen
        show={openModalRenew}
        icon={renewParameters.icon}
        setShow={setOpenModalRenew}
        title={renewParameters.title}
        content={renewParameters.content}
      />
      <Message snack={snack}></Message>
    </div>
  );
};

/**
 * @name FooterForweb
 * @description Método que devuelve un componente footer para web
 * @param {Object} { css, lang, amount, totalPay, remainingSubscription }
 * @returns View
 * @version 1.0
 */
const FooterForweb = ({ css, lang, amount, totalPay, remainingSubscription, onClick, currency }) => {
  return (
    <footer style={css.footer}>
      <div style={css.footerLeft}>
        <p style={css.p}>
          {lang.totalOrders}
          <span style={css.span}>{amount}</span>
        </p>
        <p style={css.p}>
          {lang.totalAmount}
          <span style={css.span}>{currency + totalPay}</span>
        </p>
      </div>
      <div style={css.footerRight}>
        <p style={css.p}>
          {lang.remainingSubscription}
          <span style={css.span}>{remainingSubscription}</span>
        </p>
        <Tooltip TransitionComponent={Zoom} title={lang.expandSubscription}>
          <Fab sx={css.fab} size="small" onClick={() => onClick()}>
            <AddShoppingCart />
          </Fab>
        </Tooltip>
      </div>
    </footer>
  );
};

/**
 * @name FooterForMobile
 * @description Método que devuelve un componente footer para móvil
 * @param {Object} { css, lang, amount, totalPay, remainingSubscription }
 * @returns View
 * @version 1.0
 */
const FooterForMobile = ({ css, lang, amount, totalPay, remainingSubscription, onClick, currency }) => {
  return (
    <footer style={css.footer}>
      <div style={css.footerLeft}>
        <p style={css.p}>
          {lang.totalOrders}
          <span style={css.span}>{amount}</span>
        </p>
        <p style={css.p}>
          {lang.totalAmount}
          <span style={css.span}>{currency + totalPay}</span>
        </p>
        <p style={css.p}>
          {lang.remainingSubscription}
          <span style={css.span}>{remainingSubscription}</span>
        </p>
      </div>
      <div style={css.footerRight}>
        <Tooltip TransitionComponent={Zoom} title={lang.expandSubscription}>
          <Fab sx={css.fab} onClick={() => onClick()}>
            <AddShoppingCart />
          </Fab>
        </Tooltip>
      </div>
    </footer>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} primaryColor
 * @returns Object
 * @version 1.0
 */
const styles = (primaryColor) => {
  return {
    span: { fontWeight: "500" },
    p: { display: "inline-block" },
    progress: { color: primaryColor },
    footerRight: { paddingRight: "15px" },
    containerAbove: { flex: "1", overflowY: "auto" },
    footerLeft: { display: "flex", flexDirection: "column", paddingLeft: "15px" },
    container: { display: "flex", flexDirection: "column", width: "100%", height: "100%" },
    footer: { width: "100%", color: "#444", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 0" },
    fab: { marginLeft: "15px", background: primaryColor, "&:hover": { backgroundColor: darkenColor(primaryColor, 20) }, color: "#FFF" },
  };
};

export default Subscriptions;
