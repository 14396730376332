import { useThis } from "../../../../assets/context/Context";
import express from "../../../../images/express.svg";
import { Slider } from "@mui/material";
import MenuItem from "./MenuItem";

/**
 * @name ZoomMenu
 * @description Muestra el modal para cambiar de tamaño a los ítems de categoría y menú
 * @param {Object} { size: useState, setSize: useState }
 * @returns View
 * @version 1.0
 */
const ZoomMenu = ({ size, setSize }) => {
  const css = styles();
  const { lang } = useThis();

  return (
    <div style={css.box}>
      <div style={css.container}>
        <MenuItem size={size} item={{ url: express, status: true }} text={lang.express} className="image-container" />
      </div>
      <Slider min={80} max={200} value={size * 1} aria-label="Default" valueLabelDisplay="auto" onChange={(e) => setSize(e.target.value)} />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    box: { display: "flex", flexDirection: "column" },
    container: {
      width: "300px",
      display: "flex",
      height: "250px",
      alignItems: "center",
      marginBottom: "10px",
      border: "1px solid #CCC",
      justifyContent: "center",
    },
  };
};

export default ZoomMenu;
