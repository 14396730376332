import { Button, IconButton, Slide, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Fragment } from "react";

/**
 * @name SlideTransition
 * @description Método que maneja la animación del snack
 * @param {Object} props
 * @returns {Component}
 * @version 1.0
 */
const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

/**
 * @name MessageInput
 * @description Método que devuelve un snack con botón y botón de cerrar
 * @param {Object} { open, setOpen, message, onClickButton, textButton, vertical }
 * @returns View
 * @version 1.0
 */
const MessageInput = ({ open, setOpen, message, onClickButton, textButton, vertical }) => {
  const css = styles(vertical);

  /**
   * @namehandleClose
   * @description Método que maneja el evento de cierre del snack
   * @param {Event} _
   * @param {String} reason
   * @returns N/A
   * @version 1.0
   */
  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      message={message}
      sx={css.container}
      onClose={handleClose}
      anchorOrigin={css.snack}
      TransitionComponent={SlideTransition}
      action={
        <Fragment>
          <Button sx={css.button} size="small" onClick={onClickButton}>
            {textButton}
          </Button>
          <IconButton color="inherit" sx={css.close} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Fragment>
      }
    />
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} vertical
 * @returns Object
 * @version 1.0
 */
const styles = (vertical) => {
  return {
    container: { zIndex: 1 },
    button: { color: "#E3D135", background: "#222" },
    close: { p: 0.5, width: "auto", marginLeft: "5px" },
    snack: { vertical, horizontal: "center", zIndex: 1 },
  };
};

export default MessageInput;
