import { reload } from "firebase/auth";

/**
 * @name lang_es
 * @description Método que devuelve los textos en español
 * @returns {Object} Objeto con los textos en español
 */
const lang_es = () => {
  return {
    language: "es-ES",
    or: "Ó",
    back: "Atrás",
    next: "Siguiente",
    lang: "lang-es",
    footer: "© 2024 Broshetta",
    email: "Correo electrónico",
    continue: "Continuar",
    login: "Iniciar sesión",
    password: "Contraseña",
    btnGoogle: "Continuar con Google",
    btnMicrosoft: "Continuar con Microsoft",
    remember: "Recordarme",
    forgotText: "¿Olvidó la contraseña?",
    haveAccount: "¿No tienes una cuenta?",
    register: "Regístrate ahora",
    requestPassword: "Solicitar cambio de contraseña",
    changePassword: "Cambiar contraseña",
    user: "Usuario",
    credentials: "Credenciales",
    verification: "Verificación",
    userName: "Nombre de usuario",
    registerTitle: "Registro",
    createUser: "Creación de usuario",
    createUserText: "El siguiente nombre de usuario será utilizado en la administración de todo el sistema y se mostrará en los comprobantes.",
    condition: "*Al registrarse está aceptando nuestro contrato de términos y condiciones.",
    registerCredentials: "Registro de credenciales",
    emailAndPassword: "Ingrese su correo electrónico y contraseña.",
    verify: "Verificación del correo electrónico",
    registerSuccess: "El registro fue exitoso!",
    verifyText1: "Ahora verifique el enlace que se envió al correo ",
    verifyText2: " para poder iniciar la sesión.",
    wait: "Enviando correo...",
    sent: "El correo se envió correctamente!",
    errorSent: "Error al enviar correo!",
    resend: "Reenviar correo",
    verified: "El correo ha sido verificado",
    signOff: "Cerrar sesión",
    branch: "Sucursal",
    lastLogin: "Último ingreso:",
    exit: "Salir",
    administrator: "Administrador",
    collaborator: "Colaborador",
    administratorText: "Al ingresar al sistema como Administrador, prodrá gestionar su propio restaurante, como también agregar colaboradores al mismo.",
    collaboratorText: "Para poder ingresar al sistema como Colaborador, debe contar con los permisos otorgados por un administrador.",
    selectEntryText: "Seleccione el tipo de entrada al sistema",
    createBranch: "Crear sucursal",
    hi: "HOLA",
    sorry: "¡Lo sentimos!",
    initMessage: {
      0: "Primero crea una sucursal...",
      1: "Aún no estás vinculado...",
      2: "Error al cargar permisos...",
      3: "Error cargando sucursales...",
    },
    rotate: "Por favor, gire su dispositivo.",
    close: "Cerrar ventana",
    add: "Agregar",
    identification: "Identificación",
    country: "País",
    other: "Otro",
    cr: "Costa Rica",
    electronicBill: "Facturación electrónica:",
    yes: "Sí",
    no: "No",
    generalData: "Datos generales",
    disabledCountry: "Deshabilitado para este país",
    branchName: "*Nombre",
    phone: "Teléfono",
    uploadImage: "Cargar imagen",
    changeImage: "Cambiar imagen",
    requiredFields: "*Campos requeridos",
    selectLogo: "Seleccione el logo:",
    typeID: "Tipo de identificación",
    tradename: "Nombre comercial",
    selectAddress: "Seleccione la dirección:",
    selectProvince: "Seleccione una provincia",
    selectCanton: "Seleccione un cantón",
    selectDistrict: "Seleccione un distrito",
    physical: "Física nacionales",
    legal: "Jurídica nacionales",
    dimex: "Dimex",
    nite: "Nite",
    activityCode: "Código de actividad",
    otherAddress: "Otras señas",
    idePlusEnter: "*Identificación + enter",
    branchNameForEB: "*Nombre / Razón social",
    fullScreen: "Pantalla completa",
    go: "Ir",
    options: "Opciones",
    created: "Creada",
    expiration: "Vencimiento",
    address: "Dirección",
    noAddress: "Sin dirección!",
    manageUsers: "Administrar usuarios",
    subscriptions: "Suscripciones",
    editBranch: "Editar sucursal",
    deleteBranch: "Eliminar sucursal",
    delete: "Eliminar",
    codeBranchMessage: "Digite el código de la sucursal [CODE], un caracter por cada campo.",
    deleteBranchMessage: "Para eliminar esta sucursal debe ingresar el código de la misma: ",
    deleteBranchWarning: "Advertencia! Esta acción es irreversible, y se eliminarán todos los datos vinculados a esta sucursal.",
    totalOrders: "Total de órdenes: ",
    totalAmount: "Total de pagos: ",
    remainingSubscription: "Suscripción: ",
    expandSubscription: "Ampliar subscripción",
    subscriptionTimeRemainingAt0: "0 días y 0 horas",
    daysAnd: " días y ",
    dayAnd: " día y ",
    hours: " horas",
    hour: " hora",
    subscription: "Suscripción",
    initialPlan: "Inicio del plan",
    planExpiration: "Expiración del plan",
    remainingDays: "Días restantes",
    status: "Estado",
    ammount: "Monto",
    paymentMethod: "Método de pago",
    plan: "Plan",
    term: "Plazo",
    subscriptionDetails: "Detalle de la suscripción",
    day: "día",
    days: "días",
    month: "mes",
    months: "meses",
    year: "año",
    years: "años",
    renewYourPlan: "Renueva tu plan",
    planType: "Tipo",
    planTerm: "Plazo",
    summary: "Resumen",
    methodPay: "Pago",
    docType1:
      "El plan de Comprobantes electrónicos, es fundamental para todos aquellos restaurantes a los que el país les ha establecido requisitos legales y fiscales, como lo es la emisión de facturas electrónicas y/o tiquetes electrónicos.",
    docType2:
      "Este plan es ideal para todos aquellos restaurantes en los que NO es obligatorio la emisión de documentos electrónicos, como lo son facturas electrónicas y/o tiquetes electrónicos. Generando solamente un recibo de caja.",
    physicalDocuments: "Comprobantes físicos",
    electronicDocuments: "Comprobantes electrónicos",
    selectPlan: "Seleccione el tipo de plan:",
    description: "Descripción",
    messageTerm: "El plazo inicia desde la fecha de expiración del último plan registrado, hasta los [DAYS] días después de este plan.",
    priceIVI: "Precio (I.V.I):",
    termPeriod: "Periodo del plazo: ",
    selectTerm: "Seleccione el plazo:",
    typePlanSelected: "(Tipo de plan seleccionado)",
    planSelected: "(Plan seleccionado)",
    note: "Nota:",
    renewSummary:
      "Recuerde que el plan inicia desde la fecha de expiración del último plan registrado. Por otro lado, al continuar (con la flecha siguiente), está aceptando los datos presentados en este apartado.",
    sinpe: "Sinpe móvil",
    transfer: "Transferencia",
    transfers: "Transferencias",
    card: "Tarjeta",
    cards: "Tarjetas",
    attachDoc: "Comprobante",
    generateSub: "Generar suscripción",
    sinpeText: "Es necesario adjuntar el comprobante de pago.",
    confirmDoc: "Una vez confirmemos la transacción, se aprobará esta suscripción.",
    sinpeNote: "Nota: Al presionar el botón GENERAR SUSCRIPCIÓN, recibiremos una notificación con los datos de su solicitud.",
    selectPaymentMethod: "Seleccione el método de pago:",
    alert: "Aviso",
    paymentMethods: {
      SM: "Sinpe Móvil",
      TR: "Transferencia",
      CA: "Tarjeta",
      PP: "Paypal",
    },
    currencies: {
      CRC: "Colones",
      USD: "Dólares",
    },
    notAvailable: "Aún no se encuentra disponible!",
    approved: "Activo",
    pending: "Pendiente",
    expired: "Expirado",
    searchDotDot: "Buscar…",
    addUser: "Agregar usuario",
    name: "Nombre",
    actions: "Acciones",
    personalInfo: "Información personal:",
    systemPermissions: "Permisos del sistema:",
    permissions: "Permisos",
    watch: "Ver",
    edit: "Editar",
    customerModule: "Módulo de clientes",
    editUser: "Editar usuario",
    deleteUser: "Eliminar usuario",
    emailUserMessage: "Digite el correo del usuario [EMAIL]",
    deleteUserMessage: "Para eliminar este usuario debe ingresar el correo del mismo: ",
    deleteGenericWarning: "Advertencia! Esta acción es irreversible.",
    deleteUsers: "Eliminar usuarios",
    remove: "Quitar",
    tables: "Mesas",
    products: "Productos",
    command: "Comanda",
    customers: "Clientes",
    vouchers: "Comprobantes",
    reports: "Reportes",
    statistics: "Estadísticas",
    closeBox: "Cierre de caja",
    downloads: "Descargas",
    administration: "Administración",
    home: "Home",
    minimizeMenu: "Minimizar menú",
    menu: "Menú",
    addService: "Agregar espacio",
    zoom: "Zoom",
    newService: "Nuevo espacio",
    table: "Mesa",
    express: "Express",
    carry: "Llevar",
    save: "Guardar",
    size: "Tamaño",
    cancel: "Cancelar",
    deleteMessage: "Desea eliminar el espacio [SPACE]?",
    prodAndServ: "Prod. & Serv.",
    categories: "Categorías",
    category: "Categoría",
    manageCategories: "Administrar categorías",
    manageProdAndServ: "Administrar productos y servicios",
    manageMenu: "Administrar menú",
    manageExpress: "Administrar express",
    addCategory: "Agregar categoría",
    addProdAndServ: "Agregar producto o servicio",
    addMenu: "Agregar menú",
    addExpress: "Agregar express",
    edition: "Edición",
    image: "Imagen",
    basePrice: "Precio base",
    utility: "Utilidad",
    tax: "Impuesto",
    finalPrice: "Precio final",
    price: "Precio",
    newCategory: "Nueva categoría",
    editCategory: "Editar categoría",
    withImage: "Con imagen",
    noImage: "Sin imagen",
    deleteCategoryMessage: "Eliminará la categoría <b>[CATEGORY]</b>.",
    deleteCategory: "Eliminar categoría",
    deleteAll: "Eliminar selección",
    deleteOneCategoryMessage: "Se eliminará <b>1</b> categoría.",
    deleteCategoriesMessage: "Se eliminarán <b>[CATEGORIES]</b> categorías.",
    amount: "Cantidad",
    newItem: "Nuevo Ítem",
    editItem: "Editar Ítem",
    increase: "Incrementar",
    stock: "Stock",
    productOrServ: "Producto o servicio",
    date: "Fecha",
    action: "Acción",
    reason: "Motivo",
    historic: "Histórico",
    deleteRow: "Eliminar fila",
    editRow: "Editar fila",
    increasesOrDecreases: "Aumentar o disminuir",
    deleteProdcutMessage: "Eliminará el ítem <b>[ITEM]</b>.",
    deleteProductsMessage: "Se eliminarán <b>[ITEMS]</b> ítems.",
    deleteItems: "Eliminar ítems",
    manageInventory: "Gestionar inventario",
    item: "Ítem",
    increases: "Incrementar",
    decreases: "Disminuir",
    adjustment: "Ajuste",
    loss: "Pérdida",
    obs: "Observaciones",
    at: " a las ",
    submenu: "Submenú Administrativo",
    selectOption: "Seleccione una opción",
    users: "Usuarios",
    company: "Empresa",
    atv: "ATV",
    system: "Sistema",
    print: "Impresión",
    others: "Otros",
    version: "BROSHETTA-WEB versión ",
    configOthersInfo: "Configurar más datos",
    configPrintInfo: "Configurar parámetros de impresión",
    configSystemInfo: "Configurar parámetros del sistema",
    selectCurrency: "Seleccione la moneda de su preferencia.",
    defaultCurrency: "Moneda única establecida por defecto",
    currency: "Moneda",
    configSystemTitle: "Configuración del Sistema",
    configOthersTitle: "Configuración de otros parámetros",
    exchangeRate: "Tipo de cambio del dólar Estadounidense",
    enterExchangeRate: "Digite el tipo de cambio:",
    currentExchangeRate: "Precio actual del dólar en el sistema",
    messageExchangeRate:
      "<b>Referencia: </b>Según el Banco Central de Costa Rica y el Ministerio de Hacienda, la compra del dólar para el día de hoy, se encuentra en ₡<b>[BUY]</b> y la venta en ₡<b>[SELL]</b>",
    titleTax: "Impuestos y cargos específicos",
    enableTax: "Habilitar o deshabilitar impuestos:",
    taxMessage1: "Seleccione los impuestos necesarios para mostrarlos en los distintos selectores de los módulos del sistema.",
    taxMessage2: "<b>Nota: </b>Si habilita o deshabilita impuestos debe volver a seleccionar el impuesto al servicio de restaurante.",
    taxTypes: "Tipos de impuestos:",
    taxTypesText: "Tipos de impuestos necesarios para agregar los distintos productos en el sistema.",
    taxServiceRestaurant: "Servicio de restaurante e impuesto:",
    restaurantService: "Servicio de restaurante",
    selectTax: "Seleccione un impuesto",
    selectTaxType: "Elija un tipo de impuesto",
    place: "Lugar",
    editExpress: "Editar express",
    deleteExpressMessage: "Eliminará el express <b>[EXPRESS]</b>.",
    deleteOneExpressMessage: "Se eliminará <b>1</b> express.",
    deleteMoreExpressMessage: "Se eliminarán <b>[EXPRESS]</b> express.",
    taxType: "Tipo de impuesto",
    restaurantTax: "Impuesto al servicio de restaurante",
    finalPriceCalculation: "Cálculo del precio final",
    finalPriceCalculationText: "Seleccione la fórmula con la cual el sistema calculará el precio final de cada producto establecido.",
    compound: "Compuesto",
    simple: "Simple",
    simpleText: "Precio de venta = costo + (costo * utilidad)",
    compoundText: "Precio de venta = costo / (1 - utilidad)",
    simpleExample: "[SYMBOL]1,800.00 = [SYMBOL]1,500.00 + ([SYMBOL]1,500.00 * 20%)",
    compoundExample: "[SYMBOL]1,875.00 = [SYMBOL]1,500.00 / (1 - 20%)",
    formula: "Fórmula: ",
    example: "Ejemplo: ",
    priceType: "Tipo de precio: ",
    deleteExpress: "Eliminar express",
    deleteOneMenuMessage: "Se eliminará <b>1</b> menú.",
    deleteMenuMessage: "Eliminará el menú <b>[MENU]</b>.",
    deleteMoreMenuMessage: "Se eliminarán <b>[MENU]</b> menús.",
    editMenu: "Editar menú",
    deleteMenu: "Eliminar menú",
    selectCategory: "Seleccione la categoría",
    utilityPercentage: "Utilidad o ganancia",
    requestKitchen: "Solicitar a cocina:",
    optionalImage: "Imagen (opcional)",
    requestKitchenInfo: "Si la opción está en Sí este menú aparecerá como una solicitud en la comanda, de lo contrario no llegará a cocina.",
    linkInventory: "Vincular inventario (opcional)",
    linkInventoryInfo:
      "Cuando agrega una cantidad de productos o servicios a un menú, se rebajarán del inventario (Prod. & Serv.) una vez se facturen los pedidos.",
    prodOrServ: "Prod. o Serv.",
    noProductsLabel: "Sin productos o servicios",
    itemsToMenu: "Se agregarán al menú, únicamente los ítems con cantidades superiores a 0.",
    noProdOrServ: "No posee productos ni servicios",
    noProdOrServInfo: "Para vincular un producto y/o servicio a este menú, debe ser agregado desde el módulo Prod. & Serv.",
    active: "Activo",
    inactive: "Inactivo",
    inactiveText: "En estado inactivo aparecerá en el menú pero no se podrán realizar pedidos.",
    electronicBillMessage: "Para utilizar el plan de facturación electrónica, es necesario realizar una subscripción luego de crear la sucursal.",
    warningPrice: "El precio ha cambiado!",
    showCategories: "Mostrar el panel de categorías",
    hiddenCategories: "Ocultar el panel de categorías",
    showAllMenu: "Mostrar todo el menú",
    newOrder: "Nuevo pedido",
    addOrder: "Agregar pedido",
    inventoryMessage: "Si desea tener un control del inventario, puede agregar cada producto y luego vincularlo cuando agregue un menú.",
    inventoryMessageTip: "Se rebajará del inventario cuando se facture un pedido que contenga este producto.",
    addDetail: "Agregar detalle",
    subtotal: "Subtotal",
    service: "Servicio",
    total: "Total",
    previewTotal: "Este monto es simplemente una vista previa del total con todos o algunos cargos.",
    taxes: "Impuestos",
    expressCost: "<b>Costo de envío</b>: [COST]",
    addExpressMessage: "Desea agregar el express con la siguiente ubicación:",
    deleteExpressInfo: "Desea eliminar el express con la siguiente ubicación:",
    bill: "Facturar",
    noOrders: "Sin pedidos",
    order: "Orden",
    selectedOrders: "Selección de pedidos",
    cash: "Contado",
    credit: "Crédito",
    credits: "Créditos",
    selectedRequest: "Pedidos seleccionados",
    splitAccount: "Dividir cuenta",
    splitAccountMessage: "Divida la cuenta de los pedidos seleccionados",
    printAccount: "Imprimir cuenta",
    editOrder: "Modificar pedido",
    orderAt: "Pedido el ",
    deleteOrder: "Eliminar pedido",
    deleteOrders: "Eliminar pedidos",
    deleteOrdersMessage: "Está seguro de elimimar <b>[AMOUNT]</b>",
    deleteOrdersMessageA: " pedidos?",
    deleteOrdersMessageB: " pedido?",
    deleteSelectedOrders: "Eliminar pedidos seleccionados",
    persons: "Personas",
    moveOrder: "Trasladar pedidos",
    selectSpace: "Seleccione un espacio",
    orderOf: "Orden de ",
    sendOrders: "Enviar pedidos",
    bringOrders: "Traer pedidos",
    pendingOrders: "Tiene órdenes pendientes de enviar a cocina!",
    send: "Enviar",
    ribEye: "Rib eye",
    ribEyeDescription: "Con papas y ensalada.",
    noDescription: "Sin descripción.",
    withdraw: "Retirar",
    withdrawOrder: "Retirar orden",
    withdrawOrderMessage: "Desea retirar la orden <b>[ORDER]</b>?",
    deleteCustomerMessage: "Eliminará el cliente <b>[CUSTOMER]</b>.",
    deleteCustomer: "Eliminar cliente",
    deleteOneCustomerMessage: "Se eliminará <b>1</b> cliente.",
    deleteCustomersMessage: "Se eliminarán <b>[CUSTOMERS]</b> clientes.",
    addCustomer: "Agregar cliente",
    editCustomer: "Editar cliente",
    manageCustomers: "Administrar clientes",
    withdrawn: "Retirado",
    hiddenWithdrawn: "Ocular pedidos retirados",
    showWithdrawn: "Mostrar pedidos retirados",
    inactiveCustomerMessage: "En estado inactivo no se le podrán facturar pedidos",
    commandType: "Tipo de comanda",
    forSpace: "Por espacio",
    forOrder: "Por pedido",
    config: "Configuración",
    init: "Inicio",
    closeSystem: "Cerrar sistema",
    printersSelection: "Selección de impresoras",
    printerSelection: "Seleccione una impresora",
    printedPointSale: "Impresora punto de venta",
    reportPrinter: "Impresora para reportes",
    urlServer: "URL del servidor local",
    ipPort: "http + ip + puerto",
    reloadPrinters: "Recargar impresoras",
    createBranchMessage: "La siguiente información, serán datos del emisor que se mostrarán en los comprobantes (vouchers)",
    testVoucher: "Prueba impresión de comprobantes",
    testReport: "Prueba impresión de reportes",
    addDirection: "Agregar dirección",
    cashBilling: "Facturación de contado",
    creditBilling: "Facturación de crédito",
    sale: "Venta",
    courtesy: "Cortesía",
    invoice: "Factura",
    ticket: "Tiquete",
    accountDetail: "Detalle de la cuenta",
    selectCustomer: "Seleccione un cliente",
    paymentType: "Tipo de pago",
    checkbook: "Cheque",
    checkbooks: "Cheques",
    onlySinpe: "Sinpe",
    numberDoc: "Documento",
    cashDollar: "En dólares",
    cashColon: "En colones",
    kash: "Efectivo",
    type: "Tipo",
    doc: "Documento",
    totalDoc: "Total en documentos",
    changeMessage: "El vuelto en rojo indica el monto que falta por cobrar al cliente.",
    change: "Cambio",
    undefined: "Sin definir",
    noDocs: "Sin documentos",
    cashCustomer: "Cliente de contado",
    change2: "Vuelto",
    detailedAccount: "Cuenta detallada",
    validatePayment: "Validar pago",
    collect: "Cobrar",
    withdrawOrders: "Retirar de la comanda",
    income: "Ingresos",
    diff: "Diferencia",
    movementsDay: "Movimientos del día",
    openingAndClosing: "Apertura y cierre",
    boxOpening: "Apertura de caja",
    openingCash: "Efectivo de apertura",
    inCoins: "En monedas",
    inBills: "En billetes",
    totals: "Totales",
    openBox: "Abrir caja",
    changeTo: "Cambio a",
    closeBoxMessage: "Se cerrará la caja con el siguiente saldo:",
    openBoxMessage: "Se abrirá la caja con el siguiente monto:",
    box: "Caja",
    expenses: "Egresos",
    toCloseBox: "Cerrar caja",
    items: "Rubros",
    count: "Arqueo",
    cabysCode: "Código CABYS",
    cabysMessage: "Por ejemplo para un código de Impuesto de servicio es: 2399999009900",
  };
};

export default lang_es;
