/**
 * @name getLocalStorageCodes
 * @description Método que devuelve los códigos para almacenamiento en el localStorage,
 * con el objetivo de verificar cuales exisen en caso de crear uno nuevo
 * @returns {Object}
 * @version 1.0
 */
const getLocalStorageCodes = () => {
  return {
    lang: "lang", // Código para el almacenamiento del lenguaje
    menuSize: "MS", // Código para el tamaño de los ítems de categorías y menú
    spaceSize: "SS", // Código para el tamaño de los servicios (mesa, llevar, express)
    commandSize: "CS", // Código para el tamaño de los ítems de la comanda
    commandType: "CT", // Código para almacenar el tipo de comanda
    spacePosition: "SP", // Código para las coordenadas de los servicios (mesa, llevar, express)
    verticalDivider: "VD", // Código para almacenar el ancho del panel izquierdo del modal de órdenes
    leftHorizontalDivider: "LHD", // Código para almacenar la altura del panel top izquierdo del modal de órdenes
    rightHorizontalDivider: "RHD", // Código para almacenar la altura del panel top derecho del modal de órdenes
    commandHorizontalDivider: "CHD", // Código para almacenar la altura del panel top de la commanda
  };
};

export default getLocalStorageCodes;
